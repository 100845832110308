import React from "react";
import Benching_Area from "../../src/img/pattern/Pilot_Area.jpg";

import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { Link } from "react-router-dom";
import { Card } from "antd";
const PilotAreaPage = () => {
  return (
    <Page>
      <Navbar />

      {/* Page Content */}
      <div className="page-content woocommerce">
        <div className="container clear-fix">
          <div class="grid-col-row">
            <div class="grid-col grid-col-12">
              <h2 className="container-h2">Pilot Area</h2>
              <p>
                The North wing of the JRDC is entirely dedicated as a pilot area
                for technology development-related activities. Pilot testing is
                crucial as it provides an opportunity to test and implement a
                new process on a smaller scale, this is for the purpose of
                addressing any weaknesses or improvements.
              </p>
              <p>
                The researchers or students can execute and conduct their
                projects on developing, building, and testing water treatment
                facilities, in these premises. This area can help researches
                identify and correct major gaps that occur in the water testing
                solutions and technology. It can help test the periods of
                hydraulic retention times (HRTs) and stimulate different
                conditions and its effects. Further aiding students and
                researchers’ to test out their innovative designs and
                methodology. The pilot area includes facilities for the
                following purposes:
              </p>
              <p>Testing</p>
              <p> Technology Development </p>
              <p> Modeling and Prototype Development</p>
              {/* orderby */}
              <div id="page-meta" class="group">
                <form class="woocommerce-ordering" method="get">
                  <select name="orderby" class="orderby">
                    {/* <option value="menu_order">Default sorting</option> */}
                    <option value="popularity">Sort by popularity</option>
                    <option value="rating">Sort by latest</option>
                    <option value="price">Sort by price: low to high</option>
                    <option value="price-desc">
                      Sort by price: high to low
                    </option>
                  </select>
                </form>
              </div>
              <ul class="products">
                <li class="product">
                  <Card>
                    <div class="picture">
                      {/* <div class="ribbon ribbon-blue">
                        <div class="banner">
                          <div class="text">Not Available</div>
                        </div>
                      </div> */}
                      {/* <img
                        src="http://placehold.it/270x200"
                        data-at2x="http://placehold.it/270x200"
                        alt=""
                      ></img> */}
                      <Link to="/PilotAreasPage">
                        {" "}
                        <img
                          src={Benching_Area}
                          data-at2x="/path/to/your/custom-image.jpg"
                          alt="Auditorium"
                        ></img>
                      </Link>
                    </div>
                    <div class="product-name-s">
                      <Link to="/PilotAreasPage">Pilot Area</Link>
                    </div>
                    <div class="product-description">
                      <div class="short-description">
                        <p class="short-description-s">
                          Pilot Area - 60 minutes
                        </p>
                      </div>
                    </div>

                    {/* <div class="star-rating" title="Rated 4.00 out of 5">
                      <span style={{ width: "90%" }}>
                        <strong class="rating">4.00</strong> out of 5
                      </span>
                    </div> */}
                    {/* <span class="price">
                      <span class="amount">
                        555<sup>$</sup>
                      </span>
                    </span> */}
                    {/* <a
                      href="/shopCart"
                      class="cws-button alt border-radius small bt-color-3"
                    >
                      Book
                    </a> */}
                    <div>
                      <a
                        href="/PilotAreasPage"
                        rel="nofollow"
                        data-product_id="70"
                        data-product_sku=""
                        class="cws-button border-radius icon-left alt"
                      >
                        <i class=""></i> Book Now
                      </a>
                    </div>
                  </Card>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </Page>
  );
};

export default PilotAreaPage;
