import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { getUserCustomerbyId } from "../Api/index";
import { useNavigate } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";

function Address() {
  const [userData, setUserData] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const ci = window.localStorage.getItem("jrdc_user");
        if (ci) {
          const ciObj = JSON.parse(ci);
          const response = await getUserCustomerbyId(ciObj.id);
          const userCustomerData = response.data;
          setUserData(userCustomerData);
          console.log("Fetched customer data:", userCustomerData);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <div className="items-container">
      <p className="subtitle" style={{ fontFamily: "Roboto" }}>
        The following addresses will be used on the checkout page by default.
      </p>
      <p className="billing-address">Billing Address</p>
      <br />
      <div>
        <Button
          type="primary"
          style={{
            fontSize: "18px",
            cursor: "pointer",
            backgroundColor: "#FFFFFF",
            color: "#B9001F",
          }}
          onClick={() => navigate("/MyAccount/BillingAddress")}
          icon={<EditOutlined />}
        >
          Edit Address
        </Button>
        <br />
        <br />

        <div style={{ fontSize: "18px", marginBottom: "50px" }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
              rowGap: "30px",
              columnGap: "20px",
              color: "#666",
              fontFamily: "Roboto",
            }}
          >
            <div>
              <strong>Name:</strong>
              <br />
              {userData?.customer?.first_name} {userData?.customer?.last_name}
            </div>
            <div>
              <strong>Company:</strong>
              <br />
              {userData?.customer?.company}
            </div>
            <div>
              <strong>Email:</strong>
              <br />
              {userData?.customer?.bill_email} {/* Access email directly */}
            </div>
            <div>
              <strong>Address:</strong>
              <br />
              {userData?.customer?.address_line1}
              {userData?.customer?.address_line2 &&
                `, ${userData?.customer?.address_line2}`}
            </div>
            <div>
              <strong>City/Postal Code:</strong>
              <br />
              {userData?.customer?.city}, {userData?.customer?.postcode}
            </div>
            <div>
              <strong>State/Country:</strong>
              <br />
              {userData?.customer?.state} {userData?.customer?.country}
            </div>
            <div>
              <strong>Phone:</strong>
              <br />
              {userData?.customer?.phone}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Address;
