import axios from "axios";
import { message } from "antd";

//export const BASE_URL = "http://127.0.0.1:8000/api/v1";
export const BASE_URL = "https://jrdcback.ksoft.web.lk/api/v1";

// const get = async (path, body = {}, auth = true, blob = false) => {
//   const TOKEN = await window.localStorage.getItem("token");
//   if (auth) {
//     body.headers = { Authorization: `Bearer ${TOKEN}` };
//   }
//   if (blob) {
//     body.responseType = "blob";
//   }
//   let res = null;
//   try {
//     res = await axios.get(
//       path,
//       auth ? { headers: { Authorization: `Bearer ${TOKEN}` } } : {}
//     );
//     return res;
//   } catch (e) {
//     return {
//       status: 500,
//       data: {
//         out: [],
//         message: e.message,
//       },
//     };
//   }
// };

const get = async (path, body = {}, auth = true, blob = false) => {
  const TOKEN = await window.localStorage.getItem("token");
  console.log("Retrieved token:", TOKEN);
  if (auth) {
    body.headers = { Authorization: `Bearer ${TOKEN}` };
    console.log("Request headers:", body.headers);
  }
  if (blob) {
    body.responseType = "blob";
  }
  try {
    const res = await axios.get(
      path,
      auth ? { headers: { Authorization: `Bearer ${TOKEN}` } } : {}
    );
    console.log("Response:", res);
    return res;
  } catch (e) {
    console.error("Error in get request:", e);
    throw e; // Rethrow error to be handled by calling functions
  }
};

// const post = async (path, body, auth = true) => {
//   const TOKEN = await window.localStorage.getItem("token");
//   let res = null;
//   try {
//     res = await axios.post(
//       path,
//       body,
//       auth ? { headers: { Authorization: `Bearer ${TOKEN}` } } : {}
//     );
//     return {
//       status: res.status,
//       data: res.data,
//     };
//   } catch (e) {
//     console.log(e);
//     return {
//       status: 500,
//       data: {
//         out: [],
//         message: e.message,
//       },
//     };
//   }
// };

const post = async (path, body, auth = true) => {
  const TOKEN = window.localStorage.getItem("token");
  console.log("Retrieved token:", TOKEN); // token retrieval debugging
  let res = null;
  try {
    res = await axios.post(
      path,
      body,
      auth ? { headers: { Authorization: `Bearer ${TOKEN}` } } : {}
    );
    console.log("Response:", res);
    return {
      status: res.status,
      data: res.data,
    };
  } catch (e) {
    console.error("Error in get request:", e);
    throw e; // Rethrow error to be handled by calling functions
  }
};

const PUT = async (path, body, auth = true) => {
  const TOKEN = await window.localStorage.getItem("token");
  let res = null;
  try {
    res = await axios.put(
      path,
      body,
      auth ? { headers: { Authorization: `Bearer ${TOKEN}` } } : {}
    );
    return {
      status: res.status,
      data: res.data,
    };
  } catch (e) {
    console.log(e);
    return {
      status: 500,
      data: {
        out: [],
        message: e.message,
      },
    };
  }
};

const DELETE = async (path, body, auth = true) => {
  const TOKEN = await window.localStorage.getItem("token");
  let res = null;
  try {
    res = await axios.delete(
      path,
      { headers: { Authorization: `Bearer ${TOKEN}` }, data: body },
      auth ? {} : {}
    );
    return {
      status: res.status,
      data: res.data,
    };
  } catch (e) {
    console.log(e);
    return {
      status: 500,
      data: {
        out: [],
        message: e.message,
      },
    };
  }
};

const MULTIPART = async (path, body, auth = true) => {
  const TOKEN = await window.localStorage.getItem("token");
  let res = null;
  try {
    res = await axios.post(
      path,
      body,
      auth
        ? {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "multipart/form-data",
            },
          }
        : {}
    );
    return {
      status: res.status,
      data: res.data,
    };
  } catch (e) {
    console.log(e);
    return {
      status: 500,
      data: {
        out: [],
        message: e.message,
      },
    };
  }
};

export const checkTokenExpiration = () => {
  console.log("checkTokenExpiration called");

  const tokenExpiresAt = window.localStorage.getItem("session_expire_at");
  const tokenExpiresAtWithTimezone = window.localStorage.getItem(
    "session_expiry_time"
  );
  const timezone = window.localStorage.getItem("timezone");

  if (!tokenExpiresAt || !tokenExpiresAtWithTimezone || !timezone) {
    return;
  }

  // Get the current time in the user's local timezone
  const userLocalTime = new Date().toLocaleString("en-US", {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  // Convert the stored token expiration time to the user's local timezone
  const tokenExpiryDate = new Date(tokenExpiresAtWithTimezone).toLocaleString(
    "en-US",
    { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }
  );

  console.log("User Local Time:", userLocalTime);
  console.log("Token Expiry Date:", tokenExpiryDate);

  if (new Date(userLocalTime) > new Date(tokenExpiryDate)) {
    console.log("Token is expired, logging out user");
    logoutUser();
  }
};

const logoutUser = () => {
  window.localStorage.removeItem("token");
  window.localStorage.removeItem("jrdc_user");
  window.localStorage.removeItem("activeSidebarItem");
  window.localStorage.removeItem("jrdc_user_id");
  window.localStorage.removeItem("jrdc_cart_items");
  window.localStorage.removeItem("session_expiry_time");
  window.localStorage.removeItem("session_expire_at");
  window.localStorage.removeItem("timezone");

  message.info("Session expired. You have been logged out.", 3);
  setTimeout(() => {
    window.location.href = `/login`;
  }, 3000);
};

const monitorUrlChanges = () => {
  window.addEventListener("popstate", checkTokenExpiration);
  const originalPushState = window.history.pushState;
  const originalReplaceState = window.history.replaceState;

  window.history.pushState = function (...args) {
    originalPushState.apply(this, args);
    checkTokenExpiration();
  };

  window.history.replaceState = function (...args) {
    originalReplaceState.apply(this, args);
    checkTokenExpiration();
  };
};

monitorUrlChanges();
checkTokenExpiration();

// export const getAccomodations = async (body) => {
//   return await get(BASE_URL + "/product_categories", body);
// };
export const getCategories = async (data, body) => {
  console.log(body);
  return await get(
    BASE_URL + `/product_categories_by_parent_slugs?slugs=${data.slugs}`,
    body
  );
};
export const getProductsByCategory = async (id, body) => {
  return await get(BASE_URL + `/product_categories/${id}/products`, body);
};

export const getProductDetails = async (id, body) => {
  return await get(BASE_URL + `/products/${id}/details`, body);
};

export const login = async (body) => {
  return await post(BASE_URL + "/customer/login", body, true);
  // return await POST(BASE_URL + `/login`, {
  //   credentials: "include",
  //   body,
  // });
};

export const register = async (body) => {
  return await post(BASE_URL + "/registerWeb", body);
};

export const getUserById = async (id, body) => {
  // return await get(BASE_URL + `/product?id=${id}`, body);
  return await get(BASE_URL + `/user/${id}`, body);
};

export const storeOrder = async (storeOrderData) => {
  try {
    console.log({ storeOrderData });
    const { data, status } = await post(
      BASE_URL + "/orders/storeCustomer",
      storeOrderData,
      undefined,
      false
    );
    console.log(`Status: ${status}`, `Data: ${data}`);
    return data;
  } catch (error) {
    console.error(`Error: ${error.status} - ${error.message}`);
  }
};

export const getUsersById = async () => {
  return await get(BASE_URL + `/users/userShow`, {
    credentials: "include",
  });
};
// export const getUsersById = async (id) => {
//   try {
//     const response = await get(`${BASE_URL}/api/userShow/${id}`);
//     return response.data.user;
//   } catch (error) {
//     console.error("Error fetching user data:", error);
//     throw error;
//   }
// };

export const getUserCustomerbyId = async (id) => {
  try {
    const response = await get(BASE_URL + `/user/${id}/customer`, {
      credentials: "include",
    });
    return response;
  } catch (error) {}
};

export const getOrders = async (page, pageSize) => {
  try {
    const response = await get(
      BASE_URL + `/ordersweb?page=${page}&pageSize=${pageSize}`,
      {
        withCredentials: true,
      }
    );
    console.log("Response:", response);
    if (response.status !== 200) {
      throw new Error("Failed to fetch orders");
    }
    return response.data;
  } catch (error) {
    console.error("Error fetching orders:", error);
  }
};

export const getAppointments = async () => {
  try {
    const response = await get(BASE_URL + "/appointmentsweb", {
      withCredentials: true,
    });
    console.log("Response:", response);
    if (response.status !== 200) {
      throw new Error("Failed to fetch appointments");
    }
    return response.data;
  } catch (error) {
    console.error("Error fetching appointments:", error);
  }
};

export const showOrder = async (id) => {
  try {
    const response = await fetch(BASE_URL + `/ordersweb/${id}`);
    console.log("Response:", response);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response; // Return the response object
  } catch (error) {
    console.error("Error:", error.message);
    throw error; // Re-throw the error
  }
};

export const editAccount = async (body) => {
  try {
    const response = await post(
      BASE_URL + `/users/${body.id}/editAccount`,
      body,
      {
        withCredentials: true,
      }
    );
    console.log("Response:", response);
    if (response.status !== 200) {
      throw new Error("Failed to edit account");
    }
    return response.data;
  } catch (error) {
    console.error("Error editing account:", error);
  }
};

export const editAddress = async (body) => {
  try {
    const response = await post(
      `${BASE_URL}/update_customer/${body.id}/edit`,
      body,
      {
        withCredentials: true,
      }
    );
    console.log("Response:", response);
    if (response.status !== 200) {
      throw new Error("Failed to edit address");
    }
    return response.data;
  } catch (error) {
    console.error("Error editing address:", error);
  }
};

export const getCustomerCourseProducts = async (page, pageSize) => {
  try {
    const response = await get(
      BASE_URL + `/courses?page=${page}&pageSize=${pageSize}`,
      {
        withCredentials: true,
      }
    );
    console.log("Response:", response);
    if (response.status !== 200) {
      throw new Error("Failed to fetch course products");
    }
    return response.data;
  } catch (error) {
    console.error("Error fetching course products:", error);
  }
};

export const getProductMeetingDataById = async (productId) => {
  try {
    const response = await get(`${BASE_URL}/course_links/${productId}`);
    console.log("Response:", response);
    if (response.status !== 200) {
      throw new Error("Failed to fetch product meeting data");
    }
    return response.data;
  } catch (error) {
    console.error("Error fetching product meeting data:", error);
    throw error;
  }
};

export const getStartTimeSlots = async (productId, date) => {
  try {
    const response = await get(
      `${BASE_URL}/products/${productId}/start_time_slots/?date=${date}`
    );
    console.log("Response:", response);
    // if (response.status !== 200) {
    //   throw new Error("Failed to fetch product meeting data");
    // }
    return response.data;
  } catch (error) {
    console.error("Error fetching product meeting data:", error);
    throw error;
  }
};

export const getEndTimeSlots = async (productId, date, startTime) => {
  try {
    const response = await get(
      `${BASE_URL}/products/${productId}/end_time_slots/?date=${date}&start_time=${startTime}`
    );
    console.log("Response:", response);
    // if (response.status !== 200) {
    //   throw new Error("Failed to fetch product meeting data");
    // }
    return response.data;
  } catch (error) {
    console.error("Error fetching product meeting data:", error);
    throw error;
  }
};

export const getPriceForTimeSlots = async (productId, startTime, endTime) => {
  try {
    const response = await get(
      `${BASE_URL}/products/${productId}/calculate_price?start_time=${startTime}&end_time=${endTime}`
    );
    console.log("Response:", response);
    // if (response.status !== 200) {
    //   throw new Error("Failed to fetch product meeting data");
    // }
    return response.data;
  } catch (error) {
    console.error("Error fetching product meeting data:", error);
    throw error;
  }
};

export const storeCustomerOrder = async (orderData) => {
  try {
    const response = await post(BASE_URL + "/orders/storeorder", orderData);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateCustomerOrder = async (orderId, updatedOrderData) => {
  try {
    const response = await post(
      `${BASE_URL}/orders/updateorder/${orderId}`,
      updatedOrderData
    );
    console.log("Response:", response);
    if (response.status !== 200) {
      throw new Error("Failed to update order");
    }
    return response.data;
  } catch (error) {
    console.error("Error updating order:", error);
    throw error;
  }
};

// get events

export const getEvents = async (page, pageSize) => {
  try {
    const response = await get(
      `${BASE_URL}/show_events?page=${page}&pageSize=${pageSize}`,
      {
        credentials: "include",
      }
    );
    console.log("Response:", response);
    if (response.status !== 200) {
      throw new Error("Failed to fetch events");
    }
    return response.data;
  } catch (error) {
    console.error("Error fetching events:", error);
    throw error;
  }
};

// index.js
export const getConferences = async (page, pageSize) => {
  try {
    const response = await get(
      `${BASE_URL}/show_events?type=CONFERENCE&page=${page}&pageSize=${pageSize}`,
      {
        credentials: "include",
      }
    );
    console.log("Response:", response);
    if (response.status !== 200) {
      throw new Error("Failed to fetch conferences");
    }
    return response.data;
  } catch (error) {
    console.error("Error fetching conferences:", error);
    throw error;
  }
};

export const getProductsByEventId = async (eventId) => {
  try {
    const response = await get(`${BASE_URL}/events/${eventId}/products`);
    console.log("Response:", response);
    if (response.status !== 200) {
      throw new Error("Failed to fetch products");
    }
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

export const getEventbyId = async (eventId) => {
  try {
    const response = await get(`${BASE_URL}/show_events/${eventId}`);
    console.log("Response:", response);
    if (response.status !== 200) {
      throw new Error("Failed to fetch product meeting data");
    }
    return response.data;
  } catch (error) {
    console.error("Error fetching product meeting data:", error);
    throw error;
  }
};

export const getEventswithProducts = async () => {
  try {
    const response = await get(BASE_URL + "/event_products", {});
    console.log("Response:", response);
    if (response.status !== 200) {
      throw new Error("Failed to fetch events");
    }
    return response.data;
  } catch (error) {
    console.error("Error fetching events:", error);
    throw error;
  }
};

export const getEventswithTickets = async (eventId) => {
  try {
    const response = await get(BASE_URL + `/get_event_tickets/${eventId}`, {});
    console.log("Response:", response);
    if (response.status !== 200) {
      throw new Error("Failed to fetch events");
    }
    return response.data;
  } catch (error) {
    console.error("Error fetching events:", error);
    throw error;
  }
};

//get navbar menus
export const getMenus = async () => {
  try {
    const response = await get(BASE_URL + "/menus", {});
    console.log("Response:", response);
    if (response.status !== 200) {
      throw new Error("Failed to fetch events");
    }
    return response.data;
  } catch (error) {
    console.error("Error fetching events:", error);
    throw error;
  }
};

//Contact us

export const ContactMessage = async (message) => {
  try {
    const response = await post(BASE_URL + "/contcat_us/create", message);
    console.log("Response:", response);
    if (response.status !== 201) {
      throw new Error("Failed to send Message");
    }
    return response.data;
  } catch (error) {
    console.error("Error sending message:", error);
    throw error;
  }
};

//posts

export const getPageById = async (pageId, page, pageSize) => {
  try {
    const response = await get(
      BASE_URL + `/page/${pageId}?page=${page}&pageSize=${pageSize}`
    );
    console.log("Response:", response);

    if (response.status !== 200) {
      throw new Error("Failed to fetch page data");
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching page data:", error);
    throw error;
  }
};

export const getPrograms = async () => {
  try {
    const response = await get(BASE_URL + "/get_programs", {});
    console.log("Response:", response);
    if (response.status !== 200) {
      throw new Error("Failed to fetch events");
    }
    return response.data;
  } catch (error) {
    console.error("Error fetching events:", error);
    throw error;
  }
};

export const getFirstFewNews = async (limit) => {
  try {
    const response = await get(`${BASE_URL}/page/6`, {
      params: {
        limit: 5,
      },
    });
    console.log("Response:", response);

    if (response.status !== 200) {
      throw new Error("Failed to fetch news data");
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching news data:", error);
    throw error;
  }
};

export const getCode = async (email) => {
  try {
    const response = await post(BASE_URL + "/password/email", email);
    console.log("Response:", response);
    if (response.status !== 200) {
      throw new Error("Failed to get Code");
    }
    return response.data;
  } catch (error) {
    console.error("Error getting Code:", error);
    throw error;
  }
};

export const verifyCode = async (code) => {
  try {
    const response = await post(BASE_URL + "/password/code/check", {
      code: code,
    });
    console.log("Response:", response);
    if (response.status !== 200) {
      throw new Error("Failed to get Code");
    }
    return response.data;
  } catch (error) {
    console.error("Error getting Code:", error);
    throw error;
  }
};

export const resetPassword = async (code, password, confirmPassword) => {
  try {
    const response = await post(BASE_URL + "/password/reset", {
      code: code,
      password: password,
      password_confirmation: confirmPassword,
    });
    console.log("Response:", response);
    if (response.status !== 200) {
      throw new Error("Failed to reset password");
    }
    return response.data;
  } catch (error) {
    console.error("Error resetting password:", error);
    throw error;
  }
};

export const cancelOrder = async (orderId) => {
  try {
    const response = await PUT(`${BASE_URL}/orders/${orderId}/cancelled`, {});
    console.log("Response:", response);
    if (response.status !== 200) {
      throw new Error("Failed to cancel order");
    }
    return response.data;
  } catch (error) {
    console.error("Error cancelling order:", error);
  }
};

export const search = async (query) => {
  try {
    const response = await axios.get(`${BASE_URL}/search`, {
      params: { query },
    });

    const { products, productCategories, posts, events } = response.data;

    return { products, productCategories, posts, events };
  } catch (error) {
    console.error("Error fetching search results:", error);
    throw error;
  }
};

export const getRedirectUrl = async () => {
  try {
    const response = await get(`${BASE_URL}/redirect_water_test`, {
      withCredentials: true,
    });
    if (response.status !== 200) {
      throw new Error("Failed to fetch redirect URL");
    }
    return response.data.url;
  } catch (error) {
    console.error("Error fetching redirect URL:", error);
    throw error;
  }
};

export const getPaymentSlip = async (orderId) => {
  try {
    const response = await get(BASE_URL + `/orders/viewOrder/${orderId}`, {});
    console.log("Response:", response);
    if (response.status !== 200) {
      throw new Error("Failed to fetch transaction data");
    }
    return response.data;
  } catch (error) {
    console.error("Error fetching transaction data:", error);
    throw error;
  }
};
