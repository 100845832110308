import React, { useState, useEffect } from "react";
import { Button, Card, Form, Input, Upload, message, Divider } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Footer from "./../Components/layouts/Footer";
import Navbar from "./../Components/layouts/Navbar";
import Page from "./../Components/layouts/Page";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { getEventbyId } from "./../Api/index";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import default_img from "./../img/pattern/default.jpg";
import { openDB } from "idb";

const AddResearchPaper = () => {
  const [qty, setQty] = useState(1);
  const [subtotal, setSubtotal] = useState(0);
  const isMobileView = useMediaQuery({ maxWidth: 767 });
  const isTab = useMediaQuery({ minWidth: 768, maxWidth: 1024 });

  const location = useLocation();
  const [form] = Form.useForm();
  const eventId = location.state?.event || null;
  const product = location.state?.product || null;
  const [file, setFile] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState({});
  const navigate = useNavigate();

  const dbPromise = openDB("research-papers-db", 1, {
    upgrade(db) {
      db.createObjectStore("papers", { keyPath: "id" });
    },
  });

  useEffect(() => {
    if (eventId) {
      fetchEvent(eventId);
    }
  }, [eventId]);

  const fetchEvent = async (eventId) => {
    try {
      const eventData = await getEventbyId(eventId);
      const startDate = moment(eventData.event.start_date);
      const endDate = moment(eventData.event.end_date);
      const startDateTime = startDate.format("Do MMMM YYYY [at] hh:mm A");
      const endDateTime = endDate.format("Do MMMM YYYY [at] hh:mm A");

      setSelectedEvent({
        title: eventData.event.title,
        description: eventData.event.description,
        image_url: eventData?.event?.image_url || default_img,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        location: eventData.event.location,
      });
    } catch (error) {
      console.error("Error fetching event data:", error);
    }
  };

  const handleQuantityChange = (e) => {
    const newQty = parseInt(e.target.value, 10);
    setQty(newQty);
    updateSubtotal(newQty);
  };

  const updateSubtotal = (newQty) => {
    const newSubtotal = newQty * product.sale_price;
    setSubtotal(newSubtotal.toFixed(2));
  };

  const handleFileChange = async (files) => {
    if (files && files.length > 0) {
      const selectedFile = files[0];

      const reader = new FileReader();
      reader.onload = async function (event) {
        const fileBase64 = event.target.result;
        const db = await dbPromise;
        await db.put("papers", { id: "researchPaper", data: fileBase64 });
        console.log("Uploaded file: ", selectedFile);
        setFile(selectedFile);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSubmit = async (values) => {
    try {
      const db = await dbPromise;
      const researchPaper = await db.get("papers", "researchPaper");

      if (!researchPaper) {
        message.error("Please upload a file before submitting.");
        return;
      }

      const formValues = {
        productId: product.id,
        title: values.title,
        abstract: values.abstract,
        research_paper: researchPaper.data,
        qty: qty,
        subtotal: subtotal ? subtotal : product.sale_price,
      };

      const item = {
        product: {
          id: formValues.productId,
          image_url: product?.image_url,
          name: product.name,
          sale_price: product.sale_price,
          title: formValues.title,
          research_paper: formValues.research_paper,
          abstract: formValues.abstract,
        },
        startDate: "",
        salePrice: product.sale_price,
        qty: formValues.qty,
        cost: formValues.subtotal,
      };

      console.log("Item:", item);

      const ci = window.localStorage.getItem("jrdc_cart_items");

      if (ci) {
        const ciObj = JSON.parse(ci);
        const existingIndex = ciObj.findIndex(
          (ci) => ci.product.id === item.product.id
        );
        if (existingIndex !== -1) {
          ciObj[existingIndex] = item;
        } else {
          ciObj.push(item);
        }
        window.localStorage.setItem("jrdc_cart_items", JSON.stringify(ciObj));
      } else {
        window.localStorage.setItem("jrdc_cart_items", JSON.stringify([item]));
      }

      message.success(
        "Research paper submitted! Product added to cart Successfully!"
      );
      navigate("/shopCart");
    } catch (error) {
      console.error("Form submission error:", error);
      message.error(
        "Research paper submission failed & product not added to cart!"
      );
    }
  };

  return (
    <Page>
      <Navbar />
      <div className="page-content woocommerce">
        <div className="container clear-fix">
          <p>
            <b>
              Home / {selectedEvent.title} / {product.name}
            </b>
          </p>
          <div className={`grid-col-row ${isMobileView ? "mobile-view" : ""}`}>
            <div
              className={`grid-col grid-col-12 ${
                isMobileView ? "mobile-view" : ""
              }`}
            >
              <Card
                style={{
                  marginBottom: 20,
                  width: isMobileView ? "100%" : isTab ? "90%" : "800px",
                }}
              >
                <img
                  src={selectedEvent.image_url}
                  alt={selectedEvent.title}
                  style={{ width: "100%", height: "auto" }}
                />
                <br />
                <h1
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    fontWeight: "bold",
                    fontSize: isMobileView ? "22px" : "",
                  }}
                >
                  {product.name}
                </h1>
                <h3
                  style={{
                    color: "#B9001F",
                    fontFamily: "Roboto, sans-serif",
                    fontSize: "20px",
                  }}
                >
                  <u style={{ color: "#B9001F" }}>Description</u>
                </h3>
                <p style={{ fontSize: "16px" }}>
                  <strong>{selectedEvent.title}</strong>
                </p>
                <p style={{ fontSize: "16px" }}>
                  <strong>Start Date & Time: </strong>
                  {selectedEvent.startDateTime}
                </p>
                <p style={{ fontSize: "16px" }}>
                  <strong>Venue: </strong> {selectedEvent.location}
                </p>
                <p style={{ fontSize: "16px" }}>
                  <strong>End Date & Time: </strong> {selectedEvent.endDateTime}
                </p>
                <p style={{ fontSize: "16px", textAlign: "justify" }}>
                  {selectedEvent.description}
                </p>
              </Card>
            </div>
            <div
              className={`grid-col grid-col-12 ${
                isMobileView ? "mobile-view" : ""
              }`}
            >
              <Card
                title={
                  <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                    <span style={{ fontSize: "20px", whiteSpace: "pre-wrap" }}>
                      {product.name}
                    </span>
                  </div>
                }
                style={{
                  width: isMobileView ? "100%" : "500px",
                  marginBottom: isMobileView ? "80px" : "",
                }}
              >
                <Form
                  form={form}
                  name="researchPaperForm"
                  onFinish={handleSubmit}
                  layout="vertical"
                >
                  <Form.Item
                    label={
                      <span style={{ fontSize: "16px" }}>
                        <strong>Title</strong>
                      </span>
                    }
                    name="title"
                    style={{ marginBottom: "10px" }}
                    rules={[
                      { required: true, message: "Please enter the title" },
                    ]}
                  >
                    <Input placeholder="Enter title" />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "16px" }}>
                        <strong>Abstract</strong>
                      </span>
                    }
                    name="abstract"
                    style={{ marginBottom: "10px" }}
                    rules={[
                      { required: true, message: "Please enter the abstract" },
                    ]}
                  >
                    <Input.TextArea rows={4} placeholder="Enter abstract" />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "16px" }}>
                        <strong>Upload Research Paper</strong>
                      </span>
                    }
                    name="research_paper"
                    style={{ marginBottom: "10px" }}
                    rules={[
                      {
                        required: true,
                        message: "Please upload the research paper",
                      },
                    ]}
                  >
                    <input
                      type="file"
                      accept=".pdf,.doc,.docx,.txt"
                      onChange={(event) => handleFileChange(event.target.files)}
                    />
                  </Form.Item>
                  <p style={{ fontSize: "16px", margin: "5px 0" }}>
                    (max file size: 3.5 MB)
                  </p>
                  <Divider style={{ margin: "20px 0" }} />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <div style={{ width: "300px" }}>
                      <strong style={{ fontSize: "16px" }}>
                        {qty}x {product.name}
                      </strong>
                    </div>
                    <div>
                      <strong style={{ fontSize: "16px" }}>
                        රු {subtotal ? subtotal : product.sale_price}
                      </strong>
                    </div>
                  </div>
                  <Divider style={{ margin: "20px 0" }} />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <div>
                      <strong style={{ fontSize: "16px" }}>Subtotal:</strong>
                    </div>
                    <div>
                      <strong style={{ fontSize: "16px" }}>
                        රු {subtotal ? subtotal : product.sale_price}
                      </strong>
                    </div>
                  </div>
                  <Divider style={{ margin: "20px 0" }} />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Form.Item
                      label={<span style={{ fontSize: "16px" }}>Quantity</span>}
                      style={{ marginBottom: "0" }}
                    >
                      <Input
                        type="number"
                        value={qty}
                        style={{ width: "60px" }}
                        onChange={handleQuantityChange}
                      />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: "0" }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="fos-btn-primary"
                        style={{
                          background: "#B9001F",
                          borderColor: "#B9001F",
                          borderRadius: "30px",
                          height: "40px",
                          fontSize: "16px",
                        }}
                      >
                        Add to Cart
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Page>
  );
};

export default AddResearchPaper;
