import React, { useEffect, useState } from "react";

import Suite_Room from "../../src/img//pattern/Suite_Room.jpg";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import dayjs from "dayjs";
import { CloseCircleFilled } from "@ant-design/icons";
import { getDetailsById } from "../Api/index.js";
// import { useLocation, useParams } from "react-router-dom";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Row, Table, Space, Button, Statistic, Col } from "antd";
import "./../Components/layouts/Footer.css";

const ShopCartPage = () => {
  const [details, setDetails] = useState([]);
  const { state } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [total, setTotal] = useState("0.00");

  async function fetchDetails() {
    const ci = window.localStorage.getItem("jrdc_cart_items");
    if (ci) {
      const ciObj = JSON.parse(ci);
      setDetails(ciObj);
      calculateCartTotal();
      console.log({ ciObj });
    }
  }
  useEffect(() => {
    fetchDetails();
  }, []);

  const handleRemove = (id) => {
    const indexToRemove = details.findIndex((item) => item.product.id === id);
    if (indexToRemove !== -1) {
      console.log({ item: details[indexToRemove] });
      const updatedDetails = [...details];
      updatedDetails.splice(indexToRemove, 1);
      setDetails(updatedDetails);

      window.localStorage.setItem(
        "jrdc_cart_items",
        JSON.stringify(updatedDetails)
      );
    }
  };

  const columns = [
    {
      title: "Product",
      dataIndex: "option_measure",
      width: "45%",
      render(value, record, index) {
        return (
          <>
            <Space>
              <div class="product-thumbnail">
                <a href="shop-single-item.html">
                  <img
                    src={record.product.image_url}
                    data-at2x="http://placehold.it/370x370"
                    class="attachment-shop_single"
                    alt={record.product.name}
                    title={record.product.name}
                  />
                </a>
              </div>
              <div className="product-name">
                <a href="shop-single-item.html">
                  <b>
                    {record.eventName
                      ? `${record.eventName} - ${record.product.name}`
                      : record.product.name}
                  </b>
                </a>
                {record.product.appointable && (
                  <>
                    <div className="product-name">
                      {`Date: ${dayjs(record.startDate).format(
                        "DD/MM/YYYY"
                      )} - ${dayjs(record.endDate).format("DD/MM/YYYY")}`}
                    </div>
                    {record.showTime && (
                      <div className="product-name">
                        {`Time: ${dayjs(record.startTime).format(
                          "HH:mm A"
                        )} - ${dayjs(record.endTime).format("HH:mm A")}`}
                      </div>
                    )}
                  </>
                )}
                {/* {record.tickets && record.tickets.length > 0 && (
                  <ul>
                    {record.tickets.map((ticket, index) => (
                      <li key={index}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "left",
                          }}
                        >
                          <span
                            style={{
                              marginRight: "10px",
                              fontFamily: "Roboto",
                            }}
                          >
                            {ticket.name} Ticket *
                          </span>
                          <span
                            style={{
                              marginRight: "10px",
                              fontFamily: "Roboto",
                            }}
                          >
                            {ticket.ticket_qty}
                          </span>
                          <span style={{ fontFamily: "Roboto" }}>
                            Rs. {ticket.price}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                )} */}
              </div>
            </Space>
          </>
        );
      },
    },
    {
      title: "Unit Price",
      dataIndex: "salePrice",
      align: "center",
      render(value, record, index) {
        return `Rs ${value}`;
      },
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      align: "center",
    },
    {
      title: "Sub Total",
      dataIndex: "cost",
      align: "center",
      render(value, record, index) {
        return `Rs ${value}`;
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render(value, record, index) {
        return (
          <Space>
            <Button
              type="text"
              danger
              size="large"
              shape="circle"
              onClick={() => handleRemove(record.product.id)}
              icon={<CloseCircleFilled />}
            ></Button>
          </Space>

          // <div class="product-remove">
          //     <button
          //       onClick={() => handleRemove(record.product.id)}
          //       // style={{ marginLeft: "25%" }}
          //       className="remove"
          //       title="Remove this item"
          //     ></button>
          //   </div>
        );
      },
    },
  ];

  const handleProceedToCheckout = (values) => {
    navigate("/ShopCheckoutPage", {
      state: { cartItems: details, total: renderTotal(details) },
    });
  };

  const calculateCartTotal = () => {
    let t =
      "Rs " +
      details
        .map((e) => e.cost)
        .reduce((sum, num) => sum + parseFloat(num), 0)
        .toFixed(2);

    details ? setTotal(t) : setTotal("0.00");
  };

  function renderTotal(items) {
    let total = 0;
    items.forEach(({ type, salePrice, qty, cost }) => {
      if (type === 0) {
        total += salePrice * qty;
      } else {
        total += cost;
      }
    });
    return total;
  }

  return (
    <Page>
      <Navbar />

      <div className="page-content woocommerce">
        <div className="page-wrapper">
          <div className="container clear-fix">
            <div class="title clear-fix">
              <h2>Review Booking Request</h2>
              <a href="/" class="button-back">
                Back to shopping<i class="fa fa-angle-double-right"></i>
              </a>
            </div>
            <div className="cart-items"></div>
            <div
              id="content"
              role="main"
              style={{ backgroundColor: "white", padding: "20px 20px" }}
            >
              {/* <table class="shop_table cart">
              <thead>
                <tr>
                  <th class="product-thumbnail">Product</th>
                  <th class="product-name">&nbsp;</th>
                  <th class="product-price">Unit Price</th>
                  <th class="product-quantity">Quantity</th>
                  <th class="product-subtotal">Sub Total</th>
                  <th class="product-remove">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {details &&
                  details.map((pc) => (
                    <tr class="cart_item">
                      <td class="product-thumbnail">
                        <a href="shop-single-item.html">
                          <img
                            src={pc.product.image_url}
                            data-at2x="http://placehold.it/370x370"
                            class="attachment-shop_single"
                            alt={pc.product.name}
                            title={pc.product.name}
                          />
                        </a>
                      </td>
                      <td className="product-name">
                        <a
                          href="shop-single-item.html"
                          style={{
                            fontFamily: "Roboto",
                            fontWeight: "bold",
                            marginLeft: "20px",
                          }}
                        >
                          {pc.product.name}
                        </a>
                        {pc.product.appointable && (
                          <>
                            <p
                              style={{
                                fontFamily: "Roboto",
                                marginLeft: "20px",
                              }}
                            >
                              {"Date: "}
                              {pc.showTime
                                ? `${dayjs(pc.startDate).format(
                                    "DD/MM/YYYY HH:ss A"
                                  )} - ${dayjs(pc.endDate).format(
                                    "DD/MM/YYYY HH:ss A"
                                  )}`
                                : `${dayjs(pc.startDate).format(
                                    "DD/MM/YYYY"
                                  )} - ${dayjs(pc.endDate).format(
                                    "DD/MM/YYYY"
                                  )}`}
                            </p>
                          </>
                        )}
                        {pc.tickets && pc.tickets.length > 0 && (
                          <ul>
                            {pc.tickets.map((ticket, index) => (
                              <li key={index}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "left",
                                  }}
                                >
                                  <span
                                    style={{
                                      marginRight: "10px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {ticket.name} Ticket *
                                  </span>
                                  <span
                                    style={{
                                      marginRight: "10px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {ticket.ticket_qty}
                                  </span>
                                  <span style={{ fontFamily: "Roboto" }}>
                                    Rs. {ticket.price}
                                  </span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </td>

                      <td class="product-price">
                        <span class="amount">{"Rs " + pc.salePrice}</span>
                      </td>
                      <td class="product-quantity">
                        <div class="quantity buttons_added">{pc.qty}</div>
                      </td>
                      <td class="product-subtotal">
                        <span class="amount">{"Rs " + pc.cost}</span>{" "}
                      </td>

                      <td class="product-remove">
                        <button
                          onClick={() => handleRemove(pc.product.id)}
                          style={{ marginLeft: "25%" }}
                          className="remove"
                          title="Remove this item"
                        ></button>
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td colspan="6" class="actions">
                    <button
                      type="submit"
                      className="cws-button alt border-radius small bt-color-3"
                    >
                      Update Cart
                    </button>
                  </td>
                </tr>
              </tbody>
            </table> */}
              <br />
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <div
                    style={{
                      overflowX: "auto",
                      WebkitOverflowScrolling: "touch",
                    }}
                  >
                    <Table
                      columns={columns}
                      pagination={false}
                      dataSource={details}
                      size="middle"
                      style={{ minWidth: "300px" }} // Adjust this value as needed
                    />
                  </div>
                </Col>
              </Row>
              <div class="cart-collaterals">
                <div class="cart_totals ">
                  <table
                    style={{
                      borderColor: "#B9001F",
                      borderRadius: "10",
                    }}
                  >
                    <tbody>
                      {/* <tr class="cart-subtotal">
                      <th>Subtotal</th>
                      <td>
                        <span class="amount"> Rs.4000</span>
                      </td>
                    </tr> */}

                      <tr
                      //  class="order-total"
                      >
                        <th>
                          <h6>Cart Total</h6>
                        </th>
                        <td>
                          <span>
                            <h6>
                              {"Rs "}
                              {details &&
                                details
                                  .map((e) => e.cost)
                                  .reduce(
                                    (sum, num) => sum + parseFloat(num),
                                    0
                                  )
                                  .toFixed(2)}
                            </h6>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <Button
                    onClick={handleProceedToCheckout}
                    type="primary"
                    htmlType="submit"
                    // className="fos-btn-primary w-100 mt-5"
                    style={{
                      background: "#B9001F",
                      borderColor: "#B9001F",
                      width: "100%",
                    }}
                  >
                    PROCEED TO CHECKOUT
                  </Button>
                  {/* <button
                  onClick={handleProceedToCheckout}
                  class="cws-button  border-radius small  bt-color-3"
                >
                  Proceed to Checkout
                </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Page>
  );
};

export default ShopCartPage;
