import React, { useState } from "react";
import { Card, Row, Col } from "antd";

const GridCard = ({
  gridItems,
  background,
  title,
  showDescription,
  preContent,
  postContent,
}) => {
  const [hoveredItem, setHoveredItem] = useState(null);

  const getTitleOpacity = (item) => {
    if (
      hoveredItem &&
      hoveredItem.title === item.title &&
      showDescription &&
      item.description
    ) {
      return "0";
    }
    return "1";
  };

  const getDescriptionOpacity = (item) => {
    if (hoveredItem && hoveredItem.title === item.title) {
      return showDescription && item.description ? "1" : "0";
    }
    return "0";
  };

  const cardStyle = {
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    width: "100%",
    marginBottom: "50px",
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)), url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  const cardheadStyle = {
    fontSize: "28px",
    fontWeight: "bold",
    color: "black",
    background: "white",
  };

  const tileStyle = {
    background: "#FFFFFF",
    minHeight: showDescription ? "200px" : "150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    transition: "transform 0.3s",
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    padding: "20px",
    cursor: "pointer",
    opacity: "0.8",
    objectFit: "contain",
    overflow: "hidden",
  };

  const tileHeadingStyle = {
    fontFamily: "Roboto, sans-serif",
    fontSize: "26px",
    color: "black",
    marginBottom: "10px",
    textAlign: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "90%",
  };

  const tileDescriptionStyle = {
    fontFamily: "Roboto, sans-serif",
    fontSize: "12px",
    color: "black",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "auto",
    textAlign: "justify",
    padding: "20px",
    borderRadius: "5px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  };

  const contentStyle = {
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    color: "white",
    marginBottom: "20px",
  };

  const customStyles = {
    customCard: {
      "& .ant-card-head": {
        background: "none",
      },
    },
  };

  return (
    <Card
      title={title}
      style={{ ...cardStyle, ...customStyles.customCard }}
      headStyle={cardheadStyle}
    >
      {preContent && <div style={contentStyle}>{preContent}</div>}
      <Row gutter={[32, 32]}>
        {gridItems.map((item, index) => (
          <Col
            xs={24}
            sm={gridItems.length === 3 ? 8 : 12}
            md={gridItems.length === 3 ? 8 : 8}
            lg={gridItems.length === 3 ? 8 : 6}
            key={index}
          >
            <div
              style={{
                ...tileStyle,
                transform:
                  hoveredItem && hoveredItem.title === item.title
                    ? "scale(1.1)"
                    : "scale(1)",
              }}
              onMouseEnter={() => setHoveredItem(item)}
              onMouseLeave={() => setHoveredItem(null)}
            >
              <div
                style={{
                  ...tileHeadingStyle,
                  opacity: getTitleOpacity(item),
                  transition: "opacity 0.3s",
                }}
              >
                {item.title}
              </div>
              {item.description && (
                <div
                  style={{
                    ...tileDescriptionStyle,
                    opacity: getDescriptionOpacity(item),
                    transition: "opacity 0.3s",
                  }}
                >
                  {item.description}
                </div>
              )}
            </div>
          </Col>
        ))}
      </Row>

      {postContent && <div style={{ ...contentStyle }}>{postContent}</div>}
    </Card>
  );
};

export default GridCard;
