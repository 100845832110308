import React, { useState, useEffect } from "react";
import { Card, Button, Table, Input, Divider, Row } from "antd";
import {
  CalendarOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
  CalendarFilled,
  ClockCircleFilled,
  FacebookFilled,
  TwitterOutlined,
  WhatsAppOutlined,
  MailOutlined,
  GoogleOutlined,
  YahooOutlined,
  AppleOutlined,
} from "@ant-design/icons";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { getEventswithTickets } from ".././Api/index";
import default_img from "./../img/pattern/default.jpg";

const { Column } = Table;

const ViewEvent = () => {
  const [quantity, setQuantity] = useState(1);
  const [showCalendarButtons, setShowCalendarButtons] = useState(false);
  const [events, setEvents] = useState({ tickets: [] });
  const navigate = useNavigate();
  const location = useLocation();
  //const pricePerTicket = 100;
  const [ticketQuantities, setTicketQuantities] = useState({});
  const event = location.state ? location.state.event : null;
  //const totalPrice = quantity * pricePerTicket;
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTab = useMediaQuery({ minWidth: 768, maxWidth: 1024 });

  const handleQuantityChange = (ticketId, value) => {
    value = Math.max(0, value);
    setTicketQuantities((prevState) => ({
      ...prevState,
      [ticketId]: parseInt(value),
    }));
  };

  const formatDate = (dateTimeStr) => {
    if (!dateTimeStr) return "N/A";
    const dateTime = new Date(dateTimeStr);
    return dateTime.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatTime = (dateTimeStr) => {
    if (!dateTimeStr) return "N/A";
    const dateTime = new Date(dateTimeStr);
    return dateTime.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
  };

  const tileData = [
    {
      icon: <CalendarOutlined />,
      label: "Event Date:",
      value: formatDate(event?.start_date),
      height: 60,
      backgroundColor: "#212460",
    },
    {
      icon: <ClockCircleOutlined />,
      label: "Event Time:",
      value: formatTime(event?.start_date),
      height: 60,
      backgroundColor: "#212460",
    },
    {
      icon: <EnvironmentOutlined />,
      label: "Location:",
      value: event?.location,
      height: 60,
      backgroundColor: "#212460",
    },
  ];

  const calendarButtons = [
    { icon: <GoogleOutlined />, label: "Google", id: "google" },
    { icon: <YahooOutlined />, label: "Yahoo", id: "yahoo" },
    { icon: <AppleOutlined />, label: "Apple", id: "apple" },
    { icon: <MailOutlined />, label: "Outlook", id: "outlook" },
  ];

  const socialMediaIcons = [
    { icon: <FacebookFilled style={{ fontSize: "22px" }} />, name: "Facebook" },
    { icon: <TwitterOutlined style={{ fontSize: "22px" }} />, name: "Twitter" },
    {
      icon: <WhatsAppOutlined style={{ fontSize: "22px" }} />,
      name: "WhatsApp",
    },
    { icon: <MailOutlined style={{ fontSize: "22px" }} />, name: "Gmail" },
  ];

  const socialButtonStyle = {
    backgroundColor: "#212460",
    color: "white",
    border: "none",
    marginRight: "20px",
    height: "40px",
    width: "40px",
  };

  const handleSocialMediaShare = (socialMedia) => {
    const url = window.location.href;
    switch (socialMedia) {
      case "Facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            url
          )}`
        );
        break;
      case "Twitter":
        window.open(
          `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            url
          )}&text=Check out this event!`
        );
        break;
      case "WhatsApp":
        window.open(
          `https://wa.me/?text=Check out this event! ${encodeURIComponent(url)}`
        );
        break;
      case "Gmail":
        window.location.href = `mailto:?subject=Check out this event!&body=I thought you might be interested in this event: ${url}`;
        break;
      default:
        break;
    }
  };

  const handleAddToCalendarClick = () => {
    setShowCalendarButtons(!showCalendarButtons);
  };

  const handleAddToCalendar = (calendar) => {
    const { title, start_date, end_date, location } = event;
    const startDate = new Date(start_date)
      .toISOString()
      .replace(/-|:|\.\d+/g, "");
    const endDate = new Date(end_date).toISOString().replace(/-|:|\.\d+/g, "");
    const eventDetails = encodeURIComponent(location);
    switch (calendar) {
      case "Google":
        window.open(
          `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
            title
          )}&dates=${startDate}Z/${endDate}Z&details=${eventDetails}`
        );
        break;
      case "Yahoo":
        window.open(
          `https://calendar.yahoo.com/?v=60&view=d&type=20&title=${encodeURIComponent(
            title
          )}&st=${startDate}&et=${endDate}&desc=${eventDetails}`
        );
        break;
      case "Apple":
        window.open(
          `data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0ASUMMARY:${encodeURIComponent(
            title
          )}%0ADESCRIPTION:${eventDetails}%0ADTSTART:${startDate}Z%0ADTEND:${endDate}Z%0AEND:VEVENT%0AEND:VCALENDAR`
        );
        break;
      case "Outlook":
        window.open(
          `https://outlook.live.com/owa/?path=/calendar/action/compose&rru=addevent&startdt=${startDate}Z&enddt=${endDate}Z&subject=${encodeURIComponent(
            title
          )}&location=${encodeURIComponent(location)}`
        );
        break;
      default:
        break;
    }
  };

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    for (const ticket of events.tickets) {
      const quantity = ticketQuantities[ticket.id] || 0;
      totalPrice += quantity * parseFloat(ticket.sale_price);
    }
    return totalPrice;
  };

  const totalAvailableSeats = events.tickets.reduce(
    (total, ticket) => total + parseInt(ticket.available_qty),
    0
  );

  const totalSelectedQuantity = Object.values(ticketQuantities).reduce(
    (total, quantity) => total + parseInt(quantity || 0),
    0
  );

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const eventData = await getEventswithTickets(event.id);
        console.log("Fetched Event Data: ", eventData);
        console.log("Fetched Event Tickets: ", eventData.event.tickets); // Log tickets
        setEvents(eventData.event);
      } catch (error) {
        console.error("Error fetching Event Data:", error);
      }
    };

    fetchEventData();
  }, []);

  const handleSubmit = () => {
    const eventName = events.title;

    const ticketData = events.tickets
      .filter((ticket) => ticketQuantities[ticket.id] > 0)
      .map((ticket) => ({
        product: {
          id: ticket.id,
          name: ticket.name,
          type: "TI",
          image_url:
            "https://img.freepik.com/free-photo/old-used-brown-torn-ticket-stub-isolated_1101-3193.jpg?size=626&ext=jpg&ga=GA1.1.553209589.1714867200&semt=ais",
        },
        salePrice: ticket.sale_price,
        qty: ticketQuantities[ticket.id],
        cost: (ticket.sale_price * ticketQuantities[ticket.id]).toFixed(2),
        eventName: eventName,
      }));

    ticketData.forEach((cartItem) => {
      const ci = window.localStorage.getItem("jrdc_cart_items");

      if (ci) {
        const ciObj = JSON.parse(ci);
        const existingIndex = ciObj.findIndex(
          (ci) => ci.product.name === cartItem.product.name
        );
        if (existingIndex !== -1) {
          ciObj[existingIndex] = cartItem;
        } else {
          ciObj.push(cartItem);
        }
        window.localStorage.setItem("jrdc_cart_items", JSON.stringify(ciObj));
      } else {
        window.localStorage.setItem(
          "jrdc_cart_items",
          JSON.stringify([cartItem])
        );
      }
    });

    navigate("/shopCart");
  };

  return (
    <Page>
      <Navbar />
      <div className="page-content woocommerce">
        <div className="container clear-fix">
          <div className="grid-col-row">
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: isMobile ? "center" : "space-between",
                alignItems: "stretch",
              }}
            >
              <Card
                style={{
                  width: isMobile ? "100%" : "70%",
                  marginBottom: 20,
                  maxHeight: "auto",
                }}
              >
                <p>Home / Upcoming Events / Event</p>
                <h2
                  style={{
                    fontFamily: "'Roboto', sans-serif",
                    color: "black",
                    fontSize: "30px",
                    marginBottom: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {event.title}
                </h2>
                <div style={{ width: "100%" }}>
                  <img
                    alt="event"
                    src={event?.image_url || default_img}
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "10px 0 0 10px",
                      display: "block",
                      marginBottom: "40px",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      margin: "0 20px",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      marginBottom: "40px",
                      marginTop: "40px",
                    }}
                  >
                    {tileData.map((tile, index) => (
                      <div
                        key={index}
                        style={{
                          width: "100%",
                          maxWidth: "250px",
                          marginBottom: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: tile.height,
                            backgroundColor: tile.backgroundColor,
                            borderRadius: "10px",
                            padding: "10px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "24px",
                              marginBottom: 5,
                              marginRight: 25,
                              color: "white",
                            }}
                          >
                            {tile.icon}
                          </div>
                          <div>
                            <p
                              style={{
                                margin: 0,
                                fontWeight: "bold",
                                color: "white",
                              }}
                            >
                              {tile.label}
                            </p>
                            <p style={{ margin: 0, color: "white" }}>
                              {tile.value}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <p
                    style={{
                      fontSize: "16px",
                      fontFamily: "'Roboto', sans-serif",
                      marginBottom: "10px",
                      padding: "0 20px",
                      textAlign: "justify",
                    }}
                  >
                    {event.description}
                  </p>
                </div>
                <br />
                <h3
                  style={{
                    fontFamily: "'Roboto', sans-serif",
                    color: "#B9001F",
                    fontSize: "26px",
                    marginBottom: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Register Now:
                </h3>
                <div>
                  <Table
                    dataSource={events.tickets}
                    pagination={false}
                    style={{ marginBottom: 20 }}
                    bordered
                    size="middle"
                    footer={() => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <p style={{ fontSize: "16px" }}>
                            <b>Quantity: {totalSelectedQuantity}</b>
                          </p>
                          <p style={{ fontSize: "16px" }}>
                            <b>Total:</b> Rs. {calculateTotalPrice().toFixed(2)}
                          </p>
                        </div>
                        <Button
                          type="primary"
                          style={{
                            height: "50px",
                            fontSize: "16px",
                            fontFamily: "'Roboto', sans-serif",
                            backgroundColor: "#B9001F",
                          }}
                          onClick={handleSubmit}
                        >
                          Buy Ticket(s)
                        </Button>
                      </div>
                    )}
                    headerStyle={{ background: "#630330", color: "white" }}
                  >
                    <Column
                      title="Ticket Type:"
                      align="center"
                      render={(ticket) => (
                        <>
                          <p>
                            <b>{ticket.name}</b>
                          </p>
                          <p style={{ color: "red", fontSize: "16px" }}>
                            {ticket.available_qty} tickets left!
                          </p>
                        </>
                      )}
                    />
                    <Column
                      title=""
                      align="center"
                      render={(ticket) => (
                        <>
                          <p>Ticket Qty:</p>
                          <Input
                            type="number"
                            value={
                              ticketQuantities[ticket.id] >= 0
                                ? ticketQuantities[ticket.id]
                                : 0
                            }
                            onChange={(e) =>
                              handleQuantityChange(ticket.id, e.target.value)
                            }
                            style={{ width: 80 }}
                          />
                        </>
                      )}
                    />
                    <Column
                      title=""
                      align="center"
                      render={(ticket) => {
                        return (
                          <>
                            <p>Per Ticket Price:</p>
                            <p>Rs. {ticket.sale_price}</p>
                          </>
                        );
                      }}
                    />
                  </Table>
                </div>
              </Card>
              <Card
                title={
                  <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Event Info
                  </span>
                }
                style={{
                  width: isMobile ? "100%" : "28%",
                  height: "50%",
                  marginBottom: isMobile ? 20 : 0,
                }}
                bordered={false}
              >
                <p style={{ textAlign: "center", fontSize: "16px" }}>
                  <b>Total Seats: </b>
                  {events.total_tickets} (<b>{totalAvailableSeats}</b> Left)
                </p>
                <Divider />
                <h4 style={{ fontFamily: "'Roboto', sans-serif" }}>
                  <EnvironmentOutlined style={{ color: "#212460" }} />{" "}
                  <b style={{ color: "grey" }}>Location</b>
                </h4>
                <div style={{ fontSize: "16px" }}>
                  {/* <p>{event.location}</p> */}
                  <p>{event.location}</p>
                  {/* <p>Sri Lanka</p> */}
                </div>
                <Divider />
                <h4 style={{ fontFamily: "'Roboto', sans-serif" }}>
                  <CalendarOutlined style={{ color: "#212460" }} />{" "}
                  <b style={{ color: "grey" }}>Event Schedule Details</b>
                </h4>
                <div style={{ fontSize: "16px" }}>
                  <p>
                    <CalendarFilled style={{ color: "#212460" }} />{" "}
                    {formatDate(event.start_date)}
                  </p>
                  <p>
                    <ClockCircleFilled style={{ color: "#212460" }} />{" "}
                    {formatDate(event.start_date)}{" "}
                    {formatTime(event.start_date)} -{" "}
                    {formatDate(event.end_date)} {formatTime(event.end_date)}
                  </p>
                </div>
                <Divider />
                <h4
                  style={{
                    fontFamily: "'Roboto', sans-serif",
                    textAlign: "center",
                    marginBottom: "20px",
                  }}
                >
                  <b style={{ color: "grey" }}>Share This Event</b>
                </h4>
                <Row justify="center" style={{ marginBottom: 20 }}>
                  {socialMediaIcons.map((socialMedia, index) => (
                    <Button
                      key={index}
                      style={socialButtonStyle}
                      shape="circle"
                      icon={socialMedia.icon}
                      onClick={() => handleSocialMediaShare(socialMedia.name)}
                    />
                  ))}
                </Row>
                <Divider style={{ marginTop: "30px" }} />
                <div style={{ textAlign: "center" }}>
                  <Button
                    style={{
                      width: isMobile ? "100%" : isTab ? "auto" : "70%",
                      height: isTab ? "auto" : "50px",
                      borderRadius: "30px",
                      backgroundColor: "#212460",
                      color: "white",
                      fontSize: isTab ? "12px" : "16px",
                      fontFamily: "'Roboto', sans-serif",
                    }}
                    onClick={handleAddToCalendarClick}
                  >
                    <CalendarOutlined
                      style={{ color: "white", marginRight: "8px" }}
                    />
                    Add to Calendar
                  </Button>
                  {showCalendarButtons && (
                    <div style={{ marginTop: "10px" }}>
                      {calendarButtons.map((button) => (
                        <Button
                          key={button.id}
                          id={button.id}
                          style={{
                            width: "60%",
                            height: "40px",
                            borderRadius: "30px",
                            backgroundColor: "#212460",
                            color: "white",
                            fontSize: "14px",
                            fontFamily: "'Roboto', sans-serif",
                            marginBottom: "5px",
                          }}
                          onClick={() => handleAddToCalendar(button.label)}
                        >
                          {button.icon} {button.label}
                        </Button>
                      ))}
                    </div>
                  )}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Page>
  );
};

export default ViewEvent;
