import React from "react";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { Typography, Card } from "antd";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import default_img from "./../img/pattern/default.jpg";

const { Title, Paragraph, Text } = Typography;

const ViewPreviousProject = () => {
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { projectItem } = location.state;
  console.log("Received vacancy item:", projectItem);

  const styles = {
    cardContainer: {
      display: "flex",
      justifyContent: "center",
      padding: "20px",
    },
    card: {
      //maxWidth: "1200px",
      width: "100%",
      boxSizing: "border-box",
      padding: "20px",
    },
    title: {
      color: "#333",
      fontWeight: "bold",
      fontSize: isMobile ? "1.6rem" : "3rem",
      fontFamily: "Roboto, sans-serif",
      textAlign: "center",
      display: "block",
    },
    date: {
      color: "#666",
      fontSize: "16px",
      fontFamily: "Roboto, sans-serif",
      // textAlign: "left",
      marginBottom: "20px",
      display: "block",
      textAlign: "center",
    },
    imageContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    responsiveImage: {
      width: "100%",
      height: "auto",
      maxHeight: "600px",
      objectFit: "cover",
    },
    responsiveImageLarge: {
      width: "800px",
      height: "600px",
    },
    bodyText: {
      color: "#444",
      fontSize: "16px",
      fontFamily: "Roboto, sans-serif",
      marginTop: "20px",
      textAlign: "justify",
      // padding: "20px",
    },
  };
  const isLargeScreen = window.innerWidth >= 1200;
  return (
    <Page>
      <Navbar />
      <div className="page-content woocommerce">
        <div className="container clear-fix">
          <div className="grid-col-row">
            <div style={styles.cardContainer}>
              <Card style={styles.card}>
                <Typography>
                  <Text style={styles.title}>{projectItem.title}</Text>
                  <Text style={styles.date}>{projectItem.published_at}</Text>
                </Typography>
                <br />
                <div style={styles.imageContainer}>
                  <img
                    alt={projectItem.title}
                    src={
                      projectItem?.image_url
                        ? projectItem.image_url
                        : default_img
                    }
                    style={{
                      ...styles.responsiveImage,
                      ...(isLargeScreen && styles.responsiveImageLarge),
                    }}
                  />
                </div>
                <br />
                <div
                  style={styles.bodyText}
                  dangerouslySetInnerHTML={{ __html: projectItem.body }}
                ></div>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Page>
  );
};

export default ViewPreviousProject;
