import React from "react";
import { Typography, Button } from "antd";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { useMediaQuery } from "react-responsive";
import OSimg_1 from "../img/pattern/our_service_1.jpg";
import OSimg_2 from "../img/pattern/our_service_2.jpg";
import "./../styles/pageTitle.css";
import { useNavigate } from "react-router-dom";

const OurServices = () => {
  const { Title, Text } = Typography;
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();

  const buttonStyles = {
    background: "#B9001F",
    borderColor: "#B9001F",
    borderRadius: "30px",
    height: "45px",
    fontSize: isMobile ? "16px" : "18px",
    fontWeight: "bold",
    marginRight: isMobile ? "0" : "10px",
    marginBottom: isMobile ? "30px" : "0",
    width: isMobile ? "100%" : "auto",
  };

  const pStyle = {
    color: "#666",
    fontSize: "16px",
    fontFamily: "Roboto, sans-serif",
    textAlign: "justify",
    marginBottom: "20px",
    display: "block",
  };

  return (
    <Page>
      <Navbar />
      <div className="page-content woocommerce">
        <div className="container clear-fix">
          <div className="grid-col-row">
            <div className="site-card-border-less-wrapper">
              <div className="grid-col grid-col-12">
                <div className="col2-set" id="customer_details">
                  <h1 className="section-title">Our Services</h1>
                  <br />
                  <Text style={pStyle}>
                    JRDC conducts Water treatment/ Wastewater treatment/EIA
                    (Environment Impact Assessment) /Environmental studies.
                    These treatments, assessments and studies helps sustain
                    human health as water can contain contaminants that are
                    harmful for humans. Such treatments can benefit for the
                    purpose of irrigation and heath.
                  </Text>

                  <Text style={pStyle}>
                    JRDC invites researchers to execute and conduct projects on
                    developing, building and testing leading-edge water
                    treatment facilities, in these premises. Challenges and
                    problems occurring during the process of sustaining water
                    involves an integrated and collaborative effort of high
                    level sciences and engineering. It involves a whole-system
                    approach that requires for an integrated understanding of
                    theory and practicality with a combined effort to redefine
                    and innovate of technology.
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: isMobile ? "column" : "row",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "50px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={OSimg_1}
                        alt="First Image"
                        style={{
                          width: "90%",
                          marginBottom: isMobile ? "20px" : 0,
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: isMobile ? "20px" : "0",
                      }}
                    >
                      <img
                        src={OSimg_2}
                        alt="Second Image"
                        style={{ width: "90%", borderRadius: "8px" }}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: "50px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      type="primary"
                      size="large"
                      style={buttonStyles}
                      onClick={() => navigate("/AboutUs#our-clients")}
                    >
                      View Our Clients
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Page>
  );
};

export default OurServices;
