import React from "react";
import FormInput from "../Components/form/FormInput";
import Suite_Room from "../../src/img//pattern/Suite_Room.jpg";

import Double_Room from "../../src/img//pattern/Double_Room.jpg";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { DatePicker, Form } from "antd";
const DoubleRoomPage = () => {
  let Cost = 0;

  // cartItems.forEach((item) => {
  //   total += item.price * item.qty;
  // });
  return (
    <Page>
      <Navbar />

      <div class="page-content woocommerce">
        <div class="container clear-fix">
          <div class="grid-col-row">
            <div class="grid-col grid-col-12">
              <div role="main">
                <div
                  itemscope=""
                  itemtype="http://schema.org/Product"
                  class="product"
                >
                  <div class="images">
                    <div class="picture">
                      <img
                        src={Double_Room}
                        data-at2x="http://placehold.it/370x370"
                        class="attachment-shop_single"
                        alt=""
                        title=""
                      />
                      <div class="hover-effect"></div>
                      <div class="link-cont">
                        <a
                          href="http://placehold.it/370x370"
                          class="fancy fa fa-eye woocommerce-main-image zoom"
                          data-fancybox-group="gallery"
                          title=""
                          data-rel="prettyPhoto[product-gallery]"
                        ></a>
                      </div>
                    </div>
                    <div class="thumbnails">
                      <div class="owl-carousel">
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src="http://placehold.it/116x116"
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src="http://placehold.it/116x116"
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src="http://placehold.it/116x116"
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src={Suite_Room}
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="summary entry-summary">
                    <h2 class="product_title entry-title">Double Room</h2>

                    <div itemprop="description">
                      <p>
                        <strong>Double Room - 1 day</strong>
                        <br />
                        The default booking will be one (01) day. Add more no.
                        of additional days as per your requirement in the below
                        section.
                      </p>
                    </div>
                    <div className="col-md-3">
                      <Form.Item label="" name="date">
                        <DatePicker style={{ width: "100%" }} />
                      </Form.Item>

                      <FormInput
                        label="Number of additional days (+රු 4.000,00) +1 day "
                        name="Additional_Days"
                        type="number"
                      />
                    </div>
                    <div className="total">Cost: {Cost}</div>

                    <form class="cart" method="post">
                      <div class="quantity buttons_added">
                        <input
                          type="number"
                          step="1"
                          min="1"
                          name="quantity"
                          value="1"
                          title="Qty"
                          class="input-text qty text"
                        />
                      </div>
                      <a
                        href="/shopCart"
                        class="cws-button alt border-radius small bt-color-3"
                      >
                        CHECK AVAILABILITY
                      </a>
                    </form>
                  </div>
                  <hr class="divider-color" />
                  <div class="tabs">
                    <div class="block-tabs-btn clear-fix">
                      <div class="tabs-btn active" data-tabs-id="tabs1">
                        Description
                      </div>
                    </div>
                    <div class="tabs-keeper">
                      <div
                        class="container-tabs active"
                        data-tabs-id="cont-tabs1"
                      >
                        <p>
                          <strong>Room Details</strong>
                        </p>
                        <p>
                          We provide six double rooms that is designed under
                          twin-allocation or shared basis, each room is designed
                          for a dual shared relaxing experience. The rooms
                          follows a modern minimalist and sophisticated setting,
                          creating an aerodynamic feel and a cozy ambience.
                        </p>
                        <p>
                          These spacey rooms come with an attached bathrooms and
                          air conditioning, with warm water to ensure a calm,
                          serene and a comforting stay. The wooden furnishings
                          adds a spark of elegance and luxury, allowing for
                          maximum comfort and convenience. Soft pillows and beds
                          allow you to revel and relax in comfort.
                        </p>

                        <p>
                          <strong>The amenities in the room includes:</strong>
                        </p>
                        <p>
                          We also provide other fantastic facilities such as the
                          swimming pool, a café for your refreshments and a Gym
                          for exercise.
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr class="divider-color" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </Page>
  );
};

export default DoubleRoomPage;
