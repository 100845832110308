import React, { useEffect, useRef, useState } from "react";
import { Input, Button } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";

const Captcha = ({ onChange }) => {
  const [captchaText, setCaptchaText] = useState("");
  const [isMobileView, setIsMobileView] = useState(false);
  const [isCaptchaCorrect, setIsCaptchaCorrect] = useState(false);
  const canvasRef = useRef(null);

  useEffect(() => {
    generateCaptcha();
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 768);
  };

  const generateCaptcha = () => {
    const chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const text = Array.from(
      { length: 6 },
      () => chars[Math.floor(Math.random() * chars.length)]
    ).join("");
    setCaptchaText(text);
    setIsCaptchaCorrect(false);
    drawCaptcha(text);
  };

  const drawCaptcha = (text) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Background color
    ctx.fillStyle = getRandomLightColor();
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Background noise
    for (let i = 0; i < 150; i++) {
      ctx.fillStyle = getRandomColor();
      ctx.beginPath();
      ctx.arc(
        Math.random() * canvas.width,
        Math.random() * canvas.height,
        1,
        0,
        Math.PI * 2,
        true
      );
      ctx.fill();
    }

    // Draw text with random distortions, varied fonts, and sizes
    const fonts = ["Arial", "Verdana", "Tahoma", "Georgia", "Courier"];
    text.split("").forEach((char, index) => {
      ctx.font = `${30 + getRandomInt(-5, 5)}px ${
        fonts[getRandomInt(0, fonts.length - 1)]
      }`;
      ctx.fillStyle = getRandomColor();
      ctx.fillText(
        char,
        20 + index * 30 + getRandomInt(-5, 5),
        35 + getRandomInt(-5, 5)
      );
    });

    // Add random lines
    for (let i = 0; i < 5; i++) {
      ctx.strokeStyle = getRandomColor();
      ctx.lineWidth = getRandomInt(1, 3);
      ctx.beginPath();
      ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
      ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
      ctx.stroke();
    }
  };

  const getRandomInt = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  const getRandomColor = () =>
    `rgb(${getRandomInt(100, 200)}, ${getRandomInt(100, 200)}, ${getRandomInt(
      100,
      200
    )})`;

  const getRandomLightColor = () =>
    `rgb(${getRandomInt(220, 255)}, ${getRandomInt(220, 255)}, ${getRandomInt(
      220,
      255
    )})`;

  const handleInputChange = (e) => {
    onChange(e.target.value === captchaText);
    setIsCaptchaCorrect(e.target.value === captchaText);
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <canvas
          ref={canvasRef}
          width="200"
          height="50"
          style={{ border: "1px solid black" }}
        ></canvas>

        {!isMobileView && (
          <Input
            placeholder="Enter CAPTCHA"
            onChange={handleInputChange}
            style={{ marginLeft: "30px", width: "50%", marginTop: "-10px" }}
            suffix={
              isCaptchaCorrect ? (
                <CheckCircleFilled style={{ color: "green" }} />
              ) : null
            }
          />
        )}
      </div>

      <Button
        onClick={generateCaptcha}
        style={{ marginLeft: "10px", marginTop: "10px" }}
      >
        Refresh CAPTCHA
      </Button>

      {isMobileView && (
        <Input
          placeholder="Enter CAPTCHA"
          onChange={handleInputChange}
          style={{ width: "100%", marginTop: "10px" }}
          suffix={
            isCaptchaCorrect ? (
              <CheckCircleFilled style={{ color: "green" }} />
            ) : null
          }
        />
      )}
    </div>
  );
};

export default Captcha;
