import React, { useState, useEffect } from "react";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import {
  Button,
  Form,
  Radio,
  Space,
  Table,
  Checkbox,
  message,
  Tag,
} from "antd";
import { useForm } from "antd/es/form/Form";
import mastercard from "../../src/img/pattern/mastercard.png";
import { showOrder } from ".././Api/index";
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "./../Api/index";

const PaymentPage = () => {
  const { orderId } = useParams();
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [orderData, setOrderData] = useState(null);
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [form] = Form.useForm();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const pdfUrl = process.env.PUBLIC_URL + "/t&c.pdf";

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const response = await showOrder(orderId);
        const data = await response.json();
        console.log("Order data: ", data);
        setOrderData(data);
        localStorage.setItem("orderData", JSON.stringify(data.order));
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    };

    fetchOrderData();
  }, [orderId]);

  const onChange = (e) => {
    const value = e.target.value;
    if (value === 1) {
      setDescription(
        "Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be processed until the funds have cleared in our account."
      );
    } else if (value === 2) {
      setDescription(
        "Pay by Visa, MasterCard, AMEX, eZcash, mCash, or Internet Banking via cypherpay."
      );
    }

    setSelectedOption(e.target.value);
  };

  const handleFormSubmission = () => {
    if (!selectedOption) {
      message.error("Please select a payment method to proceed.");
      return;
    }

    if (!checkboxChecked) {
      message.error("Please agree to the terms and conditions to proceed.");
      return;
    }

    if (selectedOption === 1) {
      navigate("/direct_bank_transfer", { state: { orderData } });
      console.log("Sent Order Data: ", orderData);
    } else {
      sendDirectPayment("card", orderData.total);
      console.log("Sent Order Data: ", orderData);
    }
  };

  const sendDirectPayment = (type, total) => {
    setIsButtonLoading(true);
    let formData = new FormData();
    formData.append("user_id", orderData.order.customer_id);
    formData.append("reference_id", orderData.order.ref_no);
    formData.append("payment_type", orderData.order.bill_payment_method);
    formData.append(
      "description",
      `${orderData.order.order_products} ${
        orderData.order.total_items_qty
      } ${"LKR"} ${orderData.order.total}`
    );
    formData.append("total", orderData.order.total);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    console.log(formData, "formData");
    axios
      .post(
        `${BASE_URL}/orders/${orderData.order.id}/payment_card`,
        formData,
        config
      )
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          // Store the session ID in localStorage
          localStorage.setItem("sessionId", response.data.data.session_id);
          localStorage.setItem("total", response.data.data.amount);

          // If the payment request is successful, redirect to card payment page
          navigate("/card_payment", { state: { orderData } });
        } else {
          console.error("Payment request failed");
          message.error("Failed to initiate payment. Please try again later.");
        }
        setIsButtonLoading(false);
      })
      .catch((error) => {
        console.log(error, "error");
        message.error(
          "An error occurred while processing your payment. Please try again later."
        );
        setIsButtonLoading(false);
      });
  };

  const formatDateTime = (dateTimeStr) => {
    if (!dateTimeStr) return "N/A";

    const dateTime = new Date(dateTimeStr);
    const dateOptions = { year: "numeric", month: "long", day: "numeric" };
    const timeOptions = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    const date = dateTime.toLocaleDateString("en-US", dateOptions);
    const time = dateTime.toLocaleTimeString("en-US", timeOptions);

    // Check if time is "00:00:00"
    return time === "12:00:00 AM" ? date : `${date}, ${time}`;
  };

  const mapPaymentMethod = (shortValue) => {
    switch (shortValue) {
      case "dbt":
        return "Direct Bank Transfer";
      case "chp":
        return "Cypherpay";
      case "pre":
        return "Pre Order";
      case "oth":
        return "Other";
      default:
        return "Unknown";
    }
  };

  const columns = [
    {
      title: "Product",
      dataIndex: "product.name",
      key: "product.name",
      render: (text, record) => (
        <div style={{ marginBottom: "10px" }}>
          <span>
            {" "}
            <b>{record.product.name || "Product: N/A"}</b>
          </span>
          {record.product.type === "AP" && (
            <>
              {record.appointment ? (
                <div style={{ marginTop: "5px" }}>
                  <p
                    style={{
                      margin: "0",
                    }}
                  >
                    Appointment ID: #{" "}
                    {record.appointment.id ? record.appointment.id : "N/A"}
                  </p>
                  <p style={{ margin: "0" }}>
                    When:{" "}
                    {record.appointment.start_date_time
                      ? formatDateTime(record.appointment.start_date_time)
                      : "N/A"}
                  </p>
                  <p style={{ margin: "0" }}>
                    Duration:{" "}
                    {record.appointment.start_date_time &&
                    record.appointment.end_date_time
                      ? calculateDuration(
                          record.appointment.start_date_time,
                          record.appointment.end_date_time
                        )
                      : "N/A"}{" "}
                    hours
                  </p>
                </div>
              ) : (
                <div style={{ marginTop: "5px" }}>
                  <p style={{ margin: "0" }}>
                    <b>Appointment #N/A</b>
                  </p>
                  <p
                    style={{
                      margin: "0",
                      fontSize: "12px",
                      color: "#888",
                    }}
                  >
                    When: N/A
                  </p>
                  <p
                    style={{
                      margin: "0",
                      fontSize: "12px",
                      color: "#888",
                    }}
                  >
                    Duration: N/A
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      ),
    },

    {
      title: "Appointment Status",
      dataIndex: "appointment.status",
      key: "appointment.status",
      render: (text, record) => (
        <span>
          {record.appointment ? (
            <Tag
              color={
                getAppointmentStatus[record.appointment.status]?.color ||
                "default"
              }
            >
              {getAppointmentStatus[record.appointment.status]?.name ||
                "Unknown"}
            </Tag>
          ) : (
            "N/A"
          )}
        </span>
      ),
    },

    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text) => <span>LKR: {text}</span>,
    },
  ];

  const calculateDuration = (startDateTime, endDateTime) => {
    const startDate = new Date(startDateTime);
    const endDate = new Date(endDateTime);
    const duration = (endDate - startDate) / (1000 * 60 * 60);
    return duration.toFixed(0);
  };

  const getAppointmentStatus = {
    1: { name: "Pending Confirmation", color: "blue" },
    2: { name: "Confirmed", color: "green" },
    3: { name: "Rejected", color: "red" },
    4: { name: "Completed", color: "cyan" },
    5: { name: "Cancelled", color: "volcano" },
    6: { name: "Processing Refund", color: "orange" },
    7: { name: "Refunded", color: "purple" },
  };

  return (
    <Page>
      <Navbar />
      <div className="page-content woocommerce">
        <div className="container clear-fix">
          <div className="grid-col-row">
            <h2 className="container-h2">Pay for Order</h2>

            <h3 style={{ fontFamily: "Roboto" }}>Your order</h3>
            {orderData && (
              <div
                style={{
                  overflowX: "auto",
                  maxWidth: "100%",
                }}
              >
                <Table
                  dataSource={orderData.order.order_lines}
                  columns={columns}
                  pagination={false}
                  style={{ marginBottom: "60px", minWidth: "600px" }}
                  bordered
                  footer={() => (
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: 1 }}>
                        <div
                          style={{ marginBottom: "10px", fontWeight: "bold" }}
                        >
                          Sub Total:
                        </div>
                        {/* <div
                          style={{ marginBottom: "10px", fontWeight: "bold" }}
                        >
                          Payment Method:
                        </div> */}
                        <div style={{ fontWeight: "bold" }}>Total:</div>
                      </div>
                      <div style={{ flex: 1 }}>
                        <div
                          style={{
                            marginBottom: "10px",
                            fontWeight: "bold",
                            textAlign: "right",
                          }}
                        >
                          {orderData.order.items_total}
                        </div>
                        {/* <div
                          style={{
                            marginBottom: "10px",
                            fontWeight: "bold",
                            textAlign: "right",
                          }}
                        >
                          {mapPaymentMethod(
                            orderData.order.bill_payment_method
                          )}
                        </div> */}
                        <div style={{ fontWeight: "bold", textAlign: "right" }}>
                          {orderData.order.items_total}
                        </div>
                      </div>
                    </div>
                  )}
                />
              </div>
            )}

            <div className="col-2">
              <div className="cart_totals">
                <Form
                  name="basic"
                  autoComplete="off"
                  layout="vertical"
                  onFinish={handleFormSubmission}
                  form={form}
                >
                  <Form.Item className="p-2 ">
                    <Radio.Group onChange={onChange} value={selectedOption}>
                      <Space direction="vertical">
                        <Radio value={1} style={{ fontSize: "20px" }}>
                          <b>Direct bank transfer</b>
                        </Radio>
                        {selectedOption === 1 && (
                          <div
                            style={{
                              marginTop: "10px",
                              border: "1px solid #C8C8C8",
                              padding: "10px",
                              maxWidth: "600px",
                              fontFamily: "Roboto",
                              fontSize: "16px",
                              backgroundColor: "#FFFFFF",
                            }}
                          >
                            Make your payment directly into our bank account.
                            Please use your Order ID as the payment reference.
                            Your order will not be processed until the funds
                            have cleared in our account.
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            maxWidth: "600px",
                          }}
                        >
                          <Radio value={2} style={{ fontSize: "20px" }}>
                            <b>Card Payment</b>
                          </Radio>
                          <img
                            src={mastercard}
                            data-at2x="http://placehold.it/370x370"
                            className="attachment-shop_single"
                            alt=""
                            title=""
                            style={{ marginLeft: "10px" }}
                          />
                        </div>

                        {selectedOption === 2 && (
                          <div
                            style={{
                              marginTop: "10px",
                              border: "1px solid #C8C8C8",
                              padding: "10px",
                              maxWidth: "600px",
                              fontFamily: "Roboto",
                              fontSize: "16px",
                              backgroundColor: "#FFFFFF",
                            }}
                          >
                            Pay by Visa, MasterCard, AMEX, eZcash, mCash, or
                            Internet Banking via cypherpay.
                          </div>
                        )}
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                  <div
                    style={{
                      marginTop: "40px",
                      marginBottom: "40px",
                      border: "1px solid #C8C8C8",
                      padding: "10px",
                      maxWidth: "600px",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <p>
                      <b>
                        <u>Bank Details</u>
                      </b>
                    </p>
                    <p>
                      <b>Account Number:</b> 7040576
                    </p>
                    <p>
                      <b>SWIFT Code:</b> BCEYLKLX
                    </p>
                    <p>
                      <b>Name:</b> Secretary Ministry of water supply and estate
                      infrastructure development, China Sri Lanka joint research
                      and demonstration center for water technology
                    </p>
                    <p>
                      <b>Bank:</b> BOC
                    </p>
                    <p>
                      <b>Branch:</b> Peradeniya University Branch
                    </p>
                  </div>
                  <br />
                  <Form.Item className="p-2 ">
                    <p style={{ fontSize: "18px", textAlign: "justify" }}>
                      A Facility request form must be completed and submitted to
                      JRDC administration no later than 10 business days prior
                      to the scheduled event. Please note that completion of
                      this application does not guarantee approval. JRDC
                      reserves the right to deny facility reservations on a
                      case-by-case basis regarding appropriateness for the
                      facility/building.
                    </p>
                    <br />
                    <p style={{ fontSize: "18px", textAlign: "justify" }}>
                      Requesting parties will receive an email notification that
                      a request has been received and requests will be processed
                      (confirmed or denied) within 3-5 business days. This
                      notification does not guarantee that the space has been
                      allocated. The requesting party will receive an approved
                      copy of their space request via email which will confirm
                      space allocation.
                    </p>
                    <br />
                    <p style={{ fontSize: "18px", textAlign: "justify" }}>
                      {" "}
                      JRDC is not keeping any bank details of the requesting
                      parties and all the payments are handle through the bank
                      when you're using card payments especially.
                    </p>
                    <br />
                  </Form.Item>
                  <p
                    style={{
                      color: "red",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      marginBottom: "40px",
                      textAlign: "justify",
                    }}
                  >
                    In case your card payment was successful but the order has
                    not changed / updated the status (stays pending payment)
                    first try refreshing the page. If it still doesn't update
                    please contact the admin through the contact us page. Make
                    sure to provide your transaction ID/No, Order ID along with
                    your contact information.
                  </p>
                  <Form.Item className="p-2">
                    <Checkbox
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                      checked={checkboxChecked}
                      onChange={(e) => setCheckboxChecked(e.target.checked)}
                    >
                      I have read and agree to the website{" "}
                      <a style={{ color: "#B9001F" }} href={pdfUrl}>
                        terms and conditions
                      </a>
                    </Checkbox>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="fos-btn-primary w-100 mt-5"
                      loading={loading}
                      style={{
                        background: "#B9001F",
                        borderColor: "#B9001F",
                      }}
                    >
                      Pay For Order
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Page>
  );
};

export default PaymentPage;
