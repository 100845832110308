import React, { useEffect, useState } from "react";
import { Card, Typography, Space, Button } from "antd";
import successImage from "./../../img/pattern/checked.png";
import { useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { getPaymentSlip } from "./../../Api/index.js"; // Adjust the import path

const { Title, Text } = Typography;

const PaymentSuccessCard = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();
  const { orderId } = useParams(); // Ensure this matches the route parameter name
  const [transactionData, setTransactionData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPaymentSlip(orderId);
        setTransactionData(data);
      } catch (error) {
        console.error("Error fetching transaction data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [orderId]);

  const handleRedirect = () => {
    navigate("/MyAccount/Orders");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!transactionData) {
    return <div>Transaction not found</div>;
  }

  const { amount, reference_id, updated_at } = transactionData;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f9f9f9",
      }}
    >
      <Card
        style={{
          maxWidth: "600px",
          width: "100%",
          padding: "30px",
          textAlign: "center",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          fontFamily: "Roboto, sans-serif",
        }}
      >
        <Title level={2} style={{ color: "#4BB543" }}>
          Payment Successful
        </Title>
        <img
          src={successImage}
          alt="Payment Successful"
          style={{ width: "120px", margin: "20px auto" }}
        />
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Text style={{ fontSize: "24px", fontWeight: "bold" }}>
            LKR: {amount.toLocaleString("en-US", { minimumFractionDigits: 2 })}
          </Text>
          <Text style={{ fontSize: "18px" }}>Reference ID: {reference_id}</Text>
          <Text style={{ fontSize: "18px" }}>
            {new Date(updated_at).toLocaleDateString()}{" "}
            {new Date(updated_at).toLocaleTimeString()}
          </Text>
          {!isMobile && (
            <Button
              type="primary"
              onClick={handleRedirect}
              style={{
                backgroundColor: "#B9001F",
                borderColor: "#B9001F",
                marginTop: "20px",
              }}
            >
              Go back to My Orders
            </Button>
          )}
        </Space>
      </Card>
    </div>
  );
};

export default PaymentSuccessCard;
