import React, { useState, useEffect } from "react";
import { Button, Card, Row, Col } from "antd";
import Footer from "./../Components/layouts/Footer";
import Navbar from "./../Components/layouts/Navbar";
import Page from "./../Components/layouts/Page";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getProductsByEventId } from "./../Api/index";
import { useMediaQuery } from "react-responsive";
import default_img from "./../img/pattern/default.jpg";

const ResearchpaperCategories = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const eventId = location.state ? location.state.eventId : null;
  const [products, setProducts] = useState([]);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    if (eventId) {
      fetchProducts(eventId);
    }
  }, [eventId]);

  const fetchProducts = async (eventId) => {
    try {
      const data = await getProductsByEventId(eventId);
      console.log("API Response:", data);
      if (Array.isArray(data.products) && data.products.length > 0) {
        setProducts(data.products);
      } else {
        setProducts([]);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      setProducts([]);
    }
  };

  const handleOnClick = (product, eventId) => {
    navigate(`/AddResearchPaper`, { state: { event: eventId, product } });
    console.log("sent product data:", product);
    console.log("sent event data:", eventId);
  };

  return (
    <Page>
      <Navbar />

      <div className="page-content woocommerce">
        <div className="container clear-fix">
          <div className="grid-col-row">
            <div className="grid-col grid-col-12">
              <h2 className="container-h2">Research Paper Categories</h2>
              <Row
                gutter={[16, 16]}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  marginBottom: "150px",
                }}
              >
                {products.map((product) => (
                  <div
                    key={product.id}
                    style={{ margin: "16px", width: "400px" }}
                  >
                    <Button
                      type="button"
                      onClick={() => handleOnClick(product, eventId)}
                      block
                      style={{ padding: 0, border: "none", width: "400px" }}
                    >
                      <Card
                        hoverable
                        style={{ marginBottom: 20 }}
                        onClick={() => handleOnClick(product, eventId)}
                      >
                        <img
                          alt={product.name}
                          src={product.image_url || default_img}
                          style={{
                            height: "350px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                        <div
                          className="product-name-container"
                          style={{
                            textAlign: "center",
                            marginBottom: 10,
                            marginTop: "20px",
                          }}
                        >
                          <span
                            className="product-name"
                            style={{
                              color: "red",
                              fontFamily: "Roboto, sans-serif",
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}
                          >
                            {product.name}
                          </span>
                        </div>
                      </Card>
                    </Button>
                  </div>
                ))}
              </Row>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Page>
  );
};
export default ResearchpaperCategories;
