import React from "react";
import { Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export const FloatingButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/search-results");
  };

  return (
    <Button
      type="primary"
      shape="round"
      icon={<SearchOutlined />}
      size="large"
      onClick={handleClick}
      style={floatingButtonStyle}
      className="floating-button"
    >
      Search . . .
    </Button>
  );
};

const floatingButtonStyle = {
  position: "fixed",
  bottom: "50px",
  right: "30px",
  zIndex: 1000,
  fontSize: "16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "transform 0.3s ease, background-color 0.3s ease",
  backgroundColor: "#2F5597",
  boxShadow: "0 8px 16px 0 rgba(0,0,255,0.1), 0 6px 20px 0 rgba(0,0,255,0.19)",
  color: "white",
};

const hoverEffect = `
.floating-button:hover {
  transform: scale(1.1);
}
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = hoverEffect;
document.head.appendChild(styleSheet);

export default FloatingButton;
