import React, { useState, useRef } from "react";
import { Typography, Row, Col } from "antd";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import "./../styles/pageTitle.css";

const HowtoReserve = () => {
  const { Text } = Typography;

  return (
    <Page>
      <Navbar />
      <div className="page-content woocommerce">
        <div className="container clear-fix">
          <div className="grid-col-row">
            <div className="site-card-border-less-wrapper">
              <div className="grid-col grid-col-12">
                <div className="col2-set" id="customer_details">
                  <h1 className="section-title">How to Reserve</h1>
                  <br />
                  <Text
                    style={{
                      color: "black",
                      fontSize: "18px",
                      fontFamily: "Roboto, sans-serif",
                      textAlign: "justify",
                      marginBottom: "20px",
                      display: "block",
                    }}
                  >
                    JRDC accouters a number of facilities that account for every
                    requirement that you may have, these facilities are designed
                    specifically to cater to your needs and comfort. It aims to
                    encourage development and growth through shared knowledge
                    and collaborative research. Allowing the users to master,
                    innovate, design, and refine the technology for water
                    treatment. These facilities are located around a remarkable
                    and vibrant environment that is capable of enriching the
                    mind. The Facilities are open for use on a scheduled basis.
                  </Text>
                  <Row gutter={(32, 32)} style={{ justifyContent: "center" }}>
                    <Col>
                      <div
                        style={{
                          maxWidth: "580px",
                          maxHeight: "387px",
                          margin: "0 auto",
                          overflow: "hidden",
                          cursor: "pointer",
                          marginBottom: "20px",
                          marginTop: "20px",
                        }}
                      >
                        <img
                          src="https://jrdc.lk/wp-content/uploads/2021/10/Facilities-1980x1320.jpg"
                          alt={`Image 1`}
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "8px",
                          }}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div
                        style={{
                          maxWidth: "580px",
                          maxHeight: "387px",
                          margin: "0 auto",
                          overflow: "hidden",
                          cursor: "pointer",
                          marginBottom: "20px",
                          marginTop: "20px",
                        }}
                      >
                        <img
                          src="https://jrdc.lk/wp-content/uploads/2021/06/IMG_4728-1980x1320.jpg"
                          alt={`Image 1`}
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "8px",
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <h3
                    style={{
                      color: "black",
                      fontSize: "32px",
                      fontFamily: "Roboto, sans-serif",
                      textAlign: "left",
                      marginTop: "30px",
                      marginBottom: "20px",
                      display: "block",
                    }}
                  >
                    PROCEDURES
                  </h3>
                  <Text
                    style={{
                      color: "black",
                      fontSize: "18px",
                      fontFamily: "Roboto, sans-serif",
                      textAlign: "justify",
                      marginBottom: "20px",
                      display: "block",
                    }}
                  >
                    A Facility request form must be completed and submitted to
                    JRDC administration no later than 10 business days prior to
                    the scheduled event. Please note that completion of this
                    application does not guarantee approval. JRDC reserves the
                    right to deny facility reservations on a case-by-case basis
                    regarding appropriateness for the facility/building.
                    Requesting parties will receive an email notification that
                    the request has been received and requests will be processed
                    (confirmed or denied) within 3-5 business days. This
                    notification does not guarantee that the space has been
                    allocated. The requesting party will receive an approved
                    copy of their space request via email which will confirm
                    space allocation.
                  </Text>
                  <Text
                    style={{
                      color: "black",
                      fontSize: "18px",
                      fontFamily: "Roboto, sans-serif",
                      textAlign: "justify",
                      marginBottom: "20px",
                      display: "block",
                    }}
                  >
                    JRDC accouters a number of facilities that accounts for
                    every requirement that you may have, these facilities are
                    designed specifically to cater to your needs and comfort. It
                    aims to encourage development and growth through shared
                    knowledge and collaborative research. Allowing the users to
                    master, innovate, design and refine technology for water
                    treatment. These facilities are located around a remarkable
                    and vibrant environment that is capable of enriching the
                    mind. The Facilities are open for use on a scheduled basis.
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Page>
  );
};

export default HowtoReserve;
