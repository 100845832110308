import React from "react";
import { Card, Col, Row, Button, Typography } from "antd";
import { useMediaQuery } from "react-responsive";
import default_img from "./../../img/pattern/default.jpg";

const { Title, Text } = Typography;

const CommonCard = ({ data, onClick }) => {
  const isTab = useMediaQuery({ minWidth: 768, maxWidth: 1024 });

  return (
    <Row gutter={[32, 32]}>
      {data.map((item, index) => (
        <Col key={index} xs={24} sm={12} md={12} lg={8}>
          <Card
            hoverable
            cover={
              <img
                alt="example"
                src={item?.image_url ? item.image_url : default_img}
                style={{ height: "200px", objectFit: "cover" }}
              />
            }
            style={{
              marginBottom: 10,
              height: "100%",
              borderRadius: "10px",
              width: isTab ? "90%" : "100%",
            }}
            onClick={() => onClick(item.id)}
          >
            <div style={{ height: "200px", overflow: "hidden" }}>
              <Title
                level={4}
                style={{
                  marginBottom: "8px",
                  color: "#333",
                  fontWeight: "bold",
                  fontSize: "18px",
                  fontFamily: "Roboto, sans-serif",
                  textAlign: "justify",
                }}
              >
                {item.title}
              </Title>
              <Text
                type="secondary"
                style={{
                  marginBottom: "8px",
                  color: "#666",
                  fontSize: "14px",
                  fontFamily: "Roboto, sans-serif",
                  textAlign: "justify",
                }}
              >
                {item.published_at}
              </Text>
              <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
              <p
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "hidden",
                  display: "-webkit-box",
                  WebkitLineClamp: 5,
                  WebkitBoxOrient: "vertical",
                  color: "#666",
                  fontSize: "14px",
                  fontFamily: "Roboto, sans-serif",
                }}
                dangerouslySetInnerHTML={{ __html: item.body }}
              ></p>
            </div>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default CommonCard;
