import React from "react";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { Typography, Card } from "antd";

const { Title, Paragraph } = Typography;

const NotPublishedPage = () => {
  return (
    <Page>
      <Navbar />
      <div style={{ padding: "50px 20px" }}>
        <Card
          style={{
            maxWidth: "1200px",
            margin: "0 auto",
            padding: "30px",
            borderRadius: "8px",
          }}
        >
          <Title
            level={1}
            style={{
              color: "#B9001F",
              fontWeight: "bold",
              fontSize: "3.5rem",
              fontFamily: "Roboto, sans-serif",
              marginBottom: "100px",
              textAlign: "center",
            }}
          >
            Page Not Yet Published
          </Title>
          <img
            src="https://jrdc.lk/wp-content/uploads/2021/10/istockphoto-1314780559-170667a.jpg"
            alt="Image 1"
            style={{
              maxWidth: "100%",
              height: "auto",
              display: "block",
              margin: "0 auto",
              marginBottom: "80px",
              borderRadius: "8px",
            }}
          />
          <Paragraph
            style={{
              color: "#444",
              fontSize: "20px",
              fontFamily: "Roboto, sans-serif",
              marginBottom: "80px",
              textAlign: "justify",
            }}
          >
            This page and the related content is not yet available. More content
            will be coming soon!
          </Paragraph>
        </Card>
      </div>
      <Footer />
    </Page>
  );
};

export default NotPublishedPage;
