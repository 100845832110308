import React, { useEffect, useState } from "react";
import { Button, Card, Space, Typography, Pagination } from "antd";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { DownloadOutlined, FileOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { getPageById } from "../Api/index";
import "./../styles/pageTitle.css";

const { Title, Paragraph } = Typography;

const MoUsAgreements = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTab = useMediaQuery({ minWidth: 768, maxWidth: 1024 });

  const [filesData, setFilesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const pageSize = 5;

  useEffect(() => {
    const fetchFiles = async (page) => {
      try {
        const response = await getPageById("4", page, pageSize);
        setFilesData(response.data);
        setTotalItems(response.total);
      } catch (error) {
        console.error("Error fetching MoUs and Agreements:", error);
      }
    };

    fetchFiles(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total} items`;
  };

  return (
    <>
      <Page>
        <Navbar />

        <div className="page-content woocommerce">
          <div className="container clear-fix">
            <div className="grid-col-row">
              <div className="site-card-border-less-wrapper">
                <div className="grid-col grid-col-12">
                  <div className="col2-set" id="customer_details">
                    <h1 className="section-title">MoUs Agreements</h1>
                    <br />
                    <Paragraph
                      style={{
                        fontSize: "1.2rem",
                        fontFamily: "Roboto, sans-serif",
                        textAlign: "justify",
                        width: isTab ? "90%" : "100%",
                      }}
                    >
                      The Joint Research & Demonstration Centre for Water
                      Technology (JRDC) has come into reality after signing a
                      Memorandum of Understanding (MoU) between the Ministry of
                      Urban Development, Water Supply & Drainage (MUDWSD) and
                      the Chinese Academy of Sciences (CAS) on March 26, 2015,
                      in the presence of HE Presidents of the Democratic
                      Socialist Republic of Sri Lanka and People’s Republic of
                      China.
                    </Paragraph>
                    <Paragraph
                      style={{
                        fontSize: "1.2rem",
                        fontFamily: "Roboto, sans-serif",
                        textAlign: "justify",
                        width: isTab ? "90%" : "100%",
                      }}
                    >
                      The aforementioned parties and other related Government
                      Departments, research institutions, Universities of both
                      countries have also entered into several other MoUs as
                      National Water Supply & Drainage Board and Research Center
                      for Eco-Environmental Sciences, Beijing, China on June 26,
                      2015; University of Peradeniya and Ministry of City
                      Planning & Water Supply on December 9, 2016; National
                      Science Foundation of Sri Lanka and National Natural
                      Science Foundation, China on April 7, 2016; the University
                      of Peradeniya and Chinese Academy of Sciences, China on
                      December 16, 2016; Ministry of Health and Teaching
                      Hospital Beijing, China on October 25, 2016, for the
                      cooperation on Science, Technology, innovation, and
                      capacity building which are focusing on seeking the cause
                      of CKDu and ensuring safe water supply in Sri Lanka.
                    </Paragraph>
                    <Paragraph
                      style={{
                        fontSize: "1.2rem",
                        fontFamily: "Roboto, sans-serif",
                        textAlign: "justify",
                        width: isTab ? "90%" : "100%",
                      }}
                    >
                      Moreover, an agreement is made based on the Memorandum of
                      Understanding among the Ministry of City Planning & Water
                      Supply, University of Peradeniya, and Chinese Academy of
                      Sciences on establishing a management framework for the
                      functioning of JRDC in September 28, 2018.
                    </Paragraph>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "50px",
                        width: isTab ? "90%" : "100%",
                      }}
                    >
                      {filesData.map((file, index) => (
                        <div
                          key={index}
                          style={{
                            border: "1px solid #e8e8e8",
                            borderRadius: "8px",
                            padding: "16px",
                            marginBottom: "16px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            background: "white",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FileOutlined
                              style={{ marginRight: "20px", color: "#B9001F" }}
                            />
                            <span
                              style={{
                                fontSize: isMobile ? "16px" : "18px",
                                fontFamily: "Roboto, sans-serif",
                                fontWeight: "400",
                                maxWidth: "95%",
                                color: "black",
                              }}
                            >
                              {file.title}
                            </span>
                          </div>
                          <div style={{ display: "flex" }}>
                            {file.files.map((fileItem, fileIndex) => (
                              <a
                                key={fileIndex}
                                href={fileItem.file_url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Button
                                  type="primary"
                                  className="fos-btn-primary"
                                  icon={<DownloadOutlined />}
                                  style={{
                                    background: "#B9001F",
                                    borderColor: "#B9001F",
                                    borderRadius: "30px",
                                    height: "40px",
                                    fontSize: isMobile ? "14px" : "16px",
                                    marginLeft: fileIndex > 0 ? "8px" : 0,
                                  }}
                                >
                                  Download
                                </Button>
                              </a>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                    <Pagination
                      current={currentPage}
                      pageSize={pageSize}
                      total={totalItems}
                      onChange={handlePageChange}
                      style={{ marginTop: "20px", marginBottom: "50px" }}
                      showTotal={showTotal}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Page>
    </>
  );
};

export default MoUsAgreements;
