import React, { useState, useEffect } from "react";
import { Card, Space, Typography, Row, Col, Pagination } from "antd";
import {
  DollarCircleOutlined,
  EnvironmentOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { useNavigate } from "react-router-dom";
import { getConferences } from ".././Api/index";
import "./../styles/pageTitle.css";
import default_img from "./../img/pattern/default.jpg";

const { Title, Paragraph } = Typography;

const cardStyle = {
  width: "100%",
  borderRadius: "10px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  marginBottom: "20px",
};

const imgStyle = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "10px 0 0 10px",
};

const paragraphStyle = {
  fontSize: "16px",
  fontFamily: "'Roboto', sans-serif",
  marginBottom: "10px",
  textAlign: "justify",
};

const Conferences = () => {
  const navigate = useNavigate();
  const [conferences, setConferences] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalConferences, setTotalConferences] = useState(0); // to store the total number of conferences
  const conPerPage = 5;

  useEffect(() => {
    const fetchConferences = async (page) => {
      try {
        const conData = await getConferences(page, conPerPage);
        setConferences(conData.data);
        setTotalConferences(conData.total); // assuming the backend response includes total count
      } catch (error) {
        console.error("Error fetching Conferences:", error);
      }
    };

    fetchConferences(currentPage);
  }, [currentPage]);

  const handleClick = (conference) => {
    navigate("/ViewConference", { state: { conference } });
  };

  const formatDateTime = (dateTimeStr) => {
    if (!dateTimeStr) return "N/A";

    const dateTime = new Date(dateTimeStr);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };
    return dateTime.toLocaleString("en-US", options);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getPriceStartingFrom = (tickets) => {
    if (!tickets || tickets.length === 0) {
      return "N/A";
    }
    const prices = tickets.map((ticket) => parseFloat(ticket.sale_price));
    return Math.min(...prices).toFixed(2);
  };

  const showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total} items`;
  };

  return (
    <>
      <Page>
        <Navbar />
        <div className="page-wrapper">
          <div className="page-content woocommerce">
            <div className="container clear-fix">
              <div className="grid-col-row">
                <h1 className="section-title">Conferences</h1>
                <br />
                <Row gutter={[16, 16]}>
                  {conferences.map((conference) => (
                    <Col
                      key={conference.id}
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      onClick={() => handleClick(conference)}
                    >
                      <Card hoverable style={cardStyle}>
                        <Row gutter={[16, 16]}>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={12}
                            xl={8}
                            style={{ display: "flex" }}
                          >
                            <img
                              alt="conference"
                              src={
                                conference.image_url
                                  ? conference.image_url
                                  : default_img
                              }
                              style={imgStyle}
                            />
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                            <Title
                              level={2}
                              style={{ marginBottom: "20px", fontSize: "30px" }}
                            >
                              <span style={{ color: "#630330" }}>
                                {conference.title}
                              </span>
                            </Title>
                            <Space
                              direction="vertical"
                              style={{ width: "100%" }}
                            >
                              <Paragraph style={paragraphStyle}>
                                <DollarCircleOutlined
                                  style={{ color: "#1890ff" }}
                                />{" "}
                                <b>Price:</b> Starting from{" "}
                                <span style={{ color: "red" }}>
                                  Rs: {getPriceStartingFrom(conference.tickets)}
                                </span>
                              </Paragraph>
                              <Paragraph style={paragraphStyle}>
                                {conference.description}
                              </Paragraph>
                              <Paragraph style={paragraphStyle}>
                                <EnvironmentOutlined
                                  style={{ color: "#52c41a" }}
                                />{" "}
                                <b>Location:</b> {conference.location}
                              </Paragraph>
                              <Paragraph style={paragraphStyle}>
                                <CalendarOutlined
                                  style={{ color: "#f5222d" }}
                                />{" "}
                                <b>Start:</b>{" "}
                                {formatDateTime(conference.start_date)}
                              </Paragraph>
                              <Paragraph style={paragraphStyle}>
                                <CalendarOutlined
                                  style={{ color: "#faad14" }}
                                />{" "}
                                <b>End:</b>{" "}
                                {formatDateTime(conference.end_date)}
                              </Paragraph>
                            </Space>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  ))}
                </Row>
                <Pagination
                  current={currentPage}
                  pageSize={conPerPage}
                  total={totalConferences}
                  onChange={handlePageChange}
                  style={{ marginTop: "20px", marginBottom: "80px" }}
                  showTotal={showTotal}
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Page>
    </>
  );
};

export default Conferences;
