import { Button, Form, Input, Select, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import { editAddress, getUserCustomerbyId } from "../Api";
import { COUNTRY } from "../Constants.js";
import FormInput from "../Components/form/FormInput";
import FormSelect from "../Components/form/FormSelect";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

function BillingAddress() {
  const [userData, setUserData] = useState(null);
  const [form] = useForm();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const ci = window.localStorage.getItem("jrdc_user");
        if (ci) {
          const ciObj = JSON.parse(ci);
          const response = await getUserCustomerbyId(ciObj.id);
          const userCustomerData = response.data;

          Object.assign(userCustomerData.customer, {
            firstname: userCustomerData.customer?.first_name ?? null,
            lastname: userCustomerData.customer?.last_name ?? null,
            company: userCustomerData.customer?.company ?? null,
            country: userCustomerData.customer?.country ?? null,
            address_line1: userCustomerData.customer?.address_line1 ?? null,
            address_line2: userCustomerData.customer?.address_line2 ?? null,
            city: userCustomerData.customer?.city ?? null,
            postcode: userCustomerData.customer?.postcode ?? null,
            phone: userCustomerData.customer?.phone ?? null,
            user_id: userCustomerData.id,
            bill_email: userCustomerData.customer?.bill_email ?? null,
          });

          setUserData(userCustomerData.customer);
          form.setFieldsValue(userCustomerData.customer);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [form]);

  const handleSubmit = (values) => {
    values.name = values.first_name + " " + values.last_name;
    values.id = userData.user_id;
    //  console.log("Success:", values);

    editAddress(values).then((val) => {
      console.log("response", val);
      navigate("/MyAccount/Address");
    });
  };

  const FormSelect = ({ label, name, options }) => {
    return (
      <Form.Item label={label} name={name}>
        <Select
          placeholder="Select a country"
          onChange={(value) => {
            const selectedCountry = options.find(
              (option) => option.value === value
            );
            form.setFieldsValue({ [name]: selectedCountry.text });
          }}
        >
          {options.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  };

  return (
    <div className="items-container" style={{ width: "100%" }}>
      <p className="billing-address">Billing Address</p>
      <Form
        name="basic"
        form={form}
        onFinish={handleSubmit}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item>
              <FormInput label="First Name" name="first_name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item>
              <FormInput label="Last Name" name="last_name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item>
              <FormInput label="Company name (optional)" name="company" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item>
              <FormSelect
                label="Country / Region"
                name="country"
                options={COUNTRY}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item label="Street address">
              <FormInput
                placeholder="House number and street name"
                name="address_line1"
              />
              <FormInput
                placeholder="Apartment, suite, unit, etc. (optional)"
                name="address_line2"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item>
              <FormInput label="Town / City" name="city" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item>
              <FormInput label="Postcode / ZIP " name="postcode" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item>
              <FormInput label="Phone Number" name="phone" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item>
              <FormInput label="Email Address" name="bill_email" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="fos-btn-primary mt-5"
            style={{
              background: "#B9001F",
              borderColor: "#B9001F",
              width: isMobile ? "60%" : "30%",
            }}
            icon={<EditOutlined />}
          >
            Save Address
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default BillingAddress;
