import React from "react";
import { Typography, Card } from "antd";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { useMediaQuery } from "react-responsive";
import GridCard from "../Components/common/GridComponent";
import tilebg from "./../../src/img/pattern/tilebg1.jpg";
import FA from "./../../src/img/pattern/FA3.jpg";
import "./../styles/pageTitle.css";

const FocusArea = () => {
  const { Title, Text } = Typography;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const pStyle = {
    color: "#666",
    fontSize: "16px",
    fontFamily: "Roboto, sans-serif",
    textAlign: "justify",
    marginBottom: "20px",
    display: "block",
  };

  const E_liStyle = {
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    color: "#666",
    marginBottom: "10px",
    listStyle: "none",
    paddingLeft: "20px",
    position: "relative",
  };

  const cardStyle = {
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    width: "100%",
    marginBottom: "50px",
  };

  const cardheadStyle = {
    fontSize: "28px",
    fontWeight: "bold",
  };

  const textStyle = {
    color: "#666",
    fontSize: "16px",
    fontFamily: "Roboto, sans-serif",
    textAlign: "justify",
    marginBottom: "20px",
    display: "block",
  };

  const card_paragraph = (
    <p style={{ fontFamily: "Roboto", textAlign: "justify" }}>
      The assumption that all wastewater ends in sewage treatment plants (STP)
      is faulty as in our national boundary, almost more than 100,000 cubic
      meters of untreated sewage are discharged into the ocean through two pipes
      that extend about 1.5 kilometers into the sea. While industrial wastewater
      contains pollutants at levels that may affect receiving waters or
      interfere with publicly owned treatment works (POTWs) that receive those
      discharges. JRDC aims to solve and overcome these challenges as we provide
      Reliable, Robust and Sustainable technology for wastewater treatment,
      recycling and reuse. We also design and ensure refined technology,
      economic viability and environmentally sound engineering solutions. We
      tailor our solutions according to our customers’ requirements and needs
      and making sure to abide by our governments standards.
    </p>
  );

  const firstGridItems = [
    {
      title: "Design",
      description:
        "JRDC will Provide SBR, MBR and other related wastewater treatment system design and consultancy to meet environmental regulations.",
    },
    {
      title: "Troubleshooting",
      description:
        "RDC technology team is fully dedicated to narrow down the water quality and process issues related to your wastewater treatment.",
    },
    {
      title: "Optimization",
      description:
        "JRDC facilities and optimize wastewater treatment-related works through research and by our experienced team",
    },
  ];

  const secondGridItems = [
    {
      title: "Hydro-Geological Investigation",
    },
    {
      title: "Remote Sensing and GIS Studies for groundwater",
    },
    {
      title: "Bore Well Construction and Design",
    },
    {
      title: "Groundwater and Salinity Management and Assessment",
      description: (
        <div>
          <p>- Resistivity survey</p>
          <p> - Yield testing</p>
          <p> - Troubleshooting</p>
        </div>
      ),
    },
  ];

  const thirdGridItems = [
    {
      title: "Technology Adaptation",
    },
    {
      title: "Design",
    },
    {
      title: "Troubleshooting",
    },
  ];

  const fourthGridItems = [
    {
      title: "Environmental impact assessment (EIA)",
    },
    {
      title: "Strategic environmental assessment (SEA)",
    },
    {
      title: "Environmental and Social Impact Assessment (ESIA)",
    },
    {
      title: "Environmental Statement reviews",
    },
    {
      title: "Stakeholder and Public Consultation",
    },
    {
      title: "EIA screening and scoping",
    },
    {
      title: "Environmental risk assessment",
    },
  ];

  const fifthGridItems = [
    {
      title: "Urban WSP",
    },
    {
      title: "Rural WSP",
    },
    {
      title: " Climate-resilient WSP",
    },
    {
      title: "Auditing",
      description: (
        <div>
          <p> - Training</p>
          <p> - Implementation</p>
          <p> - Auditing</p>
        </div>
      ),
    },
  ];

  const sixthGridItems = [
    {
      title: "Instrument Maintenance",
    },
    {
      title: "Planning and Design",
    },
    {
      title: "Troubleshooting",
    },
    {
      title: "Method Developments",
    },
  ];

  return (
    <Page>
      <Navbar />
      <div className="page-content woocommerce">
        <div className="container clear-fix">
          <div className="grid-col-row">
            <div className="site-card-border-less-wrapper">
              <div className="grid-col grid-col-12">
                <div className="col2-set" id="customer_details">
                  <h1 className="section-title">Focus Areas</h1>
                  <Card
                    title="1.0 Water Treatment"
                    headStyle={cardheadStyle}
                    style={{ ...cardStyle, marginTop: "50px" }}
                  >
                    <Text style={textStyle}>
                      Water treatment is essential for ensuring that water is
                      suitable for industries and individuals. The process is
                      designed to remove the contaminants in the water to make
                      it safer for use. Optimum use of water covers both the
                      conservation and the efficiency. At JRDC we provide
                      reliable, robust and sustainable technology for water
                      treatment and design and ensure refined technology,
                      economic viability and environmentally sound engineering
                      solutions to create custom-tailored solutions to match
                      each and every individual’s needs and requirements.
                    </Text>

                    <ul>
                      <li style={E_liStyle}>
                        <span style={{ fontSize: "22px" }}>&#8226;</span>{" "}
                        Provide Reliable, Robust and Sustainable technology for
                        water treatment,
                      </li>
                      <li style={E_liStyle}>
                        <span style={{ fontSize: "22px" }}>&#8226;</span> Design
                        and ensure refined technology, economic viability and
                        environmentally sound engineering solutions,
                        <br />
                        Create custom-tailored solutions to match the
                        individual’s requirements.
                      </li>
                    </ul>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        justifyContent: isMobile ? "center" : "space-between",
                        alignItems: "center",
                        marginTop: "50px",
                      }}
                    >
                      <img
                        src="https://jrdc.lk/wp-content/uploads/2021/10/Water-Treatment-1-2-1980x1320.jpg"
                        alt="First Image"
                        style={{
                          width: isMobile ? "90%" : "45%",
                          cursor: "pointer",
                          marginBottom: isMobile ? "20px" : 0,
                        }}
                      />

                      <img
                        src="https://jrdc.lk/wp-content/uploads/2021/10/Water-Treatment-2-2-1980x1320.jpg"
                        alt="Second Image"
                        style={{
                          width: isMobile ? "90%" : "45%",
                          cursor: "pointer",
                          marginTop: isMobile ? "20px" : "0",
                        }}
                      />
                    </div>
                  </Card>

                  <Card
                    title="Design"
                    headStyle={cardheadStyle}
                    style={{
                      ...cardStyle,
                      marginTop: "50px",
                      justifyContent: "center",
                    }}
                  >
                    <Text style={textStyle}>
                      JRDC will assist and provide you Process design,
                      preparation of BoQs, Cost estimation, CAD drawing (Civil,
                      Mechanical and Electrical)
                    </Text>
                  </Card>

                  <Card
                    title="Troubleshooting"
                    headStyle={cardheadStyle}
                    style={{ ...cardStyle, marginTop: "50px" }}
                  >
                    <Text style={textStyle}>
                      JRDC will provide high-end equipment, laboratory and
                      research facilities to track down and water-related issues
                      to pinpoint problem areas and the potential factors that
                      cause such issues.
                    </Text>
                  </Card>

                  <Card
                    title="Optimization"
                    headStyle={cardheadStyle}
                    style={{ ...cardStyle, marginTop: "50px" }}
                  >
                    <Text style={textStyle}>
                      JRDC facilities and optimize water treatment-related works
                      through research and by our experienced team
                    </Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        justifyContent: isMobile ? "center" : "space-between",
                        alignItems: "center",
                        marginTop: "50px",
                      }}
                    >
                      <img
                        src={FA}
                        alt="First Image"
                        style={{
                          width: isMobile ? "90%" : "45%",
                          cursor: "pointer",
                          marginBottom: isMobile ? "20px" : "50px",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                  </Card>

                  <GridCard
                    gridItems={firstGridItems}
                    background={tilebg}
                    title="2.0 Industrial/Non-industrial Wastewater Treatment"
                    showDescription={true}
                    preContent={card_paragraph}
                  />
                  <br />

                  <GridCard
                    gridItems={secondGridItems}
                    background={tilebg}
                    title="3.0 Groundwater Exploration"
                    showDescription={true}
                  />
                  <br />

                  <GridCard
                    gridItems={thirdGridItems}
                    background={tilebg}
                    title="4.0 Rainwater Harvesting"
                    showDescription={false}
                  />
                  <br />

                  <GridCard
                    gridItems={fourthGridItems}
                    background={tilebg}
                    title="5.0 Conducting EIA and associated services"
                    showDescription={false}
                    //postContent={card_image}
                  />
                  <GridCard
                    gridItems={fifthGridItems}
                    background={tilebg}
                    title="6.0 Water Safety Plan"
                    showDescription={true}
                  />

                  <GridCard
                    gridItems={sixthGridItems}
                    background={tilebg}
                    title="7.0 Laboratory development"
                    // showDescription={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Page>
  );
};

export default FocusArea;
