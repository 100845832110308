/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import FormInput from "../Components/form/FormInput";
import { useForm } from "antd/es/form/Form";
import {
  Button,
  Card,
  Typography,
  Form,
  message,
  Row,
  Col,
  Input,
  Modal,
} from "antd";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { ContactMessage } from "../Api/index";
import {
  MailOutlined,
  PhoneOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import "./../styles/pageTitle.css";

const { Title } = Typography;

const ContactUs = () => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTab = useMediaQuery({ minWidth: 768, maxWidth: 1024 });

  const showSuccessModal = () => {
    Modal.success({
      title: "Message Sent",
      content: (
        <div style={{ textAlign: "center" }}>
          <CheckCircleOutlined style={{ color: "green", fontSize: "48px" }} />
          <p style={{ fontSize: "18px", marginTop: "16px" }}>
            Your message has been successfully sent!
          </p>
        </div>
      ),
      centered: true,
      icon: null,
    });
  };

  const showErrorModal = () => {
    Modal.error({
      title: "Message Failed",
      content: (
        <div style={{ textAlign: "center" }}>
          <CloseCircleOutlined style={{ color: "red", fontSize: "48px" }} />
          <p style={{ fontSize: "18px", marginTop: "16px" }}>
            There was an error sending your message. Please try again later.
          </p>
        </div>
      ),
      centered: true,
      icon: null,
    });
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await ContactMessage(values);
      form.resetFields();
      showSuccessModal();
    } catch (error) {
      showErrorModal();
      console.error("Error sending message:", error);
    } finally {
      setLoading(false);
    }
  };

  const cardStyle = {
    width: isTab ? "90%" : "100%",
    minWidth: isMobile ? 0 : "600px",
    padding: isMobile ? "20px" : "40px",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    marginBottom: isMobile ? "30px" : 0,
    fontFamily: "'Montserrat', sans-serif",
  };

  const subTitleStyle = {
    color: "black",
    fontFamily: "Roboto",
    fontWeight: "bold",
    marginTop: "-20px",
    marginBottom: "40px",
    marginLeft: "10px",
  };

  const textContentStyle = {
    marginBottom: "20px",
    marginTop: isMobile ? "10px" : 0,
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    alignItems: "center",
  };

  const contactInfoStyle = {
    color: "black",
    fontWeight: "bold",
    fontSize: "16px",
    fontFamily: "Roboto",
    color: "black",
  };

  const contactDetailStyle = {
    marginLeft: "10px",
    fontSize: isMobile ? "14px" : "16px",
    fontFamily: "Roboto",
    color: "black",
  };

  return (
    <>
      <Page>
        <Navbar />
        <div className="page-content woocommerce">
          <div className="container clear-fix">
            <div className="grid-col-row">
              <div className="site-card-border-less-wrapper">
                <div className="grid-col grid-col-12">
                  <div className="col2-set" id="customer_details">
                    <h1 className="section-title">Contact Us</h1>
                    <br />
                    <h3 style={subTitleStyle}>We'd love to hear from you</h3>
                    <Row gutter={[64, 64]}>
                      <Col xs={24} md={24} lg={12}>
                        <Card style={cardStyle}>
                          <Form
                            name="contact-form"
                            form={form}
                            onFinish={onFinish}
                            autoComplete="off"
                            layout="vertical"
                          >
                            <Form.Item
                              label={
                                <span
                                  style={{
                                    fontFamily: "Roboto",
                                    fontSize: "16px",
                                  }}
                                >
                                  Your Name
                                </span>
                              }
                              name="name"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your name",
                                },
                                {
                                  min: 2,
                                  message: "Name must be at least 2 characters",
                                },
                              ]}
                            >
                              <Input
                                style={{
                                  borderRadius: "5px",
                                  border: "1px solid #ccc",
                                  padding: "10px",
                                }}
                              />
                            </Form.Item>

                            <Form.Item
                              label={
                                <span
                                  style={{
                                    fontFamily: "Roboto",
                                    fontSize: "16px",
                                  }}
                                >
                                  Email Address
                                </span>
                              }
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your email",
                                },
                                {
                                  type: "email",
                                  message: "Please enter a valid email",
                                },
                              ]}
                            >
                              <Input
                                style={{
                                  borderRadius: "5px",
                                  border: "1px solid #ccc",
                                  padding: "10px",
                                }}
                              />
                            </Form.Item>

                            <Form.Item
                              label={
                                <span
                                  style={{
                                    fontFamily: "Roboto",
                                    fontSize: "16px",
                                  }}
                                >
                                  Phone
                                </span>
                              }
                              name="phone"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your phone number",
                                },
                                {
                                  pattern: /^\d{10}$/,
                                  message:
                                    "Please enter a valid 10-digit phone number",
                                },
                              ]}
                            >
                              <Input
                                style={{
                                  borderRadius: "5px",
                                  border: "1px solid #ccc",
                                  padding: "10px",
                                }}
                              />
                            </Form.Item>

                            <Form.Item
                              label={
                                <span
                                  style={{
                                    fontFamily: "Roboto",
                                    fontSize: "16px",
                                  }}
                                >
                                  Type of Inquiry
                                </span>
                              }
                              name="subject"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter the type of inquiry",
                                },
                              ]}
                            >
                              <Input
                                style={{
                                  borderRadius: "5px",
                                  border: "1px solid #ccc",
                                  padding: "10px",
                                }}
                              />
                            </Form.Item>

                            <Form.Item
                              label={
                                <span
                                  style={{
                                    fontFamily: "Roboto",
                                    fontSize: "16px",
                                  }}
                                >
                                  Your Inquiry
                                </span>
                              }
                              name="message"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your inquiry",
                                },
                                {
                                  min: 10,
                                  message:
                                    "Inquiry must be at least 10 characters",
                                },
                              ]}
                            >
                              <Input.TextArea
                                style={{
                                  borderRadius: "5px",
                                  border: "1px solid #ccc",
                                  padding: "10px",
                                  fontFamily: "'Montserrat', sans-serif",
                                }}
                                rows={4}
                              />
                            </Form.Item>

                            <Form.Item>
                              <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                                style={{
                                  backgroundColor: "#B9001F",
                                  borderColor: "#B9001F",
                                  width: "100%",
                                }}
                              >
                                SEND INQUIRY
                              </Button>
                            </Form.Item>
                          </Form>
                        </Card>
                      </Col>
                      <Col xs={24} md={24} lg={12}>
                        <div
                          style={{
                            width: isMobile ? "95%" : isTab ? "90%" : "100%",
                          }}
                        >
                          <div
                            style={{
                              border: "1px solid #C8C8C8",
                              padding: "10px",
                              minWidth: isMobile ? "100%" : "600px",
                              fontFamily: "Roboto",
                              fontSize: isMobile ? "16px" : "22px",
                              backgroundColor: "#2F5597",
                              color: "#FFFFFF",
                              textAlign: "justify",
                              lineHeight: "1.5",
                            }}
                          >
                            <p style={{ padding: "20px" }}>
                              Get in touch with us today about your Water
                              Research & Testing needs.
                            </p>
                          </div>
                          <div
                            style={{ marginTop: "30px", marginBottom: "10px" }}
                          >
                            <h3
                              style={{
                                color: "#B9001F",
                                //fontWeight: "bold",
                                marginBottom: isMobile ? "20px" : "20px",
                                textAlign: isMobile ? "center" : "left",
                                fontFamily: "Roboto",
                              }}
                            >
                              Joint Research and Demonstration Centre
                            </h3>
                            <p
                              style={{
                                ...contactDetailStyle,
                                marginBottom: "5px",
                                textAlign: isMobile ? "center" : "left",
                              }}
                            >
                              E.O.E Pereira mawatha,
                            </p>
                            <p
                              style={{
                                ...contactDetailStyle,
                                marginBottom: "5px",
                                textAlign: isMobile ? "center" : "left",
                              }}
                            >
                              Meewathura,
                            </p>
                            <p
                              style={{
                                ...contactDetailStyle,
                                marginBottom: "25px",
                                textAlign: isMobile ? "center" : "left",
                              }}
                            >
                              Peradeniya.
                            </p>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={textContentStyle}>
                                <MailOutlined
                                  style={{
                                    marginRight: "10px",
                                    color: "#B9001F",
                                  }}
                                />
                                <span style={contactInfoStyle}>Email:</span>{" "}
                                <p style={contactDetailStyle}>info@jrdc.lk</p>
                              </div>
                              <div style={textContentStyle}>
                                <PhoneOutlined
                                  style={{
                                    marginRight: "10px",
                                    color: "#B9001F",
                                    fontSize: "16px",
                                  }}
                                />
                                <span style={contactInfoStyle}>Phone:</span>
                                <div>
                                  <p
                                    style={{
                                      ...contactDetailStyle,
                                      marginBottom: "-5px",
                                    }}
                                  >
                                    {" "}
                                    0812058116
                                  </p>
                                  <p style={contactDetailStyle}> 0703058116</p>
                                </div>
                              </div>
                              <div style={textContentStyle}>
                                <PhoneOutlined
                                  style={{
                                    marginRight: "10px",
                                    color: "#B9001F",
                                    fontSize: "16px",
                                  }}
                                />
                                <span style={contactInfoStyle}>Fax:</span>{" "}
                                <p style={contactDetailStyle}>0812058116</p>
                              </div>
                            </div>
                          </div>
                          <iframe
                            width="100%"
                            height="345"
                            style={{ paddingBottom: isMobile ? "20px" : 0 }}
                            frameBorder="0"
                            scrolling="no"
                            marginHeight="0"
                            marginWidth="0"
                            src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Meewathura%20Rd%20JRDC,%20address+(JRDC)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                          >
                            <a href="https://www.gps.ie/">gps devices</a>
                          </iframe>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </Page>
    </>
  );
};

export default ContactUs;
