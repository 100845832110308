import React, { useEffect } from "react";
import { Typography, message } from "antd";
import { getRedirectUrl } from "./../../Api/index";

const { Title, Text } = Typography;

const LoadingPage = () => {
  useEffect(() => {
    const timer = setTimeout(async () => {
      try {
        const url = await getRedirectUrl();
        window.location.href = url;
      } catch (error) {
        message.error("Authentication failed");
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={styles.loadingContainer}>
      <img src="/jrdc_Logo.png" alt="Logo" style={styles.logo} />
      <Title level={1}>Welcome to JRDC Water Testing Module</Title>
      <Text style={{ fontSize: "18px" }}>You will be redirected Soon...</Text>
      <img src="/loadicon.gif" alt="Water Testing" style={styles.gif} />
    </div>
  );
};

const styles = {
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#fff",
    textAlign: "center",
  },
  logo: {
    width: "150px",
    marginBottom: "20px",
  },
  loadingSpinner: {
    marginTop: "20px",
  },
  gif: {
    marginTop: "40px",
    width: "18%",
    height: "15%",
  },
};

export default LoadingPage;
