import FormInput from "../Components/form/FormInput";

import Single_Room from "../../src/img//pattern/Single_Room.jpg";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import React, { useEffect, useRef, useState } from "react";
import { DatePicker, Form } from "antd";
const SingleRoomPage = () => {
  let Cost = 0;

  // cartItems.forEach((item) => {
  //   total += item.price * item.qty;
  // });
  return (
    <Page>
      <Navbar />

      <div class="page-content woocommerce">
        <div class="container clear-fix">
          <div class="grid-col-row">
            <div class="grid-col grid-col-12">
              <div role="main">
                <div
                  itemscope=""
                  itemtype="http://schema.org/Product"
                  class="product"
                >
                  <div class="images">
                    <div class="picture">
                      <img
                        src={Single_Room}
                        data-at2x="http://placehold.it/370x370"
                        class="attachment-shop_single"
                        alt=""
                        title=""
                      />
                      <div class="hover-effect"></div>
                      <div class="link-cont">
                        <a
                          href="http://placehold.it/370x370"
                          class="fancy fa fa-eye woocommerce-main-image zoom"
                          data-fancybox-group="gallery"
                          title=""
                          data-rel="prettyPhoto[product-gallery]"
                        ></a>
                      </div>
                    </div>
                    <div class="thumbnails">
                      <div class="owl-carousel">
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src="http://placehold.it/116x116"
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src="http://placehold.it/116x116"
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src="http://placehold.it/116x116"
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src={Single_Room}
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="summary entry-summary">
                    <h2 class="product_title entry-title">Single Room</h2>

                    <div itemprop="description">
                      <p>
                        <strong>Single Room - 1 day</strong>
                        <br />
                        The default booking will be one (01) day. Add more no.
                        of additional days as per your requirement in the below
                        section.
                      </p>
                    </div>
                    <div className="col-md-3">
                      <Form.Item label="" name="date">
                        <DatePicker style={{ width: "100%" }} />
                      </Form.Item>

                      <FormInput
                        label="Number of additional days (+රු 2.000,00) +1 day "
                        name="Additional_Days"
                        type="number"
                      />
                    </div>
                    <div className="total">Cost: {Cost}</div>

                    <div class="main-features">
                      <p class="head">
                        <strong>Cost: Rs.2000</strong>
                      </p>
                    </div>
                    <form class="cart" method="post">
                      <div class="quantity buttons_added">
                        <input
                          type="number"
                          step="1"
                          min="1"
                          name="quantity"
                          value="1"
                          title="Qty"
                          class="input-text qty text"
                        />
                      </div>
                      <a
                        href="/shopCart"
                        class="cws-button alt border-radius small bt-color-3"
                      >
                        CHECK AVAILABILITY
                      </a>
                    </form>
                  </div>
                  <hr class="divider-color" />
                  <div class="tabs">
                    <div class="block-tabs-btn clear-fix">
                      <div class="tabs-btn active" data-tabs-id="tabs1">
                        Description
                      </div>
                    </div>
                    <div class="tabs-keeper">
                      <div
                        class="container-tabs active"
                        data-tabs-id="cont-tabs1"
                      >
                        <p>
                          <strong>Room Details</strong>
                        </p>
                        <p>
                          Our single rooms are lavished with elegance and
                          comfort, Sink into the cozy atmosphere. Six amazing
                          single rooms are available, each room is designed in a
                          sleek modern and minimal setting.
                        </p>
                        <p>
                          Our rooms are for your indulgence and comfort,
                          allowing a private and homey rest. The wooden
                          furnishings create a factor of elegance and style.
                          Each room contains an attached bathroom with warm
                          showers and contains air condition as well. The space
                          is embellished with different textures and patterns to
                          ensure comfort and rest in a modern style.
                        </p>

                        <p>
                          <strong>The amenities in the room includes:</strong>
                        </p>
                        <p>
                          Our guests can also enjoy working out in our gym,
                          enjoy a refreshing drink at our café and spend a
                          relaxing time in our swimming pool.
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr class="divider-color" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </Page>
  );
};

export default SingleRoomPage;
