import React from "react";
import topcurvesbg from "../../../src/img/pattern/topcurvesbg.png";
import bottomRightImage from "../../../src/img/pattern/bottomcurvesbg.png";
import bottomLeftImage from "../../../src/img/pattern/bottomleftcurvesbg.png";

const Page = ({ children }) => {
  return (
    <div
      className="shop shopBg"
      style={{
        position: "relative",
        minHeight: "100vh", // Set minimum height to 100vh
        background: `url(${topcurvesbg}) top left no-repeat, url(${bottomRightImage}) bottom right no-repeat, url(${bottomLeftImage}) bottom left no-repeat`,
        backgroundSize: "100% auto, 100% auto, 100% auto",
        backgroundPosition: "top left, bottom right, bottom left",
        zIndex: 0,
      }}
    >
      <div style={{ position: "relative", zIndex: 1 }}>{children}</div>
    </div>
  );
};

export default Page;
