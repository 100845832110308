import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Card, Button, Row, Col, Pagination, Divider } from "antd";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { getPrograms } from ".././Api/index";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "./../styles/pageTitle.css";
import { useMediaQuery } from "react-responsive";
import default_img from "./../img/pattern/default.jpg";

const { Title, Paragraph } = Typography;
const { Meta } = Card;

const ProgramsPage = () => {
  const [programsData, setProgramsData] = useState({});
  const navigate = useNavigate();
  const [currentPages, setCurrentPages] = useState({}); // State for tracking current page for each category
  const isTab = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [productsPerPage] = useState(3);

  const cardStyle = {
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    width: isTab ? "90%" : isMobile ? "70%" : "100%",
    marginBottom: "50px",
    height: "auto",
  };

  const cardheadStyle = {
    fontSize: "28px",
    fontWeight: "bold",
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const programs = await getPrograms();
      console.log("Orders data:", programs);
      setProgramsData(programs);

      const initialCurrentPages = {};
      Object.keys(programs.programs).forEach((category) => {
        initialCurrentPages[category] = 1;
      });
      setCurrentPages(initialCurrentPages);
    } catch (error) {
      console.error("Error fetching Programs:", error);
    }
  };

  const handleClick = (item) => {
    navigate(`/products/details/${item.id}`);
  };

  const handlePageChange = (page, category) => {
    setCurrentPages((prevPages) => ({
      ...prevPages,
      [category]: page,
    }));
  };

  return (
    <Page>
      <Navbar />
      <div className="page-wrapper">
        <div className="page-content woocommerce">
          <div className="container clear-fix">
            <div className="grid-col-row">
              <div className="site-card-border-less-wrapper">
                <div className="grid-col grid-col-12">
                  <div className="col2-set" id="customer_details">
                    <h1 className="section-title">Programs</h1>
                    <br />
                    <Paragraph
                      style={{
                        fontSize: isMobile ? "1.0rem" : "1.2rem",
                        fontFamily: "Roboto, sans-serif",
                        width: isTab ? "90%" : isMobile ? "70%" : "100%",
                        textAlign: "justify",
                      }}
                    >
                      JRDC is a center for water technology development,
                      knowledge generation, and dissemination, adhering to both
                      the local and international standards. We encourage
                      advancement and development through integrated efforts and
                      combined research in the fields of sciences and
                      engineering. We are blessed with advanced state-of-the-art
                      technology and instrumentation. At JRDC, we house and work
                      with highly competent resource personnel that have vast
                      dynamic knowledge in the practical and theoretical uses in
                      their respective fields.
                    </Paragraph>

                    <Paragraph
                      style={{
                        fontSize: isMobile ? "1.0rem" : "1.2rem",
                        fontFamily: "Roboto, sans-serif",
                        width: isTab ? "90%" : isMobile ? "70%" : "100%",
                        textAlign: "justify",
                      }}
                    >
                      Our institution guarantees a safe, hands-on learning and
                      growth opportunity that can help build capacity among the
                      professionals. Our programs consists of a wide array of
                      training programs, workshops and seminars that will allow
                      you the dual benefit of experience and knowledge, which
                      can definitely help in future career growth and
                      development.
                    </Paragraph>
                    <br />
                    <Card
                      title="Training (Career Development)"
                      style={cardStyle}
                      headStyle={cardheadStyle}
                    >
                      <Paragraph
                        style={{
                          fontSize: isMobile ? "1.0rem" : "1.2rem",
                          fontFamily: "Roboto, sans-serif",
                          width: isTab ? "90%" : "100%",
                          textAlign: "justify",
                        }}
                      >
                        The aim of the training programs is to allow for a
                        hands-on experience in advancing in research and
                        knowledge dissemination. Students and researchers can
                        grow both in terms of analytical thinking and refining
                        and advancing technological capabilities. This can help
                        in garnering experience and career development.
                      </Paragraph>
                      <ul
                        style={{
                          fontSize: isMobile ? "1.0rem" : "1.2rem",
                          fontFamily: "Roboto, sans-serif",
                          marginBottom: "50px",
                          marginLeft: "20px",
                          listStyleType: "disc",
                        }}
                      >
                        <li>Instrumentation</li>
                        <li>WSP</li>
                        <li>Climate resilient</li>
                        <li>Water Treatment</li>
                        <li>wastewater Treatment</li>
                        <li>Groundwater Exploration</li>
                        <li>Bottle water</li>
                        <li>Laboratory Practices</li>
                        <li>Tube well construction</li>
                      </ul>
                    </Card>
                    {programsData &&
                    programsData.programs &&
                    Object.keys(programsData.programs).length > 0 ? (
                      Object.keys(programsData.programs).map(
                        (category, index) => (
                          <div key={index}>
                            <Card
                              // hoverable
                              style={{
                                ...cardStyle,
                                textAlign: "center",
                                // width: isTab ? "90%" : "100%",
                              }}
                            >
                              <Meta
                                title={
                                  <span
                                    style={{
                                      color: "#333",
                                      fontSize: "32px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {category}
                                    <Divider
                                      style={{
                                        marginTop: "5px",
                                        marginBottom: "80px",
                                      }}
                                    />
                                  </span>
                                }
                              />
                              <Row
                                gutter={[32, 32]}
                                justify="center"
                                style={{ marginBottom: "80px" }}
                              >
                                {programsData.programs[category]
                                  .slice(
                                    (currentPages[category] - 1) *
                                      productsPerPage,
                                    currentPages[category] * productsPerPage
                                  )
                                  .map((item, itemIndex) => (
                                    <Col
                                      key={itemIndex}
                                      xs={24}
                                      sm={12}
                                      md={12}
                                      lg={8}
                                      xl={8}
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Card
                                        hoverable
                                        style={{
                                          width: "100%",
                                          //width: "450px",
                                          height: "600px",
                                          textAlign: "center",
                                          boxShadow:
                                            "0px 4px 8px rgba(0, 0, 0, 0.2)",
                                          //  overflow: "hidden",
                                        }}
                                      >
                                        <img
                                          alt={item.name}
                                          src={
                                            item?.image_url
                                              ? item.image_url
                                              : default_img
                                          }
                                          style={{
                                            height: "350px",
                                            maxWidth: "400px",
                                            width: "100%",
                                            objectFit: "contain",
                                          }}
                                        />
                                        <Divider
                                          style={{
                                            marginTop: "15px",
                                          }}
                                        />
                                        <Meta
                                          title={
                                            <span
                                              style={{
                                                color: "#333",
                                                fontSize: "22px",
                                                fontFamily: "Roboto",
                                              }}
                                            >
                                              {item.name}
                                            </span>
                                          }
                                          description={
                                            <>
                                              {item.sale_price &&
                                                item.sale_price <
                                                  item.regular_price && ( // Display sale price only if it's lower than regular price
                                                  <p
                                                    style={{
                                                      color: "red",
                                                      fontSize: "16px",
                                                      fontWeight: "bold",
                                                      fontFamily: "Roboto",
                                                    }}
                                                  >
                                                    Sale Price: Rs.
                                                    {item.sale_price}
                                                  </p>
                                                )}
                                              {item.sale_price &&
                                                item.sale_price <
                                                  item.regular_price && ( // Cross out regular price if sale price is lower
                                                  <span
                                                    style={{
                                                      textDecoration:
                                                        "line-through",
                                                      color: "#616161",
                                                      fontSize: "16px",
                                                      fontWeight: "bold",
                                                      fontFamily: "Roboto",
                                                    }}
                                                  >
                                                    Price: Rs.
                                                    {item.regular_price}
                                                  </span>
                                                )}
                                              {!item.sale_price ||
                                                (item.sale_price >=
                                                  item.regular_price && ( // Display regular price in red if sale price is higher or equal
                                                  <p
                                                    style={{
                                                      color: "red",
                                                      fontSize: "16px",
                                                      fontWeight: "bold",
                                                      fontFamily: "Roboto",
                                                    }}
                                                  >
                                                    Price: Rs.
                                                    {item.regular_price}
                                                  </p>
                                                ))}
                                            </>
                                          }
                                        />
                                        <br />
                                        <Button
                                          onClick={() => handleClick(item)}
                                          type="primary"
                                          style={{
                                            background: "#B9001F",
                                            borderColor: "#B9001F",
                                            borderRadius: "30px",
                                            height: "40px",
                                            fontSize: "14px",
                                            fontFamily: "Roboto",
                                            width: "120px",
                                          }}
                                        >
                                          Register
                                        </Button>
                                      </Card>
                                    </Col>
                                  ))}
                              </Row>
                              <div
                                style={{
                                  marginTop: "40px",
                                  textAlign: "center",
                                }}
                              >
                                <Pagination
                                  defaultCurrent={currentPages[category]}
                                  pageSize={productsPerPage}
                                  total={programsData.programs[category].length}
                                  onChange={(page) =>
                                    handlePageChange(page, category)
                                  }
                                  showSizeChanger={false}
                                  showQuickJumper={false}
                                  style={{
                                    marginTop: "20px",
                                    listStyle: "none !important",
                                  }}
                                  itemRender={(
                                    current,
                                    type,
                                    originalElement
                                  ) => {
                                    if (type === "prev") {
                                      return (
                                        <Button
                                          style={{
                                            borderRadius: "50%",
                                            backgroundColor: "transparent",
                                            border: "none",
                                          }}
                                          icon={<LeftOutlined />}
                                        />
                                      );
                                    }
                                    if (type === "next") {
                                      return (
                                        <Button
                                          style={{
                                            borderRadius: "50%",
                                            backgroundColor: "transparent",
                                            border: "none",
                                          }}
                                          icon={<RightOutlined />}
                                        />
                                      );
                                    }
                                    if (type === "page") {
                                      return (
                                        <Button
                                          style={{
                                            borderRadius: "50%",
                                            backgroundColor: "transparent",
                                            border: "none",
                                            color: "black",
                                          }}
                                        >
                                          {current}
                                        </Button>
                                      );
                                    }
                                    return originalElement;
                                  }}
                                />
                              </div>
                            </Card>
                          </div>
                        )
                      )
                    ) : (
                      <div>No programs available</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Page>
  );
};

export default ProgramsPage;
