import React, { useState, useEffect } from "react";
import { Typography, Button, Card } from "antd";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import tilebg from "./../../src/img/pattern/tilebg1.jpg";
import tilebg2 from "./../../src/img/pattern/tilebg.jpg";
import { useMediaQuery } from "react-responsive";
import GridCard from "../Components/common/GridComponent";
import { getPageById } from "../Api/index";
import "./../styles/pageTitle.css";

const TestingAndResearch = () => {
  const { Title, Text } = Typography;
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [fileData, setFileData] = useState([]);

  const buttonContainerStyle = {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    flexDirection: isMobile ? "column" : "row",
    alignItems: "center",
    width: isMobile ? "100%" : "auto",
  };

  const buttonStyles = {
    background: "#800932 ",
    borderColor: "#800932",
    fontSize: isMobile ? "12px" : "16px",
    fontWeight: "bold",
    marginRight: isMobile ? "0" : "10px",
    marginBottom: isMobile ? "10px" : "10px",
    width: isMobile ? "100%" : "auto",
    whiteSpace: "normal",
    height: "150px",
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    fontFamily: "Roboto, sans-serif",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: isMobile ? "auto" : "450px",
    minWidth: isMobile ? "auto" : "450px",
  };

  const cardStyle = {
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2 )",
    width: "100%",
    marginBottom: "50px",
  };

  const cardheadStyle = {
    fontSize: "28px",
    fontWeight: "bold",
  };

  const firstGridItems = [
    {
      title: "GroundWater",
      description:
        "Ground water contamination can adversely affect the quality of life in both community and economic development. In many countries, groundwater is the only economical water source available. Testing is crucial as many contaminants are undetectable, the tests help establish the quality and the economic value.",
      // imageUrl: tilebg,
    },
    {
      title: "RainWater",
      description:
        "Rainwater testing aims at detecting contaminants mainly fecal matters of animals, as birds, rodents and other small animals usually leave their droppings off on the roofs and the water tank systems. The testing proves to be useful in urban areas due to the substantial amount of airborne contaminants present. These tests abide by the standards set by the Safe Drinking Water Act.",
    },
    {
      title: "Surface Water",
      description:
        "Surface water refer to water bodies that are typically sensitive to the effects of contamination. The testing ensures drinking and bathing water quality and also, aims at biodiversity and ecosystem conservation. Contamination levels can vary based on the environment and anthropogenic activities such as usage of agricultural chemicals, industrial and commercial shipping and landfill",
      // imageUrl: tilebg,
    },
    {
      title: "Bottled Water",
      description:
        "This test aims to determine the quality of bottled water, the purpose of these tests are to determine the type of contaminants that are present, in drinking water contaminants are considered as those substance in the water other than the H2o molecule. However not all these are undesirable, certain contaminants are preferable as it adds a unique taste, texture and body. The tests are conducted under the FDA and abide by the standards of the Safe Drinking Water Act.",
    },
    {
      title: "Ocean Water (After Pre-treatment)",
      description:
        "This test aims at determining the level of bacterial pollution as well as to test the salinity and temperate.",
    },
    {
      title: "Soil Environment (After Pre-treatment)",
      description:
        "This test is designed to help municipalities, farmers, environmental agencies, and others in developing environmentally sound soil management programs, it is designed to trace and monitor soil elements after they have been fixed with bio solids and other such materials. JRDC also test the fertility and quality of the soil.",
      //  imageUrl: tilebg,
    },
    {
      title: "Air Phase (After Pre-treatment)",
      description:
        "Ground water contamination can adversely affect the quality of life in both community and economic development. In many countries, groundwater is the only economical water source available. Testing is crucial as many contaminants are undetectable, the tests help establish the quality and the economic value.",
    },
    {
      title: "Medical Testing (After Pre-treatment)",
      description: (
        <div>
          <p>
            This test is designed to determine the impact of the water in a
            medical standpoint, allowing clients to detect the conditions and
            the causes of those conditions. It helps to determine a diagnosis
            and plan the necessary treatments.
          </p>
          <ul>
            <li>Instructions for water sampling</li>
            <li>Customer support (Quarries on reports)</li>
          </ul>
        </div>
      ),
      //  imageUrl: tilebg,
    },
  ];

  const paragraph = (
    <p>JRDC conducts more highly sophisticated extensive testings such as:</p>
  );

  const secondGridItems = [
    {
      title: "Pharmaceutical pollutants",
    },
    {
      title: "Heavy Metal analysis",
    },
    {
      title: "Agrochemicals",
    },
    {
      title: "Persistent Organic Pollutants(POPs)",
    },
    {
      title: "Cations and Anions in water",
    },
    {
      title: "Particle Size Distribution",
    },
    {
      title: "BET surface Area",
    },
    {
      title: "Zeta Potential",
    },
    {
      title: "Absorption spectroscopy",
    },
    {
      title: "Natural gases and gaseous pollutants",
    },
    {
      title: "CHN analysis",
    },
    {
      title: "TOC analysis",
    },
  ];

  const thirdGridItems = [
    {
      title: "Particle Size Analysis",
    },
    {
      title: "BET surface Analysis",
    },
    {
      title: "Zeta Potential analysis",
    },
    {
      title: "FTIR Analysis",
    },
    {
      title: "UV-Visible Spectrometer Analysis",
    },
    {
      title: "Natural gas analysis",
    },
    {
      title: "CHN analysis",
    },
    {
      title: "TOC analysis",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPageById("13", 0, 0);
        setFileData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Page>
      <Navbar />
      <div className="page-content woocommerce">
        <div className="container clear-fix">
          <div className="grid-col-row">
            <div className="site-card-border-less-wrapper">
              <div className="grid-col grid-col-12">
                <div className="col2-set" id="customer_details">
                  <h1 className="section-title">Testing And Research</h1>
                  <br />
                  <Text
                    style={{
                      color: "#666",
                      fontSize: "16px",
                      fontFamily: "Roboto, sans-serif",
                      textAlign: "justify",
                      marginBottom: "20px",
                      display: "block",
                    }}
                  >
                    Water crisis is a serious issue that affects both industries
                    and individuals both nationally and internationally,
                    combating these issues involve collaborative efforts of
                    water sciences, environmental sciences and health-related
                    applied research. At JRDC, we provide a lucrative platform
                    that enables us to create and construct innovative and
                    robust solutions for these dynamic challenges. With our
                    cutting-edge technology and analytical instruments, we can
                    cater to a wide spectrum of water and environmental-related
                    analytical requirements.
                  </Text>

                  <Card
                    title="Testing"
                    headStyle={cardheadStyle}
                    style={{ ...cardStyle, marginTop: "50px" }}
                  >
                    <Text
                      style={{
                        color: "#666",
                        fontSize: "16px",
                        fontFamily: "Roboto, sans-serif",
                        textAlign: "justify",
                        marginBottom: "20px",
                        display: "block",
                      }}
                    >
                      Testing involves a process of analyzing a samples to
                      detect contaminants and other harmful substances and
                      organisms revolving around water and the environment, the
                      results of the test will undergo a thorough examination
                      that will prove the quality and underling issues. We can
                      then work towards creating a solution that caters the
                      situation.
                    </Text>
                  </Card>
                  <GridCard
                    gridItems={firstGridItems}
                    background={tilebg}
                    title="General Testing"
                    showDescription={true}
                  />
                  <br />

                  <GridCard
                    gridItems={secondGridItems}
                    background={tilebg}
                    title="Extended Testing"
                    showDescription={false}
                    preContent={paragraph}
                  />
                  <br />

                  <GridCard
                    gridItems={thirdGridItems}
                    background={tilebg}
                    title="Advance Instrumental Analysis"
                    showDescription={false}
                  />

                  <Card
                    style={cardStyle}
                    headStyle={cardheadStyle}
                    title="More Info"
                  >
                    <div style={buttonContainerStyle}>
                      {fileData.map((item) => (
                        <Button
                          key={item.id}
                          type="primary"
                          size="large"
                          style={buttonStyles}
                          href={item.files[0]?.file_url || "#"}
                          target="_blank"
                        >
                          {item.title}
                        </Button>
                      ))}
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Page>
  );
};

export default TestingAndResearch;
