import React, { useEffect, useState } from "react";
import ksoft_logo from "../../../src/img/pattern/footer-ksoft-logo.png";
import "./Footer.css";

const footerStyle = {
  width: "100%",
  padding: "20px 0",
};

const copyrightStyle = {
  textAlign: "center",
};

function Footer() {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const checkSticky = () => {
      const contentHeight = document.documentElement.scrollHeight;
      const viewportHeight = window.innerHeight;
      setIsSticky(contentHeight <= viewportHeight);
    };

    checkSticky();
    window.addEventListener("resize", checkSticky);
    return () => window.removeEventListener("resize", checkSticky);
  }, []);

  return (
    <footer
      className={`footer ${isSticky ? "sticky" : ""}`}
      style={footerStyle}
    >
      <div className="footer-bottom">
        <div className="grid-row clear-fix">
          <div className="copyright">
            © All rights reserved by Ministry of Water Supply and Designed by{" "}
            <img src={ksoft_logo} data-at2x="img/logo@2x.png" alt="jrdc_Logo" />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
