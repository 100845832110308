import React, { useEffect, useState } from "react";
import { Button, Col, Row, Typography, Card, Pagination } from "antd";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { useNavigate } from "react-router-dom";
import { getEventswithProducts } from "../Api/index";
import { SendOutlined } from "@ant-design/icons";
import "./../styles/pageTitle.css";
import default_img from "./../img/pattern/default.jpg";

const { Title, Text } = Typography;

const ResearchPaperSubmissionPage = () => {
  const navigate = useNavigate();
  const [eventsWithProducts, setEventsWithProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchEventsWithProducts();
  }, [currentPage]); // Fetch events when page changes

  const fetchEventsWithProducts = async () => {
    try {
      // Fetch events for the current page
      const eventsData = await getEventswithProducts(currentPage);
      setEventsWithProducts(eventsData);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const handleOnClick = (product, eventId) => {
    navigate("/AddResearchPaper", { state: { event: eventId, product } });
    console.log("sent product data:", product);
    console.log("sent event data:", eventId);
  };

  return (
    <Page>
      <Navbar />
      <div className="page-content woocommerce">
        <div className="page-wrapper">
          <div className="container clear-fix">
            <h1 className="section-title">Research Paper Submission</h1>
            <br />
            {eventsWithProducts.map(
              (
                event,
                index // Added index for key
              ) => (
                <div key={event.id} style={{ marginBottom: "80px" }}>
                  <Title
                    level={3}
                    style={{ marginBottom: "50px", fontWeight: "bold" }}
                  >
                    <SendOutlined style={{ marginRight: "10px" }} />{" "}
                    {event.title}
                  </Title>
                  <Row gutter={[16, 16]}>
                    {event.products &&
                      event.products.map((product) => (
                        <Col key={product.id} xs={24} sm={12} md={8} lg={6}>
                          <Card
                            hoverable
                            style={{
                              width: "100%",
                              marginBottom: 20,
                              height: "350px",
                            }}
                            cover={
                              <img
                                alt={product.name}
                                src={product.image_url || default_img}
                                style={{ height: "200px" }}
                              />
                            }
                            onClick={() => handleOnClick(product, event.id)}
                          >
                            <div
                              className="product-name-container"
                              style={{ textAlign: "center", marginBottom: 10 }}
                            >
                              <span
                                className="product-name"
                                style={{
                                  fontFamily: "Roboto, sans-serif",
                                  fontSize: "18px",
                                  fontWeight: "bold",
                                }}
                              >
                                {product.name}
                              </span>
                            </div>
                            <div
                              className="product-name-container"
                              style={{ textAlign: "center", marginBottom: 10 }}
                            >
                              {product.sale_price &&
                                product.sale_price < product.regular_price && (
                                  <>
                                    <div style={{ marginBottom: "5px" }}>
                                      <span
                                        className="product-name"
                                        style={{
                                          color: "red",
                                          fontFamily: "Roboto, sans-serif",
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Sale Price: Rs. {product.sale_price}
                                      </span>
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          textDecoration: "line-through",
                                          color: "#616161",
                                          fontFamily: "Roboto, sans-serif",
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Price: Rs. {product.regular_price}
                                      </span>
                                    </div>
                                  </>
                                )}
                              {(!product.sale_price ||
                                product.sale_price >=
                                  product.regular_price) && (
                                <span
                                  className="product-name"
                                  style={{
                                    color: "red",
                                    fontFamily: "Roboto, sans-serif",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Price: Rs. {product.regular_price}
                                </span>
                              )}
                            </div>
                          </Card>
                        </Col>
                      ))}
                  </Row>
                </div>
              )
            )}
            <Row justify="center">
              <Pagination
                defaultCurrent={currentPage}
                total={eventsWithProducts.length}
                pageSize={3}
                onChange={(page) => setCurrentPage(page)}
                style={{ marginTop: "20px", marginBottom: "50px" }}
              />
            </Row>
          </div>
        </div>
        <Footer />
      </div>
    </Page>
  );
};

export default ResearchPaperSubmissionPage;
