import { Form, Select } from "antd";

const FormSelect = ({ label = "", name = "", options = [], setVal = () => { } }) => {
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    return <Form.Item label={label} name={name}>
        <Select showSearch filterOption={filterOption}
            onChange={setVal}
            optionFilterProp="children">
            {options.map((opt) => <Select.Option value={opt.value}>{opt.text}</Select.Option>)}
        </Select>
    </Form.Item>
}

export default FormSelect;