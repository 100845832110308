import React, { useEffect, useState } from "react";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { Typography, Card, Button, Row, Col, Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { getPageById } from "../Api/index";
import "./../styles/pageTitle.css";
import "./../Components/layouts/Footer.css";
import default_img from "./../img/pattern/default.jpg";

const { Title } = Typography;

const Gallery = () => {
  const navigate = useNavigate();
  const [visiblePosts, setVisiblePosts] = useState(6);
  const [albumData, setAlbum] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const pageSize = 6;

  useEffect(() => {
    const fetchData = async (page) => {
      try {
        const response = await getPageById("9", page, pageSize);
        setAlbum(response.data);
        setTotalItems(response.total);
      } catch (error) {
        console.error("Error fetching research data:", error);
      }
    };

    fetchData(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlAlbumClick = (albumItemId) => {
    const clickedAlbumId = albumData.find((item) => item.id === albumItemId);
    navigate(`/ViewAlbum`, { state: { albumItem: clickedAlbumId } });
    console.log("Sent album ID: ", clickedAlbumId);
  };

  const showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total} items`;
  };

  return (
    <Page>
      <Navbar />
      <div className="page-wrapper">
        <div className="page-content woocommerce">
          <div className="container clear-fix">
            <div className="grid-col-row">
              <h1 className="section-title"> Gallery</h1>
              <br />
              <Row gutter={[16, 16]} style={{ justifyContent: "space-around" }}>
                {albumData.map((album) => (
                  <Col key={album.id} xs={24} sm={12} md={12} lg={8}>
                    <Card
                      style={{
                        minWidth: "300px",
                        marginBottom: 16,
                        height: "420px",
                      }}
                      cover={
                        <img
                          alt={album.title}
                          src={album?.image_url ? album.image_url : default_img}
                          style={{ height: 250 }}
                        />
                      }
                    >
                      <div>
                        <Title level={4} style={{ marginBottom: 0 }}>
                          {album.title}
                        </Title>
                      </div>
                      <br />
                      <div style={{ textAlign: "center" }}>
                        <Button
                          type="primary"
                          style={{
                            background: "#B9001F",
                            borderColor: "#B9001F",
                            borderRadius: "30px",
                            height: "40px",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                          onClick={() => handlAlbumClick(album.id)}
                        >
                          View More
                        </Button>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
              <Row justify="center" style={{ marginTop: "20px" }}>
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={totalItems}
                  onChange={handlePageChange}
                  showTotal={showTotal}
                  style={{ marginBottom: "50px" }}
                />
              </Row>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Page>
  );
};

export default Gallery;
