import { useState, useEffect } from "react";
import { Button, Table, Tag, Modal, Typography } from "antd";
import {
  EyeOutlined,
  DollarCircleOutlined,
  CloseCircleOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { getOrders, cancelOrder } from ".././Api/index";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

function Orders() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const navigate = useNavigate();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [cancelOrderId, setCancelOrderId] = useState(null);

  useEffect(() => {
    fetchOrders(); // Fetch orders when component mounts
  }, [pagination]); // Fetch orders whenever pagination changes

  const fetchOrders = async () => {
    try {
      const { data: ordersData, total } = await getOrders(
        pagination.current,
        pagination.pageSize
      );
      console.log("Orders data:", ordersData);

      setOrders(ordersData);
      setLoading(false);
      // Only update total if it's not already set
      if (total !== pagination.total) {
        setPagination((prevPagination) => ({
          ...prevPagination,
          total,
        }));
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching orders:", error);
    }
  };

  const handleCancelOrder = async (orderId) => {
    try {
      setLoading(true);
      await cancelOrder(orderId);
    } catch (error) {
      console.error("Error cancelling order:", error);
    } finally {
      setLoading(false);
      setCancelModalVisible(false);
    }
  };

  const handlePaginationChange = (current, pageSize) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    })); // Update pagination state
  };

  const showCancelModal = (orderId) => {
    setCancelOrderId(orderId);
    setCancelModalVisible(true);
  };

  const hideCancelModal = () => {
    setCancelModalVisible(false);
    fetchOrders();
  };

  const confirmCancelOrder = () => {
    if (cancelOrderId) {
      handleCancelOrder(cancelOrderId).then(() => {
        fetchOrders();
      });
    }
  };

  const getTagColor = (status) => {
    switch (status.toLowerCase()) {
      case "pending payment":
        return "orange";
      case "awaiting receipt approval":
        return "purple";
      case "receipt rejected":
        return "red";
      case "processing":
        return "green";
      case "on hold":
        return "volcano";
      case "completed":
        return "blue";
      case "cancelled":
        return "magenta";
      case "refunded":
        return "gold";
      case "failed":
        return "red";
      case "waiting for approval":
        return "cyan";
      default:
        return "grey";
    }
  };

  const getOrderStatusName = (statusKey) => {
    switch (statusKey) {
      case 1:
        return "Waiting for approval";
      case 2:
        return "Processing";
      case 3:
        return "On Hold";
      case 4:
        return "Pending payment";
      case 5:
        return "Awaiting Receipt Approval";
      case 6:
        return "Receipt Rejected";
      case 7:
        return "Cancelled";
      case 8:
        return "Completed";
      case 9:
        return "Failed";
      case 10:
        return "Processing Refund";
      case 11:
        return "Refunded";
      default:
        return "Unknown";
    }
  };

  const renderActionButtons = (_, record) => {
    const statusName = getOrderStatusName(parseInt(record.status));
    const showPayButton =
      statusName === "Pending payment" || statusName === "Receipt Rejected";
    const showCancelButton =
      statusName !== "Cancelled" &&
      statusName !== "Completed" &&
      statusName !== "Processing Refund" &&
      statusName !== "Refunded";
    const showSlipButton =
      statusName === "Completed" && record.bill_payment_method === "chp";
    return (
      <>
        <Button
          type="primary"
          icon={<EyeOutlined />}
          onClick={() => {
            navigate(`/MyAccount/ViewOrder/${record.id}`);
          }}
        >
          View
        </Button>
        {showPayButton && (
          <Button
            type="primary"
            icon={<DollarCircleOutlined />}
            style={{
              marginLeft: "8px",
              backgroundColor: "#04AA6D",
              borderColor: "#04AA6D",
            }}
            onClick={() => {
              navigate(`/MyAccount/PaymentPage/${record.id}`);
              console.log("passed Id: " + record.id);
            }}
          >
            Pay
          </Button>
        )}
        {showCancelButton && (
          <Button
            type="primary"
            icon={<CloseCircleOutlined />}
            style={{
              marginLeft: "8px",
              backgroundColor: "#ff4d4f",
              borderColor: "#ff4d4f",
            }}
            onClick={() => showCancelModal(record.id)}
          >
            Cancel
          </Button>
        )}
        {showSlipButton && (
          <Button
            type="primary"
            icon={<FileTextOutlined />}
            style={{
              marginLeft: "8px",
              backgroundColor: "#483D8B",
              borderColor: "#483D8B",
            }}
            onClick={() => {
              navigate(`/PaymentSlip/${record.id}`);
            }}
          >
            Payment Slip
          </Button>
        )}
      </>
    );
  };

  const columns = [
    {
      title: "Order",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (text) => <span>#{text}</span>,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <span>{new Date(text).toLocaleString()}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        const statusName = getOrderStatusName(parseInt(status));
        return <Tag color={getTagColor(statusName)}>{statusName}</Tag>;
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "item_sub_total",
      render: (text) => `Rs. ${text}`,
    },
    {
      title: "Action",
      key: "operation",
      width: 350,
      render: renderActionButtons,
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={orders}
        loading={loading}
        scroll={{ x: "max-content" }}
        sticky
        className="mt-2"
        style={{ marginBottom: "40px" }}
        pagination={{
          ...pagination,
          onChange: handlePaginationChange,
        }}
      />
      <Modal
        title="Are you sure you want to cancel this order?"
        visible={cancelModalVisible}
        onOk={confirmCancelOrder}
        onCancel={hideCancelModal}
        okText="Yes"
        cancelText="No"
      >
        <p>Clicking Yes will cancel this order.</p>
      </Modal>
      <div style={{ clear: "both" }}>
        <p
          style={{
            color: "red",
            fontFamily: "Roboto",
            fontSize: "14px",
            marginBottom: "40px",
            textAlign: "justify",
          }}
        >
          In case your card payment was successful but the order has not changed
          / updated the status (stays pending payment) first try refreshing the
          page. If it still doesn't update please contact the admin through the
          contact us page. Make sure to provide your transaction ID/No, Order ID
          along with your contact information.
        </p>
      </div>
    </div>
  );
}

export default Orders;
