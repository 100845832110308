import React, { useState } from "react";
import FormInput from "../Components/form/FormInput";
import FormSelect from "../Components/form/FormSelect";
import { Form } from "antd";
import Pilot_Single from "../../src/img/pattern/pilot_single.jpg";
import { PDURATION } from "../Constants";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import DatePicker from "react-datepicker";
import { setHours, setMinutes } from "date-fns";
const PilotAreasPage = () => {
  const [cost, setCost] = useState(5000);
  const [form] = Form.useForm();
  const [startDate, setStartDate] = useState(
    setHours(setMinutes(new Date(), 0), 9)
  );

  const handleDurationChange = (value) => {
    let baseCost = 5000;

    switch (value) {
      case 1:
        setCost(baseCost);
        break;
      case 2:
        setCost(baseCost + 1000);
        break;
      case 3:
        setCost(baseCost + 1500);
        break;
      case 4:
        setCost(baseCost + 2000);
        break;
      case 5:
        setCost(baseCost + 2500);
        break;
      case 6:
        setCost(baseCost + 3000);
        break;
      case 7:
        setCost(baseCost + 3500);
        break;
      case 8:
        setCost(baseCost + 4000);
        break;
      case 9:
        setCost(11000);
        break;
      default:
        setCost(baseCost);
    }
  };

  return (
    <Page>
      <Navbar />
      <div class="page-content woocommerce">
        <div class="container clear-fix">
          <div class="grid-col-row">
            <div class="grid-col grid-col-12">
              <div role="main">
                <div
                  itemscope=""
                  itemtype="http://schema.org/Product"
                  class="product"
                >
                  <div class="images">
                    <div class="picture">
                      <img
                        src={Pilot_Single}
                        data-at2x="http://placehold.it/370x370"
                        class="attachment-shop_single"
                        alt=""
                        title=""
                      />
                      <div class="hover-effect"></div>
                      <div class="link-cont">
                        <a
                          href="http://placehold.it/370x370"
                          class="fancy fa fa-eye woocommerce-main-image zoom"
                          data-fancybox-group="gallery"
                          title=""
                          data-rel="prettyPhoto[product-gallery]"
                        ></a>
                      </div>
                    </div>
                    <div class="thumbnails">
                      <div class="owl-carousel">
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src="http://placehold.it/116x116"
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src="http://placehold.it/116x116"
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src="http://placehold.it/116x116"
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src={Pilot_Single}
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="summary entry-summary">
                    <h2 class="product_title entry-title">Pilot Area</h2>

                    <div itemprop="description">
                      <p>
                        <strong>Pilot Area - 60 minutes</strong>
                        <br />
                        The North wing of the JRDC is entirely dedicated as a
                        pilot area for technology development-related
                        activities. Pilot testing is crucial as it provides an
                        opportunity to test and implement a new process on a
                        smaller scale, this is for the purpose of addressing any
                        weaknesses or improvements.
                      </p>
                    </div>
                    <Form
                      name="basic"
                      autoComplete="off"
                      layout="vertical"
                      form={form}
                    >
                      <div className="col-md-3">
                        <Form.Item label="" name="date">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            minTime={setHours(setMinutes(new Date(), 0), 9)}
                            maxTime={setHours(setMinutes(new Date(), 30), 16)}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeIntervals={60}
                          />
                        </Form.Item>

                        <FormSelect
                          label="Duration"
                          name="duration"
                          options={PDURATION}
                          setVal={(value) => handleDurationChange(value)}
                        />
                      </div>
                    </Form>

                    <div className="total">Cost: {cost}</div>
                    <div class="main-features"></div>
                    <form class="cart" method="post">
                      <a
                        href="/shopCart"
                        class="cws-button alt border-radius small bt-color-3"
                      >
                        CHECK AVAILABILITY
                      </a>
                    </form>
                  </div>
                  <hr class="divider-color" />
                  <div class="tabs">
                    <div class="block-tabs-btn clear-fix">
                      <div class="tabs-btn active" data-tabs-id="tabs1">
                        Description
                      </div>
                    </div>
                    <div class="tabs-keeper">
                      <div
                        class="container-tabs active"
                        data-tabs-id="cont-tabs1"
                      >
                        <p>
                          <strong>Pilot Area Details</strong>
                        </p>
                        <p>
                          The North wing of the JRDC is entirely dedicated as a
                          pilot area for technology development-related
                          activities. Pilot testing is crucial as it provides an
                          opportunity to test and implement a new process on a
                          smaller scale, this is for the purpose of addressing
                          any weaknesses or improvements.
                        </p>
                        <p>
                          The researchers or students can execute and conduct
                          their projects on developing, building, and testing
                          water treatment facilities, in these premises. This
                          area can help researches identify and correct major
                          gaps that occur in the water testing solutions and
                          technology. It can help test the periods of hydraulic
                          retention times (HRTs) and stimulate different
                          conditions and its effects. Further aiding students
                          and researchers’ to test out their innovative designs
                          and methodology.
                        </p>
                        <p>
                          The pilot area includes facilities for the following
                          purposes:
                        </p>
                        <p>
                          {" "}
                          <i class="minus">—</i>Testing
                        </p>
                        <p>
                          {" "}
                          <i class="minus">—</i>Technology Development
                        </p>
                        <p>
                          {" "}
                          <i class="minus">—</i>Modeling and Prototype
                          Development
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </Page>
  );
};

export default PilotAreasPage;
