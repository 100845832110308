import React, { useState, useEffect } from "react";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import {
  Button,
  Form,
  Input,
  Checkbox,
  Radio,
  Space,
  Row,
  Col,
  message,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { useLocation } from "react-router-dom";
import {
  storeCustomerOrder,
  login,
  register,
  getUserCustomerbyId,
} from ".././Api/index";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import RegisterForm from "../Components/form/RegisterForm";
const pdfUrl = process.env.PUBLIC_URL + "/t&c.pdf";

const ShoitemheckoutPage = ({ type, orderId }) => {
  const [checkoutform] = useForm();
  const [registerform] = useForm();
  const { state } = useLocation();
  const [cartItems, setCartItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [registerError, setRegisterError] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [checkedTAndC, setCheckedTAndC] = useState(false);
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const setCheckoutFormFields = (values) => {
    const finalValues = {
      bill_first_name: values.first_name,
      bill_last_name: values.last_name,
      bill_company: values.company,
      bill_country: values.country,
      bill_address_line1: values.address_line1,
      bill_address_line2: values.address_line2,
      bill_city: values.city,
      bill_postcode: values.postcode,
      bill_state: values.state,
      bill_email: values.bill_email,
      bill_phone_no: values.phone,
      bill_state: values.state,
    };
    checkoutform.setFieldsValue(finalValues);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const loggedInUser = JSON.parse(
          window.localStorage.getItem("jrdc_user")
        );
        if (loggedInUser) {
          setUser(loggedInUser);
          const response = await getUserCustomerbyId(loggedInUser.id);
          const userData = response.data;
          setCheckoutFormFields(userData.customer ?? {});
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();

    if (state && state.cartItems) {
      setCartItems(state.cartItems);
      setTotal(state.total);
    }
  }, [checkoutform, state]);

  const handleLoginClick = () => {
    setShowLoginForm(true);
    setShowRegisterForm(false);
  };

  const handleRegisterClick = () => {
    setShowRegisterForm(true);
  };

  const storeOrder = (values) => {
    setLoading(true);
    console.log(values);

    let userData = JSON.parse(window.localStorage.getItem("jrdc_user"));
    const cartItems = JSON.parse(
      window.localStorage.getItem("jrdc_cart_items")
    );

    const ipAddress = "";
    const status = "";
    const billPaymentMethod = "";
    const billTransactionId = generateUniqueId();

    const orderLinesData = cartItems.map((item) => {
      const defaultTime = "00:00:00";
      const orderLine = {
        product_id: item.product.id,
        qty: parseInt(item.qty),
        unit_price: item.salePrice,
        total: item.cost,
        appointment: item.product.appointable
          ? {
              start_date_time:
                dayjs(item.startDate).format("YYYY-MM-DD") +
                " " +
                (item.startTime
                  ? dayjs(item.startTime).format("HH:mm:ss")
                  : defaultTime),
              end_date_time:
                dayjs(item.endDate).format("YYYY-MM-DD") +
                " " +
                (item.endTime
                  ? dayjs(item.endTime).format("HH:mm:ss")
                  : defaultTime),
              all_day:
                item.product.appointable.reverse_measure == "days"
                  ? true
                  : false,
            }
          : null,
        paper: item.product.research_paper
          ? {
              abstract: item.product.abstract,
              name: item.product.title,
              research_paper: item.product.research_paper,
            }
          : null,
      };

      // if (item.product.research_paper) {
      //   orderLine.paper = [
      //     {
      //       abstract: item.product.abstract,
      //       name: item.product.title,
      //       research_paper: item.product.research_paper,
      //     },
      //   ];
      // }

      return orderLine;
    });

    const itemsTotal = orderLinesData.reduce(
      (acc, curr) => acc + parseFloat(curr.total),
      0
    );
    const total = orderLinesData.reduce(
      (acc, curr) => acc + parseFloat(curr.total),
      0
    );
    const total_items_qty = orderLinesData.reduce(
      (acc, curr) => acc + parseInt(curr.qty),
      0
    );

    const orderData = {
      ...values,
      customer_id: userData.customer.id,
      ref_no: userData.ref_no || "",
      ip: ipAddress,
      status: status,
      bill_payment_method: billPaymentMethod,
      bill_transaction_id: billTransactionId,
      order_lines: orderLinesData,
      items_total: itemsTotal,
      total: total,
      total_items_qty: total_items_qty,
      additional_note: values.note ?? null,
      order_logs: values.note
        ? [
            {
              note: values.note,
              user: userData.name,
            },
          ]
        : null,
    };
    console.log("OrderData:", orderData);

    storeCustomerOrder(orderData)
      .then(() => {
        window.localStorage.removeItem("jrdc_cart_items");
        navigate(`/MyAccount/Orders`);
      })
      .catch((e) => alert(e))
      .finally(() => {
        setLoading(false);
      });
    // try {
    //   let userData = JSON.parse(window.localStorage.getItem("jrdc_user"));
    //   const cartItems = JSON.parse(
    //     window.localStorage.getItem("jrdc_cart_items")
    //   );

    //   const values =  checkoutform.validateFields();

    //   const ipAddress = "";
    //   const status = "";
    //   const billPaymentMethod = "";
    //   const billTransactionId = generateUniqueId();

    //   const orderLinesData = cartItems.map((item) => {
    //     const orderLine = {
    //       product_id: item.product.id,
    //       qty: item.qty,
    //       unit_price: item.cost,
    //       total: item.cost,
    //       appointment: null,
    //     };

    //     // Conditionally include the papers array if researchPaper exists
    //     if (item.product.research_paper) {
    //       orderLine.paper = [
    //         {
    //           abstract: item.product.abstract,
    //           title: item.product.title,
    //           research_paper: item.product.research_paper,
    //         },
    //       ];
    //     }

    //     return orderLine;
    //   });

    //   const itemsTotal = orderLinesData.reduce(
    //     (acc, curr) => acc + curr.qty,
    //     0
    //   );
    //   const total = orderLinesData.reduce((acc, curr) => acc + curr.total, 0);

    //   const orderData = {
    //     customer_id: userData.customer.id,
    //     ref_no: userData.ref_no || "",
    //     ip: ipAddress,
    //     bill_first_name: values.customer.firstname,
    //     bill_last_name: values.customer.lastname,
    //     bill_company: values.customer.company,
    //     bill_country: values.customer.country,
    //     bill_address_line1: values.customer.address_line1,
    //     bill_address_line2: values.customer.address_line2,
    //     bill_city: values.customer.city,
    //     bill_postcode: values.customer.postcode,
    //     bill_state: userData.customer.state,
    //     bill_email: values.email,
    //     bill_phone_no: values.customer.phone,
    //     status: status,
    //     bill_payment_method: billPaymentMethod,
    //     bill_transaction_id: billTransactionId,
    //     order_lines: orderLinesData,
    //     items_total: itemsTotal,
    //     total: total,
    //   };
    //   console.log("OrderData:", orderData);

    //   const response = await storeCustomerOrder(orderData);
    //   console.log("Order stored successfully:", response);
    //   // clear the cart items from local storage after successful submission
    //   window.localStorage.removeItem("jrdc_cart_items");
    //   navigate(`/`);
    // } catch (error) {
    //   console.error("Error storing order:", error);
    // }
  };

  const generateUniqueId = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  };

  const onLogin = (values) => {
    console.log("Success:", values);
    login(values)
      .then((val) => {
        console.log("Login Data", val);
        if (val.status === 201) {
          window.localStorage.setItem(
            "jrdc_user",
            JSON.stringify(val.data.user)
          );
          window.localStorage.setItem("token", val.data.token);
          window.localStorage.setItem(
            "session_expiry_time",
            val.data.token_expires_at_with_timezone
          );
          window.localStorage.setItem(
            "session_expire_at",
            val.data.token_expires_at
          );
          window.localStorage.setItem("timezone", val.data.timezone);
          setIsLoggedIn(true);
          setCheckoutFormFields(val.data.user.customer);
          setUser(val.data.user);
          setShowLoginForm(false);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          message.error(
            "Incorrect username/email or password. Please try again."
          );
        } else {
          message.error("An error occurred. Please try again later.");
        }
      });
  };

  useEffect(() => {
    const token = window.localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);

  return (
    <>
      <Page>
        <Navbar />
        <div className="page-content woocommerce">
          <div className="container clear-fix">
            <div className="grid-col-row">
              <h2 className="container-h2" style={{ marginBottom: "50px" }}>
                Confirm Request
              </h2>
              <div className="grid-col">
                {!user && !showLoginForm && (
                  <p style={{ width: "100%" }}>
                    <h6 style={{ fontFamily: "Roboto", fontSize: "18px" }}>
                      Returning Customer ?
                    </h6>
                    <Button
                      type="primary"
                      onClick={handleLoginClick}
                      className="fos-btn-primary w-100 mt-2"
                      style={{
                        background: "#B9001F",
                        borderColor: "#B9001F",
                        marginBottom: "10px",
                      }}
                    >
                      Click here to Login
                    </Button>
                    <Button
                      type="primary"
                      onClick={handleRegisterClick}
                      className="fos-btn-primary w-100 mt-2"
                      style={{
                        marginLeft: "10px",
                        background: "#B9001F",
                        borderColor: "#B9001F",
                        // marginLeft: "-2px",
                      }}
                    >
                      Click here to Register
                    </Button>
                  </p>
                )}
                {showLoginForm && (
                  <h3 style={{ fontFamily: "Roboto" }}>
                    Returning customer?{" "}
                    <Button
                      type="primary"
                      onClick={() => setShowLoginForm(false)}
                      className="fos-btn-primary w-100 mt-2"
                      style={{
                        background: "#B9001F",
                        borderColor: "#B9001F",
                      }}
                    >
                      Cancel
                    </Button>
                  </h3>
                )}
                {showRegisterForm && (
                  <h3>
                    {/* Returning customer?{" "} */}
                    <Button
                      type="primary"
                      onClick={() => setShowRegisterForm(false)}
                      className="fos-btn-primary w-100 mt-2"
                      style={{
                        background: "#B9001F",
                        borderColor: "#B9001F",
                      }}
                    >
                      Cancel
                    </Button>
                  </h3>
                )}
                {!user && showLoginForm && (
                  <>
                    <h3 style={{ color: "#B9001F", fontWeight: "bold" }}>
                      Login
                    </h3>
                    <Form
                      name="basic"
                      onFinish={onLogin}
                      autoComplete="off"
                      layout="vertical"
                    >
                      <Form.Item
                        label="Username or email address"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message:
                              "Please enter your username or email address.",
                          },
                        ]}
                      >
                        <Input placeholder="Username or email address" />
                      </Form.Item>

                      <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your password.",
                          },
                        ]}
                      >
                        <Input.Password placeholder="Password" />
                      </Form.Item>

                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="fos-btn-primary w-100 mt-5"
                          style={{
                            background: "#B9001F",
                            borderColor: "#B9001F",
                          }}
                        >
                          Login
                        </Button>
                      </Form.Item>
                    </Form>
                  </>
                )}
                {!user && showRegisterForm && (
                  <RegisterForm
                    setUser={setUser}
                    setShowRegisterForm={setShowRegisterForm}
                    setCheckoutFormFields={setCheckoutFormFields}
                    setIsLoggedIn={setIsLoggedIn}
                    setLoading={setLoading}
                  />
                )}
              </div>
              <br />
              <br />
              <Form
                name="checkout"
                form={checkoutform}
                onFinish={storeOrder}
                autoComplete="off"
                layout="vertical"
              >
                <div className="grid-col grid-col-12">
                  <div className="col2-set" id="customer_details">
                    <div className="col-1">
                      <h3
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          fontFamily: "Roboto",
                        }}
                      >
                        Billing details
                      </h3>
                      <Form.Item
                        label="First Name"
                        name={"bill_first_name"}
                        rules={[
                          {
                            required: true,
                            message: "Please enter your first name.",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Last Name"
                        name={"bill_last_name"}
                        rules={[
                          {
                            required: true,
                            message: "Please enter your last name.",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Company name (optional)"
                        name={"bill_company"}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Phone Number"
                        name={"bill_phone_no"}
                        rules={[
                          {
                            required: true,
                            message: "Please enter your phone number.",
                          },
                        ]}
                      >
                        <Input type="number" maxLength={10} />
                      </Form.Item>
                      <Form.Item
                        label="Email address"
                        name={"bill_email"}
                        rules={[
                          {
                            required: true,
                            message: "Please enter your email address.",
                          },
                          {
                            type: "email",
                            message: "Please enter a valid email address.",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Apartment, suite, unit, etc. (optional)"
                        name={"bill_address_line1"}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Street address"
                        name={"bill_address_line2"}
                        rules={[
                          {
                            required: true,
                            message: "Please enter your street address.",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Town / City"
                        name={"bill_city"}
                        rules={[
                          {
                            required: true,
                            message: "Please enter your town/city.",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item label="State" name={"bill_state"}>
                        <Input />
                      </Form.Item>
                      <Form.Item label="Country / Region" name={"bill_country"}>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Postcode / ZIP"
                        name={"bill_postcode"}
                        rules={[
                          {
                            required: true,
                            message: "Please enter your postcode/ZIP.",
                          },
                        ]}
                      >
                        <Input type="number" maxLength={5} />
                      </Form.Item>

                      <Form.Item label="Additional information" name={"note"}>
                        <Input.TextArea />
                      </Form.Item>
                    </div>
                    <div className="col-2">
                      <div className="cart_totals">
                        <Row gutter={[16, 16]}>
                          <Col span={24}>
                            <h3 style={{ color: "black", fontWeight: "bold" }}>
                              Your Order
                            </h3>
                            <table>
                              <tbody>
                                <tr className=""></tr>
                                {cartItems.length > 0 ? (
                                  cartItems.map((item, index) => (
                                    <tr key={index}>
                                      <td>
                                        {item.eventName
                                          ? `${item.eventName} - ${item.product.name}`
                                          : item.product.name}{" "}
                                        x{item.qty}
                                      </td>
                                      <td>Rs.{item.cost}</td>
                                      {item.product.type == "AP" && (
                                        <>
                                          <td>
                                            <p>
                                              {"Date: "}
                                              {item.showTime
                                                ? `${dayjs(
                                                    item.startDate
                                                  ).format(
                                                    "DD/MM/YYYY"
                                                  )} ${dayjs(
                                                    item.startTime
                                                  ).format(
                                                    "HH:mm A"
                                                  )} - ${dayjs(
                                                    item.endDate
                                                  ).format(
                                                    "DD/MM/YYYY"
                                                  )} ${dayjs(
                                                    item.endTime
                                                  ).format("HH:mm A")}`
                                                : `${dayjs(
                                                    item.startDate
                                                  ).format(
                                                    "DD/MM/YYYY"
                                                  )} - ${dayjs(
                                                    item.endDate
                                                  ).format("DD/MM/YYYY")}`}
                                            </p>
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="3">No items in the cart</td>
                                  </tr>
                                )}
                                <tr className="order-total">
                                  <th></th>
                                  <td
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Total
                                  </td>
                                  <td
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {cartItems &&
                                      "Rs " +
                                        cartItems
                                          .map((e) => e.cost)
                                          .reduce(
                                            (sum, num) => sum + parseFloat(num),
                                            0
                                          )
                                          .toFixed(2)}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Col>
                          <Col span={24}>
                            <div className="p-2 ">
                              <div className="d-flex justify-content-between ">
                                <Radio checked="true">
                                  Check appointment availability
                                </Radio>
                              </div>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="p-2 ">
                              <div className="d-flex justify-content-between ">
                                <p>
                                  A Facility request form must be completed and
                                  submitted to JRDC administration no later than
                                  10 business days prior to the scheduled event.
                                  Please note that completion of this
                                  application does not guarantee approval. JRDC
                                  reserves the right to deny facility
                                  reservations on a case-by-case basis regarding
                                  appropriateness for the facility/building.
                                </p>
                                <p>
                                  Requesting parties will receive an email
                                  notification that a request has been received
                                  and requests will be processed (confirmed or
                                  denied) within 3-5 business days. This
                                  notification does not guarantee that the space
                                  has been allocated. The requesting party will
                                  receive an approved copy of their space
                                  request via email which will confirm space
                                  allocation.
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="">
                              <div className="d-flex justify-content-between ">
                                <Checkbox
                                  name="agreement_check"
                                  required
                                  value={checkedTAndC}
                                  onChange={(e) =>
                                    setCheckedTAndC(e?.target?.checked)
                                  }
                                >
                                  <p>
                                    I have read and agree to the website{" "}
                                    <a
                                      href={pdfUrl}
                                      rel="nofollow"
                                      data-product_sku=""
                                      className=""
                                    >
                                      terms and conditions
                                    </a>
                                  </p>
                                </Checkbox>
                              </div>
                            </div>
                          </Col>
                          <Col span={24}>
                            <Form.Item>
                              <Button
                                type="primary"
                                htmlType="submit"
                                className="fos-btn-primary w-100 mt-5"
                                loading={loading}
                                disabled={!checkedTAndC || !isLoggedIn}
                                style={
                                  checkedTAndC && isLoggedIn
                                    ? {
                                        background: "#B9001F",
                                        borderColor: "#B9001F",
                                      }
                                    : {
                                        background: "#B9001F10",
                                        borderColor: "#B9001F05",
                                      }
                                }
                              >
                                Request Confirmation
                              </Button>
                              <p
                                style={{
                                  fontFamily: "Roboto",
                                  fontStyle: "italic",
                                }}
                              >
                                You need to be logged in and the billing
                                information should be filled in to proceed.
                              </p>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <Footer />
      </Page>
    </>
  );
};

export default ShoitemheckoutPage;
