import React from "react";
import Benching_Area from "../../src/img/pattern/Benching_Area2.jpg";

import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { Link } from "react-router-dom";
import { Card } from "antd";
const BenchingAreaPage = () => {
  return (
    <Page>
      <Navbar />

      {/* Page Content */}
      <div className="page-content woocommerce">
        <div className="container clear-fix">
          <div class="grid-col-row">
            <div class="grid-col grid-col-12">
              <h2 className="container-h2">Benching Area</h2>
              <p>
                We provide outstanding laboratory and research facilities for
                researchers and research students. JRDC’s laboratory facilities
                comprise of newly-built research space, with over 70
                sophisticated high-end equipment and resources expected in a
                modern water science laboratory.
              </p>
              <p>
                Like modern water science laboratories the spaces are continuous
                that allows for integration and efficient allocation of
                resources, Students and researches can enjoy the facilities that
                are maintained up to standards and surround themselves in a
                remarkable and vibrant environment. Each space attends to safety
                and an aerodynamic flow.
              </p>
              {/* orderby */}
              <div id="page-meta" class="group">
                <form class="woocommerce-ordering" method="get">
                  <select name="orderby" class="orderby">
                    {/* <option value="menu_order">Default sorting</option> */}
                    <option value="popularity">Sort by popularity</option>
                    <option value="rating">Sort by latest</option>
                    <option value="price">Sort by price: low to high</option>
                    <option value="price-desc">
                      Sort by price: high to low
                    </option>
                  </select>
                </form>
              </div>

              <ul class="products">
                <li class="product">
                  <Card>
                    <div class="picture">
                      {/* <div class="ribbon ribbon-blue">
                        <div class="banner">
                          <div class="text">Not Available</div>
                        </div>
                      </div> */}
                      {/* <img
                        src="http://placehold.it/270x200"
                        data-at2x="http://placehold.it/270x200"
                        alt=""
                      ></img> */}
                      <Link to="/BenchingAreasPage">
                        <img
                          src={Benching_Area}
                          data-at2x="/path/to/your/custom-image.jpg"
                          alt="Auditorium"
                        ></img>
                      </Link>

                      {/* <span class="hover-effect"></span>
                    <div class="link-cont">
                      <a
                        href="http://placehold.it/270x200"
                        class="cws-right fancy cws-slide-left "
                      >
                        <i class="fa fa-search"></i>
                      </a>
                      <a
                        href="/singleProductPage"
                        class=" cws-left cws-slide-right"
                      >
                        <i class="fa fa-link"></i>
                      </a>
                    </div> */}
                    </div>
                    <div class="product-name-s">
                      <Link to="/BenchingAreasPage">Benching Area</Link>
                    </div>
                    <div class="product-description">
                      <div class="short-description">
                        <p class="short-description-s">
                          Benching Area - 60 minutes
                        </p>
                      </div>
                    </div>

                    {/* <div class="star-rating" title="Rated 4.00 out of 5">
                      <span style={{ width: "90%" }}>
                        <strong class="rating">4.00</strong> out of 5
                      </span>
                    </div> */}
                    {/* <span class="price">
                      <span class="amount">
                        555<sup>$</sup>
                      </span>
                    </span> */}
                    {/* <a
                      href="/shopCart"
                      class="cws-button alt border-radius small bt-color-3"
                    >
                      Book
                    </a> */}
                    <div>
                      <a
                        href="/BenchingAreasPage"
                        rel="nofollow"
                        data-product_id="70"
                        data-product_sku=""
                        class="cws-button border-radius icon-left alt"
                      >
                        {" "}
                        <i class=""></i> Book Now
                      </a>
                    </div>
                  </Card>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </Page>
  );
};

export default BenchingAreaPage;
