import React, { useEffect, useState } from "react";
import { Button, Typography, Tabs, Card, Pagination } from "antd";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { DownloadOutlined, FileOutlined } from "@ant-design/icons";
import { getPageById } from "../Api/index";
import { useMediaQuery } from "react-responsive";
import "./../styles/pageTitle.css";
import "./../Components/layouts/Footer.css";

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const PublicationsAndDownloads = () => {
  const [postData, setPosts] = useState({ data: [], total: 0 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTab = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;

  useEffect(() => {
    const fetchData = async (page) => {
      try {
        const data = await getPageById("5", 0, 0);
        setPosts({ data: data || [], total: data.length || 0 });
      } catch (error) {
        console.error("Error fetching research data:", error);
      }
    };

    fetchData(currentPage);
  }, [currentPage]);

  const groupByCategory = (data) => {
    if (!Array.isArray(data)) {
      return {};
    }

    return data.reduce((acc, item) => {
      const categories = item.category_names || ["Uncategorized"];
      categories.forEach((category) => {
        acc[category] = acc[category] || [];
        acc[category].push(item);
      });
      return acc;
    }, {});
  };

  const categorizedPosts = groupByCategory(postData.data);

  const renderPostsForCategory = (category) => {
    const posts = categorizedPosts[category] || [];

    return posts.map((post, idx) => (
      <div
        key={idx}
        style={{
          border: "1px solid #e8e8e8",
          borderRadius: "8px",
          padding: "16px",
          marginBottom: "16px",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          justifyContent: "space-between",
          background: "white",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FileOutlined
            style={{
              marginRight: isMobile ? "20px" : "20px",
              marginBottom: isMobile ? "10px" : "0",
              color: "#B9001F",
            }}
          />
          <span
            style={{
              fontSize: "18px",
              fontFamily: "Roboto, sans-serif",
              fontWeight: "400",
              maxWidth: "95%",
              color: "black",
            }}
          >
            {post.title}
          </span>
        </div>
        <div>
          {post.files?.length > 0 ? (
            post.files.map((file, fileIdx) => (
              <Button
                key={fileIdx}
                type="primary"
                className="fos-btn-primary"
                icon={<DownloadOutlined />}
                href={file.file_url}
                target="_blank"
                style={{
                  background: "#B9001F",
                  borderColor: "#B9001F",
                  borderRadius: "30px",
                  height: "40px",
                  fontSize: "16px",
                  marginTop: isMobile ? "10px" : "0",
                  marginLeft: fileIdx > 0 ? "10px" : "0",
                }}
              >
                Download
              </Button>
            ))
          ) : (
            <span>No files available</span>
          )}
        </div>
      </div>
    ));
  };

  const categories = Object.keys(categorizedPosts);

  return (
    <>
      <Page>
        <Navbar />
        <div className="page-wrapper">
          <div className="page-content woocommerce">
            <div className="container clear-fix">
              <div className="grid-col-row">
                <div className="site-card-border-less-wrapper">
                  <div className="grid-col grid-col-12">
                    <div className="col2-set" id="customer_details">
                      <h1 className="section-title">
                        Publications & Downloads
                      </h1>
                      <br />
                      <Paragraph
                        style={{
                          fontSize: "1.2rem",
                          fontFamily: "Roboto, sans-serif",
                          textAlign: "justify",
                          width: isTab ? "90%" : "100%",
                        }}
                      >
                        Access our latest research publications and downloads
                        via the following links
                      </Paragraph>

                      <Paragraph
                        style={{
                          fontSize: "1.2rem",
                          fontFamily: "Roboto, sans-serif",
                          textAlign: "justify",
                          width: isTab ? "90%" : "100%",
                        }}
                      >
                        We are committed to making the results of our research
                        easily accessible. It assists us in demonstrating the
                        range and depth of our expertise, generating new
                        research possibilities, and increasing the visibility
                        and impact of our work. It not only ensures that we meet
                        the needs of our research funders, but it also benefits
                        society as a whole.
                      </Paragraph>

                      <Card
                        style={{
                          marginTop: "20px",
                          width: isTab ? "90%" : "100%",
                          marginBottom: "50px",
                        }}
                      >
                        {categories.length ? (
                          <Tabs
                            defaultActiveKey="0"
                            tabPosition={"top"}
                            animated={false}
                            size="large"
                            style={{ margin: 0 }}
                          >
                            {categories.map((category, index) => (
                              <TabPane
                                tab={category}
                                key={index}
                                style={{
                                  fontSize: "1.2rem",
                                  fontFamily: "Roboto, sans-serif",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {renderPostsForCategory(category)}
                                </div>
                              </TabPane>
                            ))}
                          </Tabs>
                        ) : (
                          <div>No data available</div>
                        )}
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Page>
    </>
  );
};

export default PublicationsAndDownloads;
