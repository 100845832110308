import React, { useState } from "react";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { useLocation } from "react-router-dom";
import { Button, Card, Row, Col, Pagination } from "antd";
import { getProductsByCategory } from "../Api";
import { useNavigate } from "react-router-dom";
import Product from "../models/Product";
import "./../Components/layouts/Footer.css";
import default_img from "./../img/pattern/default.jpg";
import { useMediaQuery } from "react-responsive";

const SubCategoryListPage = (props) => {
  //   React.useEffect(() => {
  //     async function getAcc() {
  //       const accomodations = await getAccomodations();
  //       console.log(accomodations);
  //     }
  //     getAcc();
  //   }, []);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [products, setproducts] = useState([]);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 9;

  React.useEffect(() => {
    async function getPc() {
      const response = await getProductsByCategory(state.id);
      console.log(state.id);
      if (response.status === 200) {
        setproducts(response.data);
        console.log(response.data);
      } else {
        setproducts([]);
      }

      console.log(products);
    }
    getPc();
  }, []);

  function handleClick(id, state) {
    navigate("/products/details/" + id, { state });
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * productsPerPage;
  const paginatedProducts = products.slice(
    startIndex,
    startIndex + productsPerPage
  );

  return (
    <Page>
      <Navbar />

      {/* Page Content */}
      <div className="page-wrapper">
        <div className="page-content woocommerce">
          <div className="container clear-fix">
            <div class="grid-col-row">
              <div class="grid-col grid-col-12">
                <h2
                  className="container-h2"
                  style={{
                    fontSize: isMobile ? "40px" : "",
                    textAlign: "left",
                  }}
                >
                  {state.name}
                </h2>
                <p
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    textAlign: "justify",
                  }}
                >
                  {state.description}
                </p>

                {/* Shop */}
                {/* <div id="page-meta" class="group">
                  <form class="woocommerce-ordering" method="get">
                    <select name="orderby" class="orderby">
                      <option value="popularity">Sort by popularity</option>
                      <option value="rating">Sort by latest</option>
                      <option value="price">Sort by price: low to high</option>
                      <option value="price-desc">
                        Sort by price: high to low
                      </option>
                    </select>
                  </form>
                </div> */}

                <ul class="products" style={{ marginBottom: "50px" }}>
                  {paginatedProducts?.map((pc) => {
                    console.log(products);
                    return (
                      <li class="product" style={{ marginBottom: "25px" }}>
                        <Button
                          class="product"
                          type="button"
                          style={{ height: "550px" }}
                          onClick={() =>
                            handleClick(pc.id, {
                              description: pc.description,
                              name: pc.name,
                              slug: pc.slug,
                              render_type: pc.render_type,
                              sale_price: pc.sale_price,
                              product_id: pc.id,
                              // id: pc.id,
                            })
                          }
                        >
                          <Card
                            style={{
                              height: "550px",
                              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                            }}
                          >
                            <div class="picture">
                              <img
                                src={pc.image_url ? pc.image_url : default_img}
                                data-at2x="/path/to/your/custom-image.jpg"
                                alt=""
                                style={{
                                  height: "350px",
                                  maxWidth: "400px",
                                  width: "100%",
                                  objectFit: "cover",
                                }}
                              ></img>
                            </div>
                            <div
                              className="product-name-s"
                              style={{
                                // width: "100%",
                                maxWidth: "400px",
                                wordWrap: "break-word",
                                whiteSpace: "normal",
                                fontFamily: "Roboto",
                                fontSize: "18px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: "vertical",
                                marginBottom: "10px",
                              }}
                            >
                              {pc.name}
                            </div>
                            <div class="product-description">
                              <div class="short-description">
                                <p class="short-description-s">
                                  {pc.type == "AP" &&
                                  pc.appointable &&
                                  pc.appointable.label_instead_price == true
                                    ? pc.appointable.price_label
                                    : `Rs ${pc.sale_price}`}
                                </p>
                              </div>
                            </div>

                            <div>
                              <Button
                                type="primary"
                                htmlType="submit"
                                className="fos-btn-primary w-100 mt-5"
                                style={{
                                  background: "#B9001F",
                                  borderColor: "#B9001F",
                                }}
                                onClick={() =>
                                  handleClick(pc.id, {
                                    description: pc.description,
                                    name: pc.name,
                                    slug: pc.slug,
                                    render_type: pc.render_type,
                                    sale_price: pc.sale_price,
                                    product_id: pc.id,
                                    // id: pc.id,
                                  })
                                }
                              >
                                Book
                              </Button>
                            </div>
                          </Card>
                        </Button>
                      </li>
                    );
                  })}
                </ul>
                <Pagination
                  current={currentPage}
                  pageSize={productsPerPage}
                  total={products.length}
                  onChange={handlePageChange}
                  style={{ textAlign: "center" }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </Page>
  );
};

export default SubCategoryListPage;
