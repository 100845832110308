import React, { useState } from "react";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { Typography, Row, Col, Button } from "antd";
import { LeftOutlined, RightOutlined, CloseOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import "./../Components/layouts/Footer.css";

const { Title } = Typography;

const ViewAlbumPage = () => {
  const location = useLocation();
  const { albumItem } = location.state;
  console.log("Received album item:", albumItem);

  const [lightboxVisible, setLightboxVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const showLightbox = (index) => {
    setCurrentIndex(index);
    setLightboxVisible(true);
  };

  const closeLightbox = () => {
    setLightboxVisible(false);
  };

  const navigateNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === albumItem.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const navigatePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? albumItem.images.length - 1 : prevIndex - 1
    );
  };

  return (
    <Page>
      <Navbar />
      <div className="page-wrapper">
        <div style={{ padding: "50px 20px" }}>
          <Title
            level={1}
            style={{
              color: "#B9001F",
              fontWeight: "bold",
              fontSize: "4.5rem",
              fontFamily: "Roboto, sans-serif",
              marginBottom: "30px",
              textAlign: "center",
            }}
          >
            {albumItem.title}
          </Title>
          {albumItem.images && albumItem.images.length > 0 && (
            <>
              <Row gutter={[32, 32]} justify="center">
                {albumItem.images.map((image, index) => (
                  <Col key={index} xs={24} sm={12} md={8} lg={8} xl={8}>
                    <div
                      style={{
                        maxWidth: "580px",
                        maxHeight: "387px",
                        margin: "0 auto",
                        overflow: "hidden",
                        cursor: "pointer",
                      }}
                      onClick={() => showLightbox(index)}
                    >
                      <img
                        src={image.image_url}
                        alt={`Image ${index + 1}`}
                        style={{
                          width: "100%",
                          height: "auto",
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
              {lightboxVisible && (
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.8)",
                    zIndex: 9999,
                  }}
                >
                  <Button
                    onClick={navigatePrev}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "10px",
                      zIndex: 1,
                      background: "transparent",
                      border: "none",
                    }}
                    icon={
                      <LeftOutlined
                        style={{ color: "white", fontSize: "24px" }}
                      />
                    }
                  />
                  <Button
                    onClick={navigateNext}
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      zIndex: 1,
                      background: "transparent",
                      border: "none",
                    }}
                    icon={
                      <RightOutlined
                        style={{ color: "white", fontSize: "24px" }}
                      />
                    }
                  />
                  <Button
                    onClick={closeLightbox}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      zIndex: 1,
                      background: "transparent",
                      border: "none",
                    }}
                    icon={
                      <CloseOutlined
                        style={{ color: "white", fontSize: "24px" }}
                      />
                    }
                  />
                  <img
                    src={albumItem.images[currentIndex].image_url}
                    alt={`Image ${currentIndex + 1}`}
                    style={{
                      maxWidth: "80%",
                      maxHeight: "80%",
                      borderRadius: "8px",
                    }}
                  />
                </div>
              )}
            </>
          )}
          {!albumItem.images ||
            (albumItem.images.length === 0 && (
              <p>No images found for this album.</p>
            ))}
        </div>
        <Footer />
      </div>
    </Page>
  );
};

export default ViewAlbumPage;
