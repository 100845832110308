export const initialState = { name: "Welcome" };

export const dashboardReducer = (state, action) => {
  switch (action.type) {
    case "SIDEBAR_CLICK":
      //   console.log(action);
      //   console.log(state);
      return { name: action.payload.label };
    //   return state.map((todo) => {
    //     if (todo.id === action.id) {
    //       return { ...todo, complete: !todo.complete };
    //     } else {
    //       return todo;
    //     }
    //   });
    default:
      return state;
  }
};
