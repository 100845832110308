import React, { useState, useEffect, useRef } from "react";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { useLocation } from "react-router-dom";
import { Button, Table, message, Upload, Tag } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { updateCustomerOrder } from ".././Api/index";
import { useNavigate } from "react-router-dom";

const BankTransferpage = () => {
  const location = useLocation();
  const { orderData } = location.state;
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  console.log("Received order:", orderData);

  const handleFileChange = (e) => {
    const file = e.target.files?.[0] || null;
    setImageFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setSelectedImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (!imageFile) {
        message.error("Please select a file.");
        setLoading(false);
        return;
      }

      const reader = new FileReader();
      reader.onload = async () => {
        const base64String = reader.result.split(",")[1]; // Get the base64 string (without data:image/png;base64,)
        const imageData = `data:${imageFile.type};base64,${base64String}`;

        await updateCustomerOrder(orderData.order.id, { image: imageData });

        setSelectedImage(null);
        setImageFile(null);
        setLoading(false);

        message.success("Order updated successfully!");

        // Reset file input field
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        navigate("/MyAccount/Orders");
      };
      reader.readAsDataURL(imageFile);
    } catch (error) {
      console.error("Error updating order:", error);
      message.error("Failed to update order");
      setLoading(false);
    }
  };

  // Format date
  const formatDate = (dateStr) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateStr).toLocaleDateString("en-US", options);
  };

  const formatDateTime = (dateTimeStr) => {
    if (!dateTimeStr) return "N/A";

    const dateTime = new Date(dateTimeStr);
    const dateOptions = { year: "numeric", month: "long", day: "numeric" };
    const timeOptions = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    const date = dateTime.toLocaleDateString("en-US", dateOptions);
    const time = dateTime.toLocaleTimeString("en-US", timeOptions);

    // Check if time is "00:00:00"
    return time === "12:00:00 AM" ? date : `${date}, ${time}`;
  };

  const mapPaymentMethod = (shortValue) => {
    switch (shortValue) {
      case "dbt":
        return "Direct Bank Transfer";
      case "chp":
        return "Cypherpay";
      case "pre":
        return "Pre Order";
      case "oth":
        return "Other";
      default:
        return "Unknown";
    }
  };

  const getAppointmentStatus = {
    1: { name: "Pending Confirmation", color: "blue" },
    2: { name: "Confirmed", color: "green" },
    3: { name: "Rejected", color: "red" },
    4: { name: "Completed", color: "cyan" },
    5: { name: "Cancelled", color: "volcano" },
    6: { name: "Processing Refund", color: "orange" },
    7: { name: "Refunded", color: "purple" },
  };

  const columns = [
    {
      title: "Product",
      dataIndex: "product.name",
      key: "product.name",
      render: (text, record) => (
        <div style={{ marginBottom: "10px" }}>
          <span>
            <b>{record.product.name || "Product: N/A"}</b>
          </span>
          {record.product.type === "AP" && (
            <>
              {record.appointment ? (
                <div style={{ marginTop: "5px" }}>
                  <p
                    style={{
                      margin: "0",
                    }}
                  >
                    Appointment ID: #{" "}
                    {record.appointment.id ? record.appointment.id : "N/A"}
                  </p>
                  <p style={{ margin: "0" }}>
                    When:{" "}
                    {record.appointment.start_date_time
                      ? formatDateTime(record.appointment.start_date_time)
                      : "N/A"}
                  </p>
                  <p style={{ margin: "0" }}>
                    Duration:{" "}
                    {record.appointment.start_date_time &&
                    record.appointment.end_date_time
                      ? calculateDuration(
                          record.appointment.start_date_time,
                          record.appointment.end_date_time
                        )
                      : "N/A"}{" "}
                    hours
                  </p>
                </div>
              ) : (
                <div style={{ marginTop: "5px" }}>
                  <p style={{ margin: "0" }}>
                    <b>Appointment #N/A</b>
                  </p>
                  <p
                    style={{
                      margin: "0",
                      fontSize: "12px",
                      color: "#888",
                    }}
                  >
                    When: N/A
                  </p>
                  <p
                    style={{
                      margin: "0",
                      fontSize: "12px",
                      color: "#888",
                    }}
                  >
                    Duration: N/A
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      ),
    },

    {
      title: "Appointment Status",
      dataIndex: "appointment.status",
      key: "appointment.status",
      render: (text, record) => (
        <span>
          {record.appointment ? (
            <Tag
              color={
                getAppointmentStatus[record.appointment.status]?.color ||
                "default"
              }
            >
              {getAppointmentStatus[record.appointment.status]?.name ||
                "Unknown"}
            </Tag>
          ) : (
            "N/A"
          )}
        </span>
      ),
    },

    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text) => <span>LKR: {text}</span>,
    },
  ];

  const calculateDuration = (startDateTime, endDateTime) => {
    const startDate = new Date(startDateTime);
    const endDate = new Date(endDateTime);
    const duration = (endDate - startDate) / (1000 * 60 * 60);
    return duration.toFixed(0);
  };

  const pStyle = {
    color: "#444",
    fontSize: "16px",
    fontFamily: "Roboto, sans-serif",
    marginTop: "20px",
    textAlign: "justify",
  };

  const billStyle = {
    color: "#808080",
    fontSize: "14px",
    fontFamily: "Roboto, sans-serif",
    textAlign: "justify",
  };

  return (
    <Page>
      <Navbar />
      <div className="page-content woocommerce">
        <div className="container clear-fix">
          <div className="grid-col-row">
            <h2 className="container-h2">Order Received</h2>
            <p style={{ ...pStyle, fontWeight: "bold" }}>
              Thank you. Your order has been received.
            </p>
            <p style={pStyle}>
              Order number: <b>#{orderData.order.id}</b>
            </p>
            <p style={pStyle}>
              Date: <b>{formatDate(orderData.order.created_at)}</b>
            </p>
            <p style={pStyle}>
              Email: <b>{orderData.order.bill_email}</b>
            </p>
            <p style={pStyle}>
              Total:<b> LKR {orderData.order.total}</b>
            </p>
            <p style={pStyle}>
              Payment method: <b>Direct bank transfer</b>
            </p>
            <br />
            <h2 style={{ fontFamily: "Roboto", fontWeight: "bold" }}>
              Order Details
            </h2>
            {orderData && (
              <Table
                dataSource={orderData.order.order_lines}
                columns={columns}
                pagination={false}
                style={{ marginBottom: "60px", minWidth: "1200px" }}
                bordered
                footer={() => (
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                      <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
                        Sub Total:
                      </div>
                      <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
                        Payment Method:
                      </div>
                      <div style={{ fontWeight: "bold" }}>Total:</div>
                    </div>
                    <div style={{ flex: 1 }}>
                      <div
                        style={{
                          marginBottom: "10px",
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        {orderData.order.items_total}
                      </div>
                      <div
                        style={{
                          marginBottom: "10px",
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        {/* {mapPaymentMethod(orderData.order.bill_payment_method)} */}
                        Direct bank transfer
                      </div>
                      <div style={{ fontWeight: "bold", textAlign: "right" }}>
                        {orderData.order.items_total}
                      </div>
                    </div>
                  </div>
                )}
              />
            )}

            <div
              style={{
                marginBottom: "50px",
              }}
            >
              <h2 style={{ fontFamily: "Roboto", fontWeight: "bold" }}>
                Upload Receipt
              </h2>
              <div
                style={{
                  background: "#f9f9f9",
                  paddingLeft: "20px",
                  borderRadius: "10px",
                  borderWidth: " 1px",
                  borderColor: "#adadad",
                  paddingTop: "5px",
                  paddingBottom: "10px",
                }}
              >
                <p style={pStyle}>Upload the receipt here</p>
                <input
                  type="file"
                  onChange={handleFileChange}
                  ref={fileInputRef} // reference to the file input element
                />
                {selectedImage && (
                  <img
                    src={selectedImage}
                    alt="Selected"
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                )}

                <Button
                  type="primary"
                  onClick={handleSubmit}
                  loading={loading}
                  disabled={!imageFile}
                  icon={
                    <UploadOutlined
                      style={{ color: "#FFFFFF", fontSize: "18px" }}
                    />
                  }
                  style={{
                    color: "#FFFFFF",
                    background: "#B9001F",
                    borderColor: "#B9001F",
                    borderRadius: "10px",
                    height: "40px",
                    fontSize: "16px",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                  className="fos-btn-primary"
                >
                  Upload Receipt
                </Button>
              </div>
            </div>

            <div style={{ fontSize: "14px", marginBottom: "50px" }}>
              <h2 style={{ fontFamily: "Roboto", fontWeight: "bold" }}>
                Billing Address
              </h2>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto",
                  rowGap: "10px",
                  columnGap: "20px",
                }}
              >
                <div>
                  <strong style={pStyle}>Name:</strong>
                  <br />
                  <p style={billStyle}>
                    {" "}
                    {orderData.order.bill_first_name}{" "}
                    {orderData.order.bill_last_name}
                  </p>
                </div>
                <div>
                  <strong style={pStyle}>Company:</strong>
                  <br />
                  <p style={billStyle}>{orderData.order.bill_company}</p>
                </div>
                <div>
                  <strong style={pStyle}>Email:</strong>
                  <br />
                  <p style={billStyle}>{orderData.order.bill_email}</p>
                </div>
                <div>
                  <strong style={pStyle}>Address:</strong>
                  <br />
                  <p style={billStyle}>
                    {orderData.order.bill_address_line1}
                    {orderData.order.bill_address_line2 &&
                      `, ${orderData.order.bill_address_line2}`}
                  </p>
                </div>
                <div>
                  <strong style={pStyle}>City/Postal Code:</strong>
                  <br />
                  <p style={billStyle}>
                    {orderData.order.bill_city}, {orderData.order.bill_postcode}
                  </p>
                </div>
                <div>
                  <strong style={pStyle}>State/Country:</strong>
                  <br />
                  <p style={billStyle}>
                    {orderData.order.bill_state}, {orderData.order.bill_country}
                  </p>
                </div>
                <div>
                  <strong style={pStyle}>Phone:</strong>
                  <br />
                  <p style={billStyle}>{orderData.order.bill_phone_no}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Page>
  );
};

export default BankTransferpage;
