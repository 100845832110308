import React, { useState, useEffect } from "react";
import { Button, Typography, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { PAYMENT_GATEWAY } from "./../constants/utils";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

const CardPayment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [total, setTotal] = useState("");
  const [customerId, setCustomerId] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const orderData = JSON.parse(localStorage.getItem("orderData"));
    if (orderData) {
      // Filter out products with appointment status 3
      const filteredOrderLines = orderData.order_lines.filter((orderLine) => {
        if (orderLine.appointment) {
          return orderLine.appointment.status !== "3";
        }
        return true;
      });

      // Update orderData with filtered order lines and recalculate totals
      const filteredOrderData = {
        ...orderData,
        order_lines: filteredOrderLines,
        total: filteredOrderLines
          .reduce((sum, orderLine) => sum + parseFloat(orderLine.total), 0)
          .toFixed(2),
        total_items_qty: filteredOrderLines.reduce(
          (sum, orderLine) => sum + orderLine.qty,
          0
        ),
        order_products: filteredOrderLines.map(
          (orderLine) => orderLine.product.name
        ),
      };

      setTotal(filteredOrderData.total);
      setCustomerId(filteredOrderData.customer_id);
      localStorage.setItem("orderData", JSON.stringify(filteredOrderData));
    }
  }, []);

  const openPaymentGateway = () => {
    const sessionId = localStorage.getItem("sessionId");
    if (sessionId) {
      const cardUrl = PAYMENT_GATEWAY + sessionId + "?checkoutVersion=1.0.0";
      window.location.href = cardUrl;
    } else {
      console.error("Session ID not found in local storage");
    }
  };

  const handlePaymentSuccess = () => {
    localStorage.removeItem("sessionId");
    localStorage.removeItem("orderData.total");
  };

  const renderPaymentPage = () => {
    const orderData = JSON.parse(localStorage.getItem("orderData"));
    if (
      !orderData ||
      !orderData.order_products ||
      orderData.order_products.length === 0
    ) {
      return null;
    }

    const { order_products, total, total_items_qty } = orderData;
    const orderDetails = `${order_products.join(", ")}`;

    return (
      <div style={styles.container}>
        <Text style={styles.heading}>Proceed to Payment</Text>
        <hr style={{ marginBottom: "40px" }} />
        <Text style={styles.orderDetails}>{orderDetails}</Text>
        <br />
        <br />
        <Text style={styles.orderDetails}>
          Total Products: {orderData.total_items_qty}
        </Text>
        <br />
        <br />
        <Text style={styles.total}>Total Amount: LKR {total}</Text>
        <br />
        <br />
        <Button
          style={styles.button}
          type="primary"
          loading={isButtonLoading}
          onClick={() => {
            setIsButtonLoading(true);
            openPaymentGateway();
          }}
        >
          {isButtonLoading ? "" : "Pay With BOC"}
        </Button>
      </div>
    );
  };

  return (
    <div style={styles.wrapper}>
      {isLoading ? (
        <div style={styles.loading}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      ) : (
        renderPaymentPage()
      )}
    </div>
  );
};

const styles = {
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  container: {
    width: "500px",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    margin: "40px",
  },
  heading: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  orderDetails: {
    fontWeight: "bold",
    fontSize: "16px",
    marginBottom: "20px",
  },
  total: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#B9001F",
  },
  button: {
    width: "30%",
    marginTop: "20px",
  },
  loading: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default CardPayment;
