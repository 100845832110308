import { DatePicker, Form, Input, InputNumber, Switch } from "antd";

const FormInput = ({
  label = "",
  name = "",
  type = "text",
  placeholder = "",
  options = [],
  disabled = false,
  required = false,
  onChange = void 0,
  min = 0,
  setVal = () => {},
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      valuePropName={type === "switch" ? "checked" : "value"}
    >
      {type === "hidden" ? (
        <Input disabled={disabled} required={required} hidden={true} />
      ) : null}
      {type === "text" ? (
        <Input
          disabled={disabled}
          required={required}
          placeholder={placeholder}
        />
      ) : null}
      {type === "number" ? (
        <InputNumber
          disabled={disabled}
          required={required}
          min={min}
          onChange={(ctx) => onChange(ctx)}
        />
      ) : null}
      {type === "date" ? (
        <DatePicker
          disabled={disabled}
          required={required}
          onChange={(ctx) => onChange(ctx)}
          style={{ width: "100%" }}
        />
      ) : null}
      {type === "switch" ? (
        <Switch disabled={disabled} required={required} />
      ) : null}
      {type === "textarea" ? (
        <Input.TextArea disabled={disabled} required={required} />
      ) : null}
    </Form.Item>
  );
};

export default FormInput;
