import React from "react";
import Meeting_Rooms from "../../src/img//pattern/Meeting_Rooms.jpg";
import Meeting_Rooms2 from "../../src/img//pattern/Meeting_Rooms2.jpg";

import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { Link } from "react-router-dom";
import { Card } from "antd";

const MeetingRoomssPage = () => {
  return (
    <Page>
      <Navbar />

      {/* Page Content */}
      <div className="page-content woocommerce">
        <div className="container clear-fix">
          <div class="grid-col-row">
            <div class="grid-col grid-col-12">
              <h2 className="container-h2">Meeting Rooms</h2>
              <p>
                The Meeting room is fully air-conditioned and equipped with
                audio-visual equipment and internet facilities. This room is
                designed to cater for discussions and presentations, the added
                furnishing provide an intimate feel to the room. This has a
                seating capacity for 15 persons and an additional seating
                facility for 15 persons.
              </p>
              {/* orderby */}
              <div id="page-meta" class="group">
                <form class="woocommerce-ordering" method="get">
                  <select name="orderby" class="orderby">
                    {/* <option value="menu_order">Default sorting</option> */}
                    <option value="popularity">Sort by popularity</option>
                    <option value="rating">Sort by latest</option>
                    <option value="price">Sort by price: low to high</option>
                    <option value="price-desc">
                      Sort by price: high to low
                    </option>
                  </select>
                </form>
              </div>
              <ul class="products">
                <li class="product">
                  <Card>
                    <div class="picture">
                      <Link to="/ConferenceRoomPage">
                        <img
                          src={Meeting_Rooms}
                          data-at2x="/path/to/your/custom-image.jpg"
                          alt="Accommodations"
                        ></img>
                      </Link>
                    </div>
                    <div class="product-name-s">
                      <Link to="/ConferenceRoomPage">Conference Room</Link>
                    </div>
                    <div class="product-description">
                      <div class="short-description">
                        <p class="short-description-s">
                          Conference Room - 60 minutes
                        </p>
                      </div>
                    </div>

                    {/* <div class="star-rating" title="Rated 4.00 out of 5">
                      <span style={{ width: "90%" }}>
                        <strong class="rating">4.00</strong> out of 5
                      </span>
                    </div> */}
                    {/* <span class="price">
                      <span class="amount">
                        555<sup>$</sup>
                      </span>
                    </span> */}
                    {/* <a
                      href="/shopCart"
                      class="cws-button alt border-radius small bt-color-3"
                    >
                      Book
                    </a> */}
                    <div>
                      <a
                        href="/ConferenceRoomPage"
                        rel="nofollow"
                        data-product_id="70"
                        data-product_sku=""
                        class="cws-button border-radius icon-left alt"
                      >
                        {" "}
                        <i class=""></i> Book Now
                      </a>
                    </div>
                  </Card>
                </li>

                <li class="product">
                  <Card>
                    <div class="picture">
                      <Link to="/MeetingRoomssPage">
                        <img
                          src={Meeting_Rooms2}
                          data-at2x="/path/to/your/custom-image.jpg"
                          alt="Auditorium"
                        ></img>
                      </Link>
                    </div>
                    <div class="product-name-s">
                      <Link to="/MeetingRoomssPage">Meeting Room</Link>
                    </div>
                    <div class="product-description">
                      <div class="short-description">
                        <p class="short-description-s">
                          Conference Room - 60 minutes
                        </p>
                      </div>
                    </div>

                    <div>
                      <a
                        href="/MeetingRoomssPage"
                        rel="nofollow"
                        data-product_id="70"
                        data-product_sku=""
                        class="cws-button border-radius icon-left alt"
                      >
                        {" "}
                        <i class=""></i> Book Now
                      </a>
                    </div>
                    {/* <div class="star-rating" title="Rated 5.00 out of 5">
                      <span style={{ width: "100%" }}>
                        <strong class="rating">4.00</strong> out of 5
                      </span>
                    </div> */}
                    {/* <span class="price">
                      <span class="amount">
                        325<sup>$</sup>
                      </span>
                    </span> */}
                    {/* 
                    <div class="product-description">
                      <div class="short-description">
                        <p>
                          Phasellus tristique diam turpis, vitae rhoncus mi
                          ultricies vel
                        </p>
                      </div>
                    </div> */}

                    {/* <a
                      href="/shopCart"
                      rel="nofollow"
                      data-product_id="70"
                      data-product_sku=""
                      class="cws-button border-radius icon-left alt"
                    >
                      {" "}
                      <i class="fa fa-shopping-cart"></i> Add to cart
                    </a> */}
                  </Card>
                </li>
              </ul>
              {/* 
                <div class="page-pagination clear-fix">
                  <a href="#">
                    <i class="fa fa-angle-double-left"></i>
                  </a>
                  <a href="#">1</a>
                  <a href="#">2</a>
                  <a href="#" class="active">
                    3
                  </a>
                  <a href="#">
                    <i class="fa fa-angle-double-right"></i>
                  </a>
                </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </Page>
  );
};

export default MeetingRoomssPage;
