import { useState, useEffect } from "react";
import { Table, Tag } from "antd";
import { getAppointments } from "../Api/index";
import { useMediaQuery } from "react-responsive";

function AppointmentTable({ title, dataSource }) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTab = useMediaQuery({ minWidth: 768, maxWidth: 1024 });

  const statusMapping = {
    1: { name: "Pending Confirmation", color: "blue" },
    2: { name: "Confirmed", color: "green" },
    3: { name: "Rejected", color: "red" },
    4: { name: "Completed", color: "cyan" },
    5: { name: "Cancelled", color: "volcano" },
    6: { name: "Processing Refund", color: "orange" },
    7: { name: "Refunded", color: "purple" },
  };

  const columns = [
    {
      title: "Appointment",
      dataIndex: "id",
      key: "id",
      width: 200,
      render: (id, record) => (
        <>
          <span style={{ fontSize: "16px" }}>ID #{id}</span>
          <br />
          <a
            href={`/MyAccount/ViewOrder/${record.order_id}`}
            style={{ color: "#cd2653", fontSize: "16px" }}
          >
            View Order
          </a>
        </>
      ),
    },
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
      width: 250,
      render: (order_id, record) => (
        <>
          <span style={{ fontSize: "16px" }}>#{order_id}</span>
          <br />
        </>
      ),
    },
    {
      title: "When",
      dataIndex: "start_date_time",
      key: "start_date_time",
      width: 250,
      render: (start_date_time, record) => (
        <>
          <span>
            {new Date(start_date_time).toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })}
          </span>
          <br />
          {calculateDuration(start_date_time, record.end_date_time)}
        </>
      ),
    },
    {
      title: "Scheduled",
      dataIndex: "product_name",
      key: "product_name",
      width: 200,
      render: (product_name, record) => (
        <a
          href={`/MyAccount/ViewProduct/${record.product_id}`}
          style={{ color: "#cd2653", fontSize: "16px" }}
        >
          {product_name}
        </a>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (status) => {
        const { name, color } = statusMapping[status] || {};
        return <Tag color={color || "grey"}>{name}</Tag>;
      },
    },
  ];

  const calculateDuration = (start_date_time, end_date_time) => {
    const startDate = new Date(start_date_time);
    const endDate = new Date(end_date_time);
    const duration = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)); // Calculate duration in days
    if (startDate.toDateString() === endDate.toDateString()) {
      // If the start and end dates are the same day, show hours instead of days
      const hours = Math.ceil((endDate - startDate) / (1000 * 60 * 60));
      return <span>{hours} hours</span>;
    } else {
      return <span>{duration} days</span>;
    }
  };

  return (
    <div className="items-container">
      <h2
        className="title"
        style={{
          fontSize: "20px",
          marginBottom: "20px",
          textAlign: "left",
          fontFamily: "Roboto",
          color: "#616161",
        }}
      >
        {title}
      </h2>
      <Table
        columns={columns}
        dataSource={dataSource}
        scroll={isMobile || isTab ? { x: "max-content" } : undefined}
        pagination={title === "Past Appointments"} // Enable pagination only for "Past Appointments"
        style={{ marginBottom: "50px" }}
        sticky
        className="mt-2"
      />
    </div>
  );
}

function Appointments() {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTab = useMediaQuery({ minWidth: 768, maxWidth: 1024 });

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    try {
      const response = await getAppointments();
      console.log("Appointments data:", response.data);

      // Check if response.data is an array
      if (!Array.isArray(response.data)) {
        throw new Error("Appointments data is not an array");
      }

      const appointmentsData = response.data;

      // Filter appointments based on status and current date
      const currentAppointments = appointmentsData.filter(
        (item) =>
          new Date(item.end_date_time) >= new Date() &&
          new Date(item.start_date_time) <= new Date()
      );
      const upcomingAppointments = appointmentsData.filter(
        (item) => new Date(item.start_date_time) > new Date()
      );
      const pastAppointments = appointmentsData.filter(
        (item) => new Date(item.end_date_time) < new Date()
      );
      setAppointments({
        current: currentAppointments,
        upcoming: upcomingAppointments,
        past: pastAppointments,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching appointments:", error);
    }
  };

  return (
    <div style={{ width: isMobile || isTab ? "100%" : "auto" }}>
      <AppointmentTable
        title="Current Appointments"
        dataSource={appointments.current}
      />
      <AppointmentTable
        title="Upcoming Appointments"
        dataSource={appointments.upcoming}
      />
      <AppointmentTable
        title="Past Appointments"
        dataSource={appointments.past}
      />
    </div>
  );
}

export default Appointments;
