import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Button, Spin, Empty, List } from "antd";
import {
  SearchOutlined,
  FileTextOutlined,
  CalendarOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import { SearchContext } from "./SearchContext";
import { useSearchFunctionality } from "./useSearchFunctionality";
import DOMPurify from "dompurify";

const SearchResultsPage = () => {
  const { searchQuery, searchResults, isLoading } = useContext(SearchContext);
  const { handleSearch } = useSearchFunctionality();
  const [query, setQuery] = useState(searchQuery);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearchClick = () => {
    if (query.trim() !== "") {
      handleSearch(query);
    }
  };

  const handleItemClick = (item) => {
    navigateToDetailPage(item);
  };

  const isExternalLink = (url) => {
    return /^https?:\/\//i.test(url);
  };

  const highlightText = (text, searchTerm) => {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, "gi");
    const parts = text.split(regex);
    return parts.map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const navigateToDetailPage = (item) => {
    if (item.path) {
      let stateObject = {};

      if (item.path === "/ViewEvent") {
        stateObject = {
          event: {
            id: item.id,
            title: item.title,
            description: item.description,
            start_date: item.start_date,
            end_date: item.end_date,
            location: item.location,
            image: item.image,
            image_url: item.image_url,
            total_tickets: item.total_tickets,
            tickets: item.tickets,
          },
        };
        navigate(item.path, { state: stateObject });
      } else if (item.path === "/ViewConference") {
        stateObject = {
          conference: {
            id: item.id,
            title: item.title,
            description: item.description,
            start_date: item.start_date,
            end_date: item.end_date,
            location: item.location,
            image: item.image,
            image_url: item.image_url,
            total_tickets: item.total_tickets,
            tickets: item.tickets,
          },
        };
        navigate(item.path, { state: stateObject });
      } else if (isExternalLink(item.path)) {
        window.location.href = item.path;
      } else {
        navigate(item.path);
      }
    } else if (item.category_names && item.category_names.length > 0) {
      const categoryMap = {
        News: "/ViewNews",
        Vacancies: "/ViewVacancy",
        Gallery: "/ViewAlbum",
        "Research Projects": "/ViewResearchProject",
        "Previous Projects": "/ViewPreviousProject",
        Alumni: "/ViewAlumni",
        "Student Stories": "/ViewStudentStories",
        "Research Assistantships": "/ViewResearchAssistantship",
        Publications: "/PublicationsAndDownloads",
        Downloads: "/PublicationsAndDownloads",
      };

      if (item.category_names && item.category_names.length > 0) {
        const categoryName = item.category_names[0];
        if (categoryMap.hasOwnProperty(categoryName)) {
          let stateObject = {};

          switch (categoryName) {
            case "News":
              stateObject = {
                newsItem: {
                  id: item.id,
                  title: item.title,
                  body: item.description,
                  images: item.images,
                  image_url: item.image_url,
                  published_at: item.published_at,
                  category_ids: item.category_ids,
                  category_names: item.category_names,
                },
              };
              break;
            case "Vacancies":
              stateObject = {
                vacancyItem: {
                  id: item.id,
                  title: item.title,
                  body: item.description,
                  image_url: item.image_url,
                  category_ids: item.category_ids,
                  category_names: item.category_names,
                  created_at: item.created_at,
                  updated_at: item.updated_at,
                  published_at: item.published_at,
                  slug: item.slug,
                  files: item.files,
                },
              };
              break;
            case "Gallery":
              stateObject = {
                albumItem: {
                  id: item.id,
                  title: item.title,
                  description: item.description,
                  images: item.images,
                  category_ids: item.category_ids,
                  category_names: item.category_names,
                  created_at: item.created_at,
                  updated_at: item.updated_at,
                },
              };
              break;
            case "Research Projects":
              stateObject = {
                rpItem: {
                  id: item.id,
                  title: item.title,
                  body: item.description,
                  image_url: item.image_url,
                  category_ids: item.category_ids,
                  category_names: item.category_names,
                  created_at: item.created_at,
                  updated_at: item.updated_at,
                },
              };
              break;
            case "Previous Projects":
              stateObject = {
                projectItem: {
                  id: item.id,
                  title: item.title,
                  body: item.description,
                  image_url: item.image_url,
                  category_ids: item.category_ids,
                  category_names: item.category_names,
                  created_at: item.created_at,
                  updated_at: item.updated_at,
                },
              };
              break;
            case "Alumni":
              stateObject = {
                alumniItem: {
                  id: item.id,
                  title: item.title,
                  body: item.description,
                  image_url: item.image_url,
                  category_ids: item.category_ids,
                  category_names: item.category_names,
                  created_at: item.created_at,
                  updated_at: item.updated_at,
                },
              };
              break;
            case "Student Stories":
              stateObject = {
                storyItem: {
                  id: item.id,
                  title: item.title,
                  body: item.description,
                  image_url: item.image_url,
                  category_ids: item.category_ids,
                  category_names: item.category_names,
                  created_at: item.created_at,
                  updated_at: item.updated_at,
                },
              };
              break;
            case "Research Assistantships":
              stateObject = {
                assistantshipItem: {
                  id: item.id,
                  title: item.title,
                  body: item.description,
                  image_url: item.image_url,
                  category_ids: item.category_ids,
                  category_names: item.category_names,
                  created_at: item.created_at,
                  updated_at: item.updated_at,
                },
              };
              break;
            case "Publications":
            case "Downloads":
              stateObject = {};
              break;
            default:
              // If the category is not handled, navigate to the default route
              console.warn(
                `No valid route found for item: ${item.title}. Defaulting to /`
              );
              navigate("/", { state: {} });
              return;
          }

          // Navigate to the respective detail page with the constructed state object
          console.log("State object to be sent:", stateObject);
          navigate(categoryMap[categoryName], { state: stateObject });
        } else {
          console.warn(
            `No valid route found for item: ${item.title}. Defaulting to /`
          );
          navigate("/", { state: {} });
        }
      } else {
        // Handle scenarios where neither path nor category_names are provided
        console.warn(
          `No path or category_names found for item: ${item.title}. Defaulting to /`
        );
        navigate("/", { state: {} });
      }
    }
  };

  const renderResultType = (item) => {
    switch (item.relevance) {
      case 1:
        return "Product";
      case 2:
        return "Event";
      case 3:
        return "Post";
      case 4:
        return "Product Category";
      case 5:
        return "Page";
      case 6:
        return "Title";
      case 7:
        return "Card Title";
      case 8:
        return "Subtitle";
      case 9:
        return "Card Description";
      case 10:
        return "Description";
      case 11:
        return "Card Tiles";
      case 12:
        return "Tile Description";
      default:
        return "Unknown Type";
    }
  };

  const filterResults = (results) => {
    return results.filter((item) => {
      switch (selectedFilter) {
        case "Products":
          return item.relevance === 1;
        case "Events & Conferences":
          return item.relevance === 2;
        case "Posts & Albums":
          return item.relevance === 3;
        case "Product Categories":
          return item.relevance === 4;
        case "Page Content":
          return item.relevance >= 5 && item.relevance <= 12;
        case "All":
        default:
          return true;
      }
    });
  };

  const applyFilter = (filter) => {
    setSelectedFilter(filter);
  };

  return (
    <div style={containerStyle}>
      <div style={searchBarContainerStyle}>
        <Input
          value={query}
          onChange={handleInputChange}
          placeholder="Search..."
          style={searchInputStyle}
          onPressEnter={handleSearchClick}
          suffix={
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={handleSearchClick}
              style={searchButtonStyle}
            >
              Search
            </Button>
          }
        />
      </div>
      <div style={filterContainerStyle}>
        {[
          "All",
          "Products",
          "Events & Conferences",
          "Posts & Albums",
          "Product Categories",
          "Page Content",
        ].map((filter) => (
          <Button
            key={filter}
            onClick={() => applyFilter(filter)}
            type={selectedFilter === filter ? "primary" : "default"}
            style={{
              ...filterButtonStyle,
              ...(selectedFilter === filter && filterButtonSelectedStyle),
            }}
          >
            {filter}
          </Button>
        ))}
      </div>
      {isLoading ? (
        <div style={loadingStyle}>
          <Spin size="large" />
        </div>
      ) : !searchQuery ? (
        <div style={initialMessageStyle}>
          <Empty description="Start your search by entering a query above." />
        </div>
      ) : (
        <div>
          <h2 style={pageTitleStyle}>Search Results for "{searchQuery}"</h2>
          {filterResults(searchResults).length === 0 ? (
            <div style={noResultsStyle}>
              No results found for "{searchQuery}"
            </div>
          ) : (
            <List
              itemLayout="vertical"
              size="large"
              dataSource={filterResults(searchResults)}
              renderItem={(item) => (
                <List.Item
                  style={listItemStyle}
                  onClick={() => handleItemClick(item)}
                >
                  <div style={itemContainerStyle}>
                    <strong style={titleStyle}>
                      {highlightText(item.title, searchQuery)}
                    </strong>
                    {item.subTitle && (
                      <div style={subtitleStyle}>
                        {highlightText(item.subTitle, searchQuery)}
                      </div>
                    )}
                    {(item.description || item.paragraph) && (
                      <p style={paragraphStyle}>
                        {(() => {
                          const content = item.description || item.paragraph;
                          const sanitizedContent = DOMPurify.sanitize(content);

                          const highlightText = (text, searchTerm) => {
                            if (!searchTerm) return text;
                            const regex = new RegExp(`(${searchTerm})`, "gi");
                            return text.replace(
                              regex,
                              `<span style="background-color: yellow;">$1</span>`
                            );
                          };

                          const highlightedContent = highlightText(
                            sanitizedContent,
                            searchQuery
                          );
                          return (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: highlightedContent,
                              }}
                            />
                          );
                        })()}
                      </p>
                    )}
                    <p style={typeInfoStyle}>
                      <span style={{ fontSize: "14px" }}>
                        {renderResultType(item)}
                      </span>
                    </p>
                  </div>
                </List.Item>
              )}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SearchResultsPage;

const containerStyle = {
  maxWidth: "1200px",
  margin: "auto",
  padding: "20px",
  fontFamily: "'Roboto', sans-serif",
  marginTop: "40px",
  // boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
};

const searchBarContainerStyle = {
  marginBottom: "40px",
  textAlign: "center",
};

const searchInputStyle = {
  fontSize: "16px",
  height: "50px",
  width: "100%",
  maxWidth: "600px",
  borderRadius: "25px",
  padding: "10px 20px",
  boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
};

const searchButtonStyle = {
  fontSize: "16px",
  marginLeft: "10px",
  borderRadius: "25px",
  backgroundColor: "#2F5597",
};

const pageTitleStyle = {
  textAlign: "center",
  color: "#B9001F",
  fontSize: "24px",
  fontWeight: "bold",
  marginBottom: "40px",
  marginTop: "40px",
  fontFamily: "Roboto",
};

const listItemStyle = {
  marginBottom: "20px",
  padding: "20px",
  borderRadius: "8px",
  backgroundColor: "#FFFFFF",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  cursor: "pointer",
  transition: "transform 0.2s, box-shadow 0.2s",
};

const itemContainerStyle = {
  display: "flex",
  flexDirection: "column",
};

const titleStyle = {
  fontSize: "20px",
  color: "#2F5597",
  marginBottom: "8px",
};

const subtitleStyle = {
  fontSize: "16px",
  color: "#555",
  marginBottom: "8px",
  fontWeight: "bold",
};

const paragraphStyle = {
  fontSize: "16px",
  color: "#333",
};

const typeInfoStyle = {
  fontSize: "14px",
  color: "#666",
  marginTop: "5px",
};

const noResultsStyle = {
  textAlign: "center",
  fontSize: "20px",
  color: "#555",
  marginTop: "50px",
};

const initialMessageStyle = {
  textAlign: "center",
  fontSize: "20px",
  color: "#555",
  marginTop: "50px",
};

const loadingStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
};

const filterContainerStyle = {
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  marginBottom: "20px",
};

const filterButtonStyle = {
  margin: "5px",
  borderRadius: "20px",
  fontWeight: "bold",
};

const filterButtonSelectedStyle = {
  backgroundColor: "#2F5597",
  color: "#FFFFFF",
};

// Adding hover effects to list items
const styles = document.createElement("style");
styles.innerHTML = `
  .ant-list-item {
    transition: transform 0.2s, box-shadow 0.2s;
  }

  .ant-list-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  }
`;
document.head.appendChild(styles);
