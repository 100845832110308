import React, { useState, useEffect } from "react";
import { Button, Card } from "antd";
import { useNavigate, useResolvedPath } from "react-router-dom";
import { getCategories } from "../../../Api";
import Footer from "../../../Components/layouts/Footer";
import Navbar from "../../../Components/layouts/Navbar";
import Page from "../../../Components/layouts/Page";
import "./../../../styles/pageTitle.css";
import "./../../layouts/Footer.css";
import default_img from "./../../../img/pattern/default.jpg";
import { useMediaQuery } from "react-responsive";

const MainCategoryPageComponent = () => {
  const resolvedPath = useResolvedPath();
  const [title, setTitle] = useState("");
  const [parentCategories, setParentCategories] = useState([]);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTab = useMediaQuery({ minWidth: 768, maxWidth: 1024 });

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData(pathname) {
      let type = "";
      let pageTitle = "";

      if (pathname === "/facility_reservations") {
        type = "facility_reservations";
        pageTitle = "Facility Reservations";
      } else if (pathname === "/lab_reservations") {
        type = "lab_reservations";
        pageTitle = "Lab Reservations";
      }

      setTitle(pageTitle);
      const data = { slugs: type };
      const response = await getCategories(data);
      if (response && response.data) {
        setParentCategories(response.data);
      }
    }

    fetchData(resolvedPath?.pathname);
  }, [resolvedPath?.pathname]);

  function handleClick(slug, state) {
    navigate("/products/category/" + slug, { state });
  }

  return (
    <Page>
      <Navbar />
      <div className="page-wrapper">
        <div className="page-content woocommerce">
          <div className="container clear-fix">
            <div className="grid-col-row">
              <div className="grid-col grid-col-12">
                <h1 className="section-title">{title}</h1>
                <br />
                <ul className="products">
                  {parentCategories.map((pc) => (
                    <li className="product" key={pc.id}>
                      <Button
                        type="button"
                        style={{ height: isTab ? "465px" : "480px" }}
                        onClick={() =>
                          handleClick(pc.slug, {
                            description: pc.description,
                            name: pc.name,
                            id: pc.id,
                          })
                        }
                      >
                        <Card
                          style={{
                            height: isTab ? "465px" : "480px",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          <div className="picture">
                            <img
                              style={{
                                height: "350px",
                                maxWidth: "400px",
                                width: "100%",
                                objectFit: "contain",
                              }}
                              src={pc.image_url ? pc.image_url : default_img}
                              data-at2x="/path/to/your/custom-image.jpg"
                              alt=""
                            />
                          </div>
                          <div
                            className="product-name"
                            style={{
                              textAlign: "center",
                              maxWidth: "400px",
                              wordWrap: "break-word",
                              whiteSpace: "normal",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                              fontSize: isMobile ? "22px" : "",
                              marginTop: isMobile
                                ? "-20px"
                                : isTab
                                ? "-5px"
                                : "",
                            }}
                          >
                            {pc.name}
                          </div>
                        </Card>
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Page>
  );
};

export default MainCategoryPageComponent;
