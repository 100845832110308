import React from 'react'
import Page from '../Components/layouts/Page'
import Navbar from '../Components/layouts/Navbar'
import Footer from '../Components/layouts/Footer'
import { Card } from 'antd'

function TermsAndConditionsPage() {
  return (
    <Page>
        <Navbar/>
        <div className="page-content woocommerce">
          <div className="container clear-fix">
            <div className="grid-col-row">
              <h2 className="container-h2" style={{ marginBottom: "50px" }}>
                 Terms & Conditions
              </h2>
              <p>Welcome to our website. By accessing and using this website, you agree to comply with and be bound by the following terms and conditions of use, which govern our relationship with you in relation to this website. If you do not agree with any of these terms and conditions, please do not use our website.

The term 'us' or 'we' refers to the owner of the website. The term 'you' refers to the user or viewer of our website.

The content of the pages of this website is for your general information and use only. It is subject to change without notice and we do not guarantee its accuracy, timeliness, performance, completeness or suitability for any particular purpose.

Your use of any information or materials on this website is entirely at your own risk, and we shall not be liable for any loss or damage arising from such use.

This website contains material which is owned by or licensed to us. Reproduction of any part of this website is strictly prohibited, except in accordance with the copyright notice, which forms part of these terms and conditions.

All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website.

Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offence.

From time to time, this website may also include links to other websites. These links are provided for your convenience to provide further information. We do not endorse the website(s) and have no responsibility for the content of the linked website(s).

Your use of this website and any dispute arising out of such use of the website is subject to the laws of [insert country/state/province].ountry/state/province]</p>
              </div>
              </div>
              </div>
        <Footer/>
    </Page>
  )
}

export default TermsAndConditionsPage