import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  DashboardOutlined,
  ShoppingCartOutlined,
  BoxPlotOutlined,
  EnvironmentOutlined,
  UserOutlined,
  CalendarOutlined,
  BookOutlined,
} from "@ant-design/icons";

const items = [
  {
    id: 1,
    name: "Dashboard",
    label: "Welcome",
    path: "/MyAccount/Dashboards",
    icon: <DashboardOutlined />,
  },
  {
    id: 2,
    name: "Orders",
    label: "Orders",
    path: "/MyAccount/Orders",
    icon: <BoxPlotOutlined />,
  },
  {
    id: 3,
    name: "My Cart",
    label: "Cart",
    path: "/shopCart",
    icon: <ShoppingCartOutlined />,
  },
  {
    id: 4,
    name: "Addresses",
    label: "Addresses",
    path: "/MyAccount/Address",
    icon: <EnvironmentOutlined />,
  },
  {
    id: 5,
    name: "Account Details",
    label: "Account Details",
    path: "/MyAccount/AccountDetails",
    icon: <UserOutlined />,
  },
  {
    id: 6,
    name: "Appointments",
    label: "Appointments",
    path: "/MyAccount/Appointments",
    icon: <CalendarOutlined />,
  },
  {
    id: 7,
    name: "My Courses",
    label: "My Courses",
    path: "/MyAccount/MyCourses",
    icon: <BookOutlined />,
  },
];

function Sidebar() {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(() => {
    const storedItem = localStorage.getItem("activeSidebarItem");
    return storedItem ? JSON.parse(storedItem) : items[0]; // Default to first menu
  });

  const getActiveItemFromStorage = () => {
    const storedItem = localStorage.getItem("activeSidebarItem");
    return storedItem ? JSON.parse(storedItem) : null;
  };

  useEffect(() => {
    const storedItem = getActiveItemFromStorage();
    if (storedItem) {
      setActiveItem(storedItem);
    }
  }, []);

  // Update active item in localStorage and state
  useEffect(() => {
    const currentPath = location.pathname;
    const currentItem = items.find((item) => currentPath.startsWith(item.path));
    if (currentItem) {
      localStorage.setItem("activeSidebarItem", JSON.stringify(currentItem));
      setActiveItem(currentItem);
    }
  }, [location.pathname]);

  return (
    <div className="sidebar">
      {items.map((item) => (
        <Link
          to={item.path}
          key={item.id}
          className={`sidebar-item ${
            activeItem && activeItem.id === item.id ? "sidebar-item-active" : ""
          }`}
          onClick={() => {
            localStorage.setItem("activeSidebarItem", JSON.stringify(item));
            setActiveItem(item);
          }}
        >
          {item.icon} <span style={{ marginRight: "10px" }}> </span>
          {item.name}
        </Link>
      ))}
    </div>
  );
}

export default Sidebar;
