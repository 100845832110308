import { Link } from "react-router-dom";
import "../styles/Dashboards.css";
import { Button, Table } from "antd";
import { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";

function Dashboards() {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      search: true,
      key: "id",
      fixed: "left",
    },
    {
      title: "Ticket",
      dataIndex: "ticket",
      search: true,
      key: "first_name",
      fixed: "left",
    },
    {
      title: "Event",
      dataIndex: "event",
      search: true,
      key: "last_name",
    },
  ];
  const handleLogOut = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("jrdc_user");
    window.localStorage.removeItem("jrdc_cart_items");
    window.localStorage.removeItem("session_expiry_time");
    window.localStorage.removeItem("session_expire_at");
    window.localStorage.removeItem("timezone");
    window.location.href = "/login";
  };

  const [userData, setUserData] = useState(null);
  const [form] = useForm();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const ci = window.localStorage.getItem("jrdc_user");
        if (ci) {
          const ciObj = JSON.parse(ci);
          Object.assign(ciObj, {
            firstname: ciObj.customer?.firstname ?? null,
            lastname: ciObj.customer?.lastname ?? null,
          });
          setUserData(ciObj);
          console.log({ ciObj });
          form.setFieldsValue(ciObj);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);
  return (
    <div className="items-container">
      <div className="subtitle" style={{ fontFamily: "Roboto" }}>
        Hello {userData?.name}! Not {userData?.name}?
        <span />
        <Button
          type="primary"
          htmlType="submit"
          className="logout-button"
          style={{ background: "#B9001F", borderColor: "#B9001F" }}
          onClick={handleLogOut}
        >
          Log out
        </Button>
      </div>
      <p
        className="paragraph"
        style={{ fontSize: "16px", marginBottom: "20px", fontFamily: "Roboto" }}
      >
        From your account dashboard you can view your{" "}
        <a
          href="/MyAccount/Orders"
          rel="nofollow"
          data-product_id="70"
          data-product_sku=""
          class=""
        >
          {" "}
          recent orders
        </a>
        , manage your
        <a
          href="/MyAccount/Address"
          rel="nofollow"
          data-product_id="70"
          data-product_sku=""
          class=""
        >
          {" "}
          billing address
        </a>{" "}
        , and{" "}
        <a
          href="/MyAccount/AccountDetails"
          rel="nofollow"
          data-product_id="70"
          data-product_sku=""
          class=""
        >
          {" "}
          edit your password and account details.
        </a>{" "}
      </p>
      <Table
        columns={columns}
        // dataSource={filteredData}
        scroll={{ x: 150 }}
        sticky
        className="mt-3"
        // style={{ marginBottom: "300px" }}
      />
      <Link to="/admin/item-form"></Link>
    </div>
  );
}
export default Dashboards;
