import React from "react";
import FormInput from "../Components/form/FormInput";
import FormSelect from "../Components/form/FormSelect";
import jrdc_Logo from "../../src/img//pattern/jrdc_Logo.png";
import ksoft_logo from "../../src/img//pattern/footer-ksoft-logo.png";
import MettingSingle from "../../src/img/pattern/meeting_Single.jpg";
import { DURATION } from "../Constants";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { DatePicker, Form } from "antd";
const MeetingRoomsPage = () => {
  let Cost = 0;

  // cartItems.forEach((item) => {
  //   total += item.price * item.qty;
  // });
  return (
    <Page>
      <Navbar />

      <div class="page-content woocommerce">
        <div class="container clear-fix">
          <div class="grid-col-row">
            <div class="grid-col grid-col-12">
              <div role="main">
                <div
                  itemscope=""
                  itemtype="http://schema.org/Product"
                  class="product"
                >
                  <div class="images">
                    <div class="picture">
                      <img
                        src={MettingSingle}
                        data-at2x="http://placehold.it/370x370"
                        class="attachment-shop_single"
                        alt=""
                        title=""
                      />
                      <div class="hover-effect"></div>
                      <div class="link-cont">
                        <a
                          href="http://placehold.it/370x370"
                          class="fancy fa fa-eye woocommerce-main-image zoom"
                          data-fancybox-group="gallery"
                          title=""
                          data-rel="prettyPhoto[product-gallery]"
                        ></a>
                      </div>
                    </div>
                    <div class="thumbnails">
                      <div class="owl-carousel">
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src="http://placehold.it/116x116"
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src="http://placehold.it/116x116"
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src="http://placehold.it/116x116"
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src={MettingSingle}
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="summary entry-summary">
                    <h2 class="product_title entry-title">Meeting Room</h2>

                    <div itemprop="description">
                      <p>
                        <strong>Conference Room 60 minutes</strong>
                        <br />
                        This is a simple, virtual product.
                      </p>
                    </div>
                    <div className="col-md-3">
                      <Form.Item label="" name="date">
                        <DatePicker style={{ width: "100%" }} />
                      </Form.Item>
                      <FormSelect
                        label="Duration "
                        name="duration "
                        options={DURATION}
                      />
                      {/* <FormInput
                          label="Number of additional hours (+රු 20.000,00) +4 hours "
                          name="Additional_Days"
                          type="number"
                        /> */}
                    </div>
                    <div className="total">Cost: {Cost}</div>
                    <form class="cart" method="post">
                      <div class="quantity buttons_added">
                        <input
                          type="number"
                          step="1"
                          min="1"
                          name="quantity"
                          value="1"
                          title="Qty"
                          class="input-text qty text"
                        />
                      </div>
                      <a
                        href="/shopCart"
                        class="cws-button alt border-radius small bt-color-3"
                      >
                        CHECK AVAILABILITY
                      </a>
                    </form>
                  </div>
                  <hr class="divider-color" />
                  <div class="tabs">
                    <div class="block-tabs-btn clear-fix">
                      <div class="tabs-btn active" data-tabs-id="tabs1">
                        Description
                      </div>
                    </div>
                    <div class="tabs-keeper">
                      <div
                        class="container-tabs active"
                        data-tabs-id="cont-tabs1"
                      >
                        <p>
                          The Meeting room is fully air-conditioned and equipped
                          with audio-visual equipment and internet facilities.
                          This room is designed to cater for discussions and
                          presentations, the added furnishing provide an
                          intimate feel to the room. This has a seating capacity
                          for 15 persons and an additional seating facility for
                          15 persons.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </Page>
  );
};

export default MeetingRoomsPage;
