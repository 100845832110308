export const DURATION = [
  { value: 1, text: "1 Hours" },
  { value: 2, text: "2 Hours" },
  { value: 3, text: "3 Hours" },
  { value: 4, text: "4 Hours" },
  { value: 5, text: "5 Hours" },
  { value: 6, text: "6 Hours " },
  { value: 7, text: "7 Hours" },
  { value: 8, text: "8 Hours" },
];

export const PDURATION = [
  { value: 1, text: "1 Hours" },
  { value: 2, text: "2 Hours" },
  { value: 3, text: "3 Hours" },
  { value: 4, text: "4 Hours" },
  { value: 5, text: "5 Hours" },
  { value: 6, text: "6 Hours " },
  { value: 7, text: "7 Hours" },
  { value: 8, text: "8 Hours" },
  { value: 9, text: "9 Hours" },
];

export const ADURATION = [
  { value: 1, text: "1 Hours" },
  { value: 2, text: "2 Hours" },
  { value: 3, text: "3 Hours" },
  { value: 4, text: "4 Hours" },
];

export const COUNTRY = [
  { value: 1, text: "Afghanistan" },
  { value: 2, text: "Albania" },
  { value: 3, text: "Algeria" },
  { value: 4, text: "Andorra" },
  { value: 5, text: "Angola" },
  { value: 6, text: "Anguilla " },
  { value: 7, text: "Antarctica" },
  { value: 8, text: "Antigua and Barbuda" },
  { value: 9, text: "Argentina" },
  { value: 10, text: "Armenia" },
  { value: 11, text: "Aruba" },
  { value: 12, text: "Australia" },
  { value: 13, text: "Austria" },
  { value: 14, text: "Azerbaijan" },
  { value: 15, text: "Bahamas" },
  { value: 16, text: "Bahrain" },
  { value: 17, text: "Bangladesh" },
  { value: 18, text: "Barbados" },
  { value: 19, text: "Belarus" },
  { value: 20, text: "Belau" },
  { value: 21, text: "Belgium" },
  { value: 22, text: "Belize" },
  { value: 23, text: "Benin" },
  { value: 24, text: "Bermuda" },
  { value: 25, text: "Bhutan" },
  { value: 26, text: "Bolivia" },
  { value: 27, text: "Bonaire, Saint Eustatius and Saba" },
  { value: 28, text: "Bosnia and Herzegovina" },
  { value: 29, text: "Botswana" },
  { value: 30, text: "Bouvet" },
  { value: 31, text: "Brazil" },
  { value: 32, text: "British Indian Ocean Territory" },
  { value: 33, text: "British Virgin Islands" },
  { value: 34, text: "Brunei" },
  { value: 35, text: "Bulgaria" },
  { value: 36, text: "Burkina" },
  { value: 37, text: "Burundi" },
  { value: 38, text: "Cambodia" },
  { value: 39, text: "Cameroon" },
  { value: 40, text: "Canada" },
  { value: 41, text: "Cape Verde" },
  { value: 42, text: "Cayman Islands" },
  { value: 43, text: "Central African Republic" },
  { value: 44, text: "Chad" },
  { value: 45, text: "Chile" },
  { value: 46, text: "China" },
  { value: 47, text: "Christmas Island" },
  { value: 48, text: "Cocos (Keeling) Islands" },
  { value: 49, text: "Colombia" },
  { value: 50, text: "Comoros" },
  { value: 51, text: "Congo (Brazzaville)" },
  { value: 52, text: "Congo (Kinshasa)" },
  { value: 53, text: "Cook Islands" },
  { value: 54, text: "Costa Rica" },
  { value: 55, text: "Croatia" },
  { value: 56, text: "Cuba" },
  { value: 57, text: "Cura&Ccedil;ao" },
  { value: 58, text: "Cyprus" },
  { value: 59, text: "Czech Republic" },
  { value: 60, text: "Denmark" },
  { value: 61, text: "Djibouti" },
  { value: 62, text: "Dominica" },
  { value: 63, text: "Dominican Republic" },
  { value: 64, text: "Ecuador" },
  { value: 65, text: "Egypt" },
  { value: 66, text: "El Salvador" },
  { value: 67, text: "Equatorial Guinea" },
  { value: 68, text: "Eritrea" },
  { value: 69, text: "Estonia" },
  { value: 70, text: "Ethiopia" },
  { value: 71, text: "Falkland Islands" },
  { value: 72, text: "Faroe Islands" },
  { value: 73, text: "Fiji" },
  { value: 74, text: "Finland" },
  { value: 75, text: "France" },
  { value: 76, text: "French Guiana" },
  { value: 77, text: "French Polynesia" },
  { value: 78, text: " French Southern Territories" },
  { value: 79, text: "Gabon" },
  { value: 80, text: "Gambia" },
  { value: 81, text: "Georgia" },
  { value: 82, text: "Germany" },
  { value: 83, text: "Ghana" },
  { value: 84, text: "Gibraltar" },
  { value: 85, text: "Greece" },
  { value: 86, text: "Greenland" },
  { value: 87, text: "Grenada" },
  { value: 88, text: "Guadeloupe" },
  { value: 89, text: "Guatemala" },
  { value: 90, text: "Guernsey" },
  { value: 91, text: "Guinea" },
  { value: 92, text: "Guinea-Bissau" },
  { value: 93, text: "Guyana" },
  { value: 94, text: "Haiti" },
  { value: 95, text: " Heard Island and McDonald Islands" },
  { value: 96, text: "Honduras" },
  { value: 97, text: "Hong Kong" },
  { value: 98, text: "Hungary" },
  { value: 99, text: "Iceland" },
  { value: 100, text: "India" },
  { value: 101, text: "Indonesia" },
  { value: 102, text: "Iran" },
  { value: 103, text: "Iraq" },
  { value: 104, text: "Isle of Man" },
  { value: 105, text: "Israel" },
  { value: 106, text: "Italy" },
  { value: 107, text: "Ivory Coast" },
  { value: 108, text: "Jamaica" },
  { value: 109, text: "Japan" },
  { value: 110, text: "Jersey" },
  { value: 111, text: "Jordan" },
  { value: 112, text: "Kazakhstan" },
  { value: 113, text: "Kenya" },
  { value: 114, text: "Kiribati" },
  { value: 115, text: "Kuwait" },
  { value: 116, text: "Kyrgyzstan" },
  { value: 117, text: "Laos" },
  { value: 118, text: "Latvia" },
  { value: 119, text: "Lebanon" },
  { value: 120, text: "Lesotho" },
  { value: 121, text: "Liberia" },
  { value: 122, text: "Libya" },
  { value: 123, text: "Liechtenstein" },
  { value: 124, text: "Lithuania" },
  { value: 125, text: "Luxembourg" },
  { value: 126, text: "Macao S.A.R., China" },
  { value: 127, text: "Macedonia" },
  { value: 128, text: "Madagascar" },
  { value: 129, text: "Malawi" },
  { value: 130, text: "Malaysia" },
  { value: 131, text: "Maldives" },
  { value: 132, text: "Mali" },
  { value: 133, text: "Malta" },
  { value: 134, text: "Marshall Islands" },
  { value: 135, text: "Martinique" },
  { value: 136, text: "Mauritania" },
  { value: 137, text: "Mauritius" },
  { value: 138, text: "Mayotte" },
  { value: 139, text: "Mexico" },
  { value: 140, text: "Micronesia" },
  { value: 141, text: "Moldova" },
  { value: 142, text: "Monaco" },
  { value: 143, text: "Mongolia" },
  { value: 144, text: "Montenegro" },
  { value: 145, text: "Montserrat" },
  { value: 146, text: "Morocco" },
  { value: 147, text: "Mozambique" },
  { value: 148, text: "Myanmar" },
  { value: 149, text: "Namibia" },
  { value: 150, text: "Nauru" },
  { value: 151, text: "Nepal" },
  { value: 152, text: "Netherlands" },
  { value: 153, text: "Netherlands Antilles" },
  { value: 154, text: "New Caledonia" },
  { value: 155, text: "New Zealand" },
  { value: 156, text: "Nicaragua" },
  { value: 157, text: "Niger" },
  { value: 158, text: "Nigeria" },
  { value: 159, text: "Niue" },
  { value: 160, text: "Norfolk Island" },
  { value: 161, text: "North Korea" },
  { value: 162, text: "Norway" },
  { value: 163, text: "Oman" },
  { value: 164, text: "Pakistan" },
  { value: 165, text: "Palestinian Territory" },
  { value: 166, text: "Panama" },
  { value: 167, text: "Papua New Guinea" },
  { value: 168, text: "Paraguay" },
  { value: 169, text: "Peru" },
  { value: 170, text: "Philippines" },
  { value: 171, text: "Pitcairn" },
  { value: 172, text: "Poland" },
  { value: 173, text: "Portugal" },
  { value: 174, text: "Qatar" },
  { value: 175, text: "Republic of Ireland" },
  { value: 176, text: "Reunion" },
  { value: 177, text: "Romania" },
  { value: 178, text: "Russia" },
  { value: 179, text: "Rwanda" },
  { value: 180, text: "S&atilde;o Tom&eacute; and Pr&iacute;ncipe" },
  { value: 181, text: "Saint Barth&eacute;lemy" },
  { value: 182, text: "Saint Helena" },
  { value: 183, text: "Saint Kitts and Nevis" },
  { value: 184, text: "Saint Lucia" },
  { value: 185, text: "Saint Martin (Dutch part)" },
  { value: 186, text: "Saint Martin (French part)" },
  { value: 187, text: "Saint Pierre and Miquelon" },
  { value: 188, text: " Saint Vincent and the Grenadines" },
  { value: 189, text: "San Marino" },
  { value: 190, text: "Saudi Arabia" },
  { value: 191, text: "Senegal" },
  { value: 192, text: "Serbia" },
  { value: 193, text: "Seychelles" },
  { value: 194, text: "Sierra Leone" },
  { value: 195, text: "Singapore" },
  { value: 196, text: "Slovakia" },
  { value: 197, text: "Slovenia" },
  { value: 198, text: "Solomon Islands" },
  { value: 199, text: "Somalia" },
  { value: 200, text: "South Africa" },
  { value: 202, text: "South Georgia/Sandwich Islands" },
  { value: 203, text: "South Korea" },
  { value: 204, text: "South Sudan" },
  { value: 205, text: "Spain" },
  { value: 206, text: "Sri Lanka" },
  { value: 207, text: "Sudan" },
  { value: 208, text: "Suriname" },
  { value: 209, text: "Svalbard and Jan Mayen" },
  { value: 210, text: "Swaziland" },
  { value: 211, text: "Sweden" },
  { value: 212, text: "Switzerland" },
  { value: 213, text: "Syria" },
  { value: 214, text: "Taiwan" },
  { value: 215, text: "Tajikistan" },
  { value: 216, text: "Tanzania" },
  { value: 217, text: "Thailand" },
  { value: 218, text: "Timor-Leste" },
  { value: 219, text: "Togo" },
  { value: 220, text: "Tokelau" },
  { value: 221, text: "Tonga" },
  { value: 222, text: "Trinidad and Tobago" },
  { value: 223, text: "Tunisia" },
  { value: 224, text: "Turkey" },
  { value: 225, text: "Turkmenistan" },
  { value: 226, text: "Turks and Caicos Islands" },
  { value: 227, text: "Tuvalu" },
  { value: 228, text: "Uganda" },
  { value: 229, text: "Ukraine" },
  { value: 230, text: "United Arab Emirates" },
  { value: 231, text: "United Kingdom (UK)" },
  { value: 232, text: "United States (US)" },
  { value: 233, text: "Uruguay" },
  { value: 234, text: "Uzbekistan" },
  { value: 235, text: "Vanuatu" },
  { value: 236, text: "Vatican" },
  { value: 237, text: "Venezuela" },
  { value: 238, text: "Vietnam" },
  { value: 239, text: "Wallis and Futuna" },
  { value: 240, text: "Western Sahara" },
  { value: 241, text: "Western Samoa" },
  { value: 242, text: "Yemen" },
  { value: 243, text: "Zambia" },
  { value: 244, text: "Zimbabwe" },
];

const nowTheme = {
  COLORS: {
    HEADER: "#525F7F",
    APP_BACKGROUND: "#F5F5F5",
    APP_BLUE: "#007bff",
    WHITE: "#FFFFFF",
  },
  SIZES: {
    BASE: 16,
  },
};

export { nowTheme };
