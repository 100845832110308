import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SearchContext } from "./SearchContext";
import axios from "axios";
import { search } from "./../../Api/index";
import { content } from "./content";

const options = {
  keys: [
    "page",
    "paragraphs.title",
    "cards.title",
    "paragraphs.subTitle",
    "cards.paragraphs.subTitle",
    "paragraphs.text",
    "paragraphs.list",
    "cards.paragraphs.text",
    "cards.tiles.heading",
    "cards.tiles.description",
  ],
  includeScore: true,
  threshold: 0.3, // Adjust for search fuzziness
};

const isWordInText = (word, text) => {
  if (!text) return false; // Check if text is null or undefined
  const words = text.split(/\s+/);
  return words.some((w) => w.toLowerCase().includes(word.toLowerCase()));
};

export const useSearchFunctionality = () => {
  const navigate = useNavigate();
  const { searchQuery, setSearchQuery, setSearchResults, setIsLoading } =
    useContext(SearchContext);

  const handleSearch = async (query) => {
    setSearchQuery(query);
    setIsLoading(true);

    try {
      const response = await search(query);
      console.log("Search response:", response);

      const {
        products = [],
        productCategories = [],
        posts = [],
        events = [],
      } = response;

      const formattedResults = [];
      const uniqueResults = new Set();

      const addUniqueResult = (result) => {
        if (!uniqueResults.has(result.id)) {
          formattedResults.push(result);
          uniqueResults.add(result.id);
        }
      };

      // Process Products
      products.forEach((product) => {
        const path =
          product.type === "PA"
            ? "/SubmitResearchPaper"
            : `/products/details/${product.id}`;
        const productResult = {
          id: product.id,
          title: product.name,
          description: product.description,
          image: product.image,
          path,
          relevance: 1,
        };

        addUniqueResult(productResult);

        if (isWordInText(query, product.description)) {
          const productDescriptionResult = {
            ...productResult,
            paragraph: product.description,
            relevance: 10,
          };
          addUniqueResult(productDescriptionResult);
        }
      });

      // Process Events
      events.forEach((event) => {
        const path = event.type === "EVENT" ? "/ViewEvent" : "/ViewConference";
        const eventResult = {
          id: event.id,
          title: event.title,
          description: event.description,
          start_date: event.start_date,
          end_date: event.end_date,
          location: event.location,
          image: event.image,
          image_url: event.image_url,
          total_tickets: event.total_tickets,
          tickets: event.tickets,
          path,
          relevance: 2,
        };

        addUniqueResult(eventResult);
      });

      // Process Posts
      posts.forEach((post) => {
        post.categories.forEach((category) => {
          const categoryMap = {
            "MoUs Agreements": "/MoUsAgreements",
            Publications: "/PublicationsAndDownloads",
            Downloads: "/PublicationsAndDownloads",
            News: "/ViewNews",
            Vacancies: "/ViewVacancy",
            Gallery: "/ViewAlbum",
            "Testing & Research": "/TestingAndResearch",
            "Research Projects": "/ViewResearchProject",
            "Previous Projects": "/ViewPreviousProject",
            Alumni: "/ViewAlumni",
            "Student Stories": "/ViewStudentStories",
            "Research Assistantships": "/ViewResearchAssistantship",
          };

          const postResult = {
            id: post.id,
            title: post.title,
            description: post.body,
            relevance: 3,
            category_names: post.category_names,
            published_at: post.published_at,
            image_url: post.image_url,
            files: post.files,
            images: post.images.map((image) => ({
              id: image.id,
              image_url: image.image_url,
            })),
          };

          addUniqueResult(postResult);

          if (isWordInText(query, post.body)) {
            const postBodyResult = {
              ...postResult,
              paragraph: post.body,
              relevance: 10,
            };
            addUniqueResult(postBodyResult);
          }
        });
      });

      // Process Product Categories
      productCategories.forEach((category) => {
        const path =
          category.parent_id === 1
            ? "/facility_reservations"
            : "/lab_reservations";
        const categoryResult = {
          id: category.id,
          title: category.name,
          description: category.description,
          image: category.image,
          path,
          relevance: 4,
        };

        addUniqueResult(categoryResult);

        if (isWordInText(query, category.description)) {
          const categoryDescriptionResult = {
            ...categoryResult,
            paragraph: category.description,
            relevance: 10,
          };
          addUniqueResult(categoryDescriptionResult);
        }
      });

      // Process Static Content
      content.forEach((item, index) => {
        let pageMatched = false;

        if (item.page.toLowerCase().includes(query.toLowerCase())) {
          const contentResult = {
            id: `content-${item.page}`,
            page: item.page,
            path: item.path,
            paragraph: "",
            title: item.page,
            subTitle: "",
            list: [],
            relevance: 5,
          };
          addUniqueResult(contentResult);
          pageMatched = true;
        }

        const paragraphs = item.paragraphs || [];
        paragraphs.forEach((paragraph, paragraphIndex) => {
          let paragraphMatched = false;

          if (
            paragraph.title &&
            paragraph.title.toLowerCase().includes(query.toLowerCase())
          ) {
            const paragraphResult = {
              id: `paragraph-${item.page}-${paragraph.title}`,
              page: item.page,
              path: item.path,
              paragraph: paragraph.text,
              title: paragraph.title,
              subTitle: paragraph.subTitle,
              list: paragraph.list || [],
              relevance: 6,
            };
            addUniqueResult(paragraphResult);
            paragraphMatched = true;
          }

          if (
            paragraph.subTitle &&
            paragraph.subTitle.toLowerCase().includes(query.toLowerCase())
          ) {
            const subtitleResult = {
              id: `subtitle-${item.page}-${paragraph.subTitle}`,
              page: item.page,
              path: item.path,
              paragraph: paragraph.text,
              title: item.page,
              subTitle: paragraph.subTitle,
              list: paragraph.list || [],
              relevance: 8,
            };
            addUniqueResult(subtitleResult);
            paragraphMatched = true;
          }

          if (paragraph.text) {
            if (isWordInText(query, paragraph.text) && !paragraphMatched) {
              const textResult = {
                id: `text-${item.page}-${paragraph.text}`,
                page: item.page,
                path: item.path,
                paragraph: paragraph.text,
                title: item.page,
                subTitle: paragraph.subTitle,
                list: paragraph.list || [],
                relevance: 10,
              };
              addUniqueResult(textResult);
              paragraphMatched = true;
            }
          }
        });

        const cards = item.cards || [];
        cards.forEach((card, cardIndex) => {
          let cardMatched = false;

          if (
            card.title &&
            card.title.toLowerCase().includes(query.toLowerCase())
          ) {
            (card.paragraphs || []).forEach((paragraph, paragraphIndex) => {
              const cardParagraphResult = {
                id: `card-${item.page}-${card.title}-${paragraph.text}`,
                page: item.page,
                path: item.path,
                paragraph: paragraph.text,
                title: card.title,
                subTitle: paragraph.subTitle,
                list: paragraph.list || [],
                relevance: 7,
              };
              addUniqueResult(cardParagraphResult);
              cardMatched = true;
            });
          }

          const cardParagraphs = card.paragraphs || [];
          cardParagraphs.forEach((paragraph, paragraphIndex) => {
            if (
              paragraph.subTitle &&
              paragraph.subTitle.toLowerCase().includes(query.toLowerCase())
            ) {
              const cardSubtitleResult = {
                id: `card-subtitle-${item.page}-${paragraph.subTitle}`,
                page: item.page,
                path: item.path,
                paragraph: paragraph.text,
                title: card.title,
                subTitle: paragraph.subTitle,
                list: paragraph.list || [],
                relevance: 9,
              };
              addUniqueResult(cardSubtitleResult);
              cardMatched = true;
            }
          });

          const cardTiles = card.tiles || [];
          cardTiles.forEach((tile, tileIndex) => {
            if (
              tile.heading &&
              tile.heading.toLowerCase().includes(query.toLowerCase())
            ) {
              const tileHeadingResult = {
                id: `tile-heading-${item.page}-${tile.heading}`,
                page: item.page,
                path: item.path,
                paragraph: tile.description,
                title: card.title,
                subTitle: tile.heading,
                relevance: 11,
              };
              addUniqueResult(tileHeadingResult);
            } else if (
              tile.description.toLowerCase().includes(query.toLowerCase())
            ) {
              const tileDescriptionResult = {
                id: `tile-description-${item.page}-${tile.description}`,
                page: item.page,
                path: item.path,
                paragraph: tile.description,
                title: card.title,
                subTitle: tile.heading,
                relevance: 12,
              };
              addUniqueResult(tileDescriptionResult);
            }
          });
        });
      });

      formattedResults.sort((a, b) => a.relevance - b.relevance);

      setSearchResults(formattedResults);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchQuery) {
      handleSearch(searchQuery);
    }
  }, [searchQuery]);

  return { handleSearch };
};

export default useSearchFunctionality;
