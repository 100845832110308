import React, { useEffect, useState } from "react";
import { Button, Form, Input, message } from "antd";
import FormInput from "../Components/form/FormInput";
import { useForm } from "antd/es/form/Form";
import { editAccount, getUserCustomerbyId } from "../Api";

function AccountDetails() {
  const [userData, setUserData] = useState(null);
  const [form] = useForm();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const ci = window.localStorage.getItem("jrdc_user");
        if (ci) {
          const ciObj = JSON.parse(ci);
          const response = await getUserCustomerbyId(ciObj.id);
          const userCustomerData = response.data;

          setUserData(userCustomerData.customer);

          form.setFieldsValue({
            first_name: userCustomerData.customer.first_name,
            last_name: userCustomerData.customer.last_name,
            name: userCustomerData.name,
            email: userCustomerData.email,
          });
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [form]);

  const onFinish = (values) => {
    values.id = userData.user_id;
    if (values.new_password !== values.password_confirmation) {
      message.error("New password and confirm password do not match");
      return;
    }

    editAccount(values).then((val) => {
      if (val.error) {
        message.error(val.error);
      } else {
        console.log("Success:", values);
        message.success("Account successfully updated!");
        form.resetFields([
          "current_password",
          "new_password",
          "password_confirmation",
        ]);
      }
    });
  };

  return (
    <div className="items-container">
      <Form
        name="basic"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <div className="d-flex" style={{ fontFamily: "Roboto" }}>
          <p className="Password-change">Change Account Details</p>
          <br />
          <Form.Item className="w-100 p-2">
            <FormInput label="First Name" name="first_name" />
          </Form.Item>
          <Form.Item className="w-100 p-2">
            <FormInput label="Last Name" name="last_name" />
          </Form.Item>
        </div>
        <Form.Item className="w-100 p-2">
          <FormInput label="Display name " name="name" />
          <p className="display_name">
            This will be how your name will be displayed in the account section
            and in reviews
          </p>
        </Form.Item>
        <Form.Item>
          <FormInput label="Email Address" name="email" />
        </Form.Item>
        <div className="d-flex">
          <p className="Password-change">Password change</p>
          <br />
          <Form.Item
            className="w-100 p-2"
            label="Current password"
            name="current_password"
          >
            <Input.Password type="password" />
          </Form.Item>
          <Form.Item
            className="w-100 p-2"
            label="New password (leave blank to leave unchanged)"
            name="new_password"
          >
            <Input.Password type="password" />
          </Form.Item>

          <Form.Item
            className="w-100 p-2"
            label="Confirm new password"
            name="password_confirmation"
          >
            <Input.Password type="password" />
          </Form.Item>
        </div>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="fos-btn-primary w-100 mt-5"
            style={{ background: "#B9001F", borderColor: "#B9001F" }}
          >
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default AccountDetails;
