import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Card, Table, Button, Row, Col, Modal, Tag } from "antd";
import { showOrder } from ".././Api/index";
import { useMediaQuery } from "react-responsive";
import { CloseOutlined } from "@ant-design/icons";

function OrderSingle() {
  const { orderId } = useParams();
  const [orderData, setOrderData] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [lightboxVisible, setLightboxVisible] = useState(false);

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const response = await showOrder(orderId);
        const data = await response.json();
        setOrderData(data);
        console.log("Order: ", orderData);
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    };

    fetchOrderData();
  }, [orderId]);

  if (!orderData) {
    return <div>Loading...</div>;
  }

  // const getOrderStatusName = (statusKey) => {
  //   const status = orderData.order_status_options.find(
  //     (option) => option.key === statusKey
  //   );
  //   return status ? status.name : "Unknown";
  // };

  const formatDateTime = (dateTime) => {
    return new Date(dateTime).toLocaleString();
  };

  const showLightbox = () => {
    setLightboxVisible(true);
  };

  const hideLightbox = () => {
    setLightboxVisible(false);
  };

  const calculateDuration = (startDateTime, endDateTime) => {
    const startDate = new Date(startDateTime);
    const endDate = new Date(endDateTime);
    const duration = (endDate - startDate) / (1000 * 60 * 60);
    return duration.toFixed(0);
  };

  const getOrderStatusName = (statusKey) => {
    switch (Number(statusKey)) {
      case 1:
        return "Waiting for approval";
      case 2:
        return "Processing";
      case 3:
        return "On Hold";
      case 4:
        return "Pending payment";
      case 5:
        return "Awaiting Receipt Approval";
      case 6:
        return "Receipt Rejected";
      case 7:
        return "Cancelled";
      case 8:
        return "Completed";
      case 9:
        return "Failed";
      case 10:
        return "Processing Refund";
      case 11:
        return "Refunded";
      default:
        return "Unknown";
    }
  };

  const reduction = () => {
    if (orderData.order.status != "7") {
      const values = orderData.order.order_lines.filter(
        (e) => e?.appointment?.status && e?.appointment?.status == "5"
      );

      return values.length
        ? values.reduce((acc, curr) => acc + parseFloat(curr.total), 0)
        : 0;
    } else {
      return orderData.order.order_lines.reduce(
        (acc, curr) => acc + (curr.qty * parseFloat(curr.unit_price)),
        0
      );
    }
  };

  const total = () => {
    if (orderData.order.status != "7") {
      const subTotal = orderData.order.order_lines.reduce(
        (acc, curr) => acc + parseFloat(curr.total),
        0
      );
      const r = reduction();
      return subTotal - r;
    } else {
      return 0;
    }
  };

  const getAppointmentStatus = {
    1: { name: "Pending Confirmation", color: "blue" },
    2: { name: "Confirmed", color: "green" },
    3: { name: "Rejected", color: "red" },
    4: { name: "Completed", color: "cyan" },
    5: { name: "Cancelled", color: "volcano" },
    6: { name: "Processing Refund", color: "orange" },
    7: { name: "Refunded", color: "purple" },
  };

  return (
    <div
      style={{
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        width: "100%",
      }}
    >
      <p style={{ fontSize: "18px", marginBottom: "10px" }}>
        Order <span style={{ fontWeight: "bold" }}>#{orderData.order.id}</span>{" "}
        was placed on{" "}
        <span style={{ fontWeight: "bold" }}>
          {new Date(orderData.order.created_at).toLocaleString()}
        </span>{" "}
        and is currently{" "}
        <span style={{ fontWeight: "bold", color: "#1890ff" }}>
          {getOrderStatusName(orderData.order.status)}
        </span>
        .
      </p>
      <br />
      <h2
        style={{
          fontSize: "24px",
          marginBottom: "20px",
          fontFamily: "Roboto",
          fontWeight: "bold",
        }}
      >
        Order details
      </h2>
      <div style={{ overflowX: "auto", marginBottom: "20px" }}>
        <Table
          dataSource={orderData.order.order_lines}
          pagination={false}
          style={{ minWidth: "600px" }} // Setting a minimum width for the table
          footer={() => (
            <>
              <div
                style={{
                  textAlign: "right",
                  fontWeight: "bold",
                  marginRight: "10px",
                  marginTop: "20px",
                }}
              >
                Total: Rs.{total()}
                {/* {total(orderData.order.order_lines.filter(e => 
                e.appointment.status && (e.appointment.status != '3' || e.appointment.status != '5')))} */}
              </div>
              {orderData.order.status == "7" && (
                <div
                style={{
                  textAlign: "right",
                  fontWeight: "bold",
                  marginRight: "10px",
                  marginTop: "20px",
                  color: "red",
                }}
              >
                Reduction from Cancellation: - Rs.{reduction()}
                {/* {orderData.order.order_lines.filter(e => e.appointment.status && (e.appointment.status == '3' || e.appointment.status == '5')).reduce(
              (acc, curr) =>  acc + parseFloat(curr.total), 0)} */}
              </div>
              )}
              
            </>
          )}
        >
          <Table.Column
            title="Product"
            key="product.name"
            render={(text, record) => (
              <span style={{ fontWeight: "bold" }}>
                <Link to={`shop-single-item/${record.product.id}`}>
                  {record.product.name}
                </Link>
                <p style={{ fontSize: "12px", color: "#888" }}>
                  Date: {new Date(record.created_at).toLocaleString()}
                </p>
                {record.product.type === "AP" && (
                  <>
                    {record.appointment ? (
                      <div style={{ marginTop: "5px" }}>
                        <p
                          style={{
                            margin: "0",
                            fontSize: "12px",
                            color: "#888",
                          }}
                        >
                          Appointment ID: #{" "}
                          {record.appointment.id
                            ? record.appointment.id
                            : "N/A"}
                        </p>

                        <p
                          style={{
                            margin: "0",
                            fontSize: "12px",
                            color: "#888",
                          }}
                        >
                          When:{" "}
                          {record.appointment.start_date_time
                            ? formatDateTime(record.appointment.start_date_time)
                            : "N/A"}
                        </p>
                        <p
                          style={{
                            margin: "0",
                            fontSize: "12px",
                            color: "#888",
                          }}
                        >
                          Duration:{" "}
                          {record.appointment.start_date_time &&
                          record.appointment.end_date_time
                            ? calculateDuration(
                                record.appointment.start_date_time,
                                record.appointment.end_date_time
                              )
                            : "N/A"}{" "}
                          hours
                        </p>
                      </div>
                    ) : (
                      <div style={{ marginTop: "5px" }}>
                        <p style={{ margin: "0" }}>
                          <b>Appointment #N/A</b>
                        </p>
                        <p
                          style={{
                            margin: "0",
                            fontSize: "12px",
                            color: "#888",
                          }}
                        >
                          When: N/A
                        </p>
                        <p
                          style={{
                            margin: "0",
                            fontSize: "12px",
                            color: "#888",
                          }}
                        >
                          Duration: N/A
                        </p>
                      </div>
                    )}
                  </>
                )}
              </span>
            )}
          />
          <Table.Column
            title="Appointment Status"
            dataIndex="appointment.status"
            key="appointment.status"
            render={(text, record) => (
              <span>
                {record.appointment ? (
                  <Tag
                    color={
                      getAppointmentStatus[record.appointment.status]?.color ||
                      "default"
                    }
                  >
                    {getAppointmentStatus[record.appointment.status]?.name ||
                      "Unknown"}
                  </Tag>
                ) : (
                  "N/A"
                )}
              </span>
            )}
          />
          <Table.Column
            title="Price"
            dataIndex="unit_price"
            key="unit_price"
            render={(text) => <span>Rs.{text}</span>}
          />
          <Table.Column
            title="Quantity"
            dataIndex="qty"
            key="qty"
            render={(text, record) => (
              <input
                type="number"
                step="1"
                min="0"
                value={text}
                style={{ width: "50px", textAlign: "center" }}
                disabled
              />
            )}
          />
          <Table.Column
            title="Sub Total"
            dataIndex="total"
            key="total"
            render={(text) => <span>Rs.{text}</span>}
          />
        </Table>
      </div>
      <br />
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12}>
          <Card
            title={
              <span style={{ fontSize: "20px", fontFamily: "Roboto" }}>
                Order Notes
              </span>
            }
            style={{
              width: "100%",
              height: "300px",
              maxHeight: "300px",
              //minHeight: "180px",
              marginTop: "20px",
              borderRadius: "10px",
              overflow: "hidden",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            }}
            bodyStyle={{
              padding: "10px",
              maxHeight: "300px",
              overflowY: "auto",
            }}
          >
            {orderData.order.order_notes
              .filter((note) => note.note_for === "2")
              .map((note, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "16px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <p
                    style={{
                      background: "#e6f7ff",
                      padding: "8px",
                      borderRadius: "8px",
                      maxWidth: "90%",
                      fontSize: "16px",
                    }}
                  >
                    {note.note}
                  </p>
                  <div
                    style={{
                      marginTop: "4px",
                      marginBottom: "10px",
                      fontSize: "13px",
                      color: "rgba(0, 0, 0, 0.45)",
                    }}
                  >
                    {formatDateTime(note.updated_at)}
                  </div>
                </div>
              ))}
            {orderData.order.order_notes.filter((note) => note.note_for === "2")
              .length === 0 && (
              <div style={{ textAlign: "center" }}>No Notes</div>
            )}
          </Card>
        </Col>

        <Col xs={24} sm={24} md={12}>
          <Card
            title={
              <span style={{ fontSize: "20px", fontFamily: "Roboto" }}>
                Order Receipt
              </span>
            }
            style={{
              width: "100%",
              height: "300px",
              maxHeight: "400px",
              marginTop: "20px",
              borderRadius: "10px",
              overflow: "hidden",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            }}
            bodyStyle={{
              padding: "10px",
              maxHeight: "300px",
              overflowY: "auto",
            }}
          >
            {orderData.order.direct_transfer ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  {orderData.order.direct_transfer.image_url && (
                    <>
                      <img
                        src={orderData.order.direct_transfer.image_url}
                        alt="Receipt"
                        style={{
                          width: "200px",
                          height: "200px",
                          borderRadius: "8px",
                          cursor: "pointer",
                          marginRight: "10px",
                        }}
                        onClick={showLightbox}
                      />
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#888",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        Uploaded On:
                        <br />
                        {new Date(
                          orderData.order.direct_transfer.created_at
                        ).toLocaleString()}
                      </span>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>No Receipt Available</div>
            )}
            {lightboxVisible && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  zIndex: 9999,
                }}
                onClick={hideLightbox}
              >
                <CloseOutlined
                  style={{
                    color: "white",
                    fontSize: "24px",
                    position: "absolute",
                    top: "20px",
                    right: "20px",
                    cursor: "pointer",
                  }}
                  onClick={hideLightbox}
                />
                <img
                  src={orderData.order.direct_transfer.image_url}
                  alt="Receipt"
                  style={{
                    maxWidth: "80%",
                    maxHeight: "80%",
                    borderRadius: "8px",
                  }}
                />
              </div>
            )}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card
            title={
              <span style={{ fontSize: "20px", fontFamily: "Roboto" }}>
                Additional Note
              </span>
            }
            style={{
              width: "100%",
              height: "300px",
              maxHeight: "400px",
              marginTop: "20px",
              borderRadius: "10px",
              overflow: "hidden",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            }}
            bodyStyle={{
              padding: "10px",
              maxHeight: "300px",
              overflowY: "auto",
            }}
          >
            {orderData.order.additional_note != null ? (
              <p>{orderData.order.additional_note}</p>
            ) : (
              <p>No additional notes provided by customer</p>
            )}
          </Card>
        </Col>
      </Row>
      <br />
      <br />

      <div style={{ fontSize: "18px", marginBottom: "50px" }}>
        <h4
          style={{
            fontWeight: "bold",
            fontFamily: "Roboto",
            marginBottom: "-10px",
          }}
        >
          Billing Address
        </h4>
        <br />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr",
            rowGap: "30px",
            columnGap: "20px",
            color: "#666",
            fontFamily: "Roboto",
          }}
        >
          <div style={{ fontSize: "16px" }}>
            <strong>Name:</strong>
            <br />
            {orderData?.order?.bill_first_name}{" "}
            {orderData?.order?.bill_last_name}
          </div>
          <div style={{ fontSize: "16px" }}>
            <strong>Company:</strong>
            <br />
            {orderData?.order?.bill_company}
          </div>
          <div style={{ fontSize: "16px" }}>
            <strong>Email:</strong>
            <br />
            {orderData?.order?.bill_email}
          </div>
          <div style={{ fontSize: "16px" }}>
            <strong>Address:</strong>
            <br />
            {orderData?.order?.bill_address_line1}
            {orderData?.order?.bill_address_line2 &&
              `, ${orderData?.order?.bill_address_line2}`}
          </div>
          <div style={{ fontSize: "16px" }}>
            <strong>City/Postal Code:</strong>
            <br />
            {orderData?.order?.bill_city}, {orderData?.order?.bill_postcode}
          </div>
          <div style={{ fontSize: "16px" }}>
            <strong>State/Country:</strong>
            <br />
            {orderData?.order?.bill_state} {orderData?.order?.bill_country}
          </div>
          <div style={{ fontSize: "16px" }}>
            <strong>Phone:</strong>
            <br />
            {orderData?.order?.bill_phone_no}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderSingle;
