import React from "react";

import { BrowserRouter, Routes, Route, Router } from "react-router-dom";
import { SearchProvider } from "./Components/searchComponent/SearchContext";
import useSearchFunctionality from "./Components/searchComponent/useSearchFunctionality";
import FloatingButton from "./Components/searchComponent/FloatingButton";
import ShopPage from "./pages/shop_product_page";
import ShopPageO from "./pages/shop_product_pageO";
import ShopCartPage from "./pages/shop_cart_page";
// import SingleProductPage from "./pages/shop_single_product";
import SuiteRoomPage from "./pages/suite_room_page";
import DoubleRoomPage from "./pages/double_room_page";
import SingleRoomPage from "./pages/single_room_page";
import AuditoriumsPage from "./pages/auditorium_single_page";
import BenchingAreasPage from "./pages/benching_area_single_page";
import PilotAreasPage from "./pages/pilot_area_single_page";
import MeetingRoomssPage from "./pages/meeting_rooms_single_page";
import ConferenceRoomPage from "./pages/conference_room_single_page";

import AccommodationsPage from "./pages/Accommodations_page";
import AuditoriumPage from "./pages/Auditorium_page";
import BenchingAreaPage from "./pages/Benching_Area_page";
import PilotAreaPage from "./pages/Pilot_Area_page";
import MeetingRoomsPage from "./pages/Meeting_Rooms_page";
import ShopCheckoutPage from "./pages/shop_checkout_page";
import Login from "./pages/Login";

import UserLayout from "./Components/layouts/UserLayout";

import Orders from "./pages/Orders";
import Dashboards from "./pages/Dashboards";
import Address from "./pages/Address";
import AccountDetails from "./pages/AccountDetails";
import Appointments from "./pages/Appointments";
import MyCourses from "./pages/MyCourses";
import BillingAddress from "./pages/BillingAddress";
import OrderSingle from "./pages/Orders_single_page";
import ParentCategoryListPage from "./pages/ParentCategoryListPage";
import SubCategoryListPage from "./pages/SubCategoryListPage";
import DetailsPage from "./pages/ProductDetailsPage";
import PaymentPage from "./pages/Payment_Page";
import CourseLinksPage from "./pages/CourseLinks";
import UpcomingEvents from "./pages/UpcomingEvents";
import ViewEvent from "./pages/ViewEvent";
import AboutUs from "./pages/AboutUs";
import MainCategoryPageComponent from "./Components/common/categories/MainCategoryPageComponent";
import ResearchpaperCategories from "./pages/ResearchPaperCategories";
import AddResearchPaper from "./pages/AddResearchPaper";
import Conferences from "./pages/Conferences";
import ViewConference from "./pages/ViewConference";
import MoUsAgreements from "./pages/MoUsAgreements";
import ContactUs from "./pages/ContactUs";
import PublicationsAndDownloads from "./pages/PublicationsAndDownloads";
import NewsPage from "./pages/News";
import VacanciesPage from "./pages/Vacancies";
import NewsDetailPage from "./pages/NewsDetailPage";
import VacancyDetailPage from "./pages/ViewVacancy";
import Gallery from "./pages/Gallery";
import ViewAlbumPage from "./pages/ViewAlbum";
import PreviousProjects from "./pages/PreviousProjects";
import ViewPreviousProject from "./pages/ViewPreviousProject";
import ResearchProjects from "./pages/ResearchProjects";
import ViewResearchProject from "./pages/ViewResearchProject";
import AlumniPage from "./pages/Alumni";
import NotPublishedPage from "./pages/NotPublished";
import StudentStoriesPage from "./pages/StudentStories";
import ResearchAssistantshipPage from "./pages/ResearchAssistantship";
import HowtoReserve from "./pages/HowtoReserve";
import ResearchPaperSubmissionPage from "./pages/ResearchPaperSubmission";
import TestingAndResearch from "./pages/TestingResearch";
import ProgramsPage from "./pages/programs";
import Homepage from "./pages/Homepage";
import OurServices from "./pages/OurServices";
import FocusArea from "./pages/FocusArea";
import ViewAlumni from "./pages/ViewAlumni";
import ViewStudentStory from "./pages/ViewStudentStory";
import ViewResearchAssistantship from "./pages/ViewResearchAssistantship";
import BankTransferpage from "./pages/DirectBankTransferPage";
import CardPayment from "./pages/CardPayment";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage";
import SearchResultsPage from "./Components/searchComponent/SearchResultPage";
import LoadingPage from "./Components/common/WaterTesting";
import PaymentSuccessCard from "./Components/common/PaymentSuccessSlip";

function App() {
  return (
    <SearchProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route
            path="/facility_reservations"
            element={<MainCategoryPageComponent />}
          />
          <Route
            path="/lab_reservations"
            element={<MainCategoryPageComponent />}
          />
          <Route path="/Login" element={<Login />} />
          <Route
            path="/MyAccount"
            element={
              <UserLayout>
                <Dashboards />
              </UserLayout>
            }
          />
          <Route
            path="/MyAccount/Orders"
            element={
              <UserLayout>
                <Orders />
              </UserLayout>
            }
          />
          <Route
            path="/MyAccount/Dashboards"
            element={
              <UserLayout>
                <Dashboards />
              </UserLayout>
            }
          />
          <Route
            path="/MyAccount/Address"
            element={
              <UserLayout>
                <Address />
              </UserLayout>
            }
          />
          <Route
            path="/MyAccount/AccountDetails"
            element={
              <UserLayout>
                <AccountDetails />
              </UserLayout>
            }
          />
          <Route
            path="/MyAccount/Appointments"
            element={
              <UserLayout>
                <Appointments />
              </UserLayout>
            }
          />
          <Route
            path="/MyAccount/MyCourses"
            element={
              <UserLayout>
                <MyCourses />
              </UserLayout>
            }
          />
          <Route
            path="/MyAccount/BillingAddress"
            element={
              <UserLayout>
                <BillingAddress />
              </UserLayout>
            }
          />
          <Route
            path="/MyAccount/ViewOrder/:orderId"
            element={
              <UserLayout>
                <OrderSingle />
              </UserLayout>
            }
          />
          <Route
            path="/MyAccount/CourseLinks/:productId"
            element={
              <UserLayout>
                <CourseLinksPage />
              </UserLayout>
            }
          />
          <Route path="shopCart" element={<ShopCartPage />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditionsPage />}
          />
          {/* <Route path="singleProductPage" element={<SingleProductPage />} /> */}
          <Route path="SuiteRoomPage" element={<SuiteRoomPage />} />
          <Route path="ShopCheckoutPage" element={<ShopCheckoutPage />} />
          <Route
            path="/MyAccount/PaymentPage/:orderId"
            element={<PaymentPage />}
          />
          <Route path="direct_bank_transfer" element={<BankTransferpage />} />
          <Route path="card_payment" element={<CardPayment />} />
          <Route path="DoubleRoomPage" element={<DoubleRoomPage />} />
          <Route path="SingleRoomPage" element={<SingleRoomPage />} />
          <Route path="AuditoriumsPage" element={<AuditoriumsPage />} />
          <Route path="BenchingAreasPage" element={<BenchingAreasPage />} />
          <Route path="PilotAreasPage" element={<PilotAreasPage />} />
          <Route path="MeetingRoomssPage" element={<MeetingRoomssPage />} />
          <Route path="ConferenceRoomPage" element={<ConferenceRoomPage />} />
          {/* <Route path="AccommodationsPage" element={<AccommodationsPage />} /> */}
          <Route
            path="products/category/:category"
            element={<SubCategoryListPage />}
          />
          {/* <Route path="DetailsPage" element={<DetailsPage />} /> */}
          <Route path="products/details/:id" element={<DetailsPage />} />
          <Route path="AuditoriumPage" element={<AuditoriumPage />} />
          <Route path="BenchingAreaPage" element={<BenchingAreaPage />} />
          <Route path="PilotAreaPage" element={<PilotAreaPage />} />
          <Route path="MeetingRoomsPage" element={<MeetingRoomsPage />} />
          <Route path="UpcomingEvents" element={<UpcomingEvents />} />
          <Route path="ViewEvent" element={<ViewEvent />} />
          <Route path="Conferences" element={<Conferences />} />
          <Route path="ViewConference" element={<ViewConference />} />
          <Route path="AboutUs" element={<AboutUs />} />
          <Route path="MoUsAgreements" element={<MoUsAgreements />} />
          <Route path="ContactUs" element={<ContactUs />} />
          <Route path="TestingAndResearch" element={<TestingAndResearch />} />
          <Route path="OurServices" element={<OurServices />} />
          <Route path="FocusAreas" element={<FocusArea />} />
          <Route
            path="ResearchPaperCategories"
            element={<ResearchpaperCategories />}
          />
          <Route path="AddResearchPaper" element={<AddResearchPaper />} />
          <Route
            path="PublicationsAndDownloads"
            element={<PublicationsAndDownloads />}
          />
          <Route path="News" element={<NewsPage />} />
          <Route path="ViewNews" element={<NewsDetailPage />} />
          <Route path="Vacancies" element={<VacanciesPage />} />
          <Route path="ViewVacancy" element={<VacancyDetailPage />} />
          <Route path="Gallery" element={<Gallery />} />
          <Route path="ViewAlbum" element={<ViewAlbumPage />} />
          <Route path="PreviousProjects" element={<PreviousProjects />} />
          <Route path="ViewPreviousProject" element={<ViewPreviousProject />} />
          <Route path="ResearchProjects" element={<ResearchProjects />} />
          <Route path="ViewResearchProject" element={<ViewResearchProject />} />
          <Route path="Alumni" element={<AlumniPage />} />
          <Route path="ViewAlumni" element={<ViewAlumni />} />
          <Route path="PageNotPublished" element={<NotPublishedPage />} />
          <Route path="StudentStories" element={<StudentStoriesPage />} />
          <Route path="ViewStudentStories" element={<ViewStudentStory />} />
          <Route
            path="ResearchAssistantships"
            element={<ResearchAssistantshipPage />}
          />
          <Route
            path="ViewResearchassistantship"
            element={<ViewResearchAssistantship />}
          />
          <Route path="HowtoReserve" element={<HowtoReserve />} />
          <Route
            path="SubmitResearchPaper"
            element={<ResearchPaperSubmissionPage />}
          />
          <Route path="programs" element={<ProgramsPage />} />
          {/* <Route path="shopCheckout" element={<ShopCheckoutPage />} /> */}
          <Route path="/search-results" element={<SearchResultsPage />} />
          <Route path="WaterTesting" element={<LoadingPage />} />
          <Route path="PaymentSlip/:orderId" element={<PaymentSuccessCard />} />
        </Routes>
      </BrowserRouter>
    </SearchProvider>
  );
}

export default App;
