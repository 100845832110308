import {
  Table,
  Button,
  Row,
  Card,
  Col,
  Typography,
  Space,
  Pagination,
} from "antd";
import React, { useEffect, useState } from "react";
import { getCustomerCourseProducts } from ".././Api/index";
import { LinkOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { CalendarOutlined, DollarCircleOutlined } from "@ant-design/icons";
import moment from "moment";

const { Column } = Table;
const { Title, Paragraph } = Typography;
const currentDate = moment();
let message = "No Meetings";

const cardStyle = {
  borderRadius: "10px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  marginBottom: "20px",
  cursor: "auto",
};

const imgStyle = {
  width: "300px",
  height: "300px",
  objectFit: "contain",
  borderRadius: "10px 0 0 10px",
  overflow: "hidden",
};

const paragraphStyle = {
  fontSize: "16px",
  fontFamily: "'Roboto', sans-serif",
  marginBottom: "10px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "hidden",
  display: "-webkit-box",
  WebkitLineClamp: 5,
  WebkitBoxOrient: "vertical",
};

function MyCourses() {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCourses, setTotalCourses] = useState(0); // to store the total number of events
  const coursesPerPage = 5;
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTab = useMediaQuery({ minWidth: 768, maxWidth: 1024 });

  useEffect(() => {
    const fetchCourseProducts = async (page) => {
      try {
        const { data: courseProducts, total } = await getCustomerCourseProducts(
          page,
          coursesPerPage
        );

        setCourses(courseProducts);
        setTotalCourses(total);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching course products:", error);
      }
    };

    fetchCourseProducts(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const showLinks = (productId) => {
    navigate(`/MyAccount/CourseLinks/${productId}`);
    console.log("passed product: ", productId);
  };

  const showCourse = (productId) => {
    navigate(`/products/details/${productId}`);
  };

  const showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total} items`;
  };

  const currentDate = moment();

  return (
    <div className="items-container">
      <Row gutter={[16, 16]}>
        {courses.map((course) => {
          let message = "Unscheduled";

          if (course.product.meetings && course.product.meetings.length > 0) {
            const upcomingMeetings = course.product.meetings.filter((meeting) =>
              moment(meeting.start_time).isAfter(currentDate)
            );

            if (upcomingMeetings.length > 0) {
              const nearestMeeting = upcomingMeetings.reduce(
                (nearest, meeting) => {
                  return moment(meeting.start_time).isBefore(
                    moment(nearest.start_time)
                  )
                    ? meeting
                    : nearest;
                }
              );

              message = moment(nearestMeeting.start_time).format(
                "MMMM Do YYYY, h:mm:ss a"
              );
            } else {
              message = "No Upcoming Sessions";
            }
          }

          return (
            <Col
              key={course.order_line_id}
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
            >
              <Card
                hoverable
                style={{ ...cardStyle, width: isTab ? "94%" : "100%" }}
              >
                <Row gutter={[2, 16]}>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={8}
                    style={{ display: "flex" }}
                  >
                    <img
                      alt={course.product.name}
                      src={course.product.image_url}
                      style={imgStyle}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <Title
                      level={2}
                      style={{
                        marginBottom: "20px",
                        fontSize: "30px",
                        cursor: "pointer",
                      }}
                      onClick={() => showCourse(course.product.id)}
                    >
                      <span style={{ color: "#630330" }}>
                        {course.product.name}
                      </span>
                    </Title>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      {/* <Paragraph style={paragraphStyle}>
                        <DollarCircleOutlined style={{ color: "#faad14" }} />{" "}
                        <b>Price</b>: Rs:{course.product.sale_price}
                      </Paragraph> */}
                      <Paragraph style={paragraphStyle}>
                        <CalendarOutlined
                          style={{ color: "#1890ff", marginRight: "5px" }}
                        />{" "}
                        <b>Next Upcoming Meeting</b>:{" "}
                        <span style={{ color: "red" }}>{message}</span>
                      </Paragraph>
                      <p
                        style={paragraphStyle}
                        dangerouslySetInnerHTML={{
                          __html: course.product.details,
                        }}
                      />
                      <Button
                        type="primary"
                        onClick={() => showLinks(course.product.id)}
                        icon={<LinkOutlined />}
                        style={{
                          width: isMobile ? "90%" : isTab ? "60%" : "25%",
                          height: "40px",
                          margin: "5px 0",
                        }}
                      >
                        Show Zoom Links
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Card>
            </Col>
          );
        })}
      </Row>
      <Pagination
        current={currentPage}
        pageSize={coursesPerPage}
        total={totalCourses}
        onChange={handlePageChange}
        style={{
          marginTop: "20px",
          marginBottom: "80px",
        }}
        showTotal={showTotal}
      />
    </div>
  );
}

export default MyCourses;
