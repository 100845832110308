import React, { useEffect, useRef, useState } from "react";
import FormInput from "../Components/form/FormInput";
import FormSelect from "../Components/form/FormSelect";
import { Form, TimePicker } from "antd";
import dayjs from "dayjs";

import { DURATION } from "../Constants";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import "../../src/styles/main.css";
import Benching_Area from "../../src/img/pattern/Benching_Area2.jpg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setHours, setMinutes } from "date-fns";
const BenchingAreasPage = () => {
  const [cost, setCost] = useState(500);
  const [form] = Form.useForm();
  const [startDate, setStartDate] = useState(
    setHours(setMinutes(new Date(), 0), 9)
  );

  const handleDurationChange = (value) => {
    setCost(500 * (1 + value));
  };

  return (
    <Page>
      <Navbar />
      <div class="page-content woocommerce">
        <div class="container clear-fix">
          <div class="grid-col-row">
            <div class="grid-col grid-col-12">
              <div role="main">
                <div
                  itemscope=""
                  itemtype="http://schema.org/Product"
                  class="product"
                >
                  <div class="images">
                    <div class="picture">
                      <img
                        src={Benching_Area}
                        data-at2x="http://placehold.it/370x370"
                        class="attachment-shop_single"
                        alt=""
                        title=""
                      />
                      <div class="hover-effect"></div>
                      <div class="link-cont">
                        <a
                          href="http://placehold.it/370x370"
                          class="fancy fa fa-eye woocommerce-main-image zoom"
                          data-fancybox-group="gallery"
                          title=""
                          data-rel="prettyPhoto[product-gallery]"
                        ></a>
                      </div>
                    </div>
                    <div class="thumbnails">
                      <div class="owl-carousel">
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src="http://placehold.it/116x116"
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src="http://placehold.it/116x116"
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src="http://placehold.it/116x116"
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src={Benching_Area}
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="summary entry-summary">
                    <h2 class="product_title entry-title">Benching Area</h2>

                    <div itemprop="description">
                      <p>
                        <strong>Benching Area - 60 minutes</strong>
                        <br />
                        Like modern water science laboratories the spaces are
                        continuous that allows for integration and efficient
                        allocation of resources, Students and researches can
                        enjoy the facilities that are maintained up to standards
                        and surround themselves in a remarkable and vibrant
                        environment. Each space attends to safety and an
                        aerodynamic flow.
                      </p>
                    </div>

                    <Form
                      name="basic"
                      autoComplete="off"
                      layout="vertical"
                      form={form}
                    >
                      <div className="col-md-3">
                        <Form.Item label="" name="date">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            minTime={setHours(setMinutes(new Date(), 0), 9)}
                            maxTime={setHours(setMinutes(new Date(), 30), 16)}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeIntervals={60}
                          />
                        </Form.Item>
                        <FormSelect
                          label="Duration"
                          name="duration"
                          options={DURATION}
                          setVal={handleDurationChange}
                        />
                      </div>
                    </Form>
                    <div className="total">Cost: {cost}</div>
                    <form class="cart" method="post">
                      <a
                        href="/shopCart"
                        class="cws-button alt border-radius small bt-color-3"
                      >
                        CHECK AVAILABILITY
                      </a>
                    </form>
                  </div>
                  <hr class="divider-color" />
                  <div class="tabs">
                    <div class="block-tabs-btn clear-fix">
                      <div class="tabs-btn active" data-tabs-id="tabs1">
                        Description
                      </div>
                    </div>
                    <div class="tabs-keeper">
                      <div
                        class="container-tabs active"
                        data-tabs-id="cont-tabs1"
                      >
                        <p>
                          <strong>Benching Area Details</strong>
                        </p>
                        <p>
                          We provide outstanding laboratory and research
                          facilities for researchers and research students.
                          JRDC’s laboratory facilities comprise of newly-built
                          research space, with over 70 sophisticated high-end
                          equipment and resources expected in a modern water
                          science laboratory.
                        </p>
                        <p>
                          Like modern water science laboratories the spaces are
                          continuous that allows for integration and efficient
                          allocation of resources, Students and researches can
                          enjoy the facilities that are maintained up to
                          standards and surround themselves in a remarkable and
                          vibrant environment. Each space attends to safety and
                          an aerodynamic flow.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </Page>
  );
};

export default BenchingAreasPage;
