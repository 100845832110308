import React from "react";
import AuditoriumP from "../../src/img/pattern/AuditoriumP.jpg";

import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { Link } from "react-router-dom";
import { Card } from "antd";

const AuditoriumPage = () => {
  return (
    <Page>
      <Navbar />

      {/* Page Content */}
      <div className="page-content woocommerce">
        <div className="container clear-fix">
          <div class="grid-col-row">
            <div class="grid-col grid-col-12">
              <h2 className="container-h2">Auditorium</h2>
              <p>
                The JRDC offers exquisite conference facilities for training and
                other related programs. The State-of-the-art auditorium is fully
                air-conditioned and equipped with audio-visual equipment and
                internet facilities to meet international standards. It’s
                designed for the purposes of encouraging discussion, to showcase
                research data and technology and many such more enticing and
                enriching activities. The Auditorium is designed to seat 150
                individuals and is unique and intimate. It also contains two
                rooms that can be used as lecture halls, conference rooms or
                Discussion rooms which can accommodate 30 individuals.
              </p>
              {/* Shop */}
              <div id="page-meta" class="group">
                <form class="woocommerce-ordering" method="get">
                  <select name="orderby" class="orderby">
                    {/* <option value="menu_order">Default sorting</option> */}
                    <option value="popularity">Sort by popularity</option>
                    <option value="rating">Sort by latest</option>
                    <option value="price">Sort by price: low to high</option>
                    <option value="price-desc">
                      Sort by price: high to low
                    </option>
                  </select>
                </form>
              </div>
              {/* <div id="page-meta" class="group">
                  <p class="woocommerce-result-count">
                    Showing 1-9 of 23 results{" "}
                  </p>
                  <div id="list-or-grid">
                    <div class="grid-view active" title="Switch to grid view">
                      <i class="fa fa-th-large"></i>
                    </div>
                    <div class="list-view" title="Switch to list view">
                      <i class="fa fa-th-list"></i>
                    </div>
                  </div>
                  <form class="woocommerce-ordering" method="get">
                    <select name="orderby" class="orderby">
                      <option value="menu_order">Default sorting</option>
                      <option value="popularity">Sort by popularity</option>
                      <option value="rating">Sort by average rating</option>
                      <option value="date">Sort by newness</option>
                      <option value="price">Sort by price: low to high</option>
                      <option value="price-desc">
                        Sort by price: high to low
                      </option>
                    </select>
                  </form>
                </div> */}
              <ul class="products">
                <li class="product">
                  <Card>
                    <div class="picture">
                      {/* <div class="ribbon ribbon-blue">
                        <div class="banner">
                          <div class="text">Not Available</div>
                        </div>
                      </div> */}
                      {/* <img
                        src="http://placehold.it/270x200"
                        data-at2x="http://placehold.it/270x200"
                        alt=""
                      ></img> */}
                      <Link to="/AuditoriumsPage">
                        <img
                          src={AuditoriumP}
                          data-at2x="/path/to/your/custom-image.jpg"
                          alt="Auditorium"
                        ></img>
                      </Link>
                    </div>
                    <div class="product-name-s">
                      <Link to="/AuditoriumsPage">Auditorium</Link>
                    </div>
                    <div class="product-description">
                      <div class="short-description">
                        <p class="short-description-s">
                          Price Varies - 4 hours
                        </p>
                      </div>
                    </div>

                    {/* <div class="star-rating" title="Rated 4.00 out of 5">
                      <span style={{ width: "90%" }}>
                        <strong class="rating">4.00</strong> out of 5
                      </span>
                    </div> */}
                    {/* <span class="price">
                      <span class="amount">
                        555<sup>$</sup>
                      </span>
                    </span> */}
                    {/* <a
                      href="/shopCart"
                      class="cws-button alt border-radius small bt-color-3"
                    >
                      Book
                    </a> */}
                    <div>
                      <a
                        href="/AuditoriumsPage"
                        rel="nofollow"
                        data-product_id="70"
                        data-product_sku=""
                        class="cws-button border-radius icon-left alt"
                      >
                        {" "}
                        <i class=""></i> Book Now
                      </a>
                    </div>
                  </Card>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </Page>
  );
};

export default AuditoriumPage;
