import FormInput from "../Components/form/FormInput";
import Suite_Room from "../../src/img//pattern/Suite_Room.jpg";

import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import React, { useEffect, useRef, useState } from "react";
import {
  // DatePicker,
  Form,
} from "antd";
import DatePicker from "react-datepicker";
import DateRangeComp from "../Components/common/DateRangeComp.js";
import "../styles/main.css";
import DateRangePickerComp from "../Components/common/DateRangePickerComp.js";
const SuiteRoomPage = () => {
  const [cost, setCost] = useState(0);
  const [form] = Form.useForm();

  const onRangeSelected = (days) => {
    console.log({ days });

    form.setFieldsValue({
      Additional_Days: days,
    });

    setCost(4000 * (1 + days));
  };

  useEffect(() => {
    form.setFieldsValue({
      Additional_Days: 0,
    });
    setCost(4000);
  }, []);

  return (
    <Page>
      <Navbar />

      <div class="page-content woocommerce">
        <div class="container clear-fix">
          <div class="grid-col-row">
            <div class="grid-col grid-col-12">
              <div role="main">
                <div
                  itemscope=""
                  itemtype="http://schema.org/Product"
                  class="product"
                >
                  <div class="images">
                    <div class="picture">
                      <img
                        src={Suite_Room}
                        data-at2x="http://placehold.it/370x370"
                        class="attachment-shop_single"
                        alt=""
                        title=""
                      />
                    </div>
                  </div>
                  <div class="summary entry-summary">
                    <h2 class="product_title entry-title">Suite Room</h2>

                    <div itemprop="description">
                      <p>
                        <strong>Suite Room - 1 day</strong>
                        <br />
                        The default booking will be one (01) day. Add more no.
                        of additional days as per your requirement in the below
                        section.
                      </p>
                    </div>
                    <div className="col-md-3">
                      <Form
                        name="basic"
                        // onFinish={onFinish}
                        // onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        layout="vertical"
                        form={form}
                      >
                        {/* <Form.Item label="" name="date">
                          <DatePicker style={{ width: "100%" }} />
                        </Form.Item> */}
                        <Form.Item
                          label=" Select Date or Date Range"
                          name="date"
                        >
                          {/* <DateRangeComp onRangeSelected={onRangeSelected} /> */}
                          {/* <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            // inline
                          /> */}
                          <DateRangePickerComp
                            onRangeSelected={onRangeSelected}
                          />
                        </Form.Item>
                        <FormInput
                          label="Number of additional days (+රු 4.000,00) +1 day "
                          name="Additional_Days"
                          type="number"
                          disabled
                        />{" "}
                      </Form>
                    </div>
                    <div className="total">Cost: {cost}</div>

                    <form class="cart" method="post">
                      {/* <div class="quantity buttons_added">
                        <input
                          type="number"
                          step="1"
                          min="1"
                          name="quantity"
                          value="1"
                          title="Qty"
                          class="input-text qty text"
                        />
                      </div> */}

                      <a
                        href="/shopCart"
                        class="cws-button alt border-radius small bt-color-3"
                      >
                        CHECK AVAILABILITY
                      </a>
                    </form>
                  </div>
                  <hr class="divider-color" />
                  <div class="tabs">
                    <div class="block-tabs-btn clear-fix">
                      <div class="tabs-btn active" data-tabs-id="tabs1">
                        Description
                      </div>
                    </div>
                    <div class="tabs-keeper">
                      <div
                        class="container-tabs active"
                        data-tabs-id="cont-tabs1"
                      >
                        <p>
                          <strong>Room Details</strong>
                        </p>
                        <p>
                          Here at JRDC, we recognize that relaxing in comfort
                          will encourage growth and development. After a
                          productive day, coming to a space that is filled with
                          a modern minimalist ambience is the perfect end. Our
                          Suites are designed to provide you with just that.
                        </p>
                        <p>
                          There are two suites available, each room is decorated
                          with beautiful and elegant wooden furnishings to
                          create a warm and inviting space to relax and
                          recuperate. Our suites come with an attached bathroom
                          with warm water for the full relaxing experience.
                        </p>
                        <p>
                          The room is decorated to incorporate modern technology
                          such as a TV and TV Cabinets, Air Conditioning and
                          Telephone & Internet connections ensuring convenience.
                          The space is embellished with different textures and
                          patterns to ensure comfort and rest in a modern style.
                        </p>
                        <p>
                          <strong>The amenities in the room includes:</strong>
                        </p>
                        <p>
                          You can also enjoy relaxing in the swimming pool,
                          improve your fitness at our amazing gym and enjoy a
                          hearty meal and down-time at our café.
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr class="divider-color" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </Page>
  );
};

export default SuiteRoomPage;
