import React, { useState, useEffect } from "react";
import { Button, Col, Row, Typography, Pagination } from "antd";
import CommonCard from "./../Components/common/CardComponent";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { useNavigate } from "react-router-dom";
import { getPageById } from "../Api/index";
import "./../styles/pageTitle.css";
import "./../Components/layouts/Footer.css";

const { Title } = Typography;

const VacanciesPage = () => {
  const [visibleVacancies, setVisibleVacancies] = useState(6);
  const [vacancyData, setVacancyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const pageSize = 6;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async (page) => {
      try {
        const response = await getPageById("8", page, pageSize);
        setVacancyData(response.data);
        setTotalItems(response.total);
      } catch (error) {
        console.error("Error fetching vacancies:", error);
      }
    };

    fetchData(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleVacancyClick = (vacancyItemId) => {
    const clickedVacItem = vacancyData.find(
      (item) => item.id === vacancyItemId
    );
    navigate(`/ViewVacancy`, { state: { vacancyItem: clickedVacItem } });
  };

  const showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total} items`;
  };

  return (
    <>
      <Page>
        <Navbar />
        <div className="page-wrapper">
          <div className="page-content woocommerce">
            <div className="container clear-fix">
              <div className="grid-col-row">
                <Row justify="center">
                  <Col span={24}>
                    <h1 className="section-title">Vacancies</h1>
                    <br />
                  </Col>
                </Row>
                <CommonCard
                  data={vacancyData}
                  onClick={(vacancyItem) => handleVacancyClick(vacancyItem)}
                />
                <Row justify="center" style={{ marginTop: "20px" }}>
                  <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={totalItems}
                    onChange={handlePageChange}
                    showTotal={showTotal}
                    style={{ marginBottom: "50px" }}
                  />
                </Row>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Page>
    </>
  );
};

export default VacanciesPage;
