import React from "react";
import { useParams } from "react-router-dom";
import { Card, Typography } from "antd";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import default_img from "./../img/pattern/default.jpg";

const { Text } = Typography;

const NewsDetailPage = () => {
  const location = useLocation();
  const { newsItem } = location.state;
  console.log("Received news item:", newsItem);
  const isLargeScreen = window.innerWidth >= 1200;
  const isTab = useMediaQuery({ minWidth: 768, maxWidth: 1024 });

  const styles = {
    imageContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    responsiveImage: {
      width: "100%",
      height: "100%",
      maxHeight: "600px",
      objectFit: "contain",
    },
    responsiveImageLarge: {
      width: "800px",
      height: "600px",
    },
    bodyText: {
      color: "#444",
      fontSize: "16px",
      fontFamily: "Roboto, sans-serif",
      marginTop: "20px",
      textAlign: "justify",
      //padding: "20px",
    },
    multipleImagesContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      // gap: "10px",
      marginTop: isLargeScreen ? "50px" : "-80px",
    },
    individualImage: {
      width: "100%",
      height: "600px",
      maxWidth: "600px",
      objectFit: "contain",
    },
  };

  return (
    <Page>
      <Navbar />
      <div className="page-content woocommerce">
        <div className="container clear-fix">
          <div className="grid-col-row">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Card
                style={{
                  width: isTab ? "90%" : "100%",
                  height: "auto",
                  boxSizing: "border-box",
                  padding: "20px",
                  marginBottom: "50px",
                }}
              >
                <Typography>
                  <Text
                    style={{
                      color: "#333",
                      fontWeight: "bold",
                      fontSize: isLargeScreen ? "3rem" : "1.6rem",
                      fontFamily: "Roboto, sans-serif",
                      textAlign: "center",
                      display: "block",
                    }}
                  >
                    {newsItem.title}
                  </Text>
                  <Text
                    style={{
                      color: "#666",
                      fontSize: "16px",
                      fontFamily: "Roboto, sans-serif",
                      textAlign: "center",
                      marginBottom: "20px",
                      display: "block",
                    }}
                  >
                    {newsItem.published_at}
                  </Text>
                </Typography>
                <div style={styles.imageContainer}>
                  <img
                    alt={newsItem.title}
                    src={newsItem?.image_url ? newsItem.image_url : default_img}
                    style={{
                      ...styles.responsiveImage,
                      ...(isLargeScreen && styles.responsiveImageLarge),
                    }}
                  />
                </div>
                <br />
                <div
                  style={styles.bodyText}
                  dangerouslySetInnerHTML={{ __html: newsItem.body }}
                ></div>

                <div style={styles.multipleImagesContainer}>
                  {newsItem.images.map((image, index) => (
                    <img
                      key={index}
                      src={image.image_url}
                      alt={`Image ${index + 1}`}
                      style={styles.individualImage}
                    />
                  ))}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Page>
  );
};

export default NewsDetailPage;
