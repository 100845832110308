/* eslint-disable */

class PricingOption{
    constructor(optionData){
        this.id = optionData?.id,
        this.option = optionData?.option,
        this.option_measure = optionData?.option_measure,
        this.option_price = optionData?.option_price,
        this.label = optionData?.label,
        this.in_minutes = optionData?.in_minutes,
        this.in_days = optionData?.in_days
    }
}


class AppointableProduct
{
    constructor(appontableProductData){
        this.id = appontableProductData?.id ?? null,
        this.product_id = appontableProductData?.product_id,
        this.label_instead_price = appontableProductData?.label_instead_price,
        this.price_label = appontableProductData?.price_label,
        this.lead_time = appontableProductData?.lead_time,
        this.lead_time_measure = appontableProductData?.lead_time_measure,
        this.requires_confirmation = appontableProductData?.requires_confirmation,
        this.calendar_color = appontableProductData?.calendar_color,
        this.created_at = appontableProductData?.created_at,
        this.updated_at = appontableProductData?.updated_at,
        this.from_date = appontableProductData?.from_date,
        this.to_date = appontableProductData?.to_date,
        this.from_time = appontableProductData?.from_time,
        this.to_time = appontableProductData?.to_time,
        this.min_reserve =appontableProductData?.min_reserve,
        this.max_reserve = appontableProductData?.max_reserve,
        this.reserve_measure =  appontableProductData?.reserve_measure,             
        this.interval =  appontableProductData?.interval
    }
}

class Product
{
    constructor(data){
        this.id = data?.id,
        this.product_category_id = data?.product_category_id,
        this.name = data?.name,
        this.details = data?.details,
        this.description = data?.description,
        this.regular_price = data?.regular_price,
        this.sale_price = data?.sale_price,
        this.quantity = data?.quantity,
        this.sku = data?.sku,
        this.slug = data?.slug,
        this.render_type = data?.render_type,
        this.status = data?.status,
        this.type = data?.type,
        this.image = data?.image,
        this.display_type = data?.display_type,
        this.created_at = data?.created_at,
        this.updated_at = data?.updated_at,
        this.show_zoom_links = data?.show_zoom_links,
        this.show_pricing_options = data?.show_pricing_options,
        this.course = data?.course,
        this.image_url = data?.image_url,
        this.is_appointable = data?.is_appointable,
        this.category_names = data?.category_names,
        this.show_no_rooms = data?.show_no_rooms,
        this.image_urls = data?.image_urls,
        this.show_quantity_field = data?.show_quantity_field,
        this.quantity_label = data?.quantity_label,
        this.appointable = data?.type == 'AP'? new AppointableProduct(data?.appointable): null ,
        this.amenities =  data?.amenities
        // this.pricing_options = data?.type == 'AP'? data?.pricing_options.map(element => new PricingOption(element)): null 
    }
}

export default Product;