import React, { useState, useCallback } from "react";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import {
  Button,
  Form,
  Input,
  Checkbox,
  message,
  Row,
  Col,
  Card,
  Tabs,
} from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import Captcha from "./../Components/common/Captcha";
import { login, register } from "../Api";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import "./../styles/pageTitle.css";
import Link from "antd/es/typography/Link";
import LostPassword from "./../Components/common/ForgetPassword";

const { TabPane } = Tabs;

const LoginForm = React.memo(
  ({ onFinish, error, isMobile, handleLostPasswordClick, loading }) => (
    <Form
      name="login"
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
      style={{ width: "100%" }}
    >
      <h2
        style={{
          fontFamily: "Roboto",
          fontWeight: "bold",
          marginBottom: "20px",
          textAlign: "center",
          marginTop: "15px",
        }}
      >
        Login
      </h2>
      <Form.Item
        label={
          <span style={{ fontFamily: "Roboto", fontSize: "14px" }}>
            <strong>Username or email address</strong>
          </span>
        }
        name="email"
        rules={[
          {
            required: true,
            message: "Please enter your username or email address.",
          },
        ]}
      >
        <Input placeholder="Username or email address" />
      </Form.Item>
      <Form.Item
        label={
          <span style={{ fontFamily: "Roboto", fontSize: "14px" }}>
            <strong> Password</strong>
          </span>
        }
        name="password"
        rules={[{ required: true, message: "Please enter your password." }]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>
      <Form.Item>
        <div className="d-flex justify-content-between">
          <Checkbox style={{ fontFamily: "Roboto", fontSize: "14px" }}>
            <strong>Remember me</strong>
          </Checkbox>
        </div>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="fos-btn-primary w-100 mt-5"
          loading={loading}
          style={{
            background: "#B9001F",
            borderColor: "#B9001F",
            width: isMobile ? "100%" : "30%",
          }}
        >
          Login
        </Button>
      </Form.Item>
      {error && <div className="text-danger">{error}</div>}
      <div>
        <Link to="#" onClick={handleLostPasswordClick}>
          Lost your password?
        </Link>
      </div>
    </Form>
  )
);

const RegisterForm = React.memo(
  ({ onFinish, onFinishFailed, isMobile, loading }) => {
    const [captchaVerified, setCaptchaVerified] = useState(false);
    // const [loading, setLoading] = useState(false);

    const handleCaptchaChange = (isValid) => {
      setCaptchaVerified(isValid);
    };

    const validateConfirmPassword = ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue("password") === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error("The two passwords do not match!"));
      },
    });

    return (
      <Form
        name="register"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        style={{ width: "100%" }}
      >
        <h2
          style={{
            fontFamily: "Roboto",
            fontWeight: "bold",
            marginBottom: "-10px",
            textAlign: "center",
            marginTop: "15px",
          }}
        >
          Register
        </h2>
        <br />
        <Form.Item
          label={
            <span style={{ fontFamily: "Roboto", fontSize: "14px" }}>
              <strong>Username</strong>
            </span>
          }
          name="username"
          rules={[{ required: true, message: "Please enter your username." }]}
        >
          <Input placeholder="Username" />
        </Form.Item>
        <Form.Item
          label={
            <span style={{ fontFamily: "Roboto", fontSize: "14px" }}>
              <strong>First Name</strong>
            </span>
          }
          name="first_name"
          rules={[{ required: true, message: "Please enter your firstname." }]}
        >
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item
          label={
            <span style={{ fontFamily: "Roboto", fontSize: "14px" }}>
              <strong>Last Name</strong>
            </span>
          }
          name="last_name"
          rules={[{ required: true, message: "Please enter your lastname." }]}
        >
          <Input placeholder="Last Name" />
        </Form.Item>
        <Form.Item
          label={
            <span style={{ fontFamily: "Roboto", fontSize: "14px" }}>
              <strong>Email address</strong>
            </span>
          }
          name="email"
          rules={[
            { required: true, message: "Please enter your email address." },
          ]}
        >
          <Input placeholder="Email address" />
        </Form.Item>
        <Form.Item
          label={
            <span style={{ fontFamily: "Roboto", fontSize: "14px" }}>
              <strong>Password</strong>
            </span>
          }
          name="password"
          rules={[{ required: true, message: "Please enter your password." }]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item
          label={
            <span style={{ fontFamily: "Roboto", fontSize: "14px" }}>
              <strong>Confirm Password</strong>
            </span>
          }
          name="password_confirmation"
          rules={[
            { required: true, message: "Please confirm your password." },
            validateConfirmPassword,
          ]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <p style={{ fontFamily: "Roboto", textAlign: "justify" }}>
            A Facility request form must be completed and submitted to JRDC
            administration no later than 10 business days prior to the scheduled
            event. Please note that completion of this application does not
            guarantee approval. JRDC reserves the right to deny facility
            reservations on a case-by-case basis regarding appropriateness for
            the facility/building.
          </p>
          <p style={{ fontFamily: "Roboto", textAlign: "justify" }}>
            Requesting parties will receive an email notification that a request
            has been received and requests will be processed (confirmed or
            denied) within 3-5 business days. This notification does not
            guarantee that the space has been allocated. The requesting party
            will receive an approved copy of their space request via email which
            will confirm space allocation.
          </p>
        </Form.Item>
        <Form.Item>
          <Captcha onChange={handleCaptchaChange} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="fos-btn-primary w-100 mt-2"
            style={
              captchaVerified
                ? {
                    background: "#B9001F",
                    borderColor: "#B9001F",
                    width: isMobile ? "100%" : "30%",
                  }
                : {
                    background: "#B9001F10",
                    borderColor: "#B9001F05",
                    width: isMobile ? "100%" : "30%",
                  }
            }
            disabled={!captchaVerified}
            loading={loading}
          >
            Register
          </Button>
        </Form.Item>
      </Form>
    );
  }
);

function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTab = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const [activeTab, setActiveTab] = useState("login");
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showLostPassword, setShowLostPassword] = useState(false);

  const handleLostPasswordClick = () => {
    setShowLostPassword(true);
  };

  const handleBackToLogin = () => {
    setShowLostPassword(false);
  };

  const onLogin = async (values) => {
    setLoading(true);
    try {
      console.log("Success:", values);
      const response = await login(values);

      if (response.status === 201) {
        window.localStorage.setItem(
          "jrdc_user",
          JSON.stringify(response.data.user)
        );
        window.localStorage.setItem("token", response.data.token);
        window.localStorage.setItem(
          "session_expiry_time",
          response.data.token_expires_at_with_timezone
        );
        window.localStorage.setItem(
          "session_expire_at",
          response.data.token_expires_at
        );
        window.localStorage.setItem("timezone", response.data.timezone);

        message.success("Login successful!");
        navigate("/MyAccount");
      } else {
        message.error("An error occurred during login. Try again later.");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        message.error(
          "An error occurred during login. Check your credentials and try again."
        );
      } else {
        console.error("Login failed:", error);
        message.error(
          "An error occurred during login. Please try again later."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCaptchaChange = useCallback((val) => {
    setCaptchaVerified(true);
  }, []);

  const onRegister = (values) => {
    console.log("Success:", values);

    const { ...newValues } = values;
    newValues.bill_email = values.email;

    setLoading(true);
    register(newValues)
      .then((val) => {
        console.log("register success", val);
        if (val.status === 201) {
          window.localStorage.setItem(
            "jrdc_user",
            JSON.stringify(val.data.user)
          );
          window.localStorage.setItem(
            "session_expiry_time",
            val.data.token_expires_at_with_timezone
          );
          window.localStorage.setItem(
            "session_expire_at",
            val.data.token_expires_at
          );
          window.localStorage.setItem("timezone", val.data.timezone);
          window.localStorage.setItem("token", val.data.token);
          navigate("/MyAccount?id=" + val.data.user.id);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          console.error("Error: The email already exists.");
          message.error(
            "The email already exists. Please use a different email."
          );
        } else {
          console.error("An unexpected error occurred:", error);
          message.error(
            "An unexpected error occurred. Please try again later."
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const cardStyle = {
    width: isMobile ? "100%" : "650px",
    minWidth: isMobile ? 0 : "600px",
    padding: "20px",
    paddingRight: "50px",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    marginBottom: isMobile ? "150px" : isTab ? "100px" : "80px",
    fontFamily: "'Montserrat', sans-serif",
    margin: "0 auto",
  };

  const tabPaneStyle = {
    padding: "20px",
    width: "100%",
  };

  const tabsContainerStyle = {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    borderBottom: "1px solid #ccc",
  };

  const tabStyle = {
    fontSize: "18px",
    flex: 1,
    textAlign: "center",
    cursor: "pointer",
    transition: "background-color 0.3s",
    borderBottom: "none",
    color: "black",
  };

  const activeTabStyle = {
    borderBottom: "2px solid #B9001F",
    color: "#B9001F",
  };

  return (
    <Page>
      <Navbar />
      <div className="page-content woocommerce">
        <div className="container clear-fix">
          <div className="grid-col-row">
            <h1 className="section-title" style={{ textAlign: "center" }}>
              My Account
            </h1>
            <br />
            <Card style={cardStyle}>
              {!showLostPassword && (
                <div style={tabsContainerStyle}>
                  <div
                    style={{
                      ...tabStyle,
                      ...(activeTab === "login" && activeTabStyle),
                    }}
                    onClick={() => setActiveTab("login")}
                  >
                    <span>Login</span>
                  </div>
                  <div
                    style={{
                      ...tabStyle,
                      ...(activeTab === "register" && activeTabStyle),
                    }}
                    onClick={() => setActiveTab("register")}
                  >
                    <span>Register</span>
                  </div>
                </div>
              )}
              <div style={tabPaneStyle}>
                {!showLostPassword && (
                  <React.Fragment>
                    {activeTab === "login" && (
                      <LoginForm
                        onFinish={onLogin}
                        error={error}
                        isMobile={isMobile}
                        handleLostPasswordClick={handleLostPasswordClick}
                        loading={loading}
                      />
                    )}
                    {activeTab === "register" && (
                      <RegisterForm
                        onFinish={onRegister}
                        onFinishFailed={onFinishFailed}
                        handleCaptchaChange={handleCaptchaChange}
                        captchaVerified={captchaVerified}
                        isMobile={isMobile}
                        loading={loading}
                      />
                    )}
                  </React.Fragment>
                )}
                {showLostPassword && (
                  <LostPassword onBackToLogin={handleBackToLogin} />
                )}
              </div>
            </Card>
          </div>
        </div>
      </div>
      <Footer />
    </Page>
  );
}

export default Login;
