import React, { useEffect, useReducer, useRef, useState } from "react";
import {
  Form,
  Row,
  Col,
  Carousel,
  DatePicker,
  Table,
  Card,
  Tooltip,
  Input,
  Button,
  message,
  Select,
  Calendar,
  theme,
  Space,
  Image,
} from "antd";
import "react-datepicker/dist/react-datepicker.css";
import Navbar from "../Components/layouts/Navbar.js";
import Footer from "../Components/layouts/Footer.js";
import Page from "../Components/layouts/Page.js";
import { useLocation, useParams } from "react-router-dom";
import {
  getEndTimeSlots,
  getPriceForTimeSlots,
  getProductDetails,
  getStartTimeSlots,
} from "../Api/index.js";
import { CartProvider } from "react-use-cart";
import Product from "../models/Product";
import parse from "html-react-parser";
import dayjs from "dayjs";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import table from "../img/amenities/table.png";
import air_conditioner from "../img/amenities/air-conditioner.png";
import bed from "../img/amenities/bed.png";
import chair from "../img/amenities/chair.png";
import coffee_table from "../img/amenities/coffee-table.png";
import cupboard from "../img/amenities/cupboard.png";
import double_bed from "../img/amenities/double-bed.png";
import drawer_cabinet from "../img/amenities/drawer-cabinet.png";
import internet_wifi from "../img/amenities/internet-wifi.png";
import sofa from "../img/amenities/sofa.png";
import telephone from "../img/amenities/telephone.png";
import tv from "../img/amenities/tv.png";
import water_jug_and_glasses from "../img/amenities/water-jug-and-glasses.png";
import default_img from "./../img/pattern/default.jpg";
import NumCalculator from "antd/es/theme/util/calc/NumCalculator.js";

const { RangePicker } = DatePicker;

const reducer = (totalCost, action) => {
  let baseCost = parseFloat(action.payload.salePrice);
  let noRooms = action.payload.noRooms ?? 1;

  totalCost = baseCost * noRooms;

  return totalCost > 0 ? totalCost.toFixed(2) : "0.00";
};

const DetailsPage = (props) => {
  const styles = {
    product: {
      display: "flex",
      flexDirection: "row",
      marginBottom: "20px",
    },
    images: {
      marginRight: "20px",
    },
    picture: {
      width: "100%",
      maxWidth: "600px",
    },
    summary: {
      flex: "1",
    },
    entryTitle: {
      color: "#000",
    },
    formSection: {
      marginTop: "20px",
      backgroundColor: "#f1f1f1",
      padding: "20px",
    },
    tabs: {
      width: "100%",
      marginTop: "40px",
      backgroundColor: "#f1f1f1",
    },
    tabsKeeper: {
      width: "100%",
    },
    tabContent: {
      marginTop: "20px",
      backgroundColor: "#fff",
      padding: "20px",
    },
  };

  const [form] = Form.useForm();
  const { state } = useLocation();
  const { id } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [product, setProduct] = useState(null);
  const [disableEndTimeField, setDisableEndTimeField] = useState(true);
  const [disableStartTimeField, setDisableStartTimeField] = useState(true);
  const [roomQty, setRoomQty] = useState(1);
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [startTimeOptions, setStartTimeOptions] = useState([]);
  const [endTimeOptions, setEndTimeOptions] = useState([]);
  const [totalCost, dispatch] = useReducer(reducer, 0);
  const [validDateRange, setValidDateRange] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [pricingOptions, setPricingOptions] = useState([]);
  const [validation, setValidation] = useState(null);
  const [unitPrice, setUnitPrice] = useState(null);

  const navigate = useNavigate();
  let sliderRef = useRef(null);
  const { token } = theme.useToken();
  const wrapperStyle = {
    border: `1px solid ${token.colorBorderSecondary}`,
    borderRadius: token.borderRadiusLG,
  };

  const fetchDetails = () => {
    getProductDetails(id).then((response) => {
      if (response) {
        console.log(response);
        if (response?.data?.product) {
          setProduct(new Product(response?.data?.product));
        }

        setUnavailableDates(response?.data?.date_options?.confirmed_dates);
        setStartTimeOptions(response?.data?.start_time_options);
        setEndTimeOptions(response?.data?.end_time_options);
        const s = response?.data?.date_options?.start_date
          ? dayjs(response?.data?.date_options?.start_date).startOf("day")
          : null;
        const e = response?.data?.date_options?.end_date
          ? dayjs(response?.data?.date_options?.end_date).startOf("day")
          : null;
        setValidDateRange([s, e]);
        setPricingOptions(response?.data?.pricing_options);
        dispatch({
          payload: {
            salePrice: response?.data?.product?.sale_price,
          },
        });
        setUnitPrice(response?.data?.product?.sale_price);
      }
    });
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const disabledDate = (currentDate) => {
    const current = dayjs(currentDate).startOf("day");
    return (
      (validDateRange[0] !== null && current.isBefore(validDateRange[0])) ||
      (validDateRange[1] !== null && current.isAfter(validDateRange[1]))
    );
  };

  const validate = (values) => {
    let numberOfDays = dayjs(values[1] ?? undefined).diff(
      values[0] ?? undefined,
      "days"
    );
    if (
      product?.appointable?.max_reserve != 0 &&
      numberOfDays > product?.appointable?.max_reserve &&
      numberOfDays > product?.appointable?.min_reserve
    ) {
      setValidation(
        `A maximum of ${product?.appointable?.max_reserve} days can be booked at a time! `
      );
    } else if (
      numberOfDays < product?.appointable?.max_reserve &&
      numberOfDays < product?.appointable?.min_reserve
    ) {
      setValidation(
        `A minimum of ${product?.appointable?.min_reserve} days must be booked! `
      );
    } else {
      setValidation(null);
    }
  };

  const amenities = {
    icon: {
      table: table,
      air_conditioner: air_conditioner,
      bed: bed,
      chair: chair,
      coffee_table: coffee_table,
      cupboard: cupboard,
      double_bed: double_bed,
      drawer_cabinet: drawer_cabinet,
      internet_wifi: internet_wifi,
      sofa: sofa,
      telephone: telephone,
      tv: tv,
      water_jug_and_glasses: water_jug_and_glasses,
    },
    name: {
      table: "Table",
      air_conditioner: "Air Conditioner",
      bed: "Single Bed",
      chair: "Chair",
      coffee_table: "Coffee Table",
      cupboard: "Cupboard",
      double_bed: "Double Bed",
      drawer_cabinet: "Drawer Cabinet",
      internet_wifi: "WIFI",
      sofa: "Sofa",
      telephone: "Telephone",
      tv: "TV",
      water_jug_and_glasses: "Water Jug & Glasses",
    },
  };

  const columns = [
    {
      title: "Duration",
      dataIndex: "label",
    },
    {
      title: "Price",
      dataIndex: "price",
    },
  ];

  const calenderCellRender = (current, info) => {
    let date = dayjs(current).format("YYYY-MM-DD");
    let isPresent = unavailableDates.includes(date);

    const cellStyle = {
      width: "100%",
      height: "2px",
      backgroundColor: `#e22658`,
    };

    if (isPresent) {
      return (
        <Tooltip title="Selected date is partially scheduled, but appointments might still remain.">
          <div style={isPresent ? cellStyle : {}}></div>
        </Tooltip>
      );
    }
  };

  const cellRender = (current, info) => {
    let date = dayjs(current).format("YYYY-MM-DD");
    let isPresent = unavailableDates.includes(date);
    const cellStyle = {
      border: `1px solid #e22658`,
      borderRadius: "12%",
    };

    if (isPresent) {
      return (
        <Tooltip title="Selected date is partially scheduled, but appointments might still remain.">
          <div
            className="ant-picker-cell-inner"
            style={isPresent ? cellStyle : {}}
          >
            {dayjs(current).date()}
          </div>
        </Tooltip>
      );
    } else {
      return info.originNode;
    }
  };

  const addToCart = () => {
    const item = {
      product: product,
      startDate: dayjs(dateRange[0]),
      endDate: dayjs(dateRange[1]),
      qty: roomQty ?? 1,
      cost: totalCost,
      showTime: product?.appointable?.reserve_measure === "minutes",
      salePrice: (totalCost / (roomQty ?? 1)).toFixed(2),
      endTime: dayjs(endTime, "HH:mm:ss"),
      startTime: dayjs(startTime, "HH:mm:ss"),
    };
    console.log({ item });
    const ci = window.localStorage.getItem("jrdc_cart_items");

    if (ci) {
      const ciObj = JSON.parse(ci);
      const exsisting = ciObj.findIndex((ci) => ci.product.id === product?.id);
      if (exsisting !== -1) {
        ciObj[exsisting] = item;
        window.localStorage.setItem("jrdc_cart_items", JSON.stringify(ciObj));
        console.log({ ci });
        navigate("/shopCart");
        return;
      }
      ciObj.push(item);
      window.localStorage.setItem("jrdc_cart_items", JSON.stringify(ciObj));
      console.log({ ci });
      navigate("/shopCart");
      return;
    }
    window.localStorage.setItem("jrdc_cart_items", JSON.stringify([item]));
    console.log({ ci });

    navigate("/shopCart");
  };

  const handleCheckAvailability = () => {
    if (product?.appointable?.reserve_measure == "days") {
      validate(dateRange);
      if (validation == null) {
        form
          .validateFields(["no_rooms"])
          .then(() => {
            addToCart();
          })
          .catch((e) => message.error(e));
      }
    } else {
      form
        .validateFields(["start_time", "end_time", "no_rooms"])
        .then(() => {
          addToCart();
        })
        .catch((e) => message.error(e));
    }
  };
  return (
    <Page>
      <Navbar />
      <div className="page-content woocommerce">
        <div className="container clear-fix">
          <div className="grid-col-row">
            <div className=" grid-col-12">
              <div role="main">
                <CartProvider>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={12}>
                      <Row gutter={[16, isMobile ? 8 : 32]}>
                        <Col span={24}>
                          <Carousel
                            dots={false}
                            autoplay
                            autoplaySpeed={3000}
                            ref={(slider) => {
                              sliderRef = slider;
                            }}
                            style={{
                              width: "100%",
                              height: isMobile ? "300px" : "500px",
                              overflow: "hidden",
                            }}
                          >
                            {product?.image_urls.map((e, index) => (
                              <div
                                key={index}
                                style={{ width: "100%", height: "100%" }}
                              >
                                <Image
                                  src={e ? e : default_img}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                  fallback={default_img}
                                />
                              </div>
                            ))}
                          </Carousel>
                        </Col>
                        <Col
                          span={24}
                          style={{ marginTop: isMobile ? "8px" : "16px" }}
                        >
                          <ImageList
                            sx={{
                              width: "100%",
                              height: "auto",
                              maxHeight: isMobile ? "100px" : "500px",
                              objectFit: "cover",
                            }}
                            cols={isMobile ? 4 : 4}
                            rowHeight={isMobile ? 75 : 100}
                            style={{ marginTop: isMobile ? "8px" : "0px" }}
                          >
                            {product?.image_urls?.map((item, index) => (
                              <ImageListItem key={index}>
                                <Button
                                  type="link"
                                  width={isMobile ? "75px" : "100px"}
                                  height={isMobile ? "75px" : "100px"}
                                  onClick={() => {
                                    sliderRef.goTo(index);
                                  }}
                                >
                                  <Image
                                    width={isMobile ? "75px" : "100px"}
                                    height={isMobile ? "75px" : "100px"}
                                    fallback={default_img}
                                    src={item ? item : default_img}
                                    data-at2x="/path/to/your/custom-image.jpg"
                                    alt={`${product?.name} - ${index}`}
                                    loading="lazy"
                                    style={{ objectFit: "cover" }}
                                    preview={false}
                                  />
                                </Button>
                              </ImageListItem>
                            ))}
                          </ImageList>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={24} md={24} lg={12}>
                      <Card>
                        <Form
                          form={form}
                          layout="vertical"
                          width="100%"
                          onFinish={handleCheckAvailability}
                        >
                          <Row gutter={[16, 16]} align={"bottom"}>
                            <Col span={24}>
                              <div
                                className="summary entry-summary"
                                style={styles.summary}
                              >
                                <h2
                                  className="product_title entry-title"
                                  style={styles.entryTitle}
                                >
                                  {product?.name}
                                </h2>
                                <p style={{ textAlign: "justify" }}>
                                  {product?.type === "AP" &&
                                    product?.appointable?.label_instead_price ==
                                      true && (
                                      <strong>
                                        {product?.appointable?.price_label}
                                      </strong>
                                    )}

                                  <br />
                                  {product?.description ?? ""}
                                </p>
                              </div>
                            </Col>
                            {product?.type == "AP" &&
                              product?.appointable?.reserve_measure ==
                                "minutes" && (
                                <Col>
                                  <div style={wrapperStyle}>
                                    <Calendar
                                      defaultValue={null}
                                      fullscreen={false}
                                      disabledDate={disabledDate}
                                      cellRender={calenderCellRender}
                                      onSelect={(date) => {
                                        getStartTimeSlots(
                                          product?.id,
                                          dayjs(date).format("YYYY-MM-DD")
                                        ).then((response) => {
                                          setDisableEndTimeField(true);
                                          setDisableStartTimeField(false);
                                          setStartTime(null);
                                          form.setFieldsValue({
                                            start_time: null,
                                          });
                                          setEndTime(null);

                                          form.setFieldsValue({
                                            end_time: null,
                                          });
                                          form.setFieldsValue("endTime", null);
                                          setStartTimeOptions(
                                            response?.start_time_options
                                          );
                                          setSelectedDate(date);
                                        });
                                      }}
                                    />
                                  </div>
                                </Col>
                              )}

                            {product?.show_pricing_options == true && (
                              <>
                                <Col span={24}>
                                  <p>
                                    <b>
                                      Please note fees will be charged according
                                      to the following table.
                                    </b>
                                  </p>
                                </Col>
                                <Col span={24}>
                                  <Table
                                    columns={columns}
                                    dataSource={pricingOptions}
                                    pagination={false}
                                    size="small"
                                  />
                                </Col>
                              </>
                            )}

                            {product?.type == "AP" &&
                              product?.appointable?.reserve_measure ==
                                "minutes" && (
                                <>
                                  <Col span={12}>
                                    <Form.Item
                                      name="start_time"
                                      label={"Start Time"}
                                      rules={[
                                        {
                                          required: true,
                                          message: `Start Time is required!`,
                                        },
                                      ]}
                                    >
                                      <Select
                                        value={startTime}
                                        options={startTimeOptions}
                                        disabled={disableStartTimeField}
                                        onSelect={(value) => {
                                          setStartTime(value);
                                          getEndTimeSlots(
                                            product?.id,
                                            dayjs(selectedDate).format(
                                              "YYYY-MM-DD"
                                            ),
                                            value
                                          ).then((response) => {
                                            setEndTimeOptions(
                                              response?.end_time_options
                                            );
                                            setEndTime(null);
                                            form.setFieldsValue({
                                              end_time: null,
                                            });
                                            setDisableEndTimeField(false);
                                          });
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item
                                      label={"End Time"}
                                      name="end_time"
                                      rules={[
                                        {
                                          required: true,
                                          message: `End Time is required!`,
                                        },
                                        ({ getFieldValue }) => ({
                                          validator(_, value) {
                                            const startTime =
                                              getFieldValue("start_time");
                                            if (!value || !startTime) {
                                              return Promise.resolve();
                                            }
                                            if (
                                              dayjs(value, "HH:mm").isBefore(
                                                dayjs(startTime, "HH:mm")
                                              )
                                            ) {
                                              return Promise.reject(
                                                new Error(
                                                  "End Time must be later than Start Time!"
                                                )
                                              );
                                            }
                                            return Promise.resolve();
                                          },
                                        }),
                                      ]}
                                    >
                                      <Select
                                        value={endTime}
                                        options={endTimeOptions}
                                        disabled={disableEndTimeField}
                                        onSelect={(value) => {
                                          setEndTime(value);
                                          getPriceForTimeSlots(
                                            product?.id,
                                            startTime,
                                            value
                                          ).then((response) => {
                                            console.log(response);
                                            setUnitPrice(response?.price);
                                            dispatch({
                                              payload: {
                                                salePrice: response?.price,
                                                noRooms: roomQty,
                                              },
                                            });
                                          });
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                </>
                              )}

                            {product?.type == "AP" &&
                              product?.appointable?.reserve_measure ==
                                "days" && (
                                <Col span={24}>
                                  <Form.Item
                                    label={"Date"}
                                    name={"date"}
                                    rules={[
                                      {
                                        required: true,
                                        message: `Date is required!`,
                                      },
                                    ]}
                                  >
                                    <RangePicker
                                      style={{
                                        display: "flex",
                                        flexGrow: 1,
                                      }}
                                      onChange={(values) => {
                                        if (values) {
                                          let numberOfDays = dayjs(
                                            values[1] ?? undefined
                                          ).diff(
                                            values[0] ?? undefined,
                                            "days"
                                          );
                                          //min_reserve validation
                                          let ratio =
                                            Math.round(
                                              (numberOfDays /
                                                product?.appointable
                                                  ?.min_reserve) *
                                                10
                                            ) / 10;

                                          console.log(numberOfDays);
                                          validate(values);
                                          setUnitPrice(
                                            product?.sale_price * ratio
                                          );
                                          dispatch({
                                            payload: {
                                              salePrice:
                                                product?.sale_price * ratio,
                                              noRooms: roomQty,
                                            },
                                          });
                                          setDateRange(values);
                                        }
                                      }}
                                      disabledDate={disabledDate}
                                      cellRender={cellRender}
                                    />
                                  </Form.Item>
                                  <p style={{ color: "#ff4d4f" }}>
                                    {validation}
                                  </p>
                                </Col>
                              )}

                            <Col span={24}>
                              {product?.show_quantity_field == true && (
                                <Form.Item
                                  label={product?.quantity_label}
                                  name={"no_rooms"}
                                  rules={[
                                    {
                                      required: true,
                                      max: product?.quantity ?? null,
                                      min: 1,
                                      message: "This field is required!",
                                    },
                                  ]}
                                >
                                  <Input
                                    type="number"
                                    value={roomQty}
                                    onChange={(e) => {
                                      setRoomQty(e?.target?.value);
                                      dispatch({
                                        payload: {
                                          salePrice: unitPrice,
                                          noRooms: e?.target?.value,
                                        },
                                      });
                                    }}
                                    min={1}
                                    max={product?.quantity ?? null}
                                    placeholder={
                                      "Enter " + product?.quantity_label
                                    }
                                  />
                                </Form.Item>
                              )}
                            </Col>
                            <Col span={24}>
                              <p>
                                <b>{`Total Cost: LKR ` + totalCost}</b>
                              </p>
                            </Col>
                            <Col span={24}>
                              <div style={{ justifyItems: "center" }}>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  style={{
                                    background: "#B9001F",
                                    borderColor: "#B9001F",
                                    width: "100%",
                                  }}
                                >
                                  CHECK AVAILABILITY
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </Card>
                    </Col>
                  </Row>
                  <br />
                  {product?.details && (
                    <Row>
                      <Col span={24}>
                        <Card
                          title={"Description"}
                          style={{ marginBottom: "50px", textAlign: "justify" }}
                        >
                          <Row gutter={[16, 16]}>
                            <Col span={24}>
                              <strong style={{ fontSize: "larger" }}>
                                {product?.details && parse(product?.details)}
                              </strong>
                            </Col>

                            {product?.amenities && (
                              <Col span={24}>
                                <h2>The amenities in the room includes:</h2>
                                <Row gutter={[50, 24]}>
                                  {product?.amenities?.map((e) => {
                                    return (
                                      <Col>
                                        <Space>
                                          <Image
                                            width={75}
                                            height={75}
                                            key={e}
                                            src={amenities["icon"][e]}
                                            alt={e}
                                          />
                                          <h4>{amenities["name"][e]}</h4>
                                        </Space>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </Col>
                            )}
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  )}
                </CartProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Page>
  );
};

export default DetailsPage;
