import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import homepage from "../img/pattern/homepage.png";
import { PlusOutlined } from "@ant-design/icons";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Typography } from "antd";
import { getFirstFewNews } from "../Api/index";
import dayjs from "dayjs";
import "dayjs/locale/en";
import { FloatingButton } from "./../Components/searchComponent/FloatingButton";

const TextSlider = () => {
  const { Title } = Typography;
  const [newsData, setNewsData] = useState([]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await getFirstFewNews(5);
        const firstFewNews = response.data;
        console.log("fetched news items: ", firstFewNews);
        setNewsData(firstFewNews);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchNews();
  }, []);

  return (
    <Slider {...settings}>
      {newsData.map((news) => (
        <div key={news.id}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <span
              style={{
                padding: "0 8px",
                marginRight: "10px",
                backgroundColor: "#2F5597",
                color: "#fff",
                fontSize: "12px",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              {dayjs(news.published_at).format("Do MMMM YYYY")}
            </span>
            <p
              style={{
                fontSize: "14px",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              {news.title}
            </p>
          </div>
        </div>
      ))}
    </Slider>
  );
};

const Homepage = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTab = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const [hovered, setHovered] = useState(new Array(4).fill(false));
  const [backgroundVisible, setBackgroundVisible] = useState(
    new Array(4).fill(false)
  ); // Initialize as array

  const handleMouseEnter = (index) => {
    setHovered((prevState) => prevState.map((value, i) => i === index));
  };

  const handleMouseLeave = () => {
    setHovered(new Array(4).fill(false));
  };

  const handleIconTransitionEnd = (index) => {
    setBackgroundVisible((prevState) => {
      const newState = [...prevState];
      newState[index] = hovered[index];
      return newState;
    });
  };

  const cardContentStyle = {
    background: "rgba(0, 0, 0, 0)",
    padding: "10px",
    width: isTab ? "100%" : "95%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    marginLeft: "10px",
  };

  const wrapperDivStyle = {
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
    background: "transparent",
  };

  const headingStyles = (index) => ({
    color: hovered[index] ? "#FFFFFF" : "#2F5597",
    fontSize: "26px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "bold",
    position: "relative",
    cursor: "pointer",
    marginLeft: "10px",
    marginTop: "10px",
    transition: "color 1s ease",
  });

  const plusButtonStyles = (index) => ({
    position: "absolute",
    top: 0,
    right: 0,
    color: "#B9001F",
    cursor: "pointer",
    fontSize: "24px",
    transform: hovered[index] ? "rotate(360deg)" : "rotate(0deg)",
    transition: "transform 0.3s ease",
    marginLeft: "10px",
  });

  const subheadingStyles = (index) => ({
    color: hovered[index] ? "#FFFFFF" : "#B9001F",
    fontSize: "18px",
    fontFamily: "Roboto, sans-serif",
    cursor: "pointer",
    marginLeft: "10px",
    transition: "color 1s ease",
  });

  const descriptionStyle = {
    color: "#0F0673",
    fontSize: "16px",
    fontFamily: "Roboto, sans-serif",
    marginLeft: "10px",
  };

  const backgroundSliderStyles = (index) => ({
    position: "absolute",
    content: "",
    width: "100%",
    height: "120%",
    top: "-10px",
    left: backgroundVisible[index] ? "0" : "105%",
    background: "#B9001F",
    transition: "left 0.5s ease",
    zIndex: -1,
  });

  const SpinnerStyle = {
    animationName: "spinner",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
    animationDuration: "60s",
    transformStyle: "preserve-3d",
  };

  return (
    <Page>
      <Navbar />
      <TextSlider />
      <div className="page-wrapper">
        <div
          style={{
            marginLeft: isMobile || isTab ? 0 : "160px",
            marginTop: "20px",
            marginBottom: "-50px",
          }}
        >
          <Row
            justify="space-around"
            style={{
              padding: "0 20px",
              height: "auto",
            }}
          >
            {" "}
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={8}
              xl={6}
              style={{
                marginTop: isMobile || isTab ? 0 : "50px",
                marginLeft: isMobile || isTab ? 0 : "-100px",
                marginBottom: isMobile || isTab ? "20px" : 0,
                zIndex: 2,
              }}
            >
              <div style={cardContentStyle}>
                <a
                  href="https://research.jrdc.lk/login-request"
                  style={{ textDecoration: "none" }}
                  onMouseEnter={() => handleMouseEnter(0)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div style={wrapperDivStyle}>
                    <div style={{ position: "relative" }}>
                      <p style={headingStyles(0)}>
                        Testing & Research
                        <PlusOutlined
                          style={plusButtonStyles(0)}
                          onTransitionEnd={() => handleIconTransitionEnd(0)}
                        />
                      </p>
                      <p style={subheadingStyles(0)}>
                        Robust and Innovative solutions for dynamic challenges
                      </p>
                      <div style={backgroundSliderStyles(0)}></div>
                    </div>
                  </div>
                </a>
                <p style={descriptionStyle}>
                  Water sustainability comes with grand challenges that affect
                  individuals and industries combined. These challenges require
                  an integrated effort from water, environment, and health
                  sciences.
                </p>
              </div>
            </Col>
            <Col xs={24} sm={12} md={24} lg={4} xl={6}></Col>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              style={{
                marginRight: isMobile || isTab ? 0 : "20px",
                marginLeft: isMobile || isTab ? 0 : "60px",
                marginTop: isMobile || isTab ? 0 : "50px",
                marginBottom: isMobile || isTab ? "20px" : 0,
                zIndex: 2,
              }}
            >
              <div
                style={cardContentStyle}
                onClick={() => navigate("/facility_reservations")}
                onMouseEnter={() => handleMouseEnter(1)}
                onMouseLeave={handleMouseLeave}
              >
                <div style={wrapperDivStyle}>
                  <div style={{ position: "relative" }}>
                    <p style={headingStyles(1)}>
                      Facility Reservation
                      <PlusOutlined
                        style={plusButtonStyles(1)}
                        onTransitionEnd={() => handleIconTransitionEnd(1)}
                      />
                    </p>
                    <p style={subheadingStyles(1)}>
                      Research and advance in comfort
                    </p>
                    <div style={backgroundSliderStyles(1)}></div>
                  </div>
                </div>
                <p style={descriptionStyle}>
                  JRDC is located in a vibrant and enriching environment. We
                  house a number of remarkable facilities that are designed to
                  accommodate and cater to your specific needs. The facilities
                  are open for use on a scheduled basis.
                </p>
              </div>
            </Col>
          </Row>

          <Row
            justify="center"
            style={{
              margingLeft: isMobile ? "0" : "180px  ",
            }}
          >
            <Col
              xs={24}
              sm={24}
              md={32}
              lg={32}
              xl={16}
              style={{ margingLeft: isMobile ? "0" : "200px  " }}
            >
              <div style={{ textAlign: "center" }}>
                <img
                  id="spinner"
                  className="model-image"
                  data-aos="fade-up"
                  data-aos-easing="easeOutBack"
                  data-aos-duration="1000"
                  data-aos-delay="2900"
                  src={homepage}
                  alt="Center Image"
                  style={{
                    ...SpinnerStyle,
                    width: isMobile || isTab ? "100%" : "70%",
                    maxWidth: "100%",
                    padding: isMobile || isTab ? 0 : "0px 0px 50px 80px",
                    marginTop: isMobile || isTab ? "50px" : "-160px",
                    marginBottom: isMobile || isTab ? "50px" : "0",
                    zIndex: 1,
                  }}
                />
              </div>
              <style>
                {`
          @keyframes spinner {
            0% {
              transform: scale(1);
            }
            30% {
              transform: scale(1.2);
            }
            60% {
              transform: scale(1.2) rotate(-5eg);
            }
            60% {
              transform: scale(1.2);
            }
            30% {
              transform: scale(1.2) rotate(-5deg);
            }
            0% {
              transform: scale(1);
            }
          }
        `}
              </style>
            </Col>
          </Row>

          <Row
            justify="space-around"
            style={{
              padding: "0 20px",
              height: "auto",
              marginTop: isMobile || isTab ? 0 : "-80px",
              marginBottom: "100px",
            }}
          >
            {" "}
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              style={{
                marginLeft: isMobile || isTab ? 0 : "-100px",
                marginTop: isMobile || isTab ? 0 : "-80px",
                marginBottom: isMobile || isTab ? "20px" : 0,
                zIndex: 2,
              }}
            >
              <div
                style={cardContentStyle}
                onClick={() => navigate("/OurServices")}
                onMouseEnter={() => handleMouseEnter(2)}
                onMouseLeave={handleMouseLeave}
              >
                <div style={wrapperDivStyle}>
                  <div style={{ position: "relative" }}>
                    <p style={headingStyles(2)}>
                      Consultancy & Technology
                      <PlusOutlined
                        style={plusButtonStyles(2)}
                        onTransitionEnd={() => handleIconTransitionEnd(2)}
                      />
                    </p>
                    <p style={subheadingStyles(2)}>
                      Shared vision for sustainability
                    </p>
                    <div style={backgroundSliderStyles(2)}></div>
                  </div>
                </div>
                <p style={descriptionStyle}>
                  JRDC provides a range of services that cater to your need, our
                  consultancy services are backed with the state-of-the-art
                  technology to provide you the solution for the challenges.
                  These treatments, assessments and studies helps sustain human
                  health as water can contain contaminants that are harmful for
                  humans.
                </p>
              </div>
            </Col>
            <Col xs={24} sm={12} md={24} lg={6} xl={6}></Col>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              style={{
                marginRight: isMobile || isTab ? 0 : "20px",
                marginLeft: isMobile || isTab ? 0 : "80px",
                marginTop: isMobile || isTab ? 0 : "-60px",
                marginBottom: isMobile || isTab ? "20px" : 0,
                zIndex: 2,
              }}
            >
              <div
                style={cardContentStyle}
                onClick={() => navigate("/programs")}
                onMouseEnter={() => handleMouseEnter(3)}
                onMouseLeave={handleMouseLeave}
              >
                <div style={wrapperDivStyle}>
                  <div style={{ position: "relative" }}>
                    <p style={headingStyles(3)}>
                      Programs
                      <PlusOutlined
                        style={plusButtonStyles(3)}
                        onTransitionEnd={() => handleIconTransitionEnd(3)}
                      />
                    </p>
                    <p style={subheadingStyles(3)}>
                      Learn, Train and Research with the best
                    </p>
                    <div style={backgroundSliderStyles(3)}></div>
                  </div>
                </div>
                <p style={descriptionStyle}>
                  Our programs aim at encouraging and promoting advancement and
                  development through collaboration and integration, we are a
                  center for water technology development, knowledge generation
                  and dissemination.
                </p>
              </div>
            </Col>
          </Row>
          <FloatingButton />
        </div>
        <Footer />
      </div>
    </Page>
  );
};

export default Homepage;
