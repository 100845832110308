import React, { useState, useEffect } from "react";
import jrdc_Logo from "../../../src/img/pattern/jrdc_Logo.png";
import { getMenus } from "../../Api/index";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

let cachedMenus = [];

function Navbar() {
  const [menus, setMenus] = useState(cachedMenus);
  const [showSidebar, setShowSidebar] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if menus are already cached
    if (cachedMenus.length === 0) {
      // If not cached, fetch menus from the database
      async function fetchMenus() {
        try {
          const response = await getMenus();
          cachedMenus = response; // Update cachedMenus
          setMenus(response);
        } catch (error) {
          console.error("Error fetching menus:", error);
        }
      }

      fetchMenus();
    } else {
      // If menus are already cached, use the cached data
      setMenus(cachedMenus);
    }
  }, []);

  const isLoggedIn = !!window.localStorage.getItem("token");

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    // Get the current path on component mount
    const currentPath = window.location.pathname;
    const currentPageName = currentPath.split("/").pop();
    setSelectedMenu(currentPageName);
    console.log("Selected Menu on Mount:", currentPageName);
  }, []);

  const handleNavigation = (url) => {
    if (url) {
      if (url.startsWith("http")) {
        window.location.href = url;
      } else {
        if (url !== selectedMenu) {
          setSelectedMenu(url);
          navigate(`/${url}`);
        }
      }
    }
  };

  const homepageNav = () => {
    navigate("/");
  };

  const isMenuSelected = (menu) => {
    if (menu.url === selectedMenu) {
      return true;
    } else if (menu.child_menus) {
      return menu.child_menus.some(
        (child) =>
          child.url === selectedMenu ||
          (child.page && child.page.url) === selectedMenu
      );
    }
    return false;
  };

  useEffect(() => {
    if (!isMobile) {
      setShowSidebar(false);
    }
  }, [isMobile]);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
    if (!isMobile) {
      document.body.style.overflow = showSidebar ? "auto" : "hidden";
    }
  };

  const closeSidebar = () => {
    setShowSidebar(false);
    if (!isMobile) {
      document.body.style.overflow = "auto";
    }
  };

  const hoverStyle = {
    color: "blue",
  };

  return (
    <header style={{ background: "none !important" }}>
      <div className="page-header-top">
        <div className="grid-row clear-fix"></div>
      </div>
      <div className="sticky-wrapper">
        <div className="sticky-menu">
          <div className="grid-row clear-fix">
            <div style={{ display: "flex", alignItems: "center" }}>
              <a href="/" className="logo">
                <img
                  src={jrdc_Logo}
                  data-at2x="img/logo@2x.png"
                  alt="jrdc_Logo"
                />
              </a>
              {isMobile ? (
                <div style={{ marginLeft: "auto" }}>
                  <button
                    className="hamburger-icon"
                    onClick={toggleSidebar}
                    style={{ border: "none", background: "transparent" }}
                  >
                    <i className="fa fa-bars"></i>
                  </button>
                </div>
              ) : (
                <nav className="main-nav" style={{ marginLeft: "auto" }}>
                  <ul className="clear-fix">
                    {menus.map((menu) => (
                      <li
                        key={menu.id}
                        style={{
                          marginLeft: "30px",
                          fontFamily: "Raleway",
                          fontSize: "15px",
                        }}
                      >
                        <span
                          onClick={() => handleNavigation(menu.url)}
                          style={{
                            cursor: "pointer",
                            marginRight: "20px",
                            color: isMenuSelected(menu) ? "blue" : "inherit",
                          }}
                          onMouseEnter={(e) => {
                            if (!isMenuSelected(menu)) {
                              e.target.style.color = hoverStyle.color;
                            }
                          }}
                          onMouseLeave={(e) => {
                            if (!isMenuSelected(menu)) {
                              e.target.style.color = "inherit";
                            }
                          }}
                        >
                          {menu.name}
                        </span>
                        {menu.child_menus && (
                          <ul>
                            {menu.child_menus.map((child) => (
                              <li
                                key={child.id}
                                style={{ width: "200px", marginLeft: "20px" }}
                              >
                                <span
                                  onClick={() =>
                                    handleNavigation(
                                      child.url ||
                                        (child.page && child.page.url)
                                    )
                                  }
                                  style={{
                                    cursor: "pointer",
                                    color:
                                      selectedMenu ===
                                      (child.url ||
                                        (child.page && child.page.url))
                                        ? "blue"
                                        : "inherit",
                                  }}
                                  onMouseEnter={(e) => {
                                    if (
                                      !(
                                        selectedMenu ===
                                        (child.url ||
                                          (child.page && child.page.url))
                                      )
                                    ) {
                                      e.target.style.color = hoverStyle.color;
                                    }
                                  }}
                                  onMouseLeave={(e) => {
                                    if (
                                      !(
                                        selectedMenu ===
                                        (child.url ||
                                          (child.page && child.page.url))
                                      )
                                    ) {
                                      e.target.style.color = "inherit";
                                    }
                                  }}
                                >
                                  {child.name}
                                </span>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}

                    {isLoggedIn ? (
                      <li>
                        <i
                          className="fa fa-user mr-3"
                          style={{ marginLeft: "20px" }}
                        >
                          {"  "}
                          <span> </span>
                          <span
                            onClick={() => handleNavigation("MyAccount")}
                            style={{
                              cursor: "pointer",
                              fontSize: "15px",
                              fontFamily: "Raleway",
                              color:
                                selectedMenu === "MyAccount"
                                  ? "blue"
                                  : "inherit",
                            }}
                            onMouseEnter={(e) => {
                              if (selectedMenu !== "MyAccount") {
                                e.target.style.color = hoverStyle.color;
                              }
                            }}
                            onMouseLeave={(e) => {
                              if (selectedMenu !== "MyAccount") {
                                e.target.style.color = "inherit";
                              }
                            }}
                          >
                            My Account
                          </span>
                        </i>
                      </li>
                    ) : (
                      <li>
                        <i
                          className="fa fa-user mr-3"
                          style={{ marginLeft: "20px" }}
                        >
                          {" "}
                          <span> </span>
                          <span
                            onClick={() => handleNavigation("Login")}
                            style={{
                              cursor: "pointer",
                              fontSize: "15px",
                              fontFamily: "Raleway",
                              color:
                                selectedMenu === "Login" ? "blue" : "inherit",
                            }}
                            onMouseEnter={(e) => {
                              if (selectedMenu !== "Login") {
                                e.target.style.color = hoverStyle.color;
                              }
                            }}
                            onMouseLeave={(e) => {
                              if (selectedMenu !== "Login") {
                                e.target.style.color = "inherit";
                              }
                            }}
                          >
                            Login
                          </span>
                        </i>
                      </li>
                    )}
                  </ul>
                </nav>
              )}
            </div>
            {showSidebar && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#fff",
                  zIndex: 999,
                  boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <div style={{ textAlign: "left", flex: 1 }}>
                    <a className="logo" onClick={homepageNav}>
                      <img
                        src={jrdc_Logo}
                        data-at2x="img/logo@2x.png"
                        alt="jrdc_Logo"
                        style={{ width: "50px", height: "auto" }}
                      />
                    </a>
                  </div>
                  <div
                    style={{ flex: 1, textAlign: "left", marginLeft: "-20px" }}
                  >
                    <span
                      style={{
                        fontSize: "26px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      JRDC
                    </span>
                  </div>
                  <button
                    className="close-button"
                    onClick={toggleSidebar}
                    style={{
                      fontSize: "32px",
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",
                      outline: "none",
                      marginRight: "10px",
                    }}
                  >
                    &times;
                  </button>
                </div>
                <hr />
                <nav>
                  {menus.map((menu, index) => (
                    <div key={menu.id}>
                      {menu.url || (menu.page && menu.page.url) ? (
                        <a
                          href={menu.url || (menu.page && menu.page.url)}
                          style={{
                            border: "none",
                            background: "transparent",
                            cursor: "pointer",
                            textAlign: "left",
                            padding: "10px",
                            fontSize: "16px",
                            display: "block",
                            color:
                              selectedMenu === menu.url ? "blue" : "inherit",
                          }}
                        >
                          {menu.name}
                        </a>
                      ) : (
                        <>
                          <button
                            onClick={() => {
                              // Toggle visibility of submenu
                              menu.showChildren = !menu.showChildren;
                              setMenus([...menus]);
                            }}
                            style={{
                              border: "none",
                              background: "transparent",
                              cursor: "pointer",
                              textAlign: "left",
                              padding: "10px",
                              fontSize: "16px",
                              display: "block",
                              marginBottom:
                                index !== menus.length - 1 ? "10px" : "0",
                              color: isMenuSelected(menu) ? "blue" : "inherit",
                            }}
                          >
                            {menu.name}
                          </button>
                          {menu.showChildren && menu.child_menus && (
                            <ul
                              className="submenu"
                              style={{
                                paddingLeft: "20px",
                                listStyleType: "none",
                              }}
                            >
                              {menu.child_menus.map((child) => (
                                <div
                                  key={child.id}
                                  style={{ marginBottom: "20px" }}
                                >
                                  <span
                                    onClick={() =>
                                      handleNavigation(
                                        child.url ||
                                          (child.page && child.page.url)
                                      )
                                    }
                                    style={{
                                      cursor: "pointer",
                                      color:
                                        selectedMenu ===
                                        (child.url ||
                                          (child.page && child.page.url))
                                          ? "blue"
                                          : "inherit",
                                    }}
                                  >
                                    {child.name}
                                  </span>
                                </div>
                              ))}
                            </ul>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                  {isLoggedIn ? (
                    <span
                      onClick={() =>
                        handleNavigation("MyAccount", toggleSidebar)
                      }
                      style={{
                        border: "none",
                        background: "transparent",
                        cursor: "pointer",
                        textAlign: "left",
                        padding: "10px",
                        fontSize: "16px",
                        display: "block",
                        marginBottom: "10px",
                        marginTop: "20px",
                        color:
                          selectedMenu === "MyAccount" ? "blue" : "inherit",
                      }}
                    >
                      My Account
                    </span>
                  ) : (
                    <span
                      onClick={() => handleNavigation("Login", toggleSidebar)}
                      style={{
                        border: "none",
                        background: "transparent",
                        cursor: "pointer",
                        textAlign: "left",
                        padding: "10px",
                        fontSize: "16px",
                        display: "block",
                        marginTop: "20px",
                        color: selectedMenu === "Login" ? "blue" : "inherit",
                      }}
                    >
                      Login
                    </span>
                  )}
                </nav>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
