import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import Link from "antd/es/typography/Link";
import otp from "./../../img/pattern/otp.png";
import { getCode } from "./../../Api/index";
import { verifyCode } from "./../../Api/index";
import { resetPassword } from "./../../Api/index";

const LostPassword = ({ onBackToLogin }) => {
  const [form] = Form.useForm();
  const [submitted, setSubmitted] = useState(false);
  const [verification, setVerification] = useState(false);
  const [digitInputs, setDigitInputs] = useState(Array(6).fill(""));
  const [codeinput, setInputs] = useState([]);
  const [loading, setLoading] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleSendVerificationCode = async (email) => {
    setLoading(true);
    try {
      await getCode(email);
      setSubmitted(true);
    } catch (error) {
      message.error("Failed to send verification code. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const backtoVerifyMail = () => {
    setSubmitted(false);
  };

  const handleChange = (index, value) => {
    const updatedInputs = [...digitInputs];
    updatedInputs[index] = value;
    setDigitInputs(updatedInputs);

    const isFilled = updatedInputs.every((input) => input !== "");
    if (isFilled) {
      handleVerifyCode(updatedInputs);
    }
  };

  const handleVerifyCode = async (inputs) => {
    const code = inputs.join("");
    setInputs(code);
    setLoading(true);
    try {
      await verifyCode(code);
      setVerification(true);
      message.success("Code verified successfully!");
    } catch (error) {
      message.error("Failed to verify code. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const backtoVerifyCode = () => {
    setVerification(false);
  };

  const onSubmit = async (values) => {
    const { password, confirmPassword } = values;
    const code = codeinput;
    setLoading(true);
    try {
      await resetPassword(code, password, confirmPassword);
      message.success("Password reset successful!");
      setSubmitted(false);
      setVerification(false);
      onBackToLogin();
    } catch (error) {
      message.error("Failed to reset password. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (!submitted) {
    // Form 1
    return (
      <div>
        <Button onClick={onBackToLogin} icon={<ArrowLeftOutlined />}>
          Back
        </Button>
        <h2
          style={{
            fontFamily: "Roboto",
            fontWeight: "bold",
            marginBottom: "10px",
            textAlign: "center",
            marginTop: "10px",
          }}
        >
          Reset Password
        </h2>
        <br />
        <p
          style={{
            fontFamily: "Roboto",
            textAlign: "justify",
            fontSize: "18px",
            marginBottom: "15px",
          }}
        >
          Enter your email address and we'll send you a verification code to
          reset your password.
        </p>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSendVerificationCode}
          style={{ marginBottom: "-25px" }}
        >
          <Form.Item
            name="email"
            label="Enter your Email"
            rules={[
              {
                required: true,
                message: "Please enter your email address.",
              },
              {
                type: "email",
                message: "Please enter a valid email address.",
              },
            ]}
          >
            <Input
              placeholder="Email address"
              style={{ width: isMobile ? "100%" : "500px" }}
            />
          </Form.Item>
          <br />
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="fos-btn-primary w-100 mt-2"
              style={{
                background: "#B9001F",
                borderColor: "#B9001F",
                width: isMobile ? "100%" : "40%",
                height: "40px",
              }}
              loading={loading}
            >
              Send Verification Code
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  } else if (!verification) {
    // Second Form
    return (
      <div>
        <Button onClick={backtoVerifyMail} icon={<ArrowLeftOutlined />}>
          Back
        </Button>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <img
            src={otp}
            alt="Mail Icon"
            style={{
              maxWidth: isMobile ? "250px" : "350px",
              maxHeight: isMobile ? "250px" : "350px",
              alignContent: "center",
              marginTop: isMobile ? "20px" : "25px",
              marginLeft: isMobile ? "-15px" : "20%",
            }}
          />
        </div>
        <h2
          style={{
            fontFamily: "Roboto",
            fontWeight: "bold",
            marginBottom: "10px",
            textAlign: "center",
            marginTop: "10px",
          }}
        >
          Check your Mail
        </h2>
        <br />
        <p
          style={{
            fontFamily: "Roboto",
            textAlign: "center",
            fontSize: "18px",
            marginBottom: "30px",
          }}
        >
          We have sent a verification code to your email.
        </p>
        <Form
          layout="inline"
          style={{
            display: "flex",
            justifyContent: "center",
            width: isMobile ? "120%" : "",
            marginLeft: isMobile ? "-20px" : "",
          }}
        >
          {digitInputs.map((input, index) => (
            <Form.Item key={index}>
              <Input
                maxLength={1}
                style={{
                  width: isMobile ? "15px" : "40px",
                }}
                value={input}
                onChange={(e) => {
                  handleChange(index, e.target.value);
                  if (e.target.value && index < digitInputs.length - 1) {
                    const nextInput = document.getElementById(
                      `input-${index + 1}`
                    );
                    if (nextInput) {
                      nextInput.focus();
                    }
                  }
                }}
                id={`input-${index}`}
              />
            </Form.Item>
          ))}
        </Form>
        <br />
        <p style={{ textAlign: "center" }}>
          <Link style={{ fontSize: "18px" }} loading={loading}>
            {" "}
            Didn't get the code?{" "}
          </Link>
        </p>
      </div>
    );
  } else {
    // Form 3
    return (
      <div>
        <Button onClick={backtoVerifyCode} icon={<ArrowLeftOutlined />}>
          Back
        </Button>
        <h2
          style={{
            fontFamily: "Roboto",
            fontWeight: "bold",
            marginBottom: "10px",
            textAlign: "center",
            marginTop: "10px",
          }}
        >
          Create New Password
        </h2>
        <br />
        <p
          style={{
            fontFamily: "Roboto",
            textAlign: "center",
            fontSize: "18px",
            marginBottom: "30px",
          }}
        >
          Your new password must be different from the previous password.
        </p>
        <Form
          form={form}
          layout="vertical"
          style={{ width: isMobile ? "100%" : "100%", margin: "0 auto" }}
          onFinish={onSubmit}
        >
          <Form.Item
            label={
              <span style={{ fontFamily: "Roboto", fontSize: "14px" }}>
                <strong>New Password</strong>
              </span>
            }
            name="password"
            rules={[{ required: true, message: "Please enter your password." }]}
          >
            <Input.Password placeholder="Enter your new password" />
          </Form.Item>
          <Form.Item
            label={
              <span style={{ fontFamily: "Roboto", fontSize: "14px" }}>
                <strong>Confirm New Password</strong>
              </span>
            }
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              { required: true, message: "Please confirm your password." },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match.")
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm your new password" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="fos-btn-primary w-100 mt-2"
              style={{
                background: "#B9001F",
                borderColor: "#B9001F",
                width: isMobile ? "100%" : "40%",
                height: "40px",
              }}
              loading={loading}
            >
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
};

export default LostPassword;
