export const content = [
  {
    page: "My Account",
    path: "/Login",
    paragraphs: [
      {
        title: "Login",
        subTitle: "",
        text: "",
      },
      {
        title: "Register",
        subTitle: "",
        text: "A Facility request form must be completed and submitted to JRDC administration no later than 10 business days prior to the scheduled event. Please note that completion of this application does not guarantee approval. JRDC reserves the right to deny facility reservations on a case-by-case basis regarding appropriateness for the facility/building.",
      },
      {
        title: "",
        subTitle: "",
        text: "Requesting parties will receive an email notification that a request has been received and requests will be processed (confirmed or denied) within 3-5 business days. This notification does not guarantee that the space has been allocated. The requesting party will receive an approved copy of their space request via email which will confirm space allocation.",
      },
    ],
  },
  {
    page: "Homepage",
    path: "/",
    paragraphs: [
      {
        title: "Testing & Research",
        subTitle: "Robust and Innovative solutions for dynamic challenges",
        text: "Water sustainability comes with grand challenges that affect individuals and industries combined. These challenges require an integrated effort from water, environment, and health sciences.",
      },
      {
        title: "Facility Reservation",
        subTitle: "Research and advance in comfort",
        text: "JRDC is located in a vibrant and enriching environment. We house a number of remarkable facilities that are designed to accommodate and cater to your specific needs. The facilities are open for use on a scheduled basis.",
      },
      {
        title: "Consultancy & Technology",
        subTitle: "Shared vision for sustainability",
        text: "JRDC provides a range of services that cater to your need, our consultancy services are backed with the state-of-the-art technology to provide you the solution for the challenges. These treatments, assessments and studies helps sustain human health as water can contain contaminants that are harmful for humans.",
      },
      {
        title: "Programs",
        subTitle: "Learn, Train and Research with the best",
        text: "Our programs aim at encouraging and promoting advancement and development through collaboration and integration, we are a center for water technology development, knowledge generation and dissemination.",
      },
    ],
  },
  {
    page: "About Us",
    path: "/AboutUs",
    paragraphs: [
      {
        title: "",
        subTitle: "",
        text: "JRDC develops globally translatable solutions for regional water problems, by engaging diverse industrial, governmental, and community experts to apply the highest levels of science and engineering.",
      },
      {
        title: "",
        subTitle: "",
        text: "We provide a range of services, as research facilities, technology development, training, and knowledge dissemination and consultancy services, required in mobilizing towards a water-secure nation, in order to engage with and address evolving water challenges.",
      },
      {
        title: "",
        subTitle: "",
        text: "What sets us apart is the ability to provide scientific excellence to all customer’s needs while assuring environmental safety and sustainability. We prioritize sustainability, as we understand the impact on the future.",
      },
      {
        title: "Our Vision",
        subTitle: "",
        text: "Our Vision is “To be a global partner in water research offering a platform to promote North-South and South-South dialogues”",
      },
      {
        title: "Our Mission",
        subTitle: "",
        text: "Our Mission is “To facilitate researchers and professionals in the fields of water, health, environment and other related areas of the national interest to work together in performing advance research and disseminating new knowledge nationally and internationally.”",
      },
      {
        title: "Our Partners",
        subTitle: "",
        text: "",
      },
      {
        title: "Our History",
        subTitle: "",
        text: "In March 2015, a Memorandum of Understating (MoU) on Water Research and Technology was inked between the Chinese Academy of Sciences (CAS) and the Ministry of Water Supply of Sri Lanka, for the purpose of achieving the Sustainable Development Goal 06, which aims to ensure 100% safe drinking water supply throughout the nation by 2030. The goal didn’t just involve combating water scarcity but also addressing water stress, droughts, and increasing desertification, and the need for protection of water-related eco-systems. Water stress affects about 2 billion and is projected to increase yearly. Another main factor was water sanitation. The key objectives of this MoU are,",
        list: [
          "to identify the possible causing factors to the occurrence and distribution of the CKDu in Sri Lanka",
          "to develop groundwater treatment methods and technologies in CKDu affected regions in Sri Lanka",
          "to develop rainwater collection and use methods and technologies in Dry areas of Sri Lanka",
          "to help the government of Sri Lanka make an integrated water supply and sanitation infrastructure development plan, integrated water resource management plan, and land use plan.",
        ],
      },
      {
        title: "",
        subTitle: "",
        text: "These objectives are being executed through the China Sri Lanka Research Grant Project (CSLRGP) under the Ministry of Water Supply Sri Lanka that was initiated in 2016 and from the MoU the Joint Research and Demonstration Center for Water Technology (JRDC) was established. This was constructed in an area of 5000 sq.m under the Chinese grant of Rs. 1950 Mn together with GoSL funds worth Rs. 880 Mn.",
      },
      {
        title: "",
        subTitle: "",
        text: "This state-of-the-art research and demonstration facility accouters over 70 sophisticated analytical equipment, using high-end technology, worth Rs. 600 million excluding taxes. In addition, it provides facilities to conduct research, training, and technology development, piloting/demonstration, and to conduct and host national and international workshops, seminars, and conferences related to the provision of clean drinking water and addressing all aspects of the health issues arising from or suspected to be arising from drinking water environment.",
      },
      {
        title: "",
        subTitle: "",
        text: "In an era where water resources across the world are constantly being threatened by increasing populations, climate change, and pollution, the establishment of expertise centers as JRDC is expected to enmesh the research community to effectively overcome the emerging water challenges through sustainable solutions.",
      },
      {
        title: "",
        subTitle: "",
        text: "The center is extending the reach of the Sri Lankan water sector through extensive collaborative research, technology development, postgraduate educational programs, training and knowledge dissemination activities, institutional partnerships in Sri Lanka and abroad while promoting water sectors’ activity.",
      },
      {
        title: "",
        subTitle: "",
        text: "China Sri Lanka Research Grant Project",
      },
      {
        title: "Organizational Structure",
        subTitle: "",
        text: "JRDC organization structure",
      },
      {
        title: "Our Clients",
        subTitle: "",
        text: "",
      },
      {
        title: "Our Team",
        subTitle: "",
        text: "",
      },
    ],
  },
  {
    page: "Contact Us",
    path: "/ContactUs",
    paragraphs: [
      {
        title: "",
        subTitle: "",
        text: "Get in touch with us today about your Water Research & Testing needs.",
      },
      {
        title: "Joint Research and Demonstration Centre",
        subTitle: "",
        text: "E.O.E Pereira mawatha, Meewathura, Peradeniya.\n\nEmail: info@jrdc.lk\n\nPhone: 0812058116\n\nFax: 0812058116",
      },
    ],
  },
  {
    page: "MoUs Agreements",
    path: "/MoUsAgreements",
    paragraphs: [
      {
        title: "",
        subTitle: "",
        text: "The Joint Research & Demonstration Centre for Water Technology (JRDC) has come into reality after signing a Memorandum of Understanding (MoU) between the Ministry of Urban Development, Water Supply & Drainage (MUDWSD) and the Chinese Academy of Sciences (CAS) on March 26, 2015, in the presence of HE Presidents of the Democratic Socialist Republic of Sri Lanka and People’s Republic of China.",
      },
      {
        title: "",
        subTitle: "",
        text: "The aforementioned parties and other related Government Departments, research institutions, Universities of both countries have also entered into several other MoUs as National Water Supply & Drainage Board and Research Center for Eco-Environmental Sciences, Beijing, China on June 26, 2015; University of Peradeniya and Ministry of City Planning & Water Supply on December 9, 2016; National Science Foundation of Sri Lanka and National Natural Science Foundation, China on April 7, 2016; the University of Peradeniya and Chinese Academy of Sciences, China on December 16, 2016; Ministry of Health and Teaching Hospital Beijing, China on October 25, 2016, for the cooperation on Science, Technology, innovation, and capacity building which are focusing on seeking the cause of CKDu and ensuring safe water supply in Sri Lanka.",
      },
      {
        title: "",
        subTitle: "",
        text: "Moreover, an agreement is made based on the Memorandum of Understanding among the Ministry of City Planning & Water Supply, University of Peradeniya, and Chinese Academy of Sciences on establishing a management framework for the functioning of JRDC in September 28, 2018.",
      },
    ],
  },
  {
    page: "Publications & Downloads",
    path: "/PublicationsAndDownloads",
    paragraphs: [
      {
        title: "",
        subTitle: "",
        text: "Access our latest research publications and downloads via the following links",
      },
      {
        title: "",
        subTitle: "",
        text: "We are committed to making the results of our research easily accessible. It assists us in demonstrating the range and depth of our expertise, generating new research possibilities, and increasing the visibility and impact of our work. It not only ensures that we meet the needs of our research funders, but it also benefits society as a whole.",
      },
    ],
  },
  {
    page: "News",
    path: "/News",
    paragraphs: [],
  },
  {
    page: "Upcoming Events",
    path: "/UpcomingEvents",
    paragraphs: [],
  },
  {
    page: "Inventory",
    path: "https://inventory.jrdc.lk/login",
    paragraphs: [],
  },
  {
    page: "Vacancies",
    path: "/Vacancies",
    paragraphs: [],
  },
  {
    page: "Gallery",
    path: "/Gallery",
    paragraphs: [],
  },
  {
    page: "Library",
    path: "http://dlib.pdn.ac.lk/",
    paragraphs: [],
  },
  {
    page: "How to Reserve",
    path: "/HowtoReserve",
    paragraphs: [
      {
        title: "",
        subTitle: "",
        text: "JRDC accouters a number of facilities that account for every requirement that you may have, these facilities are designed specifically to cater to your needs and comfort. It aims to encourage development and growth through shared knowledge and collaborative research. Allowing the users to master, innovate, design, and refine the technology for water treatment. These facilities are located around a remarkable and vibrant environment that is capable of enriching the mind. The Facilities are open for use on a scheduled basis.",
      },
      {
        title: "",
        subTitle: "PROCEDURES",
        text: "A Facility request form must be completed and submitted to JRDC administration no later than 10 business days prior to the scheduled event. Please note that completion of this application does not guarantee approval. JRDC reserves the right to deny facility reservations on a case-by-case basis regarding appropriateness for the facility/building. Requesting parties will receive an email notification that the request has been received and requests will be processed (confirmed or denied) within 3-5 business days. This notification does not guarantee that the space has been allocated. The requesting party will receive an approved copy of their space request via email which will confirm space allocation.",
      },
      {
        title: "",
        subTitle: "",
        text: "JRDC accouters a number of facilities that accounts for every requirement that you may have, these facilities are designed specifically to cater to your needs and comfort. It aims to encourage development and growth through shared knowledge and collaborative research. Allowing the users to master, innovate, design and refine technology for water treatment. These facilities are located around a remarkable and vibrant environment that is capable of enriching the mind. The Facilities are open for use on a scheduled basis.",
      },
    ],
  },
  {
    page: "Lab Reservations",
    path: "/lab_reservations",
    paragraphs: [],
  },
  {
    page: "Facility Reservations",
    path: "/facility_reservations",
    paragraphs: [],
  },
  {
    page: "Testing And Research",
    path: "/TestingAndResearch",
    paragraphs: [
      {
        title: "",
        subTitle: "",
        text: "Water crisis is a serious issue that affects both industries and individuals both nationally and internationally, combating these issues involve collaborative efforts of water sciences, environmental sciences and health-related applied research. At JRDC, we provide a lucrative platform that enables us to create and construct innovative and robust solutions for these dynamic challenges. With our cutting-edge technology and analytical instruments, we can cater to a wide spectrum of water and environmental-related analytical requirements.",
      },
    ],
    cards: [
      {
        title: "Testing",
        paragraphs: [
          {
            subTitle: "",
            text: "Testing involves a process of analyzing a samples to detect contaminants and other harmful substances and organisms revolving around water and the environment, the results of the test will undergo a thorough examination that will prove the quality and underling issues. We can then work towards creating a solution that caters the situation.",
          },
        ],
        tiles: [],
      },
      {
        title: "General Testing",
        paragraphs: [
          {
            subTitle: "",
            text: "JRDC accouters a number of facilities that accounts for every requirement that you may have, these facilities are designed specifically to cater to your needs and comfort. It aims to encourage development and growth through shared knowledge and collaborative research. Allowing the users to master, innovate, design and refine technology for water treatment. These facilities are located around a remarkable and vibrant environment that is capable of enriching the mind. The Facilities are open for use on a scheduled basis.",
          },
        ],
        tiles: [
          {
            heading: "Groundwater",
            description:
              "Ground water contamination can adversely affect the quality of life in both community and economic development. In many countries, groundwater is the only economical water source available. Testing is crucial as many contaminants are undetectable, the tests help establish the quality and the economic value.",
          },
          {
            heading: "RainWater",
            description:
              "Rainwater testing aims at detecting contaminants mainly fecal matters of animals, as birds, rodents and other small animals usually leave their droppings off on the roofs and the water tank systems. The testing proves to be useful in urban areas due to the substantial amount of airborne contaminants present. These tests abide by the standards set by the Safe Drinking Water Act.",
          },
          {
            heading: "Surface Water",
            description:
              "Surface water refer to water bodies that are typically sensitive to the effects of contamination. The testing ensures drinking and bathing water quality and also, aims at biodiversity and ecosystem conservation. Contamination levels can vary based on the environment and anthropogenic activities such as usage of agricultural chemicals, industrial and commercial shipping and landfill",
          },
          {
            heading: "Bottled Water",
            description:
              "This test aims to determine the quality of bottled water, the purpose of these tests are to determine the type of contaminants that are present, in drinking water contaminants are considered as those substance in the water other than the H2o molecule. However not all these are undesirable, certain contaminants are preferable as it adds a unique taste, texture and body. The tests are conducted under the FDA and abide by the standards of the Safe Drinking Water Act.",
          },
          {
            heading: "Ocean Water (After Pre-Treatment)",
            description:
              "This test aims at determining the level of bacterial pollution as well as to test the salinity and temperate.",
          },
          {
            heading: "Soil Environment (After Pre-Treatment)",
            description:
              "This test is designed to help municipalities, farmers, environmental agencies, and others in developing environmentally sound soil management programs, it is designed to trace and monitor soil elements after they have been fixed with bio solids and other such materials. JRDC also test the fertility and quality of the soil.",
          },
          {
            heading: "Air Phase (After Pre-Treatment)",
            description:
              "Ground water contamination can adversely affect the quality of life in both community and economic development. In many countries, groundwater is the only economical water source available. Testing is crucial as many contaminants are undetectable, the tests help establish the quality and the economic value. \n \n Instructions for water sampling\n\n Customer support (Quarries on reports)",
          },
          {
            heading: "Medical Testing (After Pre-Treatment)",
            description:
              "This test is designed to determine the impact of the water in a medical standpoint, allowing clients to detect the conditions and the causes of those conditions. It helps to determine a diagnosis and plan the necessary treatments.",
          },
        ],
      },
      {
        title: "Extended Testing",
        paragraphs: [
          {
            subTitle: "",
            text: "JRDC conducts more highly sophisticated extensive testings such as:",
          },
        ],
        tiles: [
          {
            heading: "Pharmaceutical pollutants",
            description: "",
          },
          {
            heading: "Heavy Metal analysis",
            description: "",
          },
          {
            heading: "Agrochemicals",
            description: "",
          },
          {
            heading: "Persistent Organic Pollutants(POPs)",
            description: "",
          },
          {
            heading: "Cations and Anions in water",
            description: "",
          },
          {
            heading: "Particle Size Distribution",
            description: "",
          },
          {
            heading: "BET surface Area",
            description: "",
          },
          {
            heading: "Zeta Potential",
            description: "",
          },
          {
            heading: "Absorption spectroscopy",
            description: "",
          },
          {
            heading: "Natural gases and gaseous pollutants",
            description: "",
          },
          {
            heading: "CHN analysis",
            description: "",
          },
          {
            heading: "TOC analysis",
            description: "",
          },
        ],
      },
      {
        title: "Advance Instrumental Analysis",
        paragraphs: [
          {
            subTitle: "",
            text: "",
          },
        ],
        tiles: [
          {
            heading: "Particle Size Analysis",
            description: "",
          },
          {
            heading: "BET surface Analysis",
            description: "",
          },
          {
            heading: "Zeta Potential analysis",
            description: "",
          },
          {
            heading: "FTIR Analysis",
            description: "",
          },
          {
            heading: "UV-Visible Spectrometer Analysis",
            description: "",
          },
          {
            heading: "Natural gas analysis",
            description: "",
          },
          {
            heading: "CHN analysis",
            description: "",
          },
          {
            heading: "TOC analysis",
            description: "",
          },
        ],
      },
      {
        title: "More Info",
        paragraphs: [
          {
            subTitle: "",
            text: "",
          },
        ],
        tiles: [],
      },
    ],
  },
  {
    page: "Research Projects",
    path: "/ResearchProjects",
    paragraphs: [],
  },
  {
    page: "Water Testing",
    path: "https://research.jrdc.lk/login-request",
    paragraphs: [],
  },
  {
    page: "Our Services",
    path: "/OurServices",
    paragraphs: [
      {
        title: "",
        subTitle: "",
        text: "JRDC conducts Water treatment/ Wastewater treatment/EIA (Environment Impact Assessment) /Environmental studies. These treatments, assessments and studies helps sustain human health as water can contain contaminants that are harmful for humans. Such treatments can benefit for the purpose of irrigation and heath.",
      },
      {
        title: "",
        subTitle: "",
        text: "JRDC invites researchers to execute and conduct projects on developing, building and testing leading-edge water treatment facilities, in these premises. Challenges and problems occurring during the process of sustaining water involves an integrated and collaborative effort of high level sciences and engineering. It involves a whole-system approach that requires for an integrated understanding of theory and practicality with a combined effort to redefine and innovate of technology.",
      },
    ],
  },

  {
    page: "Focus Areas",
    path: "/FocusAreas",
    cards: [
      {
        title: "1.0 Water Treatment",
        paragraphs: [
          {
            subTitle: "",
            text: "Water treatment is essential for ensuring that water is suitable for industries and individuals. The process is designed to remove the contaminants in the water to make it safer for use. Optimum use of water covers both the conservation and the efficiency. At JRDC we provide reliable, robust and sustainable technology for water treatment and design and ensure refined technology, economic viability and environmentally sound engineering solutions to create custom-tailored solutions to match each and every individual’s needs and requirements. Key features include: Provide Reliable, Robust and Sustainable technology for water treatment, Design and ensure refined technology, economic viability and environmentally sound engineering solutions, Create custom-tailored solutions to match the individual’s requirements.",
          },
        ],
        tiles: [],
      },
      {
        title: "Design",
        paragraphs: [
          {
            subTitle: "",
            text: "JRDC will assist and provide you Process design, preparation of BoQs, Cost estimation, CAD drawing (Civil, Mechanical and Electrical)",
          },
        ],
        tiles: [],
      },
      {
        title: "Optimization",
        paragraphs: [
          {
            subTitle: "",
            text: "JRDC facilities and optimize water treatment-related works through research and by our experienced team",
          },
        ],
        tiles: [],
      },
      {
        title: "2.0 Industrial/Non-industrial Wastewater Treatment",
        paragraphs: [
          {
            subTitle: "",
            text: "The assumption that all wastewater ends in sewage treatment plants (STP) is faulty as in our national boundary, almost more than 100,000 cubic meters of untreated sewage are discharged into the ocean through two pipes that extend about 1.5 kilometers into the sea. While industrial wastewater contains pollutants at levels that may affect receiving waters or interfere with publicly owned treatment works (POTWs) that receive those discharges. JRDC aims to solve and overcome these challenges as we provide Reliable, Robust and Sustainable technology for wastewater treatment, recycling and reuse. We also design and ensure refined technology, economic viability and environmentally sound engineering solutions. We tailor our solutions according to our customers’ requirements and needs and making sure to abide by our governments standards.",
          },
        ],
        tiles: [
          {
            heading: "Design",
            description:
              "JRDC will Provide SBR, MBR and other related wastewater treatment system design and consultancy to meet environmental regulations.",
          },
          {
            heading: "Troubleshooting",
            description:
              "JRDC technology team is fully dedicated to narrow down the water quality and process issues related to your wastewater treatment.",
          },
          {
            heading: "Optimization",
            description:
              "JRDC facilities and optimize wastewater treatment-related works through research and by our experienced team",
          },
        ],
      },
      {
        title: "3.0 Groundwater Exploration",
        tiles: [
          {
            heading: "Hydro-Geological Investigation",
            description: "",
          },
          {
            heading: "Remote Sensing and GIS Studies for groundwater",
            description: "",
          },
          {
            heading: "Bore Well Construction and Design",
            description: "",
          },
          {
            heading: "Groundwater and Salinity  Management and Assessment",
            description:
              "- Resistivity survey - Yield testing - Troubleshooting",
          },
        ],
      },
      {
        title: "4.0 Rainwater Harvesting",
        tiles: [
          {
            heading: "Technology Adaptation",
            description: "",
          },
          {
            heading: "Design",
            description: "",
          },
          {
            heading: "Troubleshooting",
            description: "",
          },
        ],
      },
      {
        title: "5.0 Conducting EIA and associated services",
        tiles: [
          {
            heading: "Environmental impact assessment (EIA)",
            description: "",
          },
          {
            heading: "Strategic environmental assessment (SEA)",
            description: "",
          },
          {
            heading: "Environmental and Social Impact Assessment (ESIA)",
            description: "",
          },
          {
            heading: "Environmental Statement reviews",
            description: "",
          },
          {
            heading: "Stakeholder and Public Consultation",
            description: "",
          },
          {
            heading: "EIA screening and scoping",
            description: "",
          },
          {
            heading: "Environmental risk assessment",
            description: "",
          },
        ],
      },
      {
        title: "6.0 Water Safety Plan",
        tiles: [
          {
            heading: "Urban WSP",
            description: "",
          },
          {
            heading: "Rural WSP",
            description: "",
          },
          {
            heading: "Climate-resilient WSP",
            description: "",
          },
          {
            heading: "Auditing",
            description: "- Training - Implementation - Auditing",
          },
        ],
      },
      {
        title: "7.0 Laboratory development",
        tiles: [
          {
            heading: "Instrument Maintenance",
            description: "",
          },
          {
            heading: "Planning and Design",
            description: "",
          },
          {
            heading: "Troubleshooting",
            description: "",
          },
          {
            heading: "Method Developments",
            description: "",
          },
        ],
      },
    ],
  },
  {
    page: "Programs",
    path: "/programs",
    paragraphs: [
      {
        title: "",
        subTitle: "",
        text: "JRDC is a center for water technology development, knowledge generation, and dissemination, adhering to both the local and international standards. We encourage advancement and development through integrated efforts and combined research in the fields of sciences and engineering. We are blessed with advanced state-of-the-art technology and instrumentation. At JRDC, we house and work with highly competent resource personnel that have vast dynamic knowledge in the practical and theoretical uses in their respective fields.",
      },
      {
        title: "",
        subTitle: "",
        text: "Our institution guarantees a safe, hands-on learning and growth opportunity that can help build capacity among the professionals. Our programs consists of a wide array of training programs, workshops and seminars that will allow you the dual benefit of experience and knowledge, which can definitely help in future career growth and development.",
      },
      {
        title: "",
        subTitle: "Training (Career Development)",
        text: "The aim of the training programs is to allow for a hands-on experience in advancing in research and knowledge dissemination. Students and researchers can grow both in terms of analytical thinking and refining and advancing technological capabilities. This can help in garnering experience and career development.",
        list: [
          "Instrumentation",
          "WSP",
          "Climate resilient",
          "Water Treatment",
          "Wastewater Treatment",
          "Groundwater Exploration",
          "Bottled water",
          "Laboratory Practices",
          "Tube well construction",
        ],
      },
    ],
  },
  {
    page: "Alumni",
    path: "/Alumni",
    paragraphs: [],
  },
  {
    page: "Student Stories",
    path: "/StudentStories",
    paragraphs: [],
  },
  {
    page: "Research Assistantships",
    path: "/ResearchAssistantships",
    paragraphs: [],
  },
  {
    page: "Research Paper Submission",
    path: "/SubmitResearchPaper",
    paragraphs: [],
  },
  {
    page: "Conferences",
    path: "/Conferences",
    paragraphs: [],
  },
];
