import React, { useEffect, useRef, useState } from "react";
import FormInput from "../Components/form/FormInput";
import {
  // DatePicker,
  TimePicker,
  Form,
} from "antd";
import AuditoriumP from "../../src/img/pattern/AuditoriumP.jpg";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import "../../src/styles/main.css";
import { TimePickerComponent } from "@syncfusion/ej2-react-calendars";
import dayjs from "dayjs";
// import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import DateTimePicker from "../Components/common/DateTimePicker.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setHours, setMinutes } from "date-fns";
import FormSelect from "../Components/form/FormSelect.js";
import { ADURATION } from "../Constants.js";
const AuditoriumsPage = () => {
  const [cost, setCost] = useState(30000);

  const [baseCost, setBaseCost] = useState(30000);
  const [form] = Form.useForm();

  const handleDurationChange = (value) => {
    const additionalCostPerHour = 20000;
    const additionalCost = additionalCostPerHour * value;

    const totalCost = baseCost + additionalCost;

    setCost(totalCost);
  };

  const [startDate, setStartDate] = useState(
    setHours(setMinutes(new Date(), 0), 9)
  );
  return (
    <Page>
      <Navbar />
      <div class="page-content woocommerce">
        <div class="container clear-fix">
          <div class="grid-col-row">
            <div class="grid-col grid-col-12">
              <div role="main">
                <div
                  itemscope=""
                  itemtype="http://schema.org/Product"
                  class="product"
                >
                  <div class="images">
                    <div class="picture">
                      <img
                        src={AuditoriumP}
                        data-at2x="http://placehold.it/370x370"
                        class="attachment-shop_single"
                        alt=""
                        title=""
                      />
                      <div class="hover-effect"></div>
                      <div class="link-cont">
                        <a
                          href="http://placehold.it/370x370"
                          class="fancy fa fa-eye woocommerce-main-image zoom"
                          data-fancybox-group="gallery"
                          title=""
                          data-rel="prettyPhoto[product-gallery]"
                        ></a>
                      </div>
                    </div>
                    <div class="thumbnails">
                      <div class="owl-carousel">
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src="http://placehold.it/116x116"
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src="http://placehold.it/116x116"
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src="http://placehold.it/116x116"
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                        <div class="pic thumbnail">
                          <a
                            href="http://placehold.it/116x116"
                            class="zoom first fancy"
                            data-fancybox-group="gallery"
                            title=""
                            data-rel="prettyPhoto[product-gallery]"
                          >
                            <div class="hover-effect"></div>
                            <img
                              src={AuditoriumP}
                              data-at2x="http://placehold.it/116x116"
                              class="attachment-shop_thumbnail"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="summary entry-summary">
                    <h2 class="product_title entry-title">Auditorium</h2>

                    <div itemprop="description">
                      <p>
                        <strong>Price Varies - 4 hours</strong>
                        <br />
                        The default booking will be four (04) hours . Add more
                        no. of additional hours as per your requirement in the
                        below section. A maximum of 8 hours is allowed per day
                        and only one day can be booked in a single entry
                      </p>
                    </div>
                    <Form
                      name="basic"
                      autoComplete="off"
                      layout="vertical"
                      form={form}
                    >
                      <div className="col-md-3">
                        <Form.Item label="" name="date">
                          {/* <DatePicker /> */}
                          {/* <TimePicker
                          disabledHours={() => [
                            0, 1, 2, 3, 4, 5, 6, 7, 8, 11, 12, 13, 14, 15, 16,
                            17, 18, 19, 20, 21, 22, 23,
                          ]}
                          disabledMinutes={() => [
                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                            16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
                            29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
                            42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
                            55, 56, 57, 58, 59,
                          ]}
                          disabledSeconds={() => [
                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                            16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
                            29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
                            42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
                            55, 56, 57, 58, 59,
                          ]}
                        />
                        <TimePicker
                          defaultValue={dayjs("09:00", format)}
                          format={format}
                          disabledHours={() => [
                            0, 1, 2, 3, 4, 5, 6, 7, 8, 11, 12, 13, 14, 15, 16,
                            17, 18, 19, 20, 21, 22, 23,
                          ]}
                          disabledMinutes={() => [
                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                            16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
                            29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
                            42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
                            55, 56, 57, 58, 59,
                          ]}
                        /> */}
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            minTime={setHours(setMinutes(new Date(), 0), 9)}
                            maxTime={setHours(setMinutes(new Date(), 30), 10)}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeIntervals={60}
                            // inline
                          />
                        </Form.Item>
                        {/* <TimePickerComponent
                        placeholder="Select a time"
                        value={timeValue}
                        min={minTime}
                        max={maxTime}
                        // format="HH:mm"
                        step={60}
                      ></TimePickerComponent> */}
                        {/* <TimePicker
                        onChange={onChange}
                        value={value}
                      ></TimePicker> */}
                        <FormSelect
                          label="Number of additional hours (+රු 20.000,00) +4 hours"
                          name="duration"
                          options={ADURATION}
                          setVal={(value) => handleDurationChange(value)}
                        />
                        {/* <FormInput
                          label="Number of additional hours (+රු 20.000,00) +4 hours "
                          name="Additional_Days"
                          type="number"
                        /> */}
                      </div>
                    </Form>
                    <div className="total">Cost: {cost}</div>
                    {/* <div class="main-features">
                        <p class="head">
                          <strong>Main features:</strong>
                        </p>
                        <div class="features">
                          <div>
                            <i class="minus">—</i> Aliquam justo lorem, commodo
                            eget tristique non sore cotoad
                          </div>
                          <div>
                            <i class="minus">—</i> Curabitur vehicula leo
                            accumsan, varius tellus vitae mui uno{" "}
                          </div>
                          <div>
                            <i class="minus">—</i> Pellentesque imperdiet, leo
                            ut pulvinar facilisis dorto marstro
                          </div>
                          <div>
                            <i class="minus">—</i> Curabitur ullamcorper
                            ultricies nisi. Nam eget dui, logkosto{" "}
                          </div>
                          <div>
                            <i class="minus">—</i> Massa nec vulputate congue,
                            dolor erat ullamcorper, joto
                          </div>
                        </div>
                      </div> */}
                    <form class="cart" method="post">
                      {/* <div class="quantity buttons_added">
                        <input
                          type="number"
                          step="1"
                          min="1"
                          name="quantity"
                          value="1"
                          title="Qty"
                          class="input-text qty text"
                        />
                      </div> */}
                      <a
                        href="/shopCart"
                        class="cws-button alt border-radius small bt-color-3"
                      >
                        CHECK AVAILABILITY
                      </a>
                    </form>
                  </div>
                  <hr class="divider-color" />
                  <div class="tabs">
                    <div class="block-tabs-btn clear-fix">
                      <div class="tabs-btn active" data-tabs-id="tabs1">
                        Description
                      </div>
                    </div>
                    <div class="tabs-keeper">
                      <div
                        class="container-tabs active"
                        data-tabs-id="cont-tabs1"
                      >
                        <p>
                          <strong>Auditorium Details</strong>
                        </p>
                        <p>
                          The JRDC offers exquisite conference facilities for
                          training and other related programs. The
                          State-of-the-art auditorium is fully air-conditioned
                          and equipped with audio-visual equipment and internet
                          facilities to meet international standards. It’s
                          designed for the purposes of encouraging discussion,
                          to showcase research data and technology and many such
                          more enticing and enriching activities.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </Page>
  );
};

export default AuditoriumsPage;
