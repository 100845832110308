import "./UserLayout.css";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Page from "./Page";
import Footer from "./Footer";
import { dashboardReducer, initialState } from "./slice/dashboard/reducer";
import { useEffect, useReducer } from "react";
import CustomContext from "./slice/dashboard/context";

function UserLayout({ children }) {
  const [content, dispatch] = useReducer(dashboardReducer, initialState);

  const providerState = { content, dispatch };

  useEffect(() => {
    return () => {
      localStorage.removeItem("activeSidebarItem");
    };
  }, []);

  return (
    <CustomContext.Provider value={providerState}>
      <Page>
        <div className="layout-container">
          <Navbar />
          <div className="">
            <h2 className="title">{content.name}</h2>
          </div>
          <div className="inner-layout">
            <Sidebar />
            {children}
          </div>
        </div>
        <div className="footer">
          <Footer />
        </div>
      </Page>
    </CustomContext.Provider>
  );
}

export default UserLayout;
