import { Alert, Button, Col, Form, Input, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useState } from "react";
import { register } from "../../Api";
import Captcha from "./../common/Captcha";

const RegisterForm = ({
  setUser,
  setShowRegisterForm,
  setCheckoutFormFields,
  setIsLoggedIn,
  setLoading,
}) => {
  const [registerform] = useForm();
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [company, setCompany] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [addressline1, setAddressline1] = useState("");
  const [addressline2, setAddressline2] = useState("");
  const [phone, setPhone] = useState(0);
  const [postcode, setPostcode] = useState(0);
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const handleRegister = (values) => {
    const { ...newValues } = values;
    newValues.bill_email = values.email;

    console.log(newValues);
    setLoading(true);

    register(newValues)
      .then((response) => {
        window.localStorage.setItem(
          "jrdc_user",
          JSON.stringify(response.data.user)
        );
        window.localStorage.setItem("token", response.data.token);
        window.localStorage.setItem(
          "session_expiry_time",
          response.data.token_expires_at_with_timezone
        );
        window.localStorage.setItem(
          "session_expire_at",
          response.data.token_expires_at
        );
        window.localStorage.setItem("timezone", response.data.timezone);
        setUser(response.data.user);
        setCheckoutFormFields(response.data.user.customer);
        setIsLoggedIn(true);
        setShowRegisterForm(false);
      })
      .catch((e) => {
        alert(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("The two passwords do not match!"));
    },
  });

  return (
    <>
      <h3 style={{ color: "#B9001F", fontWeight: "bold" }}>Register</h3>
      <Form
        form={registerform}
        layout="vertical"
        onFinish={handleRegister}
        isFieldsChanged={isFieldsChanged}
        setFieldsChanged={setFieldsChanged}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              className="w-100  p-2"
              label="Username"
              name="username"
              required={true}
              requiredMark
            >
              <Input
                value={username}
                onChange={(e) => {
                  setUsername(e?.target?.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              className="w-100  p-2"
              label="Name"
              name="name"
              required={true}
              requiredMark
            >
              <Input
                value={name}
                onChange={(e) => {
                  setName(e?.target?.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              className="w-100  p-2"
              label="Email address"
              name="email"
              required={true}
              requiredMark
            >
              <Input
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e?.target?.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              className="w-100 p-2"
              label="Password"
              name="password"
              required={true}
              requiredMark
            >
              <Input.Password
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e?.target?.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Confirm Password"
              name="password_confirmation"
              rules={[
                { required: true, message: "Please confirm your password." },
                validateConfirmPassword,
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="First Name"
              name={"first_name"}
              required
              requiredMark
            >
              <Input
                value={firstname}
                onChange={(e) => {
                  setFirstname(e?.target?.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Last Name"
              name={"last_name"}
              required
              requiredMark
            >
              <Input
                value={lastname}
                onChange={(e) => {
                  setLastname(e?.target?.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item label="Company name (optional)" name={"company"}>
              <Input
                value={company}
                onChange={(e) => {
                  setCompany(e?.target?.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Apartment, suite, unit, etc. (optional)"
              name={"address_line1"}
              required
              requiredMark
            >
              <Input
                value={addressline1}
                onChange={(e) => {
                  setAddressline1(e?.target?.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Street address"
              name={"address_line2"}
              required
              requiredMark
            >
              <Input
                value={addressline2}
                onChange={(e) => {
                  setAddressline2(e?.target?.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item label="Town / City" name={"city"} required requiredMark>
              <Input
                value={city}
                onChange={(e) => {
                  setCity(e?.target?.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item label="State" name={"state"} required requiredMark>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Country / Region"
              name={"country"}
              required
              requiredMark
            >
              <Input
                value={country}
                onChange={(e) => {
                  setCountry(e?.target?.value);
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            {" "}
            <Form.Item
              label="Postcode / ZIP"
              name={"postcode"}
              required
              requiredMark
            >
              <Input
                type="number"
                maxLength={5}
                value={postcode}
                onChange={(e) => {
                  setPostcode(e?.target?.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Phone Number"
              name={"phone"}
              required
              requiredMark
            >
              <Input
                type="tel"
                maxLength={10}
                value={phone}
                onChange={(e) => {
                  setPhone(e?.target?.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Captcha onChange={setCaptchaVerified} />
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={24} md={12} lg={8}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={
                  captchaVerified
                    ? {
                        background: "#B9001F",
                        borderColor: "#B9001F",
                      }
                    : {
                        background: "#B9001F10",
                        borderColor: "#B9001F05",
                      }
                }
                disabled={!captchaVerified}
              >
                register
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default RegisterForm;
