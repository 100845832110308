import React, { useState, useEffect } from "react";
import { Typography, Row, Col } from "antd";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import UoP from "../img/pattern/UoP.png";
import CAS from "../img/pattern/CAS.png";
import RCEES from "../img/pattern/RCEES.jpg";
import NWSDB from "../img/pattern/NWSDB.png";
import MWS from "../img/pattern/MWS.png";
import MoH from "../img/pattern/MoH.png";
import OrgStructure from "../img/pattern/OrgStructure.jpg";
import History from "../img/pattern/History.jpg";
import DNCWS from "../img/pattern/DNCWS.png";
import NIFS from "../img/pattern/NIFS.png";
import NARA from "../img/pattern/NARA.jpg";
import KDU from "../img/pattern/KDU.png";
import UCAS from "../img/pattern/UCAS.png";
import { getPageById } from "../Api/index";
import "./../styles/pageTitle.css";

import { useMediaQuery } from "react-responsive";

const { Title, Text } = Typography;

const AboutUs = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTab = useMediaQuery({ minWidth: 768, maxWidth: 1024 });

  const [membersData, setMembersData] = useState([]);

  const subTitleStyle = {
    color: "black",
    fontSize: "38px",
    fontFamily: "Roboto, sans-serif",
    textAlign: "left",
    marginTop: "60px",
    marginBottom: "40px",
    display: "block",
  };

  const cardStyle = {
    border: "1px solid #C8C8C8",
    padding: "10px",
    minWidth: isMobile ? "100%" : "650px",
    fontFamily: "Roboto",
    fontSize: isMobile ? "16px" : "18px",
    backgroundColor: "#2F5597",
    color: "#FFFFFF",
    marginBottom: "20px",
    textAlign: "justify",
  };

  const textStyle = {
    color: "black",
    fontSize: "16px",
    fontFamily: "Roboto, sans-serif",
    textAlign: "justify",
    marginBottom: "20px",
    display: "block",
    width: "100%",
  };

  const partnerLogoStyle = {
    display: "block",
    margin: "10px auto",
    maxWidth: "100%",
    height: isMobile ? "100px" : "150px",
    objectFit: "contain",
  };

  const partnerNameStyle = {
    textAlign: "center",
    fontSize: "16px",
    fontFamily: "Roboto, sans-serif",
    marginTop: "8px",
    marginBottom: "30px",
  };

  const listStyle = {
    fontFamily: "Roboto, sans-serif",
    color: "black",
    width: "100%",
    fontWeight: " normal",
  };

  const OurPartners = [
    {
      imagePath: UoP,
      title: "UoP",
    },
    {
      imagePath: CAS,
      title: "CAS",
    },
    {
      imagePath: RCEES,
      title: "RCEES",
    },
    {
      imagePath: NWSDB,
      title: "NWSDB",
    },
    {
      imagePath: MWS,
      title: "MWS",
    },
    {
      imagePath: MoH,
      title: "MoH",
    },
  ];

  const OurClients = [
    {
      imagePath: DNCWS,
    },
    {
      imagePath: NIFS,
    },
    {
      imagePath: NARA,
    },
    {
      imagePath: KDU,
    },
    {
      imagePath: UCAS,
    },
  ];

  const memberCardStyle = {
    position: "relative",
    overflow: "hidden",
    //objectFit: "contain",
  };

  const memberImageStyle = {
    width: "100%",
    height: "320px",
    // objectFit: "contain",
  };

  const memberInfoStyle = {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "80px",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "#FFFFFF",
    paddingTop: "10px",
    textAlign: "center",
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Pass 0 values for pagination parameters
        const response = await getPageById("3", 0, 0);
        setMembersData(response);
      } catch (error) {
        console.error("Error fetching Team Members:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1)); // Remove the '#' and get the element
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <>
      <Page>
        <Navbar />
        <div className="page-content woocommerce">
          <div className="container clear-fix">
            <div className="grid-col-row">
              <div className="site-card-border-less-wrapper">
                <div className="grid-col grid-col-12">
                  <div className="col2-set" id="customer_details">
                    <h1 className="section-title">About Us</h1>
                    <br />
                    <Row gutter={[32, 32]} style={{ width: "100%" }}>
                      <Col xs={24} md={24} lg={12}>
                        <div>
                          <Text style={textStyle}>
                            JRDC develops globally translatable solutions for
                            regional water problems, by engaging diverse
                            industrial, governmental, and community experts to
                            apply the highest levels of science and engineering.
                          </Text>
                          <Text style={textStyle}>
                            We provide a range of services, as research
                            facilities, technology development, training, and
                            knowledge dissemination and consultancy services,
                            required in mobilizing towards a water-secure
                            nation, in order to engage with and address evolving
                            water challenges.
                          </Text>
                          <Text style={textStyle}>
                            What sets us apart is the ability to provide
                            scientific excellence to all customer’s needs while
                            assuring environmental safety and sustainability. We
                            prioritize sustainability, as we understand the
                            impact on the future.
                          </Text>
                        </div>
                      </Col>
                      <Col xs={24} md={24} lg={12}>
                        <div>
                          <div style={cardStyle}>
                            <h3
                              style={{ color: "#FFFFFF", fontWeight: "bold" }}
                            >
                              Our Vision{" "}
                            </h3>
                            Our Vision is "To be a global partner in water
                            research offering a platform to promote North-South
                            and South-South dialogues"
                          </div>
                          <div style={cardStyle}>
                            <h3
                              style={{ color: "#FFFFFF", fontWeight: "bold" }}
                            >
                              Our Mission
                            </h3>
                            Our Mission is “To facilitate researchers and
                            professionals in the fields of water, health,
                            environment and other related areas of the national
                            interest to work together in performing advance
                            research and disseminating new knowledge nationally
                            and internationally.”
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br />
                    <h3 style={subTitleStyle}>Our Partners</h3>
                    <Row gutter={[12, 24]} justify="center">
                      {" "}
                      {OurPartners.map((partner, index) => (
                        <Col key={index} xs={24} sm={12} md={8} lg={6}>
                          <img
                            src={partner.imagePath}
                            alt={partner.title}
                            style={partnerLogoStyle}
                          />
                          <div style={partnerNameStyle}>{partner.title}</div>
                        </Col>
                      ))}
                    </Row>
                    <br />
                    <div
                      style={{
                        width: isMobile ? "95%" : isTab ? "90%" : "100%",
                      }}
                    >
                      <h3 style={subTitleStyle}>Our History</h3>
                      <Text style={textStyle}>
                        In March 2015, a Memorandum of Understating (MoU) on
                        Water Research and Technology was inked between the
                        Chinese Academy of Sciences (CAS) and the Ministry of
                        Water Supply of Sri Lanka, for the purpose of achieving
                        the Sustainable Development Goal 06, which aims to
                        ensure 100% safe drinking water supply throughout the
                        nation by 2030.
                      </Text>
                      <Text style={textStyle}>
                        The goal didn’t just involve combating water scarcity
                        but also addressing water stress, droughts, and
                        increasing desertification, and the need for protection
                        of water-related eco-systems. Water stress affects about
                        2 billion and is projected to increase yearly. Another
                        main factor was water sanitation.
                      </Text>
                      <Text style={textStyle}>
                        The key objectives of this MoU are,
                      </Text>
                      <Text style={textStyle}>
                        <ol type="number" style={{ marginBottom: "20px" }}>
                          <li style={listStyle}>
                            to identify the possible causing factors to the
                            occurrence and distribution of the CKDu in Sri Lanka
                          </li>
                          <li style={listStyle}>
                            to develop groundwater treatment methods and
                            technologies in CKDu affected regions in Sri Lanka
                          </li>
                          <li style={listStyle}>
                            to develop rainwater collection and use methods and
                            technologies in Dry areas of Sri Lanka
                          </li>
                          <li style={listStyle}>
                            to help the government of Sri Lanka make an
                            integrated water supply and sanitation
                            infrastructure development plan, integrated water
                            resource management plan, and land use plan.
                          </li>
                        </ol>
                      </Text>
                      <Text style={textStyle}>
                        These objectives are being executed through the China
                        Sri Lanka Research Grant Project (CSLRGP) under the
                        Ministry of Water Supply Sri Lanka that was initiated in
                        2016 and from the MoU the Joint Research and
                        Demonstration Center for Water Technology (JRDC) was
                        established. This was constructed in an area of 5000
                        sq.m under the Chinese grant of Rs. 1950 Mn together
                        with GoSL funds worth Rs. 880 Mn.
                      </Text>
                      <Text style={textStyle}>
                        This state-of-the-art research and demonstration
                        facility accouters over 70 sophisticated analytical
                        equipment, using high-end technology, worth Rs. 600
                        million excluding taxes. In addition, it provides
                        facilities to conduct research, training, and technology
                        development, piloting/demonstration, and to conduct and
                        host national and international workshops, seminars, and
                        conferences related to the provision of clean drinking
                        water and addressing all aspects of the health issues
                        arising from or suspected to be arising from drinking
                        water environment.
                      </Text>
                      <Text style={textStyle}>
                        In an era where water resources across the world are
                        constantly being threatened by increasing populations,
                        climate change, and pollution, the establishment of
                        expertise centers as JRDC is expected to enmesh the
                        research community to effectively overcome the emerging
                        water challenges through sustainable solutions.
                      </Text>
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "60px",
                          marginBottom: "60px",
                        }}
                      >
                        <img
                          src={History}
                          alt=""
                          class="Hcenter"
                          title="China Sri Lanka Research Grant Project"
                        />
                        <div
                          style={{
                            ...partnerNameStyle,
                            textAlign: "center",
                            marginTop: "20px",
                          }}
                        >
                          China Sri Lanka Research Grant Project
                        </div>
                      </div>
                      <Text style={textStyle}>
                        The center is extending the reach of the Sri Lankan
                        water sector through extensive collaborative research,
                        technology development, postgraduate educational
                        programs, training and knowledge dissemination
                        activities, institutional partnerships in Sri Lanka and
                        abroad while promoting water sectors’ activity.
                      </Text>
                      <h3 style={subTitleStyle}>Organizational Structure</h3>
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "20px",
                          marginBottom: "60px",
                        }}
                      >
                        <img
                          src={OrgStructure}
                          alt=""
                          class="Hcenter"
                          title="JRDC organization structure"
                        />
                        <div
                          style={{
                            ...partnerNameStyle,
                            textAlign: "center",
                            marginTop: "20px",
                          }}
                        >
                          JRDC organization structure
                        </div>
                      </div>
                    </div>
                    <h3 id="our-clients" style={subTitleStyle}>
                      Our Clients
                    </h3>
                    <Row
                      gutter={[12, 24]}
                      justify="center"
                      style={{ width: isMobile ? "100%" : "90%" }}
                    >
                      {" "}
                      {OurClients.map((client, index) => (
                        <Col key={index} xs={24} sm={12} md={12} lg={6}>
                          <img
                            src={client.imagePath}
                            alt={client.title}
                            style={partnerLogoStyle}
                          />
                        </Col>
                      ))}
                    </Row>
                    <h3 style={{ ...subTitleStyle, marginBottom: "60px" }}>
                      Our Team
                    </h3>
                    <Row
                      gutter={[24, 24]}
                      justify="center"
                      style={{
                        width: isMobile ? "100%" : "90%",
                        marginLeft: isMobile ? "" : isTab ? "" : "55px",
                      }}
                    >
                      {membersData.map((member) => (
                        <Col key={member.id} xs={24} sm={12} md={12} lg={6}>
                          <div style={memberCardStyle}>
                            <img
                              src={member.image_url}
                              alt={member.title}
                              style={memberImageStyle}
                            />
                            <div style={memberInfoStyle}>
                              <h5
                                style={{
                                  color: "#FFFFFF",
                                  fontFamily: "Roboto, sans-serif",
                                }}
                              >
                                {member.title}{" "}
                              </h5>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: member.body,
                                }}
                              ></div>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </Page>
    </>
  );
};

export default AboutUs;
