import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col } from "antd";
import { getProductMeetingDataById } from "../Api/index";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const { Meta } = Card;

const CourseLinksPage = () => {
  const { productId } = useParams();
  const [meetings, setMeetings] = useState([]);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const isDesktop = useMediaQuery({ minWidth: 1025 });

  useEffect(() => {
    fetchMeetingData();
  }, [productId]);

  const fetchMeetingData = async () => {
    try {
      const data = await getProductMeetingDataById(productId);
      setMeetings(data.meeting_data); // Update state with meeting_data array
    } catch (error) {
      console.error("Error fetching meeting data:", error);
    }
  };

  const formatDateTime = (dateTimeStr) => {
    const dateTime = new Date(dateTimeStr);
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return dateTime.toLocaleDateString("en-US", options);
  };

  const getColSpan = () => {
    if (isMobile) return 24;
    if (isTablet) return 16;
    return 12;
  };

  return (
    <div
      className="meetings-container"
      style={{
        padding: "20px",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      {meetings.length === 0 ? (
        <div
          style={{
            fontFamily: "Roboto",
            color: "black",
            fontSize: "20px",
          }}
        >
          No links available for this Course.
        </div>
      ) : (
        <Row gutter={[16, 16]}>
          {meetings.map((meeting, index) => (
            <Col key={index} span={getColSpan()}>
              <Card
                style={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  width: "100%",
                  marginLeft: isTablet ? "40px" : "",
                  minWidth: isMobile ? "" : "500px",
                }}
                bodyStyle={{
                  padding: "20px",
                }}
              >
                <Meta
                  title={
                    <span
                      style={{
                        fontSize: isMobile ? "18px" : "24px",
                        fontWeight: "bold",
                        color: "#333",
                      }}
                    >
                      {meeting.topic}
                    </span>
                  }
                  description={
                    <>
                      <hr />
                      <p
                        style={{
                          fontSize: isMobile ? "14px" : "16px",
                          marginBottom: "20px",
                          marginTop: "20px",
                        }}
                      >
                        <strong>Meeting Date:</strong>{" "}
                        {formatDateTime(meeting.start_time)}
                      </p>
                      <div style={{ marginTop: "20px" }}>
                        <p
                          style={{
                            fontSize: isMobile ? "14px" : "16px",
                            marginBottom: "20px",
                          }}
                        >
                          <strong>Add to:</strong>{" "}
                          <a
                            href={`http://www.google.com/calendar/event?action=TEMPLATE&text=${meeting.topic}&dates=${meeting.start_time}/${meeting.start_time}&details=Meeting&location=&trp=false&sprop=`}
                            style={{
                              fontSize: isMobile ? "14px" : "16px",
                              fontWeight: "bold",
                              color: "#0E71EB",
                              marginRight: "20px",
                              marginLeft: "10px",
                              textDecoration: "none",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Google Calendar
                          </a>
                          <strong>|</strong>{" "}
                          <a
                            href={`data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0APRODID:my-app%0ABEGIN:VEVENT%0ADTSTART:${meeting.start_time}%0ADTEND:${meeting.start_time}%0ASUMMARY:${meeting.topic}%0ADESCRIPTION:Meeting%0ALOCATION:%0AEND:VEVENT%0AEND:VCALENDAR%0A`}
                            style={{
                              fontSize: isMobile ? "14px" : "16px",
                              fontWeight: "bold",
                              color: "#0E71EB",
                              marginLeft: "20px",
                              textDecoration: "none",
                            }}
                            download={`${meeting.topic}_meeting.ics`}
                          >
                            iCal Export
                          </a>
                        </p>
                      </div>
                      <p
                        style={{
                          fontSize: isMobile ? "14px" : "16px",
                          marginBottom: "20px",
                        }}
                      >
                        <strong>Meeting Duration:</strong> {meeting.duration}{" "}
                        Minutes
                      </p>
                      <p
                        style={{
                          fontSize: isMobile ? "14px" : "16px",
                          marginBottom: "20px",
                        }}
                      >
                        <strong>Meeting ID:</strong> {meeting.meeting_id}
                      </p>
                      <p
                        style={{
                          fontSize: isMobile ? "14px" : "16px",
                          marginBottom: "20px",
                        }}
                      >
                        <strong>Password:</strong> {meeting.password}
                      </p>
                      <Button
                        type="primary"
                        href={meeting.join_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          width: "100%",
                          maxWidth: isMobile ? "120px" : "150px",
                          height: "40px",
                          fontSize: isMobile ? "14px" : "16px",
                        }}
                      >
                        Join Meeting
                      </Button>
                    </>
                  }
                />
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

export default CourseLinksPage;
