import React, { useState, useEffect } from "react";
import { Button, Row, Pagination, Typography } from "antd";
import CommonCard from "../Components/common/CardComponent";
import Footer from "../Components/layouts/Footer";
import Navbar from "../Components/layouts/Navbar";
import Page from "../Components/layouts/Page";
import { useNavigate } from "react-router-dom";
import { getPageById } from "../Api/index";
import "./../styles/pageTitle.css";

const AlumniPage = () => {
  const navigate = useNavigate();
  const { Title } = Typography;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const pageSize = 6;

  const [alumniData, setAlumniData] = useState([]);

  useEffect(() => {
    const fetchData = async (page) => {
      try {
        const response = await getPageById("19", page, pageSize);
        setAlumniData(response.data);
        setTotalItems(response.total);
      } catch (error) {
        console.error("Error fetching Alumnis:", error);
      }
    };

    fetchData(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleAlumniClick = (alumniItemId) => {
    const clickedalumniItem = alumniData.find(
      (item) => item.id === alumniItemId
    );
    navigate(`/ViewAlumni`, { state: { alumniItem: clickedalumniItem } });
  };

  const showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total} items`;
  };

  return (
    <Page>
      <Navbar />
      <div className="page-wrapper">
        <div className="page-content woocommerce">
          <div className="container clear-fix">
            <div className="grid-col-row">
              <div className="site-card-border-less-wrapper">
                <div className="grid-col grid-col-12">
                  <div className="col2-set" id="customer_details">
                    <h1 className="section-title">Alumni</h1>
                    <br />
                    <CommonCard
                      data={alumniData}
                      onClick={(alumniItem) => handleAlumniClick(alumniItem)}
                    />
                    <Row justify="center" style={{ marginTop: "20px" }}>
                      <Pagination
                        current={currentPage}
                        pageSize={pageSize}
                        total={totalItems}
                        onChange={handlePageChange}
                        showTotal={showTotal}
                        style={{ marginBottom: "50px" }}
                      />
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Page>
  );
};

export default AlumniPage;
