import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";

const DateRangePickerComp = ({ onRangeSelected }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    const daysInRange = end
      ? Math.round((end - start) / (1000 * 60 * 60 * 24))
      : 0;
    onRangeSelected(daysInRange, start, end);
  };

  return (
    <DatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      minDate={new Date()}
      selectsRange
      dateFormat="yyyy/MM/dd"
      inline
    />
  );
};

export default DateRangePickerComp;
